<template>
    <div :class="{ 'onboarding-komponente': !istMobile, 'onboarding-komponente-mobile': istMobile }"
        @keydown.enter.prevent="weiter">
        <OnboardingTitel titel="Telefonnummer Angeben"
            untertitel="An diese Nummer senden wir Bestätigungscodes für Ihr Konto." />
        <div class="w-full h-full flex flex-col"
            :class="{ 'justify-start space-y-8': !istMobile, 'justify-between': istMobile }">
            <form @submit.prevent class="flex space-x-2" :class="{ 'w-96': !istMobile, 'w-full': istMobile }">
                <div class="w-28 h-14 relative">
                    <div class="absolute w-full h-full bg-TextField rounded-md flex justify-center items-center">
                        <p>{{ `+ ${nummerVorwahl.vorwahl}` }}</p>
                    </div>
                    <select v-model="nummerVorwahl" name="vorwahl" id="vorwahl"
                        class="absolute w-full h-full rounded-md flex justify-center items-start opacity-0 hover:cursor-pointer"
                        @change="nummerFokussieren">
                        <option v-for="vorwahl in vorwahlen" :key="vorwahl.vorwahl" :value="vorwahl">
                            {{ vorwahl.vorwahl }}
                        </option>
                    </select>
                </div>
                <input type="text" v-model="nummerText" ref="nummerRef" placeholder="z.B '12345678'"
                    :class="{ 'default-textfield': !istMobile, 'default-textfield-dynamic-length': istMobile }">
            </form>
            <OnboardingWeiterButton :laedt="false" :deaktiviert="!nummerGueltig" @click="weiter" />
        </div>
    </div>
</template>
<script>
import OnboardingTitel from '@/components/OnboardingTitel.vue';
import OnboardingWeiterButton from '@/components/OnboardingWeiterButton.vue';
import { computed, onMounted, ref, watch } from 'vue';
import { vorwahlen } from '@/constants/vorwahlen';
import store from '@/store';
export default {
    components: { OnboardingTitel, OnboardingWeiterButton },
    emits: ["telefonNummerAktualisiert", "weiter"],
    setup(props, context) {
        const istMobile = computed(() => store.state.mobile)

        //Standardvorwahl ist die Deutsche
        const nummerVorwahl = ref(vorwahlen.find(v => v.vorwahl == 49))
        const nummerText = ref("")
        const nummerRef = ref(null)

        const nummer = computed(() => `+${nummerVorwahl.value.vorwahl}${nummerText.value}`)
        const nummerGueltig = computed(() => /^\+\d{6,14}$/.test(nummer.value))

        watch(nummer, n => context.emit("telefonNummerAktualisiert", n))

        function nummerFokussieren() {
            if (nummerRef.value && nummerRef.value.focus) {
                nummerRef.value.focus()
            }
        }

        function weiter() {
            if (nummerGueltig.value === true) {
                context.emit("weiter")
            }
        }

        onMounted(nummerFokussieren)

        return { nummerGueltig, nummerVorwahl, nummerText, nummerRef, vorwahlen, istMobile, nummerFokussieren, weiter }
    }
}
</script>