<template>
    <div class="w-full px-10 py-6 rounded-2xl bg-TextField flex flex-col space-y-3 items-center">
        <p class="w-full text-sm text-MittelGrau">Rechnungsadresse</p>
        <span class="w-full">{{ adresse.name }}<br />{{ adresse.strasse }} {{ adresse.hausnummer }}<br />{{
            adresse.postleitzahl }} {{ adresse.stadt }}<br />{{ adresse.land }}</span>
    </div>
</template>
<script>
export default {
    props: ["adresse"]
}
</script>