<template>
    <div class="onboarding-komponente">
        <OnboardingTitel
        :hatZurueckButton="true"
        titel="Themen & Ordner"
        untertitel="Wählen Sie die Themen und optional Ordner Ihrer Session."
        @zurueck="navigieren(false)"/>
        <div class="w-full h-96 overflow-y-auto justify-start items-start space-y-10">
            <div class="flex flex-col space-y-4">
                <p>Themen</p>
                <div class="flex space-x-6">
                    <!-- Themen Links -->
                    <div class="flex flex-col space-y-2">
                        <Thema 
                            v-for="tl in alleThemen.links" 
                            :key="tl.id" 
                            :thema="tl" 
                            :gewaehlt="themen != null && themen.find(t => t.id == tl.id) != null"
                            @aktualisiert="() => themaGewaehlt(tl, true)"/>
                    </div>

                    <!-- Themen Rechts -->
                    <div class="flex flex-col space-y-2">
                        <Thema 
                            v-for="tr in alleThemen.rechts" 
                            :key="tr.id" 
                            :thema="tr" 
                            :gewaehlt="themen != null && themen.find(t => t.id == tr.id) != null"
                            @aktualisiert="() => themaGewaehlt(tr, true)"/>
                    </div>
                </div>
            </div>
            <div class="flex flex-col space-y-4">
                <p>Ordner</p>
                <div class="flex space-x-6">
                    <!-- Themen Links -->
                    <div class="flex flex-col space-y-2">
                        <Thema 
                            v-for="ol in alleOrdner.links" 
                            :key="ol.id" 
                            :thema="ol" 
                            :gewaehlt="ordner != null && ordner.find(t => t.id == ol.id) != null"
                            @aktualisiert="() => themaGewaehlt(ol, false)"/>
                    </div>

                    <!-- Themen Rechts -->
                    <div class="flex flex-col space-y-2">
                        <Thema 
                            v-for="or in alleOrdner.rechts" 
                            :key="or.id" 
                            :thema="or" 
                            :gewaehlt="ordner != null && ordner.find(t => t.id == or.id) != null"
                            @aktualisiert="() => themaGewaehlt(or, false)"/>
                    </div>
                </div>
            </div>
        </div>
        <OnboardingWeiterButton 
            :deaktiviert="!weiterAktiv" 
            :laedt="false"
            :text="aktivePartnerschaften != null && aktivePartnerschaften.length == 0 ? 'Hochladen' : null"
            @click="navigieren(true)"/>
    </div>
</template>
<script>
import OnboardingTitel from '@/components/OnboardingTitel.vue';
import OnboardingWeiterButton from '@/components/OnboardingWeiterButton.vue';
import Thema from "./components/Thema.vue"
import store from '@/store';
import { computed } from 'vue';
export default {
    components: {
        OnboardingTitel,
        OnboardingWeiterButton,
        Thema
    },
    props: ["aktivePartnerschaften", "themen", "ordner"],
    emits: ["themenAktualisiert", "ordnerAktualisiert", "navigieren"],
    setup(props, context) {
        
        function themenListenAbfragen(standard) {
            var links = []
            var rechts = []
            const themen = store.state.themen.filter(t => t.standard === standard)
            for (let i = 0; i < themen.length; i++) {
                if(i % 2 === 0) {
                    links.push(themen[i])
                } else {
                    rechts.push(themen[i])
                }
            }
            return {
                links: links,
                rechts: rechts
            }
        }
        
        const alleThemen = computed(() => themenListenAbfragen(true))
        const alleOrdner = computed(() => themenListenAbfragen(false))
        
        const weiterAktiv = computed(() => props.themen.length > 0 || props.ordner.length > 0)

        function themaGewaehlt(thema, istThema) {
            if(istThema) {
                var aktualisierteThemen = props.themen
                if(aktualisierteThemen.find(t => t.id == thema.id) != null) {
                    aktualisierteThemen = aktualisierteThemen.filter(t => t.id != thema.id)
                } else {
                    aktualisierteThemen.push(thema)
                }
                context.emit("themenAktualisiert", aktualisierteThemen)
            } else {
                var aktualisierteOrdner = props.ordner
                if(aktualisierteOrdner.find(o => o.id == thema.id) != null) {
                    aktualisierteOrdner = aktualisierteOrdner.filter(o => o.id != thema.id)
                } else {
                    aktualisierteOrdner.push(thema)
                }
                context.emit("ordnerAktualisiert", aktualisierteOrdner)
            }
        }

        function navigieren(forwaerts) {
            if(forwaerts === true && weiterAktiv.value === true) {
                context.emit("navigieren", true)
            } else if (forwaerts === false) {
                context.emit("navigieren", false)
            }
        }

        return {
            themaGewaehlt,
            navigieren,
            weiterAktiv,
            alleThemen,
            alleOrdner
        }
    }
}
</script>