<template>
  <Transition name="fade" mode="out-in" class="w-7 h-7">
    <component :is="widgetFuerZustand"></component>
  </Transition>
</template>

<script>
//Alle eigenen Komponenten werden importiert
import OnboardingCheckboxEmpty from "./components/OnboardingCheckboxEmpty.vue";
import OnboardingCheckboxChecked from "./components/OnboardingCheckboxChecked.vue";
import { computed } from "@vue/runtime-core";

export default {
  components: {
    OnboardingCheckboxEmpty,
    OnboardingCheckboxChecked,
  },

  props: ["wert"],

  setup(props, _) {
    const checked = computed(() => props.wert);

    const widgetFuerZustand = computed(() => {
      switch (checked.value) {
        case true:
          return OnboardingCheckboxChecked;
        default:
          return OnboardingCheckboxEmpty;
      }
    });

    return {
      //State für das UI
      checked,
      widgetFuerZustand,
    };
  },
};
</script>
<!-- Styles needed for transition animation -->
<style scoped>

/* Style needed for images to act as buttons */
img {
  pointer-events:all;
  cursor: pointer;
}

.fade-enter-active {
  transition: all 0.1s ease-out;
}

.fade-leave-active {
  transition: all 0.1s ease-in;
}

.fade-enter-from {
  opacity: 0.5;
}

.fade-enter-to {
  opacity: 1;
}

.fade-leave-from {
  opacity: 1;
}

.fade-leave-to {
  opacity: 0.5;
}
</style>