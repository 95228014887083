<template>
    <div v-if="!istMobile" class="w-full h-12 flex justify-start pointer-events-none font-dm-sans">
        <button v-if="!istMobile" :disabled="deaktiviert || laden" :style="text != null ? `width: ${width}` : ''"
            class="h-full rounded-full text-white pointer-events-auto" :class="{
                'transition duration-100 hover:bg-opacity-80': !deaktiviert && !laden,
                'bg-DentaBlue': deaktiviert === false && !destruktiv,
                'bg-Warning': deaktiviert === false && destruktiv === true,
                'bg-LeichtesGrau': deaktiviert === true,
                'w-12': text == null,
                'px-6': text != null
            }">
            <LottieAnimation v-if="laden === true" class="h-full" :animation-data="ladenWeiss" :loop="true" />
            <div v-else class="flex justify-center items-center space-x-4">
                <p v-if="text != null">{{ text }}</p>
                <img src="@/assets/icons/arrow-forward.svg" alt="->" class="h-6">
            </div>
        </button>
    </div>
    <button v-else :disabled="deaktiviert || laden" class="w-full h-12 flex justify-center items-center rounded-full"
        :class="{
            'bg-DentaBlue': deaktiviert === false && !destruktiv,
            'bg-Warning': deaktiviert === false && destruktiv === true,
            'bg-LeichtesGrau': deaktiviert === true,
        }">
        <LottieAnimation v-if="laden" class="h-12" :animation-data="ladenWeiss" :loop="true" />
        <div v-else class="flex items-center space-x-2">
            <span class="text-white">{{ text ? text : 'Weiter' }}</span>
            <img src="@/assets/icons/arrow-forward.svg" alt="->" class="h-7 object-contain">
        </div>
    </button>
</template>

<script>
import { LottieAnimation } from 'lottie-web-vue';
import { computed } from 'vue';
import ladenWeiss from "@/assets/animations/laden-weiss.json"
import store from '@/store';
export default {
    components: { LottieAnimation },
    props: ["destruktiv", "width", "deaktiviert", "laden", "text"],
    setup() {
        const istMobile = computed(() => store.state.mobile)
        return { ladenWeiss, istMobile }
    }
}
</script>