<template>
    <PatientErstellen :state="erstellenState" :nummer="patientenSuchText" @schliessen="erstellenState = false"
        @erstellt="p => patientErstellt(p)" />

    <div class="onboarding-komponente">
        <OnboardingTitel :hatZurueckButton="bearbeitungsModus == null || bearbeitungsModus === false" titel="Patient Wählen"
            untertitel="Sie können bestehende Patienten wählen, oder einen neuen anlegen." @zurueck="navigieren(false)" />

        <div class="w-80 h-72 flex flex-col justify-start items-start space-y-5">
            <div v-if="patient != null"
                class="w-full h-12 flex justify-between items-center px-6 rounded-lg bg-DentaBlue text-white">
                <p>{{ patient.nummer }}</p>
                <button @click="patientenZuruecksetzen"><img src="@/assets/icons/schliessen-weiss.svg" alt="x"
                        class="w-3 aspect-square object-contain"></button>
            </div>
            <input v-else class="default-textfield-dynamic-length" ref="patientenSuchfeldRef" placeholder="Patientennummer"
                type="text" name="patient" id="patient" v-model="patientenSuchText"
                @input="patientenSuchText = $event.target.value.replace(/[^0-9]/g, '')">

            <button :disabled="!gueltigePatientenNummer"
                :class="{ 'opacity-60 hover:cursor-not-allowed': !gueltigePatientenNummer }"
                class="pl-5 flex justify-start items-center space-x-3 text-DentaBlue" @click="erstellenState = true">
                <img class="w-7 aspect-square object-contain" src="@/assets/icons/plus-blau-kreis-grau.png" alt="+">
                <p>{{ gueltigePatientenNummer ? `"${patientenSuchText}" anlegen` : "Neuen Patienten anlegen" }}</p>
            </button>

            <div v-if="patientenSuchErgebnisse && patientenSuchErgebnisse.length" class="pl-5 flex flex-col space-y-2">
                <Suchergebnis v-for="wert in patientenSuchErgebnisse" :key="wert" :patient="wert"
                    @gewaehlt="() => patientGewaehlt(wert)" />
            </div>
        </div>
        <OnboardingWeiterButton :deaktiviert="patient == null" :laedt="laedt" @click="navigieren(true)" />
    </div>
</template>
<script>
import OnboardingTitel from '@/components/OnboardingTitel.vue';
import OnboardingWeiterButton from '@/components/OnboardingWeiterButton.vue';
import PatientErstellen from '@/pages/patienten/components/PatientErstellen.vue';
import Suchergebnis from './components/Suchergebnis.vue';
import Erstellen from './components/Erstellen.vue';
import { computed, nextTick, ref } from 'vue';
import store from '@/store';
import { faelleFuerPatientAbfragen } from '@/services/firestoreDienst';
export default {
    components: { Suchergebnis, Erstellen, PatientErstellen, OnboardingTitel, OnboardingWeiterButton },
    props: ["patient", "bearbeitungsModus"],
    emits: ["patientAktualisiert", "fallAktualisiert", "faelleAktualisiert", "navigieren"],
    setup(props, context) {
        const laedt = ref(false)

        const patientenSuchText = ref("")
        const patientenSuchfeldRef = ref(null)

        const erstellenState = ref(false)

        const gueltigePatientenNummer = computed(() => /^[0-9]+$/.test(patientenSuchText.value))

        const patientenSuchErgebnisse = computed(() => {
            if (store.state.patienten && store.state.patienten.length && patientenSuchText.value) {
                // Filtering
                let filtered = store.state.patienten.filter(patient =>
                    patient.nummer.includes(patientenSuchText.value)
                );

                // Sorting
                filtered.sort((a, b) => {
                    // Find the index of the substring in both strings
                    let indexA = a.nummer.indexOf(patientenSuchText.value);
                    let indexB = b.nummer.indexOf(patientenSuchText.value);

                    // Compare the indices
                    if (indexA < indexB) {
                        return -1;
                    } else if (indexA > indexB) {
                        return 1;
                    } else {
                        return 0;
                    }
                });

                return filtered;
            } else {
                return []; // Return an empty array if the condition is not met
            }
        });

        function patientGewaehlt(pat) {
            context.emit("patientAktualisiert", {
                id: pat.id,
                nummer: pat.nummer,
                neu: false
            })

            //Potentielle vorher ausgewählte und gesetzte Fälle werden hier zurückgesetzt
            context.emit("fallAktualisiert", null)

            patientenSuchText.value = ""
        }

        function patientenZuruecksetzen() {
            context.emit("patientAktualisiert", null)
            nextTick(() => patientenSuchfeldRef.value.focus())
        }

        function patientErstellt(patient) {
            context.emit("patientAktualisiert", {
                id: patient.id,
                nummer: patient.nummer,
                neu: false
            })

            //Potentielle vorher ausgewählte und gesetzte Fälle werden hier zurückgesetzt
            context.emit("fallAktualisiert", null)

            patientenSuchText.value = ""
        }

        function navigieren(forwaerts) {
            if (forwaerts === true && props.patient && props.patient.neu === false) {
                laedt.value = true
                faelleFuerPatientAbfragen(props.patient.id)
                    .then(faelle => {
                        context.emit("faelleAktualisiert", faelle)
                        context.emit("navigieren", true)
                    })
                    .finally(() => laedt.value = false)
            } else {
                context.emit("faelleAktualisiert", [])
                context.emit("navigieren", forwaerts)
            }
        }

        return {
            patientenSuchText,
            patientenSuchfeldRef,
            erstellenState,
            navigieren,
            patientenSuchErgebnisse,
            gueltigePatientenNummer,
            patientGewaehlt,
            patientErstellt,
            patientenZuruecksetzen
        }
    }
}
</script>