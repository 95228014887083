<template>
    <div class="onboarding-komponente">
        <OnboardingTitel titel="Wechsel Gestartet"
            untertitel="Wir führen den Wechsel durch und informieren sie per E-Mail, wenn Ihre neuen Kapazitäten verfügbar sind." />
        <LottieAnimation :animation-data="haken" class="w-40" />
        <OnboardingWeiterButton :deaktiviert="false" :laden="false" @click="zuPraxis" />
    </div>
</template>
<script>
import OnboardingTitel from '@/components/OnboardingTitel.vue';
import OnboardingWeiterButton from '@/components/OnboardingWeiterButton.vue';
import { LottieAnimation } from 'lottie-web-vue';
import haken from "@/assets/animations/haken.json"
import { useRouter } from 'vue-router';
export default {
    components: { OnboardingTitel, OnboardingWeiterButton, LottieAnimation },
    setup(props, context) {
        const router = useRouter()
        const zuPraxis = () => router.push({ name: "PraxisVerwaltungAbonnement" })
        return {
            haken,
            zuPraxis
        }
    }
}
</script>