<template>
    <button
        class="w-full py-4 px-6 rounded-xl flex justify-start items-center space-x-3 text-sm transition duration-100 hover:bg-opacity-70"
        :class="{
            'bg-Hintergrund text-DunklesViolett': gewaehlt === false, 
            'bg-DunklesViolett text-white': gewaehlt === true}
        "
        @click="aktualisiert">
        <img :src="iconUrl" alt="icon" class="h-5 aspect-square object-contain">
        <p>{{ name }}</p>   
    </button>
</template>

<script>
import { computed } from 'vue';
export default {
    props: ["ordner", "gewaehlt"],
    emits: ["aktualisiert"],
    setup(props, context) {
        const name = computed(() => {
            if(props.ordner.name.length > 20) {
                return `${props.ordner.name.substring(0, 20)}...`
            } else {
                return props.ordner.name
            }
        })

        const iconUrl = computed(() => {
            if(props.gewaehlt) {
                return require("@/assets/icons/ordner-weiss.svg")
            } else {
                return require("@/assets/icons/ordner.svg")
            }
        })

        const aktualisiert = () => context.emit("aktualisiert")

        return { name , iconUrl, aktualisiert}
    }
}
</script>