<template>
    <div class="w-full flex flex-col font-dm-sans text-sm">
        <div class="w-full h-[1px]" :class="{ ' bg-TextField': divider === true, 'bg-transparent': !divider }"></div>
        <div class="w-full flex">
            <button
                class="text-left w-full rounded-md px-2 flex justify-between text-sm text-black py-4 transition duration-100 hover:bg-UltraLeichtesGrau"
                @click="patientenFaelle">
                <p v-if="(typeof patient.name === 'string')" class="w-4/12">{{ patient.name }}</p>
                <p v-else class="w-4/12 text-LeichtesGrau italic">Kein Name</p>
                <p class="w-2/12">{{ patient.nummer }}</p>
                <p v-if="(typeof geburtsDatum === 'string')" class="w-2/12">{{ geburtsDatum }}</p>
                <p v-else class="w-2/12 text-LeichtesGrau italic">Kein Geburtsdatum</p>
                <p class="w-1/12">{{ fallZahl }}</p>
                <div class="w-3/12">
                    <div v-if="eigentuemer != null" class="w-full flex space-x-2">
                        <img :src="eigentuemer.avatarUrl" alt="avatar"
                            class="w-5 h-5 rounded-full object-cover border border-GeteiltViolett">
                        <p class="text-GeteiltViolett">{{ eigentuemer.name }}</p>
                    </div>
                </div>
            </button>
            <button v-if="!eigentuemer" @click="bearbeiten"><img class="w-4 h-4 object-contain"
                    src="@/assets/icons/punkte-vertikal.svg" alt="menü"></button>
            <div v-else class="w-4"></div>
        </div>
    </div>
</template>
<script>
import { router } from '@/router'
import store from '@/store'
import dayjs from 'dayjs'
import { computed } from 'vue'

export default {
    props: ["patient", "divider"],
    emits: ["bearbeiten", "loeschen"],
    setup(props, context) {
        const bearbeiten = () => context.emit("bearbeiten");
        const loeschen = () => context.emit("loeschen")

        const geburtsDatum = computed(() => {
            if (typeof props.patient.geburtsDatum === "number") {
                return dayjs.unix(props.patient.geburtsDatum).format("DD.MM.YYYY")
            } else return null
        })

        const eigentuemer = computed(() => {
            if (props.patient.eigentuemer !== store.getters.praxisAbfragen.id && !store.state.partnerschaftenLaden) {
                const partnerschaft = store.state.partnerschaften.find(p => Object.keys(p.teilnehmer).some(k => k === props.patient.eigentuemer))

                if (!partnerschaft) return null
                else {
                    const partner = partnerschaft.teilnehmer[props.patient.eigentuemer]

                    return {
                        name: partner.name,
                        avatarUrl: partner.avatarUrl
                    }
                }
            } else {
                return null
            }
        })

        const fallZahl = computed(() => {
            if (!store.state.sessions) return 0
            else {
                const sessions = store.state.sessions.filter(s => s.patient === props.patient.id)
                return sessions.length
            }
        })

        function patientenFaelle() {
            router.push({ name: "AufnahmenPatient", params: { id: props.patient.id } })
        }

        return { bearbeiten, loeschen, patientenFaelle, fallZahl, eigentuemer, geburtsDatum }
    }
}
</script>