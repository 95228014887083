<!-- Stellt die Titel für die einzelnen Checkboxen dar -->
<template>
  <div class="w-full h-12 flex">
    <!-- Linker Teil -->
    <div class="w-3/12">
    </div>

    <!-- Rechter Teil -->
    <div class="w-9/12 flex justify-between items-center text-Mittelgrau text-xs">
      <p class="w-full text-center mx-1 flex justify-center items-center">Mobil Zugriff</p>
      <p class="w-full text-center mx-1 flex justify-center items-center">Web Zugriff</p>
      <p class="w-full text-center mx-1 flex justify-center items-center">Sessions Importieren</p>
      <p class="w-full text-center mx-1 flex justify-center items-center">Sessions Verwalten</p>
      <p class="w-full text-center mx-1 flex justify-center items-center">Aufnahmen Verwalten</p>
      <p class="w-full text-center mx-1 flex justify-center items-center">Themen Verwalten</p>
      <p class="w-full text-center mx-1 flex justify-center items-center">Zusammenarbeit Verwalten</p>
      <p class="w-full text-center mx-1 flex justify-center items-center">Patienten Verwalten</p>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>