<template>
    <form @submit.prevent @keydown.enter.prevent="speichern"
        :class="{ 'onboarding-komponente': !istMobile, 'onboarding-komponente-mobile': istMobile }">
        <div class="w-full flex flex-col space-y-8">
            <OnboardingTitel titel="Profil Konfigurieren"
                untertitel="Diese Daten werden nur Mitarbeitern Ihrer Organisation angezeigt. Ihre Telefonnummer sehen ausschließlich Sie." />
            <div class="w-full flex flex-col justify-start items-start space-y-3">

                <!-- TODO: Richtige Styles auf Dropdown anwenden! -->
                <select
                    class="px-5 py-3 bg-TextField focus:outline-none rounded-md relative flex justify-center items-center"
                    :class="{ 'w-96': !istMobile, 'w-full': istMobile }" v-model="gewaehlteAnrede" name="vorwahl"
                    id="vorwahl">
                    <option v-for="wert in anreden" :key="wert" :value="wert">
                        {{ wert }}
                    </option>
                </select>

                <input type="text" v-model="vorname" placeholder="Vorname"
                    :class="{ 'default-textfield': !istMobile, 'default-textfield-dynamic-length': istMobile }">
                <input type="text" v-model="nachname" placeholder="Nachname"
                    :class="{ 'default-textfield': !istMobile, 'default-textfield-dynamic-length': istMobile }">

                <!-- Telefonnummer -->
                <div class="flex space-x-3" :class="{ 'w-96': !istMobile, 'w-full': istMobile }">
                    <select v-model="vorwahl" name="vorwahl" id="vorwahl"
                        class="w-1/3 px-5 py-3 bg-TextField focus:outline-none rounded-md relative flex justify-center items-start">
                        <option v-for="vorwahl in vorwahlen" :key="vorwahl.vorwahl" :value="vorwahl">
                            {{ `+ ${vorwahl.vorwahl}` }}
                        </option>
                    </select>
                    <input type="text" v-model="telefonNummer" placeholder="Telefonnummer"
                        class="default-textfield w-2/3">
                </div>
            </div>
        </div>

        <div class="w-full flex flex-col justify-end" :class="{ 'h-12': !istMobile, 'h-full': istMobile }">
            <OnboardingWeiterButton :deaktiviert="!angabenGueltig" :laden="laedt" @click="speichern" />
        </div>
    </form>
</template>
<script>
import OnboardingTitel from '@/components/OnboardingTitel.vue';
import OnboardingWeiterButton from '@/components/OnboardingWeiterButton.vue';
import { vorwahlen } from '@/constants/vorwahlen';
import { anreden } from '@/constants/konstanten';
import { useCloudFunction } from '@/services/cloudFunctionDienst';
import { computed, ref, watchEffect } from 'vue';
import store from '@/store';
export default {
    components: {
        OnboardingTitel,
        OnboardingWeiterButton
    },
    emits: ["telefonNummerAktualisiert", "weiter"],
    setup(props, context) {
        const istMobile = computed(() => store.state.mobile)

        const laedt = ref(false)

        const gewaehlteAnrede = ref(anreden[0])

        const vorname = ref(null)
        const nachname = ref(null)

        const displayName = computed(() => {
            if (gewaehlteAnrede.value && vorname.value && vorname.value != "" && nachname.value && nachname.value != "") {
                return `${gewaehlteAnrede.value}-${vorname.value}-${nachname.value}`
            }
        })

        const vorwahl = ref(vorwahlen.find(v => v.vorwahl === 49) ?? null)
        const telefonNummer = ref(null)

        const telefonNummerVollstaendig = computed(() => {
            if (vorwahl.value && telefonNummer.value) {
                return `+${vorwahl.value.vorwahl}${telefonNummer.value}`
            }
        })

        const {
            error: aktualisierungsFehler,
            result: aktualisierungsErgebnis,
            call: aktualisieren
        } = useCloudFunction("kontoAktualisieren")

        watchEffect(() => {
            if (telefonNummerVollstaendig.value) {
                context.emit("telefonNummerAktualisiert", telefonNummerVollstaendig.value)
            }
        })

        const anredeGewaehlt = (event) => gewaehlteAnrede.value = event.target.value

        const angabenGueltig = computed(() => displayName.value != null && /^\+\d{6,14}$/.test(telefonNummerVollstaendig.value))

        /**
         * Ruft die "kontoAktualisieren" Cloud Funktion mit den gegebenen Daten auf, und
         * gibt dann das "weiter" Event ab.
         */
        function speichern() {
            if (displayName.value) {
                laedt.value = true
                aktualisieren({ displayName: displayName.value })
                    .then(() => {
                        laedt.value = false
                        context.emit("weiter")
                    })
            }

        }

        return { laedt, vorwahlen, anreden, gewaehlteAnrede, vorname, nachname, displayName, vorwahl, telefonNummer, angabenGueltig, istMobile, anredeGewaehlt, speichern }
    }
}
</script>