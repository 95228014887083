<template>
  <div class="flex items-center justify-start mt-6 mb-10 text-black text-3xl font-bold font-dm-sans">
    <h1>{{ title }}</h1>
  </div>
</template>

<script>
export default {
  props: ["icon", "title"],
};
</script>