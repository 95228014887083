<template>
    <button class="w-full aspect-fallkarte rounded-xl bg-white border border-LeichtesGrau p-3 flex flex-col justify-between items-start transition duration-100 hover:bg-DentaBlueUltraLight hover:bg-opacity-40 hover:border-DentaBlue">
         <!--Infos  -->
        <div class="w-full h-2/6 flex">
            <!-- Schritte und Datum -->
            <div class="w-1/2 h-full flex flex-col justify-start items-start">
                <p class="text-xs text-Mittelgrau">Schritt: {{ fall.schritt }}</p>
                <p>{{ datumUndUhrzeit }}</p>
            </div>

            <!-- Zähne -->
            <div class="w-1/2 h-10 flex flex-wrap justify-end items-start overflow-y-hidden">
                <div 
                    v-for="zahn in fall.zaehne" 
                    :key="zahn.nummer"
                    class="text-DentaBlue text-xs pr-2 pb-1">{{ zahn.nummer }}</div>
            </div>
        </div>

        <!-- Themen / Ordner -->
        <div class="w-full h-4/6 py-2">
            <div 
                v-if="fall.themen.length <= 3"
                class="w-full h-full flex justify-evenly items-center">

                <!-- Themen -->
                <Thema 
                    v-for="thema in fall.themen" 
                    :key="thema.id" 
                    :thema="thema" 
                    :overflowIndikator="false"/>

                <!-- Filler, falls die themenliste nicht 3 lang ist -->
                <div 
                    v-for="index in fehlendeThemenIndizes" 
                    :key="index"
                    class="h-full aspect-square">
                    </div>
            </div>
            <div 
                v-else
                class="w-full h-full flex justify-evenly items-center">

                <!-- Themen -->
                <Thema 
                    v-for="thema in overflowThemen" 
                    :key="thema.id" 
                    :thema="thema" 
                    :overflowIndikator="false"/>

                <!-- Filler, falls die themenliste nicht 3 lang ist -->
                <Thema :overflowZahl="fall.themen.length - overflowThemen.length"/>
            </div>

        </div>
    </button>
</template>
<script>
import { computed } from 'vue';
import dayjs from 'dayjs';
import Thema from "./Thema.vue"
export default {
    props: ["fall"],
    emits: ["gewaehlt"],
    components: {Thema},
    setup(props, context) {
        const datumUndUhrzeit = computed(() => {
            if(props.fall && props.fall.hochladeZeit) {
                return dayjs.unix(props.fall.hochladeZeit).format("DD.MM.YY HH:mm")
            } else {
                return ""
            }
        })

        const fehlendeThemenIndizes = computed(() => {
            const maxIndex = props.fall.themen.length - 1;

            if (maxIndex >= 2) { return [] }

            const result = [];
            for (let i = maxIndex; i < 2; i++) {
                result.push(i);
            }
                return result;
        });

        const overflowThemen = computed(() => props.fall.themen.slice(0, 2))

        return {
            datumUndUhrzeit,
            fehlendeThemenIndizes,
            overflowThemen
        }
    }
}
</script>