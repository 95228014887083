<template>
    <div class="w-full h-screen flex justify-start items-center p-5 bg-white">
        <OnboardingFortschrittsAnzeige class="h-full w-1/3 mr-40" :index="index" :schritte="fortschrittElemente" :fehler="false"/>
        <component 
            :is="ansicht"
            :partnerName="partnerName"
            :partnerCode="partnerCode"
            @partnerNameAktualisiert="n => partnerName = n"
            @partnerCodeAktualisiert="c => partnerCode = c"
            @weiter="navigieren"/>
    </div>
</template>
<script>
import OnboardingFortschrittsAnzeige from '@/components/OnboardingFortschrittsAnzeige.vue';
import PartnerCode from "./components/partner-code/PartnerCode.vue"
import Jcv from "./components/jcv/Jcv.vue"
import { computed, ref } from 'vue';

export default {
    components: {OnboardingFortschrittsAnzeige, PartnerCode, Jcv},
    setup(props, context) {
        const index = ref(0)

        const fortschrittElemente = [{
            index: 0,
            titel: "DentaSnap ID",
            untertitel: "Geben Sie die ID Ihres Partners an.",
        },
        {
            index: 1,
            titel: "Joint Controll Vertrag",
            untertitel: "Unterschreiben Sie den JCV.",
        },
        ]

        const ansicht = computed(() => {
            switch (index.value) {
                case 1:
                    return Jcv;
                default:
                    return PartnerCode
            }
        })

        const partnerName = ref(null)
        const partnerCode = ref(null)
        
        function navigieren() {
            if(index.value < 1) {
                index.value++
            }
        }

        return {
            index,
            fortschrittElemente,
            ansicht,
            partnerName,
            partnerCode,
            navigieren
        }
    }
}
</script>