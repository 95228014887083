<template>
    <div class="onboarding-komponente">
        <OnboardingTitel 
            :hatZurueckButton="bearbeitungsModus == null || bearbeitungsModus === false"
            titel="Zusammenarbeiten" 
            untertitel="Teilen Sie diese Session mit Ihren Partnern."
            @zurueck="navigieren(false)"
        />
        <div class="w-full max-h-72 overflow-y-auto">
            <div class="flex flex-wrap justify-start items-start">
                <div class="pr-2 pb-8"
                    v-for="p in aktivePartnerschaften" 
                    :key="p.id">
                        <Partnerschaft 
                        :partnerschaft="p" 
                        :gewaehlt="gewaehltePartnerschaften != null && gewaehltePartnerschaften.find(ps => ps.id == p.id) != null"
                        @aktualisiert="partnerschaftGewaehlt(p)"/>
                </div>
            </div>
        </div>
        <OnboardingWeiterButton :deaktiviert="false" :laedt="laedt" @click="navigieren(true)"/>
    </div>
</template>
<script>
import OnboardingTitel from '@/components/OnboardingTitel.vue';
import OnboardingWeiterButton from '@/components/OnboardingWeiterButton.vue';
import Partnerschaft from "./components/Partnerschaft.vue"
import { computed } from 'vue';
import store from '@/store';
export default {
    props: ["aktivePartnerschaften","gewaehltePartnerschaften", "bearbeitungsModus", "laedt"],
    emits: ["partnerschaftenAktualisiert", "navigieren"],
    components: { OnboardingTitel, OnboardingWeiterButton, Partnerschaft },
    setup(props, context) {
        function partnerschaftGewaehlt(partnerschaft) {
            var akt = props.gewaehltePartnerschaften
            if(akt.find(p => p.id == partnerschaft.id)) {
                akt = akt.filter(p => p.id != partnerschaft.id)
            } else {
                akt.push(partnerschaft)
            }
            context.emit("partnerschaftenAktualisiert", akt)
        }

        function navigieren(forwaerts) {
            context.emit("navigieren", forwaerts)
        }

        return {
            partnerschaftGewaehlt,
            navigieren
        }
    }
}
</script>