<template>
  <BestaetigungsDialog
    :state="state"
    :titel="dialogTitel"
    body="Diese Aktion kann nicht rückgängig gemacht werden."
    :destruktiv="true"
    :laedt="laedt"
    bestaetigungsText="Löschen"
    @bestaetigt="themaLoeschen"
    @abgebrochen="schliessen"
  />
</template>

<script>
//Alle benötigten Helfer werden importiert
import BestaetigungsDialog from '@/components/dialoge/BestaetigungsDialog.vue'
import { useCloudFunction } from '@/services/cloudFunctionDienst'
import { erfolgAnzeigen, fehlerAnzeigen, standardToastAnzeigen } from '@/services/toastDient'
import { ref } from '@vue/reactivity'
import { watch } from '@vue/runtime-core'
import { computed } from 'vue'

export default {
  props: ["state","thema"],
  emits: ["schliessen"],
  components: {BestaetigungsDialog},
  setup(props, context) {

    /** Aufrufbare Instanz des "Thema Löschen" Endpunkts */
    const {pending: loeschenLaed, error: loeschenFehler, call: loeschen } = useCloudFunction("themaLoeschen")

    const dialogTitel = computed(() => props.thema && props.thema.name 
        ? `"${props.thema.name.length > 11 ? props.thema.name.substring(0,11) + '…' : props.thema.name}" Löschen?` 
        : "");

    let themenKopie = ref(props.thema != null ? JSON.parse(JSON.stringify(props.thema)) : null)
    watch(() => props.thema, () => {
      if(props.thema) {
        themenKopie.value = JSON.parse(JSON.stringify(props.thema))
      }
    })

    const laedt = ref(false)

    /** Startet den Löschprozess, wenn eine Referenz zu einem gültigen Thema gegeben ist. */
    function themaLoeschen() {
      if(themenKopie.value) {
        laedt.value = true
        loeschen({id: themenKopie.value.id})
        .then(() => {
          if(!loeschenFehler.value) {
            erfolgAnzeigen(`"${themenKopie.value.name}" gelöscht.`)
            schliessen()
          } else {
            fehlerAnzeigen("Fehler beim Löschen aufgetreten.")
          }
        })
        .finally(() => laedt.value = false)
      }
    }

    /** Gibt das "schliessen" Event ab */
    function schliessen() {
      context.emit("schliessen")
    }

    return {
      //State für das UI
      loeschenLaed,
      themenKopie,
      dialogTitel,
      laedt,

      //Funktionen für das UI
      themaLoeschen,
      schliessen,
    }
  }
}
</script>