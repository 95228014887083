<template>
  <div v-if="!laedt" class="w-full h-screen flex justify-start items-center p-5 bg-white"
    :class="{ 'flex-col': istMobile === true, 'flex-row': istMobile === false }">
    <OnboardingFortschrittsAnzeige
      :class="{ 'h-full w-1/3 mr-40': istMobile === false, 'w-full h-44': istMobile === true }" :mobile="istMobile"
      :schritte="schritte" :index="index" :fehler="false" :laedt="laedt" />

    <LottieAnimation v-if="laedt" class="aspect-square" :animation-data="ladenBlau" :auto-play="true" :loop="true"
      :speed="1" ref="ladeanimation" />
    <component v-else :is="komponente" :nummer="telefonnummer"
      @telefonNummerAktualisiert="(wert) => telefonnummer = wert" @weiter="forwaertsNavigieren">
    </component>
  </div>
  <LadenInline v-else />
</template>

<script>
//Allgemein
import OnboardingFortschrittsAnzeige from '@/components/OnboardingFortschrittsAnzeige.vue';
import LadenInline from '@/components/LadenInline.vue';
import { computed, ref, watch, watchEffect } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { auth } from '@/firebase/config';
import { checkActionCode } from 'firebase/auth';
import { LottieAnimation } from 'lottie-web-vue';
import ladenBlau from "@/assets/animations/laden-blau"
import store from '@/store';
import { eigentuemer } from '@/constants/konstanten';
import { ausstehendeKonfigurationenPruefen, konfigRoutenGenerieren } from '@/services/helfer';
import { berechtigungenPruefen, nutzer, nutzerAktualisieren } from '@/services/authentifizierungsDienst';
import { instandhaltungPruefen } from "@/services/firestoreDienst"

//Komponenten
import Agb from './components/agb/Agb.vue';
import RegistrierungsLink from './components/RegistrierungsLink/RegistrierungsLink.vue';
import EmailAendern from './components/EmailAendern/EmailAendern.vue';
import EmailBestaetigen from './components/EmailBestaetigen/EmailBestaetigen.vue';
import TelefonnummerAngeben from './components/TelefonnummerAngeben/TelefonnummerAngeben.vue';
import MfaVerifizieren from './components/MfaVerifizierung/MfaVerifizieren.vue';
import MitarbeiterEinrichten from './components/MitarbeiterEinrichten/MitarbeiterEinrichten.vue';
import PasswortErstellen from './components/PasswortErstellen/PasswortErstellen.vue';
import PraxisLogoHinzufuegen from './components/praxis-logo-hinzufuegen/PraxisLogoHinzufuegen.vue';
import Profil from './components/Profil/Profil.vue';
import ProfilBild from "./components/ProfilBild/ProfilBild.vue"

//Routen
import {
  agbErklaerung,
  registrierungsLink,
  emailBestaetigen,
  emailAendern,
  telefonnummerAngeben,
  mfaVerifizierung,
  mitarbeiterEinrichten,
  passwortErstellen,
  praxisAngaben,
  profilAngaben,
  profilBildHochladen
} from '@/constants/kontoKonfigRouten';
import { router } from '@/router';
import { fehlerAnzeigen } from '@/services/toastDient';

export default {
  components: {
    LadenInline,
    OnboardingFortschrittsAnzeige,
    Agb,
    EmailAendern,
    EmailBestaetigen,
    TelefonnummerAngeben,
    MfaVerifizieren,
    MitarbeiterEinrichten,
    PasswortErstellen,
    PraxisLogoHinzufuegen,
    Profil,
    ProfilBild
  },
  setup(props, context) {
    const istMobile = computed(() => store.state.mobile)

    /** Gibt an, ob die Konto Konfiguration aktuell noch geladen wird */
    const laedt = ref(true)

    /** Speichert die Liste aller Routen, die bei der vorliegenden Konfiguration angezeigt werden sollen */
    const routen = ref([])

    /** Speichert die Route, die aktuell als Komponente angezeigt wird */
    const route = ref(null)

    /** Gibt für die Fortschrittsanzeige den 0-basierten Index der aktuellen Komponente zurück. */
    const index = computed(() => routen.value.indexOf(route.value))

    const pfadProvider = useRoute()
    const navigationsProvider = useRouter()

    /** Gibt als Objekt alle aktuellen URL Parameter zurück. */
    const urlParameter = computed(() => pfadProvider.query);

    /** Gibt als Objekt das Konfigurationsobjekt des Nutzers zurück */
    const kontoKonfiguration = computed(() => store.state.kontoKonfiguration)

    const konfigurationLaeuft = ref(false)

    /** Die Komponente für Profilbearbeitung gibt eine Telefonnummer als event aus, 
     * die hier gespeichert wird und der "MfaVerifizierung" Komponente übergeben wird*/
    const telefonnummer = ref("")

    /** Gibt für jede Route das passende "schritt" Objekt für die Fortschrittsanzeige zurück.*/
    const schritte = computed(() => {
      if (routen.value) {
        return routen.value.map(route => {
          var titel
          var untertitel

          switch (route) {
            case agbErklaerung:
              titel = "AGB & Datensicherheit";
              untertitel = "Bitte lesen und bestätigen Sie unsere AGB.";
              break;
            case emailBestaetigen:
              titel = "E-Mail Adresse Bestätigen";
              untertitel = "Geben Sie zur Bestätigung die E-Mail Adresse ein, unter der Sie den Link erhalten haben.";
              break;
            case registrierungsLink:
              titel = "Willkommen bei DentaSnap!"
              untertitel = "Bestätigen Sie zunächst Ihre E-Mail Adresse."
              break;
            case emailAendern:
              titel = "E-Mail Adresse Ändern";
              untertitel = "Bitte geben Sie die neue E-Mail Adresse an, die für ihr DentaSnap Konto verwendet werden soll.";
              break;
            case telefonnummerAngeben:
              titel = "Telefonnummer"
              untertitel = "Bitte geben Sie die gewünschte Telefonnummer an, an die wir Verifizierungscodes senden sollen."
              break;
            case mfaVerifizierung:
              titel = "Telefonnummer Bestätigen";
              untertitel = "Bitte geben Sie zur Bestätigung den 6-stelligen Code ein, den wir Ihnen gesendet haben.";
              break;
            case mitarbeiterEinrichten:
              titel = "Ihr Team";
              untertitel = "Laden Sie in Sekunden Ihr gesamtes Team in Ihre neue DentaSnap Praxis ein!";
              break;
            case passwortErstellen:
              titel = "Ihr Passwort";
              untertitel = "Wählen Sie ein sicheres Passwort für Ihr DentaSnap Konto.";
              break;
            case praxisAngaben:
              titel = "Praxislogo";
              untertitel = "Fügen Sie das Logo Ihrer Praxis hinzu.";
              break;
            case profilAngaben:
              titel = "Ihr Konto";
              untertitel = "Richten Sie Ihr DentaSnap Konto fertig ein.";
              break;
            case profilBildHochladen:
              titel = "Profilbild";
              untertitel = "Fügen Sie Ihrem Konto ein Profilbild hinzu.";
              break;
            default:
              titel = "";
              untertitel = "";
              break;
          }

          return {
            index: routen.value.indexOf(route),
            titel: titel,
            untertitel: untertitel
          }
        })
      }
    })

    /** Gibt anhand des aktuellen "route" Werts die passende Komponente zurück */
    const komponente = computed(() => {
      if (route.value) {
        switch (route.value) {
          case agbErklaerung:
            return Agb;
          case registrierungsLink:
            return RegistrierungsLink;
          case emailBestaetigen:
            return EmailBestaetigen;
          case emailAendern:
            return EmailAendern;
          case telefonnummerAngeben:
            return TelefonnummerAngeben;
          case mfaVerifizierung:
            return MfaVerifizieren;
          case mitarbeiterEinrichten:
            return MitarbeiterEinrichten;
          case passwortErstellen:
            return PasswortErstellen;
          case praxisAngaben:
            return PraxisLogoHinzufuegen;
          case profilAngaben:
            return Profil;
          case profilBildHochladen:
            return ProfilBild;
          default:
            break;
        }
      }
    })

    /** Im Fall, dass keine Routenparameter gegeben sind, wird diese Fuktion aufgerufen. 
     *  Wenn für das vorliegende Nutzerkonto Konfigurationsschritte nötig sind,
     *  generiert diese Funktion die nötigen Routen für alle nötigen Schritte, und speichert sie unter "routen".
     */
    async function konfigurationStarten() {
      laedt.value = true

      if (kontoKonfiguration.value != null && ausstehendeKonfigurationenPruefen(kontoKonfiguration.value) === true && konfigurationLaeuft.value === false) {
        const istEigentuemer = berechtigungenPruefen([eigentuemer])
        routen.value = konfigRoutenGenerieren(kontoKonfiguration.value, istEigentuemer)
        laedt.value = false

        //Verhindert, dass wärend der Konfiguration durch Updates am Nutzer und der Praxis eine weitere Konfiguration gestartet wird.
        konfigurationLaeuft.value = true
      } else {
        //Keine ausstehende Konfiguration, die Startseite wird geladen
        navigationsProvider.push({ path: "/" })
      }
    }

    /**
     * Im Fall, dass in der URL eine E-Mail Aktion angegeben ist, wird diese hier geprüft. 
     * Wenn es sich um eine gültige Aktion handelt, wird die entsprechende Route geladen.
     */
    function emailAktionEmpfangen() {
      laedt.value = true
      instandhaltungPruefen()
        .then(erg => {
          if (erg.code === 0) {
            checkActionCode(auth, urlParameter.value.oobCode)
              .catch(err => {
                //TODO: Nutzer über ungültigen / fehlerhaften OOB Code informieren, und Möglichkeit bieten zur Anmeldung zurückzukehren
                console.log("Fehler bei der validierung des OOB Codes: ", err);
              })
              .then(res => {
                switch (res.operation) {
                  case "EMAIL_SIGNIN":
                    //Einzelne Route für Bestätigung einer E
                    routen.value = [registrierungsLink]
                    route.value = registrierungsLink

                    laedt.value = false
                    break;
                  case "VERIFY_EMAIL":
                    //Einzelroute für "E-Mail Bestätigen" wird geladen
                    routen.value = [emailBestaetigen]
                    route.value = emailBestaetigen

                    laedt.value = false
                  case "PASSWORD_RESET":
                    routen.value = [passwortErstellen]
                    route.value = passwortErstellen

                    laedt.value = false
                    break;

                  case "RECOVER_EMAIL":
                  //E-Mail Änderungslink (Kann von Praxiseigentümern an gewünschte neue E-Mail Adresse gesendet werden)
                  default:
                    //TODO: Nutzer über ungültigen / fehlerhaften OOB Code informieren, und Möglichkeit bieten zur Anmeldung zurückzukehren
                    break;
                }
              })
          } else {
            fehlerAnzeigen("DentaSnap Version muss aktualisiert werden.")
            router.push({ name: "Anmeldung" })
          }
        })
    }

    /** Wird aufgerufen, wenn sich die URL oder das Konto-Konfigurationsobjekt
     * des Nutzers ändert. Prüft den aktuellen State, und entscheidet:
     *  - Ob eine E-Mail Aktion behandelt werden soll (entsprechende URL parameter)
     *  - Ob eine Konto Konfiguration durchgeführt wird (nutzer hat ausstehende Konfiguration)
     * - Lädt die "Heute" Seite (regulärer Fall)
     */
    async function initialisieren() {
      //Praxis-Sperrungen sind der Konto Konfiguration vorangestellt. D.h falls ein Nutzer angemeldet ist, wird hier das Laden der Daten abgewartet
      //und dann geprüft, ob die Praxis des Nutzers gesperrt ist.
      if (nutzer.value != null && store.getters.praxisAbfragen.sperrung != null) {
        router.push({ name: "PraxisGesperrt" })
      } else {
        if (Object.keys(urlParameter.value).length != 0 && typeof urlParameter.value.oobCode === "string") {
          //URL enthält die nötigen Parameter für eine E-Mail Aktion
          emailAktionEmpfangen()
        } else {
          //Nutzer hat ausstehende Konfigurationsschritte
          konfigurationStarten()
        }
      }

    }

    /** Wenn möglich wird zur nächsten Route in der "routen Liste navigiert" */
    function forwaertsNavigieren() {
      if (routen.value && routen.value.length && routen.value.length - 1 > index.value) {
        console.log("Es wird weiter navigiert");
        route.value = routen.value[index.value + 1]
      } else {
        console.log("Es wird zur Heute Seite navigiert");
        nutzerAktualisieren(true)
          .then(() => router.push({ path: "/" }))
        //Nutzer möchte von letzter Komponente weiter gehen, und wird zur "Heute" Seite weitergeleitet
      }
    }

    /** Wenn unter "routen" eine oder mehrere gültige Routen gespeichert werden, wird hier automatisch auf die Erste route gesetzt */
    watch(routen, newVal => {
      if (newVal && newVal.length) {
        console.log("Neue Routen erkannt");
        route.value = newVal[0]
      }
    })

    watchEffect(() => {
      //Falls ein Nutzer angemeldet ist, muss neben der Konfig auch die Praxis geladen sein, damit eine Sperrung ausgeschlossen werden kann.
      if (konfigurationLaeuft.value === false && (urlParameter.value || kontoKonfiguration.value) && (store.state.praxisLaedt === false || !nutzer.value)) {
        initialisieren()
      }
    })

    return {
      ladenBlau,
      laedt,
      routen,
      index,
      komponente,
      schritte,
      telefonnummer,
      istMobile,
      forwaertsNavigieren
    }
  }
}
</script>