<template>
    <div class="rounded-xl bg-TextField px-4 py-2 flex flex-col font-dm-sans">
        <div v-for="(zm, index) in zahlungsMethoden" :key="zm" class="flex flex-col space-2 justify-center items-center">
            <ZahlungsMethode :methode="zm" :aktuelleMethode="aktuelleMethode" @aktualisiert="aktualisiert" />
            <div v-if="index < zahlungsMethoden.length - 1" class="w-full h-[1px] rounded-full bg-LeichtesGrau opacity-30">
            </div>
        </div>
    </div>
</template>
<script>
import { ref } from 'vue';
import ZahlungsMethode from './components/ZahlungsMethode.vue';
import { zahlungsMethoden } from '@/constants/zahlungsMethoden';
export default {
    emits: ["aktualisiert"],
    components: { ZahlungsMethode },
    setup(_, context) {
        const aktuelleMethode = ref(null)
        const aktualisiert = (m) => {
            aktuelleMethode.value = m
            context.emit("aktualisiert", m)
        }
        return {
            aktuelleMethode,
            zahlungsMethoden,
            aktualisiert
        }
    }
}
</script>