<template>
    <div class="w-full py-3 px-5 mb-3 flex justify-between items-center font-dm-sans"
        :class="{ 'bg-offwhite': even === true }">
        <div class="flex justify-start items-center text-lg">
            <img :src="standardGeraeteProfilBildUrl" class="w-10 aspect-square rounded-full mr-8">
            <p class="mr-24">{{ geraetename }}</p>
        </div>
        <button class="transition duration-100 hover:opacity-75" @click="geraeteOptionenAnzeigen($event)">
            <img src="@/assets/icons/punkte-vertikal-grau.svg" class="h-6 aspect-square">
        </button>
    </div>
    <BestaetigungsDialog :state="loeschenDialogState" :laedt="loeschenLaedt"
        :titel="`'${geraet.geraeteName ?? ''}' Löschen`"
        body="Nach dem Löschen müssen Sie das Gerät neu registrieren, um es wieder zu nutzen." bestaetigungsText="Löschen"
        :destruktiv="true" @bestaetigt="loeschen" @abgebrochen="loeschenDialogState = false" />
</template>
<script>
import { router } from '@/router';
import { kontextMenueAnzeigen } from '@/services/kontextMenue';
import { computed, ref } from 'vue';
import BestaetigungsDialog from '@/components/dialoge/BestaetigungsDialog.vue';
import { useCloudFunction } from '@/services/cloudFunctionDienst';
import { fehlerSenden } from '@/services/LogDienst';
import { erfolgAnzeigen, fehlerAnzeigen } from '@/services/toastDient';
import { standardGeraeteProfilBildUrl } from '@/constants/konstanten';
export default {
    components: { BestaetigungsDialog },
    props: ["geraet", "even"],
    emits: ["geloescht"],
    setup(props, context) {

        const loeschenDialogState = ref(false)
        const loeschenLaedt = ref(false)

        const { error, call } = useCloudFunction("kontoLoeschen")

        function loeschen() {
            if (props.geraet) {
                loeschenLaedt.value = true
                call({ uid: props.geraet.uid })
                    .catch(err => fehlerSenden(err, "Fehler beim Löschen eines Gerätekontos", "loeschen", "Geraet.vue"))
                    .then(() => {
                        if (!error.value) erfolgAnzeigen(`${props.geraet.geraeteName} erfolgreich gelöscht.`)
                        else fehlerAnzeigen(`Fehler beim Löschen von ${props.geraet.geraeteName}`)
                        context.emit("geloescht")
                    })
                    .finally(() => {
                        loeschenDialogState.value = false
                        loeschenLaedt.value = false
                    })
            }
        }

        const geraetename = computed(() => {
            if (props.geraet) {
                return props.geraet.geraeteName
            }
        })

        function geraeteOptionenAnzeigen(event) {
            kontextMenueAnzeigen(event, [
                {
                    id: 0,
                    text: `${props.geraet.geraeteName} Anmelden`,
                    onClick: () => router.push({ name: "GerätAnmelden", params: { uid: props.geraet.uid } })
                },
                {
                    id: 1,
                    text: `${props.geraet.geraeteName} Löschen`,
                    destruktiv: true,
                    onClick: () => loeschenDialogState.value = true
                }
            ])
        }

        return {
            geraetename,
            loeschenDialogState,
            loeschenLaedt,
            standardGeraeteProfilBildUrl,
            loeschen,
            geraeteOptionenAnzeigen
        }
    }
}
</script>thi