<template>
  <div class="w-60 h-12 relative">
      <!-- Patientfeld -->
      <div v-if="patient" class="w-full h-full rounded-md flex justify-between items-center bg-DentaBlue text-white px-5">
        <p>{{patient.nummer}}</p>
        <ion-icon name="close-circle" class="text-xl transition duration-150 hover:cursor-pointer" @click="existierendenPatientGewaehlt(null)"></ion-icon>
      </div>
      
      <div v-else class="w-full h-full relative">
        <!-- Immer eingeblendetes Suchfeld, ÜBER der Ergebnisliste -->
        <input type="text" class="w-full h-full px-5 bg-TextField rounded-md z-30 absolute focus:outline-none" v-model="suchbegriff" placeholder="Patientnummer">

        <div v-if="suchbegriff != ''" class="w-60 max-h-48 bg-TextField rounded-md z-20 absolute flex flex-col justify-start items-start overflow-y-auto">

          <div class="h-12"></div>

          <div
            v-for="patient in suchergebnisse" 
            :key="patient.id" 
            class="px-5 py-3 w-full flex justify-start items-center transition duration-150 hover:cursor-pointer hover:bg-DentaBlueUltraLight" 
            @click="existierendenPatientGewaehlt(patient)">
            <p>{{patient.nummer}}</p>
          </div>

          <div v-if="neuErlaubt && neuErlaubt === true && nutzerDarfPatientenVerwalten === true" class="w-full">
            <div 
              v-if="patientKannErstelltWerden === true"
              class="px-5 py-3 w-full flex justify-start items-center rounded-b-md text-DentaBlue transition duration-150 hover:cursor-pointer hover:bg-DentaBlueUltraLight" 
              @click="neuenPatientErstellen">

              <div class="w-5 aspect-square rounded-full bg-DentaBlueLight flex justify-center items-center">
                  <ion-icon name="add"></ion-icon>
              </div>

              <p class="ml-3">'{{suchbegriff}}' Erstellen</p>

            </div>
            <div 
              v-else
              class="px-5 py-3 w-full flex justify-start items-center rounded-b-md text-Mittelgrau">

              <div class="w-5 aspect-square rounded-full bg-UltraLeichtesGrau flex justify-center items-center">
                  <ion-icon name="add"></ion-icon>
              </div>

              <p class="ml-3">'{{suchbegriff}}' Erstellen</p>

            </div>
          </div>

        </div>
      </div>
    </div>
</template>

<script>
import { computed, ref } from '@vue/runtime-core'
import store from '@/store'
import { einmaligenStringGenerieren } from '@/services/firestoreDienst'
import { berechtigungenPruefen } from '@/services/authentifizierungsDienst'
import { patientenVerwalten } from '@/constants/berechtigungen'
export default {
    props: ["patient", "neuErlaubt"],
    emits: ["existierend", "neu"],
    setup(props, context) {

        let nutzerDarfPatientenVerwalten = computed(() => berechtigungenPruefen([patientenVerwalten]))

        let suchbegriff = ref("")
        let suchergebnisse = computed(() => {
            if(suchbegriff.value != "") {
                return store.state.patienten.filter(p => p.nummer.includes(suchbegriff.value))
            } else {
                return []
            }
        })

        let patientKannErstelltWerden = computed(() => store.state.patienten.find(p => p.nummer == suchbegriff.value) == undefined)

        function existierendenPatientGewaehlt(wert) {
            context.emit("existierend", wert)
            suchbegriff.value = ""
        }

        function neuenPatientErstellen() {
            //TODO: Patientenobjekt erstellen, und entsprechendes Event abgeben
            let patient = {
                id: einmaligenStringGenerieren(),
                eigentuemer: store.getters.praxisAbfragen.id,
                nummer: suchbegriff.value
            }

            context.emit("neu", patient)

            suchbegriff.value = ""
        }

        return {
            //State
            suchbegriff,
            suchergebnisse,
            patientKannErstelltWerden,
            nutzerDarfPatientenVerwalten,

            //Funktionen
            existierendenPatientGewaehlt,
            neuenPatientErstellen
        }

    }
}
</script>