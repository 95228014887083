<template>
    <OptionenKarte
        v-if="jcvSrIds && jcvSrIds.length > 0"
        titel="Joint Controll">
        <div class="w-full flex flex-col justify-start items-start space-y-4">
            <YsDokumentZumDownload 
                v-for="id in jcvSrIds" 
                :key="id" 
                :signatureRequestId="id"/>
        </div>
    </OptionenKarte>
</template>
<script>
import OptionenKarte from '@/components/OptionenKarte.vue';
import YsDokumentZumDownload from './YsDokumentZumDownload.vue';
import store from '@/store';
import { computed } from 'vue';
export default {
    components: {YsDokumentZumDownload, OptionenKarte},
    setup() {
        const jcvSrIds = computed(() => {
            if(store.state.partnerschaftenLaden === false) {
                return store.state.partnerschaften.filter(p => p.aktiv === true).map(p => p.signatureRequestId)
            }
        })

        return {
            jcvSrIds
        }
    }
}
</script>