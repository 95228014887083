<template>
  <img 
    v-if="quelle"
    :src="quelle" 
    alt="Vorschau"
    class="w-full h-full rounded-md opacity-50 transition duration-150 hover:cursor-pointer hover:border-2 border-AlbumBlau object-cover"
    :class="{'border-2 border-AlbumBlau opacity-100': angezeigterSnap.id == snap.id}">
    <div v-else class="w-full h-full rounded-md bg-DentaBlueUltraLight"></div>
</template>

<script>
import { ref } from '@vue/reactivity'
import store from '@/store'
import { snapUriAbfragen } from '@/services/helfer'
import { computed } from '@vue/runtime-core'
export default {
    props: ["angezeigterSnap", "snap"],
    setup(props) {
        const quelle = computed(() => store.getters.snapVorschauLinkAbfragen(props.snap.id))

        if(!quelle.value) {
            snapUriAbfragen(props.snap.id, true)
            .then(uri => {
                store.commit("snapVorschauLinkSetzen", {id: props.snap.id, link: uri})
            })
        }

        return {
            //State für das UI
            quelle
        }
    }
}
</script>
