<template>
    <GDialog 
        v-model="state"
        border-radius="10"
        max-width="400"
        background="#FFFFFF"
        overlay-background="rgba(0, 0, 0, 0.25)"
        :depressed="true"
        :persistent="true"
        @update:modelValue="stateChanged">
        <div class="w-full py-7 px-5 flex flex-col justify-center items-center font-dm-sans">
            <p v-if="titel" class="text-2xl font-medium tracking-wide mb-2">{{ titel }}</p>
            <p v-if="body" class="text-sm text-center font-medium">{{ body }}</p>
            <div 
                class="w-44 h-11 rounded-lg text-sm font-semibold tracking-wide flex justify-center items-center mt-8"
                :class="{
                    'transition duration-100 hover:cursor-pointer hover:bg-opacity-70': !laedt || laedt === false,
                    'pointer-events-none bg-opacity-70': laedt === true,
                    'bg-DentaBlueUltraLight text-DentaBlue': !destruktiv || destruktiv === false,
                    'bg-WarningHintergrund text-Warning': destruktiv === true
                }"
                @click="zustandSenden('bestaetigt')">
                <p v-if="!laedt || laedt === false">{{ bestaetigungsText ?? "Weiter" }}</p>
                <LottieAnimation
                    v-else
                    class="h-16 aspect-square pointer-events-none"
                    :animation-data="destruktiv && destruktiv === true ? ladenRot : ladenBlau"
                    :auto-play="true"
                    :loop="true"
                />
            </div>
            <p 
                class="text-sm text-Mittelgrau mt-3 tracking-wide"
                :class="{
                    'transition duration-100 hover:cursor-pointer hover:opacity-70': !laedt || laedt === false,
                    'pointer-events-none text-opacity-70': laedt && laedt === true
                }"
                @click="zustandSenden('abgebrochen')">{{ abbruchText ?? "Abbrechen" }}</p>
        </div>
    </GDialog>
</template>
<script>
import { GDialog } from 'gitart-vue-dialog';
import ladenBlau from "@/assets/animations/laden-blau.json"
import ladenRot from "@/assets/animations/laden-rot.json"
export default {
    props: ["state", "titel", "body", "bestaetigungsText", "abbruchText", "destruktiv", "laedt"],
    events: ["stateChanged", "bestaetigt", "abgebrochen"],
    components: {GDialog},
    setup(_, context) {
        const stateChanged = (val) => context.emit("stateChanged", val);
        function zustandSenden(zustand) {
            context.emit(zustand)
            if(zustand === "abgebrochen") {
                stateChanged(false)
            }
        }
        return { stateChanged, zustandSenden, ladenBlau, ladenRot }
    }
}
</script>