<template>
    <div class="w-full h-full flex flex-col justify-start items-center font-dm-sans space-y-20 mt-20">
        <div class="flex flex-col space-y-5 items-center">
            <Karte  class="w-85" :methode="zahlungsMethode" :laedt="zahlungsMethodeLaedt"/>
            <div 
                class="bg-LeichtesBlau text-DentaBlue px-10 py-2 flex justify-center items-center hover:cursor-pointer hover:bg-opacity-70 rounded-full w-min"
                @click="zahlungsMethodeAendernOeffnen">
                <p>Ändern</p>
            </div>
        </div>

        <!-- Info zur Verlängerung -->
        <OptionenKarte v-if="typeof verlaengerungsDatum === 'string'" titel="Verlängerung">
            <span>Ihr Abonnement wird am <b>{{ verlaengerungsDatum }}</b> automatisch verlängert.<br/><br/>Fragen zu Ihrem Abonnement?<br/>Kontaktieren Sie uns: <a class="text-DentaBlue transition duration-100 hover:opacity-70" href="mailto:kontakt@dentasnap.de">kontakt@dentasnap.de</a></span>
        </OptionenKarte>

        <div class="pb-20">
            <OptionenKarte titel="Rechnungen">
            <div class="w-full flex flex-col justify-center items-start">
                <!-- Überschrift -->
                <div class="w-full flex justify-between items-center text-sm font-bold mb-4">
                    <p>Nummer</p>
                    <p>Datum</p>
                    <p>Download</p>
                </div>

                <!-- Rechnungen -->
                <div class="w-full flex flex-col justify-start items-center text-sm space-y-4">
                    <div 
                        class="w-full flex justify-between items-center text-center"
                        v-for="rechnung in rechnungen" 
                        :key="rechnung.nummer">
                        <p class="w-1/3 flex justify-start">{{ rechnung.nummer }}</p>
                        <p class="w-1/3 flex justify-center">{{ dayjs.unix(rechnung.zeitpunkt).format("DD.MM.YYYY") }}</p>
                        <div class="w-1/3 flex justify-end">
                            <button class="transition duration-100 hover:opacity-70" @click="rechnungHerunterladen(rechnung)">
                                <img 
                                src="@/assets/icons/download.svg" 
                                class="w-6 aspect-square">
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </OptionenKarte>
        </div>
    </div>
</template>
<script>
import { computed, onMounted } from 'vue';
import store from "@/store"
import Karte from './components/Karte.vue';
import LadenInline from '@/components/LadenInline.vue';
import OptionenKarte from '@/components/OptionenKarte.vue';
import { useRouter } from 'vue-router';
import { useCloudFunction } from '@/services/cloudFunctionDienst';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { firestore, storage } from '@/firebase/config';
import dayjs from 'dayjs';
import { getDownloadURL, ref } from 'firebase/storage';
export default {
    components: {Karte, LadenInline, OptionenKarte},
    setup() {
        const router = useRouter()

        const verlaengerungsDatum = computed(() => {
            if(store.state.abonnementLaedt === false) {
                const aenderung = store.state.abonnement.aenderung

                if(aenderung == null || (aenderung.art != "beenden" && aenderung.art != "fehlgeschlagen")) {
                    //Verlängerungsdatum nur relevant, falls das Abo nicht zum nächsten Zahldatum beendet statt verlängert wird!
                    return dayjs.unix(store.state.abonnement.naechsteZahlung).format("DD.MM.YYYY")
                }
            }
        })

        const zahlungsMethode = computed(() => store.state.zahlungsMethode)
        const zahlungsMethodeLaedt = computed(() => store.state.zahlungsMethodeLaedt)

        const rechnungen = computed(() => store.state.rechnungen)
        const rechnungenLaden = computed(() => store.state.rechnungenLaden)

        function zahlungsMethodeAendernOeffnen() {
            router.push({name: "ZahlungsMethodeAktualisieren"})
        }

        const {error: zmAbfrageFehler, result: zmErgebnis, call: zmAbfragen} = useCloudFunction("zahlungsMethodeAbfragen")

        function zahlungsMethodenSetzen() {
            if(zahlungsMethodeLaedt.value) {
                zmAbfragen()
                .then(() => {
                    if(!zmAbfrageFehler.value) {
                        store.commit("zahlungsMethodeSetzen", zmErgebnis.value)
                    }
                })
            }
        }

        async function rechnungenSetzen() {
            try {
                if (rechnungenLaden.value) {
                    // Sollte das Abonnement der Praxis noch nicht geladen sein, wird es hier zunächst gesetzt
                    if (store.state.abonnementLaedt) {
                        const aboQuery = query(collection(firestore, "abonnements"), where("praxisId", "==", store.getters.praxisAbfragen.id));
                        const aboSnapshot = await getDocs(aboQuery);
                        
                        if (!aboSnapshot.empty) {
                            store.commit("abonnementSetzen", aboSnapshot.docs[0].data())
                        }
                    }

                    // Danach werden die Rechnungen abgefragt und gesetzt
                    const rechnungenSnapshot = await getDocs(collection(firestore, `abonnements/${store.state.abonnement.id}/rechnungen`));
                    if (!rechnungenSnapshot.empty) {
                        store.commit("rechnungenSetzen", rechnungenSnapshot.docs.map(d => d.data()).sort((a, b) => b.zeitpunkt - a.zeitpunkt))
                    }
                }
            } catch (error) {
                console.log("Error loading Invoices: ", error);
            }
        }

        function rechnungHerunterladen(rechnung) {
            // Convert the UNIX timestamp to mm-yyyy format
            const formattedDate = dayjs.unix(rechnung.zeitpunkt).format('MM-YYYY');

            // Generate the path reference to the file in Firebase Cloud Storage
            const filePath = `rechnungen/${formattedDate}/${rechnung.nummer}.pdf`;
            const fileRef = ref(storage, filePath);

            // Fetch the download URL and trigger a download
            getDownloadURL(fileRef)
                .then((url) => {
                    // Create an anchor element to trigger the download
                    const a = document.createElement("a");
                    a.href = url;
                    a.download = `${rechnung.nummer}.pdf`; // Name the file with the invoice number
                    a.click();
                })
                .catch((error) => {
                    //TODO: Fehler senden
                    console.error("Error fetching download URL:", error);
                });
        }

        onMounted(() => {
            zahlungsMethodenSetzen()
            rechnungenSetzen()
        })

        return {
            dayjs,
            zahlungsMethodeAendernOeffnen,
            rechnungHerunterladen,
            zahlungsMethode,
            zahlungsMethodeLaedt,
            rechnungen,
            verlaengerungsDatum
        }
    }
}
</script>