<template>
  <LadenInline v-if="(typeof index !== 'number')" />
  <div v-else class="w-full h-screen flex justify-start items-center p-5 bg-white">
    <OnboardingFortschrittsAnzeige class="h-full w-1/3 mr-40" :index="index" :schritte="schritte" :fehler="fehler" />

    <component :is="komponente" :fehler="fehler" :laedt="laedt" :zahlungsMethode="zahlungsMethode"
      @zahlungsMethodeAktualisiert="m => zahlungsMethode = m" @aendern="zahlungsMethodeAendern" />

  </div>
</template>
<script>
import OnboardingFortschrittsAnzeige from '@/components/OnboardingFortschrittsAnzeige.vue';
import Information from "./components/information/Information.vue"
import Fertig from "./components/fertig/Fertig.vue"
import LadenInline from '@/components/LadenInline.vue';
import { computed, onMounted, ref } from 'vue';
import { useCloudFunction } from '@/services/cloudFunctionDienst';
import { fehlerSenden } from '@/services/LogDienst';
import { fehlerAnzeigen } from '@/services/toastDient';
import { useRoute } from 'vue-router';
export default {
  components: {
    LadenInline,
    OnboardingFortschrittsAnzeige,
    Information,
    Fertig
  },
  setup() {
    const route = useRoute()

    //Aktueller Navigationsindex
    const index = ref(null)

    const laedt = ref(false)

    const fehler = ref(false)

    /** Aktuelle vom Nutzer gewählte Zahlungsmethode */
    const zahlungsMethode = ref(null)

    //Gibt zu jeder Route einen passenden "Schritt" zurück, 
    //der auf der Fortschrittsanzeige angezeigt wird.
    /** Gibt für jede Route das passende "schritt" Objekt für die Fortschrittsanzeige zurück.*/
    const schritte = [
      {
        index: 0,
        titel: "Neue Zahlungsmethode",
        untertitel: "Wählen Sie Ihre neue Zahlungsmethode."
      }, {
        index: 1,
        titel: "Fertig",
        untertitel: "Der Wechsel wird nun durchgeführt."
      },
    ]

    const komponente = computed(() => {
      switch (index.value) {
        case 1:
          return Fertig
        default:
          return Information
      }
    })

    const { error: aktualisierungFehler, result: aktualisierungErgebnis, call: aktualisieren } = useCloudFunction("methodeAktualisierenStarten")

    /**
     * Startet die Änderung der Zahlungsmethode, in dem der Endpunkt aufgerufen wird,
     * und bei Erfolgreicher Rückgabe die Weiterleitungsseite angezeigt wird,
     */
    function zahlungsMethodeAendern() {
      if (typeof zahlungsMethode.value === "string") {
        laedt.value = true
        aktualisieren({
          methode: zahlungsMethode.value
        })
          .then(() => {
            if (aktualisierungFehler.value === false) {
              window.location.href = aktualisierungErgebnis.value.weiterleitung
            } else {
              fehlerAnzeigen("Beim Vorbereiten des Wechsels ist ein Fehler aufgetreten. Es wurden keine Änderungen vorgenommen.")
              laedt.value = false
            }
          })
      }
    }

    function initialisieren() {
      const status = route.query.erfolg
      if (status === "true" || status === "false") {
        fehler.value = route.query.erfolg === "false"
        console.log(fehler.value);
        index.value = 1
      } else {
        index.value = 0
      }
    }

    onMounted(initialisieren)

    return {
      index,
      schritte,
      fehler,
      komponente,
      zahlungsMethode,
      laedt,
      zahlungsMethodeAendern
    }
  }
}
</script>