<template>
    <div class="w-full h-full flex justify-center items-center">
        <MitarbeiterEinrichten :onboarding="false" @weiter="nutzerEingeladen" />
    </div>
</template>
<script>
import MitarbeiterEinrichten from '@/pages/konto-konfiguration/components/MitarbeiterEinrichten/MitarbeiterEinrichten.vue';
import { erfolgAnzeigen } from '@/services/toastDient';
export default {
    components: {
        MitarbeiterEinrichten
    },
    setup() {

        function nutzerEingeladen() {
            erfolgAnzeigen("Einladungen Versendet.")
        }

        return {
            nutzerEingeladen
        }
    }
}
</script>