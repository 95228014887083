<template>
  <div
    class="w-full h-full rounded-md flex flex-col justify-between items-start p-4 bg-LeichtesGrau"
  >
    <p class="text-DentaBlue">Guide</p>
    <img src="@/assets/arrow.svg" class="w-10 h-10" />
  </div>
</template>

<script>
export default {};
</script>