<template>
    <div 
        v-if="dokumentFehler === false" 
        class="w-full h-6 flex justify-between items-center px-5">
            <div class="w-full flex space-x-5 justify-start items-center">
                <div v-if="dokumentLaedt === true" 
                    class="h-5 w-5 rounded-lg bg-LeichtesGrau bg-opacity-40"/>
                <img
                    v-else
                    class="w-5 aspect-square object-contain" 
                    src="@/assets/icons/dokument-schwarz.svg" 
                    alt="doc">
                <div v-if="dokumentLaedt === true" class="h-5 w-2/3 rounded-lg bg-LeichtesGrau bg-opacity-40"/>
                <p v-else>{{ name }}</p>
            </div>

            <div v-if="dokumentLaedt === true" class="h-5 w-5 rounded-lg bg-LeichtesGrau bg-opacity-40"/>
            <button
                v-else 
                :disabled="downloadLaedt" 
                class="transition duration-100 hover:opacity-70" 
                :class="{'opacity-70': downloadLaedt}"
                @click="dokumentHerunterladen">
                    <img
                        class="w-4 aspect-auto object-contain" 
                        src="@/assets/icons/download-blau.svg" 
                        alt="download">
            </button>
    </div>
</template>
<script>
import { useCloudFunction } from '@/services/cloudFunctionDienst';
import { onMounted, ref } from 'vue';

export default {
    props: ["signatureRequestId"],
    setup(props) {
        const dokumentLaedt = ref(true)
        const dokumentFehler = ref(false)
        const downloadLaedt = ref(false)

        const name = ref(null)
        const dokumentId = ref(null)

        const {error: infoFehler, result: infoErg, call: informationenAbfragen} = useCloudFunction("dokumentInfosAbfragen")
        const {error: herunterladeFehler, result: herunterladeErg, call: herunterladen} = useCloudFunction("dokumentHerunterladen")

        function dokumentVorbereiten() {
            dokumentLaedt.value = true
            informationenAbfragen({signatureRequestId: props.signatureRequestId})
            .then(() => {
                if(infoFehler.value === false) {
                    name.value = infoErg.value.name
                    dokumentId.value = infoErg.value.id
                } else {
                    dokumentFehler.value = true
                }
            })
            .finally(() => dokumentLaedt.value = false)
        }

        function base64ToBlob(base64, mimeType) {
            const byteCharacters = atob(base64);
            const byteArrays = [];

            for (let offset = 0; offset < byteCharacters.length; offset += 512) {
                const slice = byteCharacters.slice(offset, offset + 512);

                const byteNumbers = new Array(slice.length);
                for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
                }

                const byteArray = new Uint8Array(byteNumbers);
                byteArrays.push(byteArray);
            }

            return new Blob(byteArrays, { type: mimeType });
        }

        function dokumentHerunterladen() {
            if(typeof props.signatureRequestId === "string" && typeof dokumentId.value === "string") {
                downloadLaedt.value = true
                herunterladen({signatureRequestId: props.signatureRequestId, documentId: dokumentId.value})
                .then(() => {
                    if(herunterladeFehler.value === false) {
                        //Empfangene Binärdaten werden in einen "Dateiblob" konbertiert 
                        const blob = base64ToBlob(herunterladeErg.value.datei, 'application/pdf');

                        //Für den Dateiblow wird ein Downloadlink + URL erstellt, und das Dokument dann heruntergeladen
                        const link = document.createElement('a');
                        link.href = URL.createObjectURL(blob);
                        link.download = name.value;
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);
                    }
                })
                .finally(() => downloadLaedt.value = false)
            }
        }

        onMounted(dokumentVorbereiten)

        return {
            dokumentLaedt,
            dokumentFehler,
            downloadLaedt,
            name,
            dokumentVorbereiten,
            dokumentHerunterladen
        }
    }
}
</script>