import { createRouter, createWebHistory } from "vue-router";
import { berechtigungenPruefen } from "@/services/authentifizierungsDienst";
const { nutzer } = require("@/services/authentifizierungsDienst");
import { RouteLocationNormalized } from "vue-router";
import {
  ausstehendeKonfigurationenPruefen,
  mobileAbfragen,
} from "@/services/helfer";
import Anmeldung from "../pages/anmeldung/Anmeldung.vue";
import Aufnahmen from "../pages/aufnahmen/Aufnahmen.vue";
import Partnerschaften from "../pages/partnerschaften/Partnerschaften.vue";
import AnfrageSenden from "../pages/partnerschaften/pages/anfrage-senden/AnfrageSenden.vue";
import AnfrageAnnehmen from "../pages/partnerschaften/pages/anfrage-annehmen/AnfrageAnnehmen.vue";
import Themen from "../pages/themen/Themen.vue";
import Patienten from "../pages/patienten/Patienten.vue";
import Heute from "../pages/heute/Heute.vue";
import SessionHochladen from "../pages/gallerie/pages/session-hochladen/SessionHochladen.vue";
import SessionBearbeiten from "../pages/gallerie/pages/session-bearbeiten/SessionBearbeiten.vue";
import Gallerie from "../pages/gallerie/Gallerie.vue";
import Praxis from "../pages/praxis/Praxis.vue";
import PraxisVerwaltung from "../pages/praxis-verwaltung/PraxisVerwaltung.vue";

import Informationen from "../pages/praxis-verwaltung/pages/informationen/Informationen.vue";
import Berechtigungen from "../pages/praxis-verwaltung/pages/berechtigungen/Berechtigungen.vue";
import NutzerEinladen from "../pages/praxis-verwaltung/pages/nutzer-einladen/NutzerEinladen.vue";
import Zahlungen from "../pages/praxis-verwaltung/pages/zahlungen/Zahlungen.vue";
import ZahlungsMethodeAktualisieren from "../pages/praxis-verwaltung/pages/zahlungen/pages/ZahlungsMethodeAktualisieren/ZahlungsMethodeAktualisieren.vue";
import Abonnement from "../pages/praxis-verwaltung/pages/abonnement/Abonnement.vue";
import PaketUpgrade from "../pages/praxis-verwaltung/pages/abonnement/pages/paket-upgrade/PaketUpgrade.vue";
import AbonnementKuendigen from "../pages/praxis-verwaltung/pages/abonnement/pages/beenden/Beenden.vue";
import Dokumente from "../pages/praxis-verwaltung/pages/dokumente/Dokumente.vue";

import GeraetAnmelden from "../pages/praxis/pages/geraet-anmelden/GeraetAnmelden.vue";

import Konto from "@/pages/konto/Konto.vue";
import KontoInformationen from "@/pages/konto/pages/informationen/Informationen.vue";
import KontoSicherheit from "@/pages/konto/pages/sicherheit/Sicherheit.vue";
import KontoNummerHinzufuegen from "@/pages/konto/pages/sicherheit/pages/nummer-hinzufuegen/NummerHinzufuegen.vue";

import Aufnahme from "../pages/aufnahme/Aufnahme.vue";
import NichtVorhanden from "@/pages/nicht-vorhanden/NichtVorhanden.vue";
import Wartungsmodus from "@/pages/wartungsmodus/Wartungsmodus.vue";
import UpdateNoetig from "@/pages/update-noetig/UpdateNoetig.vue";
import PraxisGesperrt from "@/pages/praxis-gesperrt/PraxisGesperrt.vue";
import KontoKonfiguration from "@/pages/konto-konfiguration/KontoKonfiguration.vue";
import store from "@/store";
import Laden from "@/pages/laden/Laden.vue";
import MobileSperrung from "../pages/mobile-sperrung/MobileSperrung.vue";
import NichtBerechtigt from "@/pages/nicht-berechtigt/NichtBerechtigt.vue";
import { aktiv } from "@/constants/abonnement-status";
import {
  patientenVerwalten,
  sessionsVerwalten,
  themenVerwalten,
  zusammenarbeitVerwalten,
} from "@/constants/berechtigungen";
import { eigentuemer } from "@/constants/konstanten";
import { pfade, routenNamen } from "@/constants/mobile-sperrung-ausnahmen";

/**
 * Prüft vor der navigation:
 * - Ob ein Nutzer angemeldet ist
 * - Ob alle essentiellen Daten geladen wurden
 * - Ob der Client veraltet oder der Wartungsmodus für den Client ausgesetzt ist
 * - (Wenn gewollt) ob der Nutzer Zugriff auf die gewünschte Seite hat
 * @param {RouteLocationNormalized} from Ursprungsroute
 * @param {RouteLocationNormalized} to Zielroute
 * @param {*} next Handler, der die Navigation vollendet (ohne Änderung, stattdessen mit Weiterleitung oder mit Abbruch)
 * @param {[String] | null} berechtigungen Optionale Liste von Berechtigungen, die der Nutzer haben muss um fortzufahren
 */
function guard(to, from, next, berechtigungen) {
  //Prüft, dass der Nutzer ein kompatibles Gerät verwendet
  if (mobileGuard(to, from, next)) return;

  //Prüft, dass ein Nutzer angemeldet ist
  if (anmeldeGuard(to, next)) return;

  //Prüft vorhandensein der essentiellen Daten
  if (datenGeladenGuard(from, to, next)) return;

  //Prüft die Instandhaltung
  if (neuerInstandhaltungsGuard(from, to, next)) return;

  //Prüft, ob die Praxis aktuell gesperrt ist
  if (praxisGesperrtGuard(next)) return;

  //Prüft die Vollständige Konfiguration des angemeldeten Nutzers
  if (kontoVollstaendigkeitsGuard(next)) return;

  if (berechtigungen && berechtigungen.length > 0) {
    //Prüft die zureichenden Berechtigungen des angemeldeten Nutzers
    if (berechtigungenGuard(next, berechtigungen)) return;
  }

  //Wenn keine Ausnahme zutrifft, wird die navigation regulär abgeschlossen
  next();
}

function mobileGuard(to, from, next) {
  const mobile = mobileAbfragen();

  if (mobile && to.name === "MobileSperrung") {
    next();
  } else if (!mobile && to.name === "MobileSperrung") {
    //Nicht-Mobile Geräte sollen nicht die "Mobile Sperrung" Seite aufrufen
    next({ path: "/" });
    return true;
  } else if (
    mobile &&
    !(
      routenNamen.includes(to.name) ||
      pfade.find((p) => to.path.includes(p)) != undefined
    )
  ) {
    //Für Mobile-Nutzung ist nur die Konto Konfiguration zugelassen
    next({ name: "MobileSperrung" });
    return true;
  }
}

/**
 * Prüft, ob ein Nutzer angemeldet ist.
 * Ist dies nicht der Fall wird der Nutzer zur Anmeldung geleitet, ansonsten passiert nichts.#
 * @param {RouteLocationNormalized} to Zielroute, wird gecached wenn der Nutzer zur Anmeldung weitergeleitet wird.
 * @param {*} next
 */
function anmeldeGuard(to, next) {
  if (!nutzer.value) {
    if (to.name != "Anmeldung") {
      store.state.anmeldeWeiterleitung = to.name;
    }

    next({ name: "Anmeldung" });

    return true;
  }
}

/**
 * Prüft, ob die essentiellen Daten für Funktion und weitere Prüdungen geladen sind. Ist dies nicht der Fall,
 * wird auf einen Ladescreen weitergeleitet, bis alle Daten geladen sind.
 */
function datenGeladenGuard(from, to, next) {
  if (
    store.state.kontoKonfigurationLaedt ||
    store.state.praxisLaedt ||
    store.state.wartungLaedt ||
    store.state.updateNoetigLaedt
  ) {
    if (to.name != "Laden") {
      store.state.ladeWeiterleitung = to;
    }

    if (from.name != "Laden") {
      next({ name: "Laden" });
    }

    return true;
  }
}

/**
 * Prüft anhand der lokal gespeicherten Werte, ob der Client veraltet ist, oder sich im Wartungsmodus befindet.
 * @param {RouteLocationNormalized} to Zielroute
 * @param {*} next Handler, der die Navigation abschließt.
 */
function neuerInstandhaltungsGuard(from, to, next) {
  if (store.state.wartung) {
    next({ name: "Wartungsmodus" });
    return true;
  }

  if (store.state.updateNoetig) {
    next({ name: "UpdateNoetig" });
    return true;
  }
}

/**
 * Prüft, ob der Nutzer seine Kontokonfiguration vollständig abgeschlossen hat. Ist dies nicht der Fall, wird
 * zur Konto-Konfiguration weitergeleitet
 */
function kontoVollstaendigkeitsGuard(next) {
  if (ausstehendeKonfigurationenPruefen(store.state.kontoKonfiguration)) {
    next({ name: "KontoKonfiguration" });
    return true;
  }
}

function praxisGesperrtGuard(next) {
  if (store.state.abonnement.status.status !== aktiv) {
    next({ name: "PraxisGesperrt" });
    return true;
  }
}

/**
 * Prüft, ob der Nutzer alle Berechtigungen hat, die in der Liste gegeben sind.
 * Andernfalls wird zur "Nicht Berechtigt" Seite weitergeleitet.
 * Eine Außnahme bilden Nutzer ohne Webapp Berechtigung im Fall, dass Sie auf die "Konto Konfiguration"
 * navigieren möchten. Dies ist in jedem Fall erlaubt.
 */
function berechtigungenGuard(next, berechtigungen) {
  if (!berechtigungenPruefen(berechtigungen)) {
    next({ name: "NichtBerechtigt" });
    return true;
  }
}

/**
 * Prüft, ob eine Praxis ein "tester" (DentaSnap Partner) Abonnement verwendet.
 * Wenn ja, können hier bestimmte Seiten (vorerst die "Zahlungen" Seite) blockiert werden.
 */
function testerGuard(to, next) {
  if (store.getters.istTesterAbo === true) {
    //Nicht erlaubte Verwaltungsseiten leiten auf die Standard Verwaltungsseite.
    if (to.name == "PraxisVerwaltungZahlungen") {
      next({ name: "PraxisVerwaltungInformationen" });
    }
  } else {
    next();
  }
}

function sessionBearbeitenGuard(to, next) {
  //TODO: Logik für Route-Guard implementieren
}

const allgemeineRouten = [
  {
    path: "/",
    redirect: "/gallerie",
  },
  {
    name: "Anmeldung",
    path: "/anmeldung",
    component: Anmeldung,
  },
  {
    name: "UpdateNoetig",
    path: "/update",
    component: UpdateNoetig,
  },
  {
    name: "Wartungsmodus",
    path: "/wartung",
    component: Wartungsmodus,
  },
  {
    name: "MobileSperrung",
    path: "/geraet-inkompatibel",
    component: MobileSperrung,
    beforeEnter: mobileGuard,
  },
  {
    name: "Praxis",
    path: "/organisation",
    component: Praxis,
    beforeEnter: (to, from, next) => guard(to, from, next, [eigentuemer]),
  },
  {
    path: "/organisation/verwaltung",
    component: PraxisVerwaltung,
    beforeEnter: (to, from, next) => guard(to, from, next, [eigentuemer]),
    children: [
      {
        name: "PraxisVerwaltungInformationen",
        path: "/organisation/verwaltung/informationen",
        component: Informationen,
      },
      {
        name: "PraxisVerwaltungBerechtigungen",
        path: "/organisation/verwaltung/berechtigungen",
        component: Berechtigungen,
      },
      {
        name: "PraxisVerwaltungNutzerEinladen",
        path: "/organisation/verwaltung/nutzer-einladen",
        component: NutzerEinladen,
      },
      {
        name: "PraxisVerwaltungDokumente",
        path: "/organisation/verwaltung/dokumente",
        component: Dokumente,
      },
    ],
  },
  {
    name: "PraxisGesperrt",
    path: "/organisation-gesperrt",
    component: PraxisGesperrt,
    beforeEnter: (to, from, next) => {
      if (datenGeladenGuard(from, to, next)) return;
      next();
    },
  },
  {
    path: "/organisation-gesperrt/erfolg",
    redirect: "/organisation-gesperrt?erfolg=true",
    beforeEnter: (to, from, next) => {
      if (datenGeladenGuard(from, to, next)) return;
      next();
    },
  },
  {
    path: "/organisation-gesperrt/fehler",
    redirect: "/organisation-gesperrt?erfolg=false",
    beforeEnter: (to, from, next) => {
      if (datenGeladenGuard(from, to, next)) return;
      next();
    },
  },
  {
    name: "KontoKonfiguration",
    path: "/kontoKonfiguration",
    component: KontoKonfiguration,
  },
  {
    name: "Gallerie",
    path: "/gallerie",
    component: Gallerie,
    beforeEnter: guard,
  },
  {
    name: "Aufnahme",
    path: "/aufnahme/:sessionId/:snapId",
    component: Aufnahme,
    beforeEnter: guard,
  },
  {
    name: "AufnahmenPatient",
    path: "/aufnahmen/patient/:id",
    component: Aufnahmen,
    beforeEnter: guard,
  },
  {
    name: "AufnahmenThema",
    path: "/aufnahmen/thema/:id",
    component: Aufnahmen,
    beforeEnter: guard,
  },
  {
    name: "AufnahmenPartner",
    path: "/aufnahmen/partner/:id",
    component: Aufnahmen,
    beforeEnter: guard,
  },
  {
    name: "Partner",
    path: "/partner",
    component: Partnerschaften,
    //Bis die Rechtsfrage geklärt ist, dürfen nur die Eiegntümer einer Praxis auf die Partnerschaften zugreifen bzw. Sie Annehmen / versenden
    beforeEnter: (to, from, next) => guard(to, from, next, [eigentuemer]),
  },
  {
    name: "AnfrageSenden",
    path: "/partner/anfrage-senden",
    component: AnfrageSenden,
    //Bis die Rechtsfrage geklärt ist, dürfen nur die Eiegntümer einer Praxis auf die Partnerschaften zugreifen bzw. Sie Annehmen / versenden
    beforeEnter: (to, from, next) => guard(to, from, next, [eigentuemer]),
  },
  {
    name: "AnfrageAnnehmen",
    path: "/partner/anfrage-annehmen",
    component: AnfrageAnnehmen,
    //Bis die Rechtsfrage geklärt ist, dürfen nur die Eiegntümer einer Praxis auf die Partnerschaften zugreifen bzw. Sie Annehmen / versenden
    beforeEnter: (to, from, next) => guard(to, from, next, [eigentuemer]),
    props: true,
  },
  {
    name: "Themen",
    path: "/themen",
    component: Themen,
    beforeEnter: (to, from, next) => guard(to, from, next, [themenVerwalten]),
  },
  {
    name: "Patienten",
    path: "/patienten",
    component: Patienten,
    beforeEnter: (to, from, next) =>
      guard(to, from, next, [patientenVerwalten]),
  },
  {
    path: "/konto",
    component: Konto,
    beforeEnter: guard,
    children: [
      {
        name: "KontoInformationen",
        path: "/konto/informationen",
        component: KontoInformationen,
      },
      {
        name: "KontoSicherheit",
        path: "/konto/sicherheit",
        component: KontoSicherheit,
      },
      {
        name: "KontoNummerHinzufuegen",
        path: "/konto/sicherheit/nummer-hinzufuegen",
        component: KontoNummerHinzufuegen,
      },
    ],
  },
  {
    name: "NichtBerechtigt",
    path: "/nicht-berechtigt",
    component: NichtBerechtigt,
  },
  {
    name: "Laden",
    path: "/laden",
    component: Laden,
  },
  {
    name: "NichtVorhanden",
    path: "/:pathMatch(.*)*",
    component: NichtVorhanden,
  },
];

const laborRouten = [
  //TODO: Wenn nötig hier laborspezifische Routen implementieren
];
const praxisRouten = [
  {
    path: "/",
    redirect: "/themen",
  },
  {
    name: "Heute",
    path: "/heute",
    component: Heute,
    beforeEnter: guard,
  },
  {
    name: "SessionHochladen",
    path: "/gallerie/session-hochladen",
    component: SessionHochladen,
    beforeEnter: (to, from, next) => guard(to, from, next, [sessionsVerwalten]),
  },
  {
    name: "SessionBearbeiten",
    path: "/gallerie/session-bearbeiten/:sessionId/:typ",
    component: SessionBearbeiten,
    props: true,
    beforeEnter: (to, from, next) => {
      //Zuerst der normale Guard
      if (!guard(to, from, next, [sessionsVerwalten])) return;

      //Der speziell auf die "Bearbeiten" Seite zugeschnittene Guard
      if (!sessionBearbeitenGuard(to, next)) return;
    },
  },
  {
    name: "Praxis",
    path: "/organisation",
    component: Praxis,
    beforeEnter: (to, from, next) => guard(to, from, next, [eigentuemer]),
  },
  {
    name: "GerätAnmelden",
    path: "/organisation/geraet-anmelden",
    component: GeraetAnmelden,
    beforeEnter: (to, from, next) => guard(to, from, next, [eigentuemer]),
  },
  {
    name: "PraxisVerwaltungZahlungen",
    path: "/organisation/verwaltung/zahlungen",
    component: Zahlungen,
    beforeEnter: (to, _, next) => testerGuard(to, next),
  },
  {
    name: "ZahlungsMethodeAktualisieren",
    path: "/organisation/verwaltung/zahlungen/zahlungsmethode-aktualisieren",
    component: ZahlungsMethodeAktualisieren,
  },
  {
    path: "/organisation/verwaltung/zahlungen/zahlungsmethode-aktualisieren/erfolg",
    redirect:
      "/organisation/verwaltung/zahlungen/zahlungsmethode-aktualisieren?erfolg=true",
  },
  {
    path: "/organisation/verwaltung/zahlungen/zahlungsmethode-aktualisieren/fehler",
    redirect:
      "/organisation/verwaltung/zahlungen/zahlungsmethode-aktualisieren?erfolg=false",
  },
  {
    name: "PraxisVerwaltungAbonnement",
    path: "/organisation/verwaltung/abonnement",
    component: Abonnement,
  },
  {
    name: "PaketAendern",
    path: "/organisation/verwaltung/abonnement/paket-upgrade",
    component: PaketUpgrade,
    props: true,
  },
  {
    name: "AbonnementKuendigen",
    path: "/organisation/verwaltung/abonnement/abonnement-kuendigen",
    component: AbonnementKuendigen,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: allgemeineRouten,
});

router.beforeEach((to, from) => {
  //Es wird geprüft, ob die angefragte Route eine andere hauptroute als die jetzige ist.
  //Wenn ja wird die jetzige im Store gespeichert
  if (to.name != from.name) {
    store.commit("vorherigeRouteSetzen", from);
  }
});

function laborRoutenSetzen() {
  for (let i = 0; i < laborRouten.length; i++) {
    const route = laborRouten[i];
    if (!router.hasRoute(route.name)) {
      router.addRoute(route);
    }
  }
}

function praxisRoutenSetzen() {
  for (let i = 0; i < praxisRouten.length; i++) {
    const route = praxisRouten[i];
    if (!router.hasRoute(route.name)) {
      router.addRoute(route);
    }
  }
}

export { router, praxisRoutenSetzen, laborRoutenSetzen };
