<!-- Standard Toast komponente mit Timeout, die mit "icon", "titel" und "inhalt" props angepasst werden kann. -->
<template>
  <div class="w-full h-full flex justify-center items-center px-5">
    <!-- Icon und Texte -->
    <div class="w-full h-full flex justify-start items-center">
      <!-- Icon -->
      <ion-icon :name="icon" class="text-3xl mr-4" :class="iconFarbklasse"></ion-icon>

      <!-- Titel -und Inhalts Texte -->
      <div class="h-full flex flex-col justify-center items-start text-sm">
        <p class="font-bold">{{titel}}</p>
        <p>{{inhalt}}</p>
      </div>
    </div>

    <!-- "Schliessen" button -->
    <ion-icon 
      name="close-outline" 
      class="text-3xl text-Mittelgrau transition duration-150 hover:cursor-pointer hover:text-LeichtesGrau"
      @click="schliessen"
    ></ion-icon>
  </div>
</template>

<script>
export default {

  props: ["toastId", "iconFarbklasse", "icon", "titel", "inhalt"],

  setup(props, context) {
    //Wird aufgerufen, wenn der "schliessen" iconbutton gedrückt wird.
    const schliessen = () => context.emit("close-toast")
  
    return {
      //Funktionen für das UI
      schliessen
    }
  }
}
</script>