const dokumente = [
  {
    index: 0,
    name: "Allgemeine-Geschäftsbedingungen.pdf",
    downloadUrl: "https://cms.dentasnap.de/uploads/agb_c9b99f2640.pdf",
  },
  {
    index: 1,
    name: "Datenschutzbestimmungen-Praxis.pdf",
    downloadUrl:
      "https://cms.dentasnap.de/uploads/datenschutzhinweise_4e569d4b7e.pdf",
  },
  {
    index: 2,
    name: "Datenschutzbestimmungen-Patient.pdf",
    downloadUrl:
      "https://cms.dentasnap.de/uploads/datenschutzhinweise_patient_30ed9b3e60.pdf",
  },
];

module.exports = {
  dokumente,
};
