<template>
  <div class="w-full h-screen flex flex-col justify-start items-center bg-white">
    <!-- Verschachtelte Navigation -->
    <VerschachtelteNavigation v-if="!navVerstecken" class="pb-24" :vorherigeRoute="vorherigeRoute" :routen="routen" />

    <!-- Aktuelle (verschachtelte) Seite -->
    <router-view />
  </div>
</template>
<script>
import VerschachtelteNavigation from "@/components/verschachtelte-navigation/VerschachtelteNavigation.vue"
import { computed } from "vue"
import { useRoute } from "vue-router"
export default {
  components: {
    VerschachtelteNavigation
  },
  setup() {
    const vorherigeRoute = { name: "Zurück", pfad: "/" }
    const route = useRoute()

    var routen = [
      { id: 0, name: "Informationen", routenName: "KontoInformationen" },
      { id: 1, name: "Sicherheit", routenName: "KontoSicherheit" },
    ]

    //Navigationsleiste wird nicht auf den Unterseiten angezeigt,
    const navVerstecken = computed(() => route.name == "KontoNummerHinzufuegen")

    return {
      vorherigeRoute,
      routen,
      navVerstecken
    }
  }
}
</script>