//Verwaltet den Lokalen Speicher, in den Nutzer, Praxen und Snaps aus Datenbankanfragen
//übergeben werden.

//Benötigte Referenzen werden importiert
import { auth } from "@/firebase/config";
import { laborRoutenSetzen, praxisRoutenSetzen, router } from "@/router";
import { createStore } from "vuex";

export default createStore({
  state: {
    istPraxis: false,
    kontoKonfiguration: null,
    abonnement: null,
    paket: null,
    praxis: null,
    konten: null,
    sessions: null,
    snaps: null,
    themen: null,
    partnerschaften: null,
    patienten: null,
    pakete: null,
    wartung: false,
    updateNoetig: false,
    zahlungsMethode: null,
    rechnungen: null,
    tester: true,
    updateLog: null,

    //Ladeindikatoren der Extern abzufragenden Daten
    kontoKonfigurationLaedt: false,
    abonnementLaedt: true,
    paketLaedt: true,
    praxisLaedt: true,
    kontenLaden: true,
    sessionsLaden: true,
    snapsLaden: true,
    themenLaden: true,
    partnerschaftenLaden: true,
    patientenLaden: true,
    wartungLaedt: false,
    updateNoetigLaedt: false,
    paketeLaden: true,
    zahlungsMethodeLaedt: true,
    rechnungenLaden: true,
    updateLogLaedt: true,

    //Gibt an, ob der Nutzer ein Gerät mit mobiler Displaygröße verwendet
    mobile: false,

    //Speichert eine liste aller vom Nutzer registrierter Faktoren für die MFA
    registrierteFaktoren: null,

    //Speichert immer die letzte "große" Route. Wird auf die "Gallerie" Route initialisiert
    previousRoute: null,

    /**
     * Hier kann der Name der Route gespeichert werden, auf die nach Fertigladen
     * aller essentiellen Daten weitergeleitet wird.
     */
    ladeWeiterleitung: null,

    /** Hier kann der Name der Route gespeichert werden,
     * auf die nach einer erfolgreichen Anmeldung navigiert wird */
    anmeldeWeiterleitung: null,

    /**
     * Hier kann der Name der Route gespeichert werden, auf den navigiert wird wenn der Wartungsmodus
     * wieder deaktiviert ist.
     */
    wartungWeiterleitung: null,

    //Wird genutzt, um bei in der "DatumsAuswahl" Komponente den Kalender und den Picker
    //zu synchronisieren
    auswahlDatum: null,

    //Wird genutzt, um den "Upload Fortschritt" Toast mit dem aktuellen Fortschrittsprozentsatz zu versorgen.
    //Liegt immer zwischen 0 und 100.
    uploadFortschritt: 0,

    //Speichert lokale links für alle Snaps
    snapUris: {},

    //Speichert die "Data-URL's" der Icons für die Standardthemen. Die Keys der Objekte sind die Themen-ID's, der Wert der URL.
    standardThemenIconCache: {},
  },

  //Mit diesen Funktionen können lokale Werte abgefragt werden.
  getters: {
    //Gint wenn vorhanden die vorherige Route zurück.
    //Wird für Rückwärtsnavigation auf gleichen Seiten genutzt (z.B Aufnahmeseite)
    vorherigeRouteAbfragen: (state) => state.previousRoute,

    //Gibt (wenn vorhanden) den aktuell gespeicherten Wert für die Route zurück, die nach der Anmeldung
    //geladen werden soll.
    nachAnmeldungRouteAbfragen: (state) => state.nachAnmeldungRoute,

    //Gibt den aktuell gespeicherten DentaSnap Nutzer zurück.
    //(nicht zu verwechseln mit dem Firebase-Auth Nutzer!)
    nutzerAbfragen: (state) => {
      if (auth.currentUser && state.praxis) {
        return state.praxis.konten[auth.currentUser.uid];
      } else {
        return null;
      }
    },

    //Gibt die aktuell lokal gespeicherte Praxis zurück.
    praxisAbfragen: (state) => {
      return state.praxis;
    },

    //Gibt die Liste der vom Nutzer registrierten Faktoren für die 2FA zurück.
    registrierteFaktorenAbfragen: (state) => state.registrierteFaktoren,

    //Gibt als Liste alle Behandler der aktuell angemeldeten Praxis zurück
    behandlerAbfragen: (state) => {
      var ergebnis = [];
      if (state.praxis) {
        for (const behandlerKey of Object.keys(state.praxis.konten)) {
          ergebnis.push(state.praxis.konten[behandlerKey]);
        }
      }
      return ergebnis;
    },

    /** Gibt alle aktuell vorhandenen Themen zurück. */
    themenAbfragen: (state) => state.themen,

    //Gibt, wenn vorhanden, das Thema zu der gegebenen Themen-ID zurück.
    themaAbfragen: (state) => (id) => state.themen.find((t) => t.id == id),

    //Gibt den patienten (wenn vorhanden) für die gegebene ID zurück
    patientAbfragen: (state) => (id) => state.patienten.find((p) => p.id == id),

    patientenNummerAbfragen: (state) => (nummer) =>
      state.patienten.find((p) => p.nummer == nummer),

    //Gibt (wenn vorhanden) das aktuell gespeicherte Datum für die "DatumsAuswahl" Komponente zurück.
    auswahlDatumAbfragen: (state) => state.auswahlDatum,

    /** Gibt alle aktuell gespeicherten Sessions zurück. */
    sessionsAbfragen: (state) => state.sessions,

    /** Gibt (wenn vorhanden) die Session passend zur gegebenen ID zurück. */
    sessionAbfragen: (state) => (id) => state.sessions.find((s) => s.id == id),

    //Gibt alle Snaps für die gegebene SessionId zurück.
    snapsFürSessionAbfragen: (state) => (session) => {
      if (state.snaps) {
        if (session) {
          return state.snaps.filter((s) => s.session == session);
        } else {
          return state.snaps;
        }
      } else {
        return null;
      }
    },

    //Gibt den spezifischen Snap für die gegebene ID zurück.
    snapAbfragen: (state) => (id) => state.snaps.find((s) => s.id == id),

    //Nimmt einen Suchstring und gibt dafür alle Suchen zurück, die damit durchgeführt werden können.
    suchanfragenAbfragen: (state) => (query) => {
      //searches through clinic doc for matching patients, behandler or albums.
      if (state.praxis && state.snaps) {
        var lq = query.toLowerCase();
        var result = [];

        let konten = Object.values(state.praxis.konten).filter((k) =>
          k.name.toLowerCase().includes(lq)
        );
        konten.forEach((k) => result.push({ text: k.name, type: "behandler" }));

        let patienten = state.praxis.patienten.filter((p) => p.includes(lq));
        patienten.forEach((p) => result.push({ text: p, type: "patient" }));

        let alben = Object.values(state.praxis.alben).filter((a) =>
          a.name.toLowerCase().includes(lq)
        );
        alben.forEach((a) => result.push({ text: a.name, type: "album" }));

        let dateRange = getDateIfValid(lq);
        if (dateRange != null) {
          //Check if a snap withim that range exists.
          let snapsExist =
            state.snaps.filter((s) => {
              let ts = Number.parseInt(s.timestamp);
              return ts > dateRange.min && ts < dateRange.max;
            }).length > 0;

          if (snapsExist)
            result.push({ text: dateRange.display, type: "datum" });
        }

        return result;
      } else {
        return null;
      }
    },

    partnerschaftAbfragen: (state) => (id) => {
      if (state.partnerschaften != null) {
        return state.partnerschaften.find((p) => p.id == id);
      }
    },

    /**
     * Wenn eine Partnerschaft mit einer Praxis mit passender ID vorhanden ist, wird dessen id, name und Avatar-URL zurückgegeben.
     * @returns {{id: String, name: String, avatarUrl: String, angenommen: Boolean}}
     */
    partnerAbfragen: (state) => (id) => {
      if (!state.partnerschaften || !state.praxis) return null;
      let partnerschaft = state.partnerschaften.find((p) => p.id == id);
      if (!partnerschaft) return null;

      //Innerhalb der Partnerschaft wird die andere Praxis gesucht
      return Object.values(partnerschaft.teilnehmer).find(
        (t) => t.id != state.praxis.id
      );
    },

    //Gibt (wenn vorhanden) die aktuelle Kontenliste zurück
    kontenAbfragen: (state) => {
      return state.konten.sort((a, b) =>
        a.displayName.localeCompare(b.displayName)
      );
    },

    kontoAbfragen: (state) => (uid) => state.konten.find((k) => k.uid == uid),

    kontoMitNameAbfragen: (state) => (name) =>
      state.konten.find((k) => k.displayName == name),

    snapVorschauLinkAbfragen: (state) => (snapId) =>
      state.snapUris[snapId] != undefined
        ? state.snapUris[snapId].vorschau
        : undefined,

    snapVollLinkAbfragen: (state) => (snapId) =>
      state.snapUris[snapId] != undefined
        ? state.snapUris[snapId].voll
        : undefined,

    istTesterAbo: (state) => state.tester,
  },

  //Mit diesen Funktionen können lokale Werte gesetzt und aktualisiert werden.
  mutations: {
    kontoKonfigurationSetzen: (state, kontoKonfiguration) => {
      state.kontoKonfiguration = kontoKonfiguration;
      state.kontoKonfigurationLaedt = kontoKonfiguration == null;
    },

    praxisSetzen: (state, praxis) => {
      state.praxis = praxis;
      state.praxisLaedt = praxis == null;
    },

    kontenSetzen: (state, konten) => {
      state.konten = konten;
      state.kontenLaden = konten == null;
    },

    sessionsSetzen: (state, sessions) => {
      state.sessions = sessions;
      state.sessionsLaden = sessions == null;
      state.snapsLaden = sessions == null;
    },

    snapsSetzen: (state, snaps) => {
      state.snaps = snaps;
      state.snapsLaden = snaps == null;
    },

    standardThemenSetzen: (state, standardThemen) => {
      if (standardThemen) {
        //Falls nötig werden die aktuellen Standard Themen gelöscht
        if (state.themen && state.themen.length) {
          state.themen = state.themen.filter((t) => t.standard === false);
        } else {
          state.themen = [];
        }

        for (let i = 0; i < standardThemen.length; i++) {
          state.themen.push(standardThemen[i]);
        }

        state.themenLaden = false;
      } else {
        state.themen = null;
        state.themenLaden = true;
      }
    },

    eigeneThemenSetzen: (state, eigeneThemen) => {
      if (eigeneThemen) {
        //Falls nötig werden die aktuellen eigenen Themen gelöscht
        if (state.themen && state.themen.length) {
          state.themen = state.themen.filter((t) => t.standard === true);
        } else {
          state.themen = [];
        }

        for (let i = 0; i < eigeneThemen.length; i++) {
          state.themen.push(eigeneThemen[i]);
        }

        state.themenLaden = false;
      } else {
        state.themen = null;
        state.themenLaden = true;
      }
    },

    partnerschaftenSetzen: (state, partnerschaften) => {
      state.partnerschaften = partnerschaften;
      state.partnerschaftenLaden = partnerschaften == null;
    },

    patientenSetzen: (state, patienten) => {
      state.patienten = patienten;
      state.patientenLaden = patienten == null;
    },

    wartungSetzen: (state, wartung) => {
      state.wartung = wartung;
      state.wartungLaedt = wartung == null;
    },

    updateNoetigSetzen: (state, updateNoetig) => {
      state.updateNoetig = updateNoetig;
      state.updateNoetig = updateNoetig == null;
    },

    paketeSetzen: (state, pakete) => {
      state.pakete = pakete;
      state.paket = state.abonnement
        ? pakete.find((p) => p.id === state.abonnement.paket)
        : null;
      state.paketLaedt = state.paket == null;
      state.paketeLaden = pakete == null;
    },

    abonnementSetzen: (state, abonnement) => {
      state.abonnement = abonnement;

      if (abonnement) {
        state.tester = abonnement.tester;
        state.istPraxis =
          abonnement.typ === "regulaer" || abonnement.typ === "praxis";

        if (state.istPraxis) {
          praxisRoutenSetzen();
        } else {
          laborRoutenSetzen();
        }
      }

      state.abonnementLaedt = abonnement == null;
    },

    registrierteFaktorenSetzen: (state, faktoren) =>
      (state.registrierteFaktoren = faktoren),

    /**
     *
     * @param {*} state der state der store-instanz, automatisch gegeben.
     * @param {{}} session Das session-objekt, dass der aktuellen Liste angehängt wird.
     */
    sessionHinzufuegen: (state, session) => {
      if (!state.sessions) {
        state.sessions = [];
      }

      state.sessions.push(session);
    },

    //Fügt die snaps der aktuellen Liste hinzu, statt die vorhandenen Werte zu überschreiben
    snapsHinzufuegen: (state, snaps) => {
      if (!state.snaps) {
        state.snaps = [];
      }

      for (let i = 0; i < snaps.length; i++) {
        state.snaps.push(snaps[i]);
      }
    },

    patientSetzen: (state, patient) => state.patienten.push(patient),

    //Setzt das gegebene Thema in der "themen" Liste. Wenn ein Thema mit gleicher ID bereits existiert, wird es ersetzt.
    themaSetzen: (state, thema) => {
      if (state.themen != null && state.themen.find((t) => t.id == thema.id)) {
        state.themen = state.themen.filter((t) => t.id != thema.id);
      }
      state.themen.push(thema);
    },

    snapLoeschen: (state, snap) => {
      state.snaps = state.snaps.filter((s) => s.id != snap.id);
    },

    //Speichert den gegebenen Wert als die lokal gespeicherte letzte route.
    vorherigeRouteSetzen: (state, route) => {
      state.previousRoute = route;
    },

    //Setzt den aktuellen Wert der lokal gespeicherten letzten Route auf "null".
    vorherigeRouteLoeschen: (state) => {
      state.previousRoute = null;
    },

    //Setzt den "auswahlDatum" für die "DatumsAuswahl" Komponente auf den gegebenen Wert
    auswahlDatumSetzen: (state, datum) => (state.auswahlDatum = datum),

    //Setzt den aktuellen Uploadfortschritt auf den gegebenen Wert
    uploadFortschrittSetzen: (state, fortschritt) => {
      if (!isNaN(fortschritt)) {
        if (fortschritt < 0) {
          state.uploadFortschritt = 0;
        } else if (fortschritt > 100) {
          state.uploadFortschritt = 100;
        } else {
          state.uploadFortschritt = Number.parseInt(fortschritt.toString());
        }
      }
    },

    snapVorschauLinkSetzen: (state, daten) => {
      if (state.snapUris[daten.id] == undefined) {
        state.snapUris[daten.id] = {
          vorschau: daten.link,
        };
      } else {
        state.snapUris[daten.id].vorschau = daten.link;
      }
    },

    snapVollLinkSetzen: (state, daten) => {
      if (state.snapUris[daten.id] == undefined) {
        state.snapUris[daten.id] = {
          voll: daten.link,
        };
      } else {
        state.snapUris[daten.id].voll = daten.link;
      }
    },

    mobileSetzen: (state, wert) => (state.mobile = wert),

    standardThemaIconCacheSetzen: (state, cacheObjekt) =>
      (state.standardThemenIconCache[cacheObjekt.id] = cacheObjekt.url),

    zahlungsMethodeSetzen: (state, wert) => {
      state.zahlungsMethode = wert;
      state.zahlungsMethodeLaedt = wert == null;
    },

    rechnungenSetzen: (state, wert) => {
      state.rechnungen = wert;
      state.rechnungenLaden = wert == null;
    },

    updateLogSetzen: (state, wert) => {
      state.updateLog = wert;
      state.updateLogLaedt = wert == null;
    },
  },
  actions: {},
  modules: {},
});
