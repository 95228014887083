<!-- Stellt die Karte dar, auf der die E-Mail und das Passwort des Nutzers eingegeben werden -->

<template>
  <form @submit.prevent @keydown.enter.prevent="anmelden" class="flex flex-col font-dm-sans"
    :class="{ 'w-85': istMobile === false, 'w-full h-full justify-between': istMobile === true }">
    <div class="w-full flex flex-col space-y-10">
      <OnboardingTitel titel="Willkommen" untertitel="Melden Sie sich mit Ihrem DentaSnap Konto an." />

      <div class="flex flex-col space-y-4" :class="{ 'w-full': istMobile === true }">
        <input type="email" ref="emailRef" v-model="emailLokal"
          :class="{ 'default-textfield': istMobile === false, 'default-textfield-dynamic-length': istMobile === true }"
          placeholder="E-Mail Adresse">
        <input type="password" ref="passwortRef" v-model="passwortLokal"
          :class="{ 'default-textfield': istMobile === false, 'default-textfield-dynamic-length': istMobile === true }"
          placeholder="Passwort">
        <div
          class="flex justify-start items-center space-x-2 pl-1 text-xs text-DentaBlue transition duration-100 hover:cursor-pointer hover:opacity-75"
          @click="passwortVergessen">
          <p>Passwort Vergessen</p>
          <img src="@/assets/icons/pfeil-rechts-blau.svg" class="h-3">
        </div>
      </div>
    </div>


    <OnboardingWeiterButton class=" mt-10" :deaktiviert="false" :laden="laedt" @click="anmelden" />
  </form>
</template>

<script>
import OnboardingTitel from '@/components/OnboardingTitel.vue'
import OnboardingWeiterButton from '@/components/OnboardingWeiterButton.vue'
import store from '@/store'
import { ref } from '@vue/reactivity'
import { watch } from '@vue/runtime-core'
import { onMounted, computed } from 'vue'
export default {
  components: { OnboardingTitel, OnboardingWeiterButton },
  props: ["laedt"],
  emits: ["emailAktualisiert", "passwortAktualisiert", "passwortVergessen", "anmelden"],

  setup(props, context) {

    const istMobile = computed(() => store.state.mobile)

    const emailLokal = ref("")
    const passwortLokal = ref("")

    const emailRef = ref(null)
    const passwortRef = ref(null)

    //Watcher für beide Felder werden eingerichtet, die bei jeder Änderung ein Event abfeuern
    watch(emailLokal, () => context.emit("emailAktualisiert", emailLokal.value))
    watch(passwortLokal, () => context.emit("passwortAktualisiert", passwortLokal.value))

    //Wird bei einem Click auf "Anmelden" aufgerufen
    const anmelden = () => {
      console.log("Anmeldung sollte gestartet sein");
      context.emit("anmelden")
    }

    //Wird bei Click auf "Passwort Vergessen" aufgerufen.
    const passwortVergessen = () => context.emit("passwortVergessen")

    function emailZuruecksetzen() {
      emailLokal.value = ""
      emailRef.value.focus()
    }

    function passwortZuruecksetzen() {
      passwortLokal.value = ""
      passwortRef.value.focus()

    }

    onMounted(() => emailRef.value.focus())

    return {
      //State für das UI
      emailRef,
      passwortRef,
      emailLokal,
      passwortLokal,
      istMobile,

      //Funktionen für das UI
      anmelden,
      passwortVergessen,
      passwortZuruecksetzen,
      emailZuruecksetzen
    }
  }

}
</script>