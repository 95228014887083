<template>
    <div class="w-full h-screen flex justify-start items-center p-5 bg-white">
        <OnboardingFortschrittsAnzeige class="h-full w-1/3 mr-40" :schritte="[]" :index="0" :fehler="false" />
        <div class="onboarding-komponente">
            <OnboardingTitel titel="Gerät Anmelden"
                untertitel="Melden Sie Ihr Gerät in wenigen Sekunden in Ihrer Organisation an, indem Sie:" />
            <ul class="text-lg space-y-2">
                <li>1. DentaSnap auf Ihrem Gerät öffnen</li>
                <li>2. "Scan & Snap" auswählen</li>
                <li>3. Den QR Code unten Scannen:</li>
            </ul>
            <GeraetAnmeldeCode :uid="uid" @authorisiert="schliessen" />
            <div class="px-4 py-2 w-min bg-UltraLeichtesGrau text-Mittelgrau rounded-lg transition duration-100 hover:cursor-pointer hover:bg-opacity-70"
                @click="schliessen">Abbrechen
            </div>
        </div>
    </div>
</template>
<script>
import OnboardingFortschrittsAnzeige from '@/components/OnboardingFortschrittsAnzeige.vue';
import OnboardingTitel from '@/components/OnboardingTitel.vue';
import GeraetAnmeldeCode from '@/components/GeraetAnmeldeCode.vue';
import store from '@/store';
import { router } from '@/router';
import { computed, onMounted } from 'vue';
import { useRoute } from 'vue-router';
export default {
    components: {
        OnboardingFortschrittsAnzeige,
        OnboardingTitel,
        GeraetAnmeldeCode
    },
    setup(props, context) {

        const route = useRoute()

        const uid = computed(() => route.params.uid)

        function uidCheck() {
            if (store.state.kontenLaden || typeof uid.value !== "string" || store.state.konten.find(k => k.uid == route.params.uid && k.typ == "geraet") == undefined) {
                router.back()
            }
        }

        const schliessen = () => router.back()

        onMounted(uidCheck)

        return {
            uid,
            schliessen
        }
    }
}
</script>