<template>
  <div class="w-full h-full justify-center items-center">
    <div class="h-full flex flex-wrap justify-center">
        <div 
            v-for="partnerschaft in partnerschaftenListe" 
            :key="partnerschaft.id"
            class="flex flex-col justify-center items-center mr-5">

                <img 
                    class="w-16 aspect-square rounded-full mb-2 transition duration-150 hover:cursor-pointer hover:scale-105"
                    :class="{'border-DentaBlue border-2': partnerschaften.find(p => p.id == partnerschaft.id) != undefined}"
                    :src="partnerDatenAbfragen(partnerschaft).url"
                    @click="partnerschaftGewaehlt(partnerschaft)">

                <p
                    class="text-sm" 
                    :class="{'font-bold text-DentaBlue': partnerschaften.find(p => p.id == partnerschaft.id) != undefined}">
                    {{partnerDatenAbfragen(partnerschaft).name}}
                </p>

        </div>
    </div>
  </div>
</template>

<script>
//Import aller benötigter Frameworks und Vue-Komponenten
import { computed } from '@vue/runtime-core'
import store from '@/store'
export default {
    props: ["partnerschaften"],
    emits: ["partnerschaftenAktualisiert"],

    setup(props, context) {

        /** Gibt alle Partnerschaften zurück, in denen die Praxis aktuell Teilnehmer ist. */
        const partnerschaftenListe = computed(() => store.state.partnerschaften.filter(p => p.aktiv === true))

        /**
         * Wird aufgerufen, wenn eine Partnerschaft ausgewählt wird. Ist die Session bereits darin beinhaltet, wird die partnerschaft entfernt.
         * Andernfalls wird die Partnerschaft hinzugefügt
         * @param {*} wert 
         */
        function partnerschaftGewaehlt(wert) {
            if(!props.partnerschaften || props.partnerschaften.length == undefined) return

            var aktuell = props.partnerschaften

            if(aktuell.find(p => p.id == wert.id) != undefined) {
                aktuell = aktuell.filter(p => p.id != wert.id)
            } else {
                aktuell.push(wert)
            }

            context.emit("partnerschaftenAktualisiert", aktuell)
        }

        /**
         * Fragt den Avatar URL der fremden Praxis in einer Partnerschaft ab
         * @param {*} partnerschaft Die Partnerschaft, dessen passender URL zurückgegeben werden soll.
         * @return {{name: string, url: string}} Der Avatar URL der fremden Partnerschaft in der Praxis 
         */
        function partnerDatenAbfragen(partnerschaft) {
            if(partnerschaft.teilnehmer) {
                let partner = Object.values(partnerschaft.teilnehmer).find(t => t.id != store.getters.praxisAbfragen.id)

                if(partner != undefined) {
                    return {
                        name: partner.name,
                        url: partner.avatarUrl
                    }
                }
            }
        }

        return {
            //State
            partnerschaftenListe,

            //Funktionen
            partnerschaftGewaehlt,
            partnerDatenAbfragen
        }
    }
}
</script>