<template>
    <div class="w-full h-screen flex flex-col justify-center items-center space-y-10 bg-Hintergrund">
        <div class="w-28 aspect-square rounded-full bg-DentaBlueUltraLight flex justify-center items-center">
            <LottieAnimation 
                v-if="komplett === true" 
                :animation-data="party"/>
            <p v-else class="text-2xl font-bold text-DentaBlue">{{ prozent }}%</p>
        </div>
        <div class="flex flex-col justify-center items-center space-y-2">
            <p class="text-xl font-bold">{{komplett === true ? 'Upload Fertig!' :  'Upload Läuft...'}}</p>
            <div class="w-60 h-10 flex justify-center items-center">
                <Fortschritt v-if="komplett === false" :fortschritt="fortschritt"/>
                <p v-else class="text-sm text-center">Ihre Session wurde hochgeladen.</p>
            </div>
        </div>
        <button 
            class="px-8 py-2 rounded-full flex justify-center items-center text-white"
            :class="{
                ' bg-LeichtesGrau bg-opacity-50': komplett === false,
                'bg-DentaBlue transition duration-100 hover:bg-opacity-70': komplett === true
            }"
            :disabled="komplett === false"
            @click="zurGallerie">
            Zur Gallerie
        </button>
    </div>
</template>
<script>
import { LottieAnimation } from 'lottie-web-vue';
import Fortschritt from '@/pages/praxis/components/Fortschritt.vue';
import { computed } from 'vue';
import { useRouter } from 'vue-router';
const party = require("@/assets/animations/party.json")
const ladenBlau = require("@/assets/animations/laden-blau.json")

export default {
    components: {LottieAnimation, Fortschritt},
    props: ["fortschritt", "komplett"],
    emits: [],
    setup(props, context) {

        const prozent = computed(() => {
        if (props.fortschritt <= 0) {
            return 0;
        } else if (props.fortschritt >= 1) {
            return 100;
        } else {
            return Math.round(props.fortschritt * 100);
        }
        });

        const router = useRouter()

        function zurGallerie() {
            if(props.komplett === true) {
                router.push({name: "Gallerie"})
            }
        }

        return {party, ladenBlau, prozent, zurGallerie}
    }
}
</script>