<template>
    <div 
        class="w-full rounded-3xl p-10 space-y-8 flex flex-col justify-start items-start"
        :class="{
            'bg-Hintergrund': laedt === true || mfaState > 0,
            'bg-HintergrundWarnung': mfaState == 0 && laedt === false
        }">

        <!-- Dialog für Löschen von Nummern -->
        <BestaetigungsDialog 
            :state="loeschenState"
            :titel="`${zielFaktor != null ? zielFaktor.phoneNumber : ''} Löschen?`"
            body="Verifizierungscodes können nicht mehr an diese Nummer gesendet werden."
            bestaetigungsText="Löschen"
            :destruktiv="true"
            :laedt="loeschenLaedt"
            @abgebrochen="() => loeschenState = false"
            @bestaetigt="faktorLoeschen"
        />

        <!-- Dialog für das Primärsetzen von Nummern -->
        <BestaetigungsDialog 
            :state="primaerSetzenState"
            titel="Primäre Nummer Wechseln?"
            :body="`Wir senden Verifizierungscodes ab jetzt an ${zielFaktor != null ? zielFaktor.phoneNumber : ''}.`"
            bestaetigungsText="Wechseln"
            :destruktiv="false"
            :laedt="primaerSetzenLaedt"
            @abgebrochen="() => primaerSetzenState = false"
            @bestaetigt="primaerFaktorWechseln"
        />

        <!-- Badge und Button -->
        <div class="h-5 w-full flex justify-between">
            <!-- Badge -->
            <div>
                <div v-if="laedt === true" class="h-full w-24 bg-LeichtesGrau bg-opacity-20 rounded-sm"></div>
                <div v-else class="h-full flex justify-start items-center space-x-3 text-lg">
                    <img v-if="mfaState === 0" src="@/assets/icons/warnung-kreis-rot.svg" alt="" class="h-full aspect-square object-contain">
                    <img v-else src="@/assets/icons/schild-filled-blau.svg" alt="" class="h-full aspect-square object-contain">
                    <p :class="{'text-Warning': mfaState == 0, 'text-DentaBlue': mfaState > 0}">{{`${mfaState == 0 ? 'deaktiviert' : 'aktiviert'}`}}</p>
                </div>
            </div>

            <div v-if="laedt === true" class="h-full w-40 bg-LeichtesGrau bg-opacity-20 rounded-sm"></div>
            <button 
                v-else 
                :disabled="mfaState == 2" 
                :class="{
                    'text-DentaBlue': mfaState < 2, 
                    'text-LeichtesGrau': mfaState == 2}"
                @click="nummerHinzufuegen">{{ mfaState == 0 ? "Nummer Hinzufügen" : "Sekundär Hinzufügen" }}
                </button>
        </div>

        <p class="text-3xl font-bold">2-Faktor<br/>Verifizierung</p>

        <div class="w-full h-24 flex px-4">
            <div v-if="laedt === true" class="w-full flex flex-col space-y-3">
                <div v-if="laedt === true" class="h-8 w-full bg-LeichtesGrau bg-opacity-20 rounded-sm"></div>
                <div v-if="laedt === true" class="h-8 w-full     bg-LeichtesGrau bg-opacity-20 rounded-sm"></div>
            </div>
            <div v-else-if="mfaState == 0" class="w-full h-full text-Warning bg-Warning bg-opacity-5 rounded-3xl flex justify-center items-center px-12 text-sm font-bold text-center">
                <span>Ihr Konto ist nicht vollständig geschützt. Klicken Sie auf "Nummer Hinzufügen", um loszulegen.</span>
            </div>
            <div v-else-if="mfaState > 0" class="w-full h-full flex flex-col justify-start items-start">
                <MfaNummerZeile 
                    v-for="nummer in faktoren" 
                    :key="nummer.uid" 
                    :faktor="nummer" 
                    :mfaState="mfaState"
                    @loeschen="uid => dialogAnzeigen(uid, true)"
                    @primaerSetzen="uid => dialogAnzeigen(uid, false)"/>
            </div>
        </div>
    </div>
</template>
<script>
import { useCloudFunction } from '@/services/cloudFunctionDienst';
import { computed, onMounted, ref } from 'vue';
import MfaNummerZeile from './MfaNummerZeile.vue';
import BestaetigungsDialog from '@/components/dialoge/BestaetigungsDialog.vue';
import { useRouter } from 'vue-router';
import { erfolgAnzeigen, fehlerAnzeigen } from '@/services/toastDient';
export default {
    components: {MfaNummerZeile, BestaetigungsDialog},
    setup() {
        const router = useRouter()

        const faktoren = ref(null)

        const loeschenState = ref(false)
        const primaerSetzenState = ref(false)

        //Speichert temporär den Faktor, der Primär gesetzt oder gelöscht werden soll
        const zielFaktor = ref(null)

        /**
         * Gibt die Länge der MFA-Faktoren Liste des nutzers zurück. Diese geben den Zustand der MFA Sicherung des Nutzers an:
         * - 0: Nutzer hat keine registrierten Faktoren, Konto ist ungeschützt
         * - 1: Nutzer hat einen Primären, aber keine Backupfaktor
         * - 2: Nutzer hat einen Primären und Sekundären Faktor, und ist komplett geschützt.
         */
        const mfaState = computed(() => {
            if(faktoren.value != null) {
                return faktoren.value.length
            } else {
                return -1
            }
        })
        
        const {pending: laedt, error: abfrageFehler, result: abfrageErgebnis, call: abfragen} = useCloudFunction("registrierteFaktorenAbfragen")

        function nummerHinzufuegen() {
            if(mfaState.value < 2) {
                router.push({name: "KontoNummerHinzufuegen", params: {typ: mfaState.value === 0 ? 'primaer' : 'sekundaer'}})
            }
        }

        function mfaFaktorenAbfragen() {
            abfragen()
            .then(() => {
                if(abfrageFehler.value === false) {
                    faktoren.value = abfrageErgebnis.value.sort((a, b) => new Date(a.enrollmentTime) - new Date(b.enrollmentTime))
                    console.log("MFA Faktoren Gesetzt: ", faktoren.value);
                } else {
                    faktoren.value = null
                }
            })
        }

        function dialogAnzeigen(uid, loeschen) {
            zielFaktor.value = faktoren.value.find(f => f.uid == uid)
            if(zielFaktor.value != null) {
                loeschen === true ? loeschenState.value = true : primaerSetzenState.value = true
            }
        }

        const {pending: loeschenLaedt, error: loeschenFehler, call: loeschenAufrufen} = useCloudFunction("mfaNummerLoeschen")
        const {pending: primaerSetzenLaedt, error: primaerSetzenFehler, call: primaerSetzenAufrufen} = useCloudFunction("mfaNummerPrimaerSetzen")

        function faktorLoeschen() {
            if(zielFaktor.value != null) {
                loeschenAufrufen({uid: zielFaktor.value.uid})
                .then(() => {
                    const nummer = zielFaktor.value.phoneNumber
                    if(loeschenFehler.value === false) {
                        erfolgAnzeigen(`${nummer} wurde gelöscht.`)
                        mfaFaktorenAbfragen()
                    } else {
                        fehlerAnzeigen(`Fehler beim Löschen der Nummer ${nummer}!`)
                    }
                })
                .finally(() => loeschenState.value = false)
                }
        }

        function primaerFaktorWechseln() {
            if(zielFaktor.value != null) {
                primaerSetzenAufrufen({nummerId: zielFaktor.value.uid})
                .then(() => {
                    const nummer = zielFaktor.value.phoneNumber
                    if(primaerSetzenFehler.value === false) {
                        erfolgAnzeigen(`Primäre 2-FA Nummer auf ${nummer} gewechselt.`)
                        mfaFaktorenAbfragen()
                    } else {
                        fehlerAnzeigen("Fehler beim Wechseln der primären 2-FA Nummer.")
                    }
                })
                .finally(() => primaerSetzenState.value = false)
            }
        }

        onMounted(mfaFaktorenAbfragen)

        return { laedt, mfaState, faktoren,loeschenState, primaerSetzenState,zielFaktor,loeschenLaedt, primaerSetzenLaedt, nummerHinzufuegen, dialogAnzeigen, faktorLoeschen, primaerFaktorWechseln }
    }
}
</script>