<template>
  <div class="fixed top-0 left-0 z-20 w-full h-screen flex justify-end items-center pointer-events-none font-dm-sans">
      <transition name="fade">
          <div 
          v-if="anzeigen" 
          class="absolute w-full h-full bg-black bg-opacity-70 pointer-events-auto" @click="schliessen"></div>
      </transition>
      <transition name="slide">
          <!-- Eigendliches Filterpanel -->
          <div 
            v-if="anzeigen"
            class="z-20 w-[38rem] h-full bg-white top-0 right-0 pointer-events-auto flex flex-col">
              
            <!-- Titel -->
            <div class="w-full px-12 h-52 flex flex-col justify-start items-center">
                <!-- Titelzeile -->
                <div class="w-full h-24 flex justify-between items-center">
                    <button 
                    class="h-5 w-5 transition duration-100 hover:opacity-70"
                    @click="schliessen">
                    <img 
                      src="@/assets/icons/pfeil-doppelt-rechts-schwarz.png" 
                      alt="schliessen"
                      class="aspect-square object-fit">
                    </button>
                  
                  <p class=" text-3xl">Suche</p>

                  <!-- (Leerer Div für Anordnung) -->
                  <div class="w-6"></div>
                </div>

                <!-- Patientensuchfeld -->
                <input
                  v-if="patient == null"
                  type="text" 
                  placeholder="Patientennummer" 
                  v-model="patientenNummerEingabe"
                  class="default-textfield-dynamic-length">

                <!-- Patientenergebnis -->
                <div 
                  v-else 
                  class="w-full rounded-md px-5 py-3 flex justify-between items-center bg-DentaBlue text-white">
                  <p>{{ patient.nummer }}</p>
                  <button @click="patient = null">
                    <img src="@/assets/icons/schliessen-weiss.svg" alt="x" class="w-3 aspect-square object-contain transition hover:opacity-70">
                  </button>
                </div>
              </div>  
            
              <div class="w-full h-full relative overflow-y-auto">
                <div class="absolute z-20 overflow-y-auto flex flex-col space-y-8 px-12 py-8 justify-start items-center">
                    <!-- Themen -->
                  <div class="w-full flex flex-col space-y-3 justify-start items-start text-lg">
                    <p>Thema</p>

                    <div class="w-full flex space-x-2 justify-start items-start">
                      <!-- Themen Links -->
                      <div class="w-1/2 flex flex-col space-y-2">
                        <Thema
                          v-for="thema in themenListeLinks"
                          :thema="thema"
                          :gewaehlt="themen.find(t => t.id == thema.id) != undefined"
                          @aktualisiert="() => themenAktualisiert(thema, true)"
                        />
                      </div>

                      <!-- Themen Rechts -->
                      <div class="w-1/2 flex flex-col space-y-2">
                        <Thema
                          v-for="thema in themenListeRechts"
                          :thema="thema"
                          :gewaehlt="themen.find(t => t.id == thema.id) != undefined"
                          @aktualisiert="() => themenAktualisiert(thema, true)"
                        />
                      </div>
                    </div>
                  </div>

                <!-- Ordner -->
                  <div 
                    v-if="ordnerListeLinks.length" 
                    class="w-full flex flex-col space-y-3 justify-start items-start text-lg">
                    <p>Ordner</p>

                    <div class="w-full flex space-x-2 justify-start items-start">
                      <!-- Links -->
                      <div class="w-1/2 flex flex-col space-y-2">
                        <Ordner
                          v-for="o in ordnerListeLinks"
                          :ordner="o"
                          :gewaehlt="ordner.find(t => t.id == o.id) != undefined"
                          @aktualisiert="() => themenAktualisiert(o, false)"
                        />
                      </div>

                      <!-- Rechts -->
                      <div class="w-1/2 flex flex-col space-y-2">
                        <Ordner
                          v-for="o in ordnerListeRechts"
                          :ordner="o"
                          :gewaehlt="ordner.find(t => t.id == o.id) != undefined"
                          @aktualisiert="() => themenAktualisiert(o, false)"/>
                      </div>
                    </div>
                  </div>

                  <!-- Zahnwahl -->
                  <div class="w-full flex flex-col space-y-3 justify-start items-start text-lg">
                    <p>Lokalisierung</p>

                    <div class="w-full flex space-x-2 justify-center items-start">
                      <ZahnSchema 
                        :zaehne="zaehne" 
                        :interaktiv="true" 
                        @aktualisiert="(val) => zaehne = val"/>
                    </div>
                  </div>

                  <!-- Zeitraum -->
                  <div class="w-full flex flex-col space-y-3 justify-start items-start text-lg pb-10">
                    <p>Zeitraum</p>

                    <div class="w-full flex justify-center">
                      <Kalender 
                        class="w-3/5"
                        @auswahl="zeitraumAktualisiert"/>
                    </div>
                  </div>
                </div>

                <!-- Overlay für Patientensuche -->
                <transition name="fade">
                  <div v-if="patientenErgebnisseAnzeigen" class="fixed z-30 w-full h-full px-12 py-16 space-y-3 bg-white flex flex-col justify-start items-start">
                    <!-- Patientensuchergebis -->
                    <button 
                      v-for="erg in patientenErgebnisse" 
                      :key="erg.id"
                      @click="patientGewaehlt(erg)">{{ erg.nummer }}</button>
                  </div>
                </transition>
              </div>

              <div class="w-full h-20 px-16 bg-white border-t border-t-LeichtesGrau flex justify-between items-center">
                <button 
                  class="underline underline-offset-2"
                  @click="filterZuruecksetzen">Zurücksetzen</button>
                <button 
                  class="px-14 py-2 bg-DentaBlue text-DentaBlue bg-opacity-[0.11] rounded-full tracking-wide"
                  @click="schliessen">
                  Suchen
                </button>
              </div>
          </div>
      </transition>
  </div>
</template>

<script>
import { computed, ref, watch, watchEffect } from 'vue';
import Thema from "./components/Thema.vue"
import Ordner from './components/Ordner.vue';
import ZahnSchema from '@/components/ZahnSchema.vue';
import Kalender from './components/Kalender.vue';
import store from '@/store';

export default {
  props: ["anzeigen", "filter"],
  emits: ["schliessen", "aktualisiert"],
  components: { Thema, Ordner, ZahnSchema, ZahnSchema, Kalender },
  setup(_, context) {
      const schliessen = () => {
        patientenSucheSchliessen()
        context.emit("schliessen")
      }

      //Trackt Eingabetext der Patientennummer
      const patientenNummerEingabe = ref("")
      const patientenErgebnisse = ref([])
      const patientenErgebnisseAnzeigen = ref(false)

      //Interner State für Kalender
      const zeitraumAnfang = ref(null)
      const zeitraumEnde = ref(null)

      const patient = ref(null)
      const themen = ref([])
      const ordner = ref([])
      const zaehne = ref([])
      const zeitraum = ref(null)

      const themenListeLinks = computed(() => {
        if(store.getters.themenAbfragen.length) {
          return store.getters.themenAbfragen.filter((thema, index) => thema.standard === true && index % 2 === 0)
        } 
      })

      const themenListeRechts = computed(() => {
        if(store.getters.themenAbfragen.length) {
          return store.getters.themenAbfragen.filter((thema, index) => thema.standard === true && index % 2 !== 0)
        }
      })

      const ordnerListeLinks = computed(() => {
        if(store.getters.themenAbfragen.length) {
          return store.getters.themenAbfragen.filter((thema, index) => thema.standard === false && index % 2 === 0)
        }
      })

      const ordnerListeRechts = computed(() => {
        if(store.getters.themenAbfragen.length) {
          return store.getters.themenAbfragen.filter((thema, index) => thema.standard === false && index % 2 !== 0)
        }
      })

      function filterZuruecksetzen() {
        patient.value = null
        themen.value = []
        ordner.value = []
        zaehne.value = []
        zeitraum.value = null
      }

      function zeitraumAktualisiert(zr) {
        zeitraum.value = zr
      }

      function themenAktualisiert(wert, istThema) {
        var aktuell = []
        
        if(istThema === true) {
          aktuell.push(...themen.value)
        } else {
          aktuell.push(...ordner.value)
        }

        if(aktuell.find(v => v.id == wert.id) != undefined) {
          aktuell = aktuell.filter(v => v.id != wert.id)
        } else {
          aktuell.push(wert)
        }

        if(istThema === true) {
          themen.value = aktuell
        } else {
          ordner.value = aktuell
        }
      }

      function patientenSuchtextAktualisiert(neuerWert) {
        if(neuerWert && neuerWert != "") {
          patientenErgebnisseAnzeigen.value = true

          // Filter the list to include only strings that contain the searchValue
          let filtered = store.state.patienten.filter(pat => pat.nummer.includes(neuerWert));
          filtered.sort((a, b) => a.nummer.indexOf(neuerWert) - b.nummer.indexOf(neuerWert));
          patientenErgebnisse.value = filtered
        } else {
          patientenErgebnisse.value = []
          patientenErgebnisseAnzeigen.value = false
        }
      }

      function patientenSucheSchliessen() {
        patientenErgebnisse.value = []
        patientenNummerEingabe.value = ""
        patientenErgebnisseAnzeigen.value = false
      }

      function patientGewaehlt(val) {
        patient.value = val
        patientenSucheSchliessen()
      }

      watch(patientenNummerEingabe, patientenSuchtextAktualisiert)

      const filter = computed(() => {
          var filter = {}

          if(patient.value) {
            filter.patient = patient.value.id
          }

          var res = []
          res.push(...themen.value.map(t => t.id))
          res.push(...ordner.value.map(o => o.id))

          if(res.length > 0) {
            filter.themen = res
          }

          if(zaehne.value.length > 0) {
            filter.zaehne = zaehne.value
          }

          if(zeitraum.value != null) {
            filter.zeitraum = zeitraum.value
          }

          return Object.keys(filter).length > 0 ? filter : null
       })

      watch(filter, f => context.emit("aktualisiert", f))

      return {
        schliessen,
        patientGewaehlt,
        themenAktualisiert,
        zeitraumAktualisiert,
        filterZuruecksetzen,
        patientenNummerEingabe, 
        themen,
        ordner,
        zaehne,
        patient,
        zeitraumAnfang,
        zeitraumEnde,
        themenListeLinks, 
        themenListeRechts,
        ordnerListeLinks,
        ordnerListeRechts,
        patientenErgebnisse,
        patientenErgebnisseAnzeigen,
      }
  }
}
</script>

<style scoped>
.slide-enter-active,
.slide-leave-active {
  transition: transform 0.2s ease;

}

.slide-enter-from,
.slide-leave-to {
  transform: translateX(100%);
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.1s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>