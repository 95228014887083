<template>
    <div class="w-full flex items-center font-dm-sans">
        <div class="w-full flex items-center space-x-3">
            <div v-if="fotoUrl == null" class="w-11 h-11 aspect-square rounded-full"></div>
            <img v-else :src="fotoUrl" alt="pb" class="w-11 h-11 aspect-square rounded-full">
            <div class="flex flex-col justify-center items-start">
                <div class="flex space-x-2">
                    <p class="font-bold">{{ name }}</p>
                    <img v-if="eigeneSession === false" src="@/assets/icons/gruppe-schwarz.svg" alt="shared" class="w-4 aspect-square">
                </div>
                <p class="text-xs text-LeichtesGrau">{{ vergangeneZeit }}</p>
            </div>
        </div>
        <div class="w-full flex flex-col items-end justify-center">
            <p>{{ patient.nummer }}</p>
            <p class="text-xs text-Mittelgrau">{{ themenVorschau }}</p>
        </div>
    </div>
</template>
<script>
import { nutzerProfilbildAbfragen } from '@/services/helfer';
import store from '@/store';
import { computed, onMounted, ref } from 'vue';
import {standardPraxisAvatar} from "@/constants/konstanten"
import { nameAbfragen } from '@/services/authentifizierungsDienst';
import dayjs from 'dayjs';
export default {
    props: ["session"],
    setup(props) {
        const eigeneSession = computed(() => store.getters.praxisAbfragen.id === props.session.eigentuemer)
        const eigentuemerPraxis = computed(() => Object.values(props.session.teilnehmer).find(t => t.id === props.session.eigentuemer))

        const fotoUrl = ref(null)
        const name = computed(() => {
            if(eigeneSession.value === true) {
                const behandler = store.getters.kontoAbfragen(props.session.behandler)
                if(behandler != null) {
                    return behandler.typ === "geraet" ? behandler.geraeteName : nameAbfragen(behandler.nutzerName)
                } else {
                    return "Gelöschter Nutzer"
                }
            } else {
                return eigentuemerPraxis.value.name
            }
        })

        const vergangeneZeit = computed(() => dayjs.unix(props.session.hochladeZeit).fromNow())

        const patient = computed(() => store.getters.patientAbfragen(props.session.patient))

        const themenVorschau = computed(() => {
            if(props.session.themen.length == 0) {
                return ""
            } else if (props.session.themen.length == 1) {
                return store.getters.themaAbfragen(props.session.themen[0]).name
            } else {
                return `${store.getters.themaAbfragen(props.session.themen[0]).name} + ${props.session.themen.length - 1}`
            }
        })

        function fotoAbfragen() {
            if(eigeneSession.value === true) {
                const behandler = store.getters.kontoAbfragen(props.session.behandler)
                nutzerProfilbildAbfragen(props.session.behandler, behandler != null && behandler)
                .then(url => fotoUrl.value = url.url)
            } else {
                if(!eigentuemerPraxis.value || !eigentuemerPraxis.value.avatarUrl) {
                    fotoUrl.value = standardPraxisAvatar
                } else {
                    fotoUrl.value = eigentuemerPraxis.value.avatarUrl
                }
                console.log(fotoUrl.value);
            }
        }

        onMounted(fotoAbfragen)

        return {
            fotoUrl,
            name,
            eigentuemerPraxis,
            vergangeneZeit,
            patient,
            themenVorschau,
            eigeneSession
        }
    }
}
</script>