import { initializeApp } from "firebase/app";
import { getFirestore, connectFirestoreEmulator } from "firebase/firestore";
import { getStorage, connectStorageEmulator } from "firebase/storage";
import { getAuth, connectAuthEmulator } from "firebase/auth";
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyD1PBxl2INaZGKftISC6huiEf4HlcWiKCI",
  authDomain: "dentacloud-a81d6.firebaseapp.com",
  projectId: "dentacloud-a81d6",
  storageBucket: "dentacloud-a81d6.appspot.com",
  messagingSenderId: "1077505649913",
  appId: "1:1077505649913:web:0101860f9271c2836e4628",
  measurementId: "G-J39MMP6TBQ",
};

let app = initializeApp(firebaseConfig);

const firestore = getFirestore();
const auth = getAuth();
const functions = getFunctions(app, "europe-west3");
const storage = getStorage();

const emulatorIp = "localhost";

//Uncomment below lines to use emulator.
// connectAuthEmulator(auth, `http://${emulatorIp}:9099`);
// connectFirestoreEmulator(firestore, emulatorIp, 8080);
// connectFunctionsEmulator(functions, emulatorIp, 5001);
// connectStorageEmulator(storage, emulatorIp, 9199);

export { firestore, auth, functions, storage };
