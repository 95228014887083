<template>
  <div class="w-full h-full relative">
    <div
      class="h-full w-full absolute flex flex-col"
      :class="{ 'pointer-events-none': laed === true }"
    >
      <div class="w-full h-full flex flex-col p-5">
        <!-- Titel -->
        <DialogTitel :text="titel" class="pt-1 pb-6 px-2" />

        <div class="w-full h-full">
          <FotosWaehlenAnsicht
            v-if="index == 0"
            :aufnahmen="aufnahmen"
            :patient="patient"
            @aufnahmeGewaehlt="aufnahmenAktualisiert"
            @patientGewaehlt="patientAktualisiert"
            @neuerPatient="patientErstellt"
          />
          <FallWaehlenAnsicht
            v-if="index == 1"
            :faelle="faelle"
            @gewaehlt="fallIdAktualisiert"
            :patientNummer="patient.nummer"
          />
          <InformationenWaehlenAnsicht
            v-if="index == 2"
            :themen="themen"
            :schritt="schritt"
            :zaehne="zaehne"
            @schrittGewaehlt="schrittAktualisiert"
            @themenGewaehlt="themenAktualisiert"
            @zaehneGewaehlt="zaehneAktualisiert"
          />
          <PartnerschaftenWaehlenAnsicht
            v-if="index == 3"
            :partnerschaften="partnerschaften"
            @partnerschaftenAktualisiert="partnerschaftenAktualisiert"
          />
        </div>
        <ButtonLeiste :konfiguration="buttonKonfiguration" />
      </div>
      <FortschrittsLeiste :fortschritt="fortschritt" />
    </div>
    <div
      v-if="laed === true"
      class="w-full h-full absolute pointer-events-none bg-white bg-opacity-90 flex justify-center items-center z-50"
    >
      <Spinner />
    </div>
  </div>
</template>

<script>
//Import aller benötigten Frameworks und Vue-Komponenten
import { ref } from "@vue/reactivity";
import { computed } from "@vue/runtime-core";
import store from "@/store";
import dayjs from "dayjs";

//Import aller benötigten eigenen Komponenten
import DialogTitel from "@/components/DialogTitel.vue";
import FotosWaehlenAnsicht from "@/components/session-bearbeitung/FotosWaehlenAnsicht.vue";
import InformationenWaehlenAnsicht from "@/components/session-bearbeitung/InformationenWaehlenAnsicht.vue";
import PartnerschaftenWaehlenAnsicht from "@/components/session-bearbeitung/PartnerschaftenWaehlenAnsicht.vue";
import FallWaehlenAnsicht from "@/components/session-bearbeitung/FallWaehlenAnsicht.vue";
import ButtonLeiste from "@/components/ButtonLeiste.vue";
import FortschrittsLeiste from "@/components/FortschrittsLeiste.vue";
import Spinner from "@/components/Spinner.vue";

//Import aller benötigten Helfer und Konstanten
import {
  einmaligenStringGenerieren,
  faelleFuerPatientAbfragen,
  patientHochladen,
  sessionHochladen,
} from "@/services/firestoreDienst";
import { nutzer } from "@/services/authentifizierungsDienst";
import { aufnahmeHochladen } from "@/services/helfer";
import { fehlerSenden } from "@/services/LogDienst";
export default {
  components: {
    DialogTitel,
    FotosWaehlenAnsicht,
    InformationenWaehlenAnsicht,
    PartnerschaftenWaehlenAnsicht,
    FallWaehlenAnsicht,
    ButtonLeiste,
    FortschrittsLeiste,
    Spinner,
  },

  emits: ["schliessen"],

  setup(props, context) {
    let laed = ref(false);

    /** Index, von dem abhängig ist welche Ansicht aktuell angezeigt wird. */
    let index = ref(0);
    let fortschritt = computed(() => (index.value + 1) * 25);

    let titel = computed(() => {
      switch (index.value) {
        case 1:
          return patient.value !== undefined
            ? patient.value.nummer.toString()
            : "Fall";
        case 2:
          return "Informationen";
        case 3:
          return "Zusammenarbeiten";
        default:
          return "Aufnahmen";
      }
    });

    function forwaertsNavigieren() {
      if (index.value < 3) {
        if (index.value == 0) {
          laed.value = true;
          faelleFuerPatientAbfragen(patient.value.id).then((ergebnis) => {
            if (ergebnis && ergebnis.length > 0) {
              faelle.value = ergebnis;
              laed.value = false;
              index.value = 1;
            } else {
              //Keine Fälle für den Patienten vorhanden (z.B wenn Patient gerade erst erstellt wurde)
              fallId.value = einmaligenStringGenerieren();
              faelle.value = [];
              laed.value = false;
              index.value = 2;
            }
          });
        } else {
          index.value++;
        }
      }
    }

    function rueckwartsNavigieren() {
      if (index.value == 2) {
        index.value = 0;
      } else if (index.value > 0) {
        index.value--;
      }
    }

    //State für Foto und Patientenwahl
    let aufnahmen = ref([]);
    let patient = ref(null);

    function aufnahmenAktualisiert(wert) {
      aufnahmen.value = wert;
    }

    function patientAktualisiert(wert) {
      patient.value = wert;
    }

    function patientErstellt(wert) {
      laed.value = true;
      patientHochladen(wert).then(() => {
        patient.value = wert;
        laed.value = false;
      });
    }

    let aufnahmenGueltig = computed(
      () => aufnahmen.value.length > 0 && patient.value != null
    );

    //State für Fallwahl
    let faelle = ref([]);
    let fallId = ref(null);

    function fallIdAktualisiert(wert) {
      fallId.value = wert;

      let fall = faelle.value.find((f) => f.id == wert);

      if (fall != undefined) {
        themen.value = fall.themen.map((t) => t.id);
        schritt.value = fall.schritt + 1;
        zaehne.value = fall.zaehne;
      } else {
        themen.value = [];
        schritt.value = [];
        zaehne.value = [];
      }

      forwaertsNavigieren();
    }

    let fallGueltig = computed(() => fallId.value != null);

    //State für Informationswahl
    let themen = ref([]);
    let schritt = ref(1);
    let zaehne = ref([]);

    function themenAktualisiert(wert) {
      themen.value = wert;
    }

    function schrittAktualisiert(wert) {
      schritt.value = wert;
    }

    function zaehneAktualisiert(wert) {
      zaehne.value = wert;
    }

    let informationenGueltig = computed(
      () =>
        themen.value.length > 0 && schritt.value > 0 && zaehne.value.length > 0
    );

    //State für Partnerschaftswahl
    let partnerschaften = ref([]);

    function partnerschaftenAktualisiert(wert) {
      partnerschaften.value = wert;
    }

    function navigationMoeglich(index) {
      switch (index) {
        case 0:
          return aufnahmenGueltig.value;
        case 2:
          return informationenGueltig.value;
        default:
          return true;
      }
    }

    let hochladenMoeglich = computed(
      () =>
        aufnahmenGueltig.value === true &&
        fallGueltig.value === true &&
        informationenGueltig.value === true
    );

    /** Konfiguration für Navigationsbuttons */
    let buttonKonfiguration = computed(() => {
      var ergebnis = {
        links: {
          aktiv: index.value > 0 && laed.value === false,
          laed: false,
          text: "Zurück",
          aktion: rueckwartsNavigieren,
        },
      };
      if (index.value == 3) {
        ergebnis.rechts = {
          aktiv: navigationMoeglich(index.value) && laed.value === false,
          laed: laed.value,
          text: "Hochladen",
          aktion: hochladen,
        };
      } else if (index.value != 1) {
        ergebnis.rechts = {
          aktiv: navigationMoeglich(index.value),
          laed: laed.value,
          text: "Weiter",
          aktion: forwaertsNavigieren,
        };
      }

      return ergebnis;
    });

    async function hochladen() {
      try {
        if (hochladenMoeglich.value === true) {
          //Der Ladezustand wird gesetzt.
          laed.value = true;

          //Eine Liste von "Session Teilnehmer" Instanzen wird für die Erstellerpraxis und alle gewählten Teilnehmer erstellt
          var teilnehmer = {};

          //Der Eigentümer ist mit "Admin" Berechtigung immer Teilnehmer.
          let eigentuemer = store.getters.praxisAbfragen;

          teilnehmer[eigentuemer.id] = {
            id: eigentuemer.id,
            name: eigentuemer.name,
            avatarUrl: eigentuemer.avatarUrl,
            zugriff: ["0"],
          };

          if (partnerschaften.value.length > 0) {
            partnerschaften.value.forEach((p) => {
              let partner = Object.values(p.teilnehmer).find(
                (t) => t.id !== eigentuemer.id
              );
              teilnehmer[partner.id] = {
                id: partner.id,
                name: partner.name,
                avatarUrl: partner.avatarUrl,
                zugriff: [p.id],
              };
            });
          }

          //Alle statischen Daten werden in ein Sessionobjekt geschrieben
          var session = {
            id: einmaligenStringGenerieren(),
            fallId: fallId.value,
            themen: themen.value,
            schritt: schritt.value,
            zaehne: zaehne.value,
            eigentuemer: store.getters.praxisAbfragen.id,
            hochladeZeit: dayjs().unix(),
            patient: patient.value.id,
            behandler: nutzer.value.uid,
            teilnehmer: teilnehmer,
            snaps: [],
          };

          //Snaps für alle Aufnahmen werden erstellt, die Session mit dessen ID (und wenn möglich dessen Erstellungszeit) erweitert, und dann hochgeladen.
          for (let i = 0; i < aufnahmen.value.length; i++) {
            let ergebnis = await aufnahmeHochladen(
              aufnahmen.value[i],
              session.id
            );

            //Die ID des hochgeladenen Snaps wird in die Session eingefügt
            session.snaps.push(ergebnis.id);

            //Wenn der Snap eine gültige Entstehungszeit hat, werden die Werte der Session hier entsprechend aktualisiert
            if (
              ergebnis.entstehungsZeitpunkt != undefined &&
              typeof ergebnis.entstehungsZeitpunkt === "number"
            ) {
              if (
                !session.anfangsZeit ||
                session.anfangsZeit > ergebnis.entstehungsZeitpunkt
              ) {
                session.anfangsZeit = ergebnis.entstehungsZeitpunkt;
              }

              if (
                !session.endZeit ||
                session.endZeit < ergebnis.entstehungsZeitpunkt
              ) {
                session.endZeit = ergebnis.entstehungsZeitpunkt;
              }
            }
          }

          //Die Session wird hochgeladen
          await sessionHochladen(session);

          //Der Ladezustand wird beendet, und der Dialog geschlossen
          laed.value = false;
          context.emit("schliessen");
        }
      } catch (error) {
        fehlerSenden(
          error,
          "Fehler beim Hochladen einer Session.",
          "hochladen",
          "SessionHochladen"
        );
      }
    }

    return {
      //State
      titel,
      laed,
      buttonKonfiguration,
      index,
      fortschritt,
      aufnahmen,
      patient,
      faelle,
      fallId,
      themen,
      schritt,
      zaehne,
      partnerschaften,

      //Funktionen
      aufnahmenAktualisiert,
      patientAktualisiert,
      patientErstellt,
      fallIdAktualisiert,
      themenAktualisiert,
      schrittAktualisiert,
      zaehneAktualisiert,
      partnerschaftenAktualisiert,
    };
  },
};
</script>