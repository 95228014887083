<template>
    <div :class="{ 'onboarding-komponente': !istMobile, 'onboarding-komponente-mobile': istMobile }">
        <OnboardingTitel titel="AGB & Datenschutz"
            :untertitel="'Bitte lesen und bestätigen Sie unsere AGB und Datenschutzbedingungen.'" />

        <div class="w-full h-full flex flex-col"
            :class="{ 'justify-start space-y-8': !istMobile, ' justify-between': istMobile }">
            <!-- AGB und Angebote Checkboxen -->
            <div class="w-full flex flex-col space-y-6 text-lg">
                <!-- AGB -->
                <div class="w-full flex justify-start items-center space-x-3">
                    <OnboardingCheckbox :wert="agbAkzeptiert" @click="agbAkzeptiert = !agbAkzeptiert" />
                    <p>Ich habe die <a href="https://dentasnap.de/rechtliches/dokumente" target="_blank">AGB</a> und die
                        <a href="https://dentasnap.de/rechtliches/dokumente" target="_blank">Datenschutzbedingungen</a>
                        gelesen
                        und stimme Ihnen zu.</p>
                </div>

                <!-- Angebote -->
                <div class="w-full flex justify-start items-center space-x-3">
                    <OnboardingCheckbox :wert="angeboteAkzeptiert" @click="angeboteAkzeptiert = !angeboteAkzeptiert" />
                    <p>Ja, ich würde gerne Angebote und updates zu Dentasnap und weiteren Produkten der JMS Invent GmbH
                        erhalten.</p>
                </div>
            </div>

            <OnboardingWeiterButton :laedt="laedt" :deaktiviert="!agbAkzeptiert" @click="speichern" />
        </div>
    </div>
</template>
<script>
import OnboardingTitel from "@/components/OnboardingTitel.vue"
import OnboardingCheckbox from "@/components/OnboardingCheckbox/OnboardingCheckbox.vue"
import OnboardingWeiterButton from "@/components/OnboardingWeiterButton.vue"
import { computed, ref } from "vue"
import { useCloudFunction } from "@/services/cloudFunctionDienst"
import store from "@/store"
export default {
    components: { OnboardingTitel, OnboardingCheckbox, OnboardingWeiterButton },
    emits: ["weiter"],
    setup(_, context) {
        const istMobile = computed(() => store.state.mobile)

        const laedt = ref(false)

        const agbAkzeptiert = ref(false)
        const angeboteAkzeptiert = ref(false)

        const { error, call } = useCloudFunction("claimsAktualisieren")

        function speichern() {
            if (agbAkzeptiert.value === true) {
                laedt.value = true
                call({
                    agb: agbAkzeptiert.value,
                    emails: angeboteAkzeptiert.value
                })
                    .then(() => {
                        if (!error.value) {
                            context.emit("weiter")
                        }
                    })
                    .finally(() => laedt.value = false)
            }
        }

        return { laedt, agbAkzeptiert, angeboteAkzeptiert, istMobile, speichern }
    }
}
</script>

<style scoped>
a {
  color: #007AFF !important;
}

a:hover {
  text-decoration: underline;
}
</style>