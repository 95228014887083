<template>
  <GDialog v-if="istPraxis === true" v-model="sessionHinzufuegenAnzeigen" max-width="850">
    <SessionHochladen @schliessen="sessionHinzufuegenAnzeigen = false" class="h-140" />
  </GDialog>

  <!-- Ladekreisel -->
  <div class="w-full h-screen flex justify-center items-center" v-if="snapsLaden">
    <LottieAnimation class="w-72 aspect-square" :animation-data="ladenBlau" :auto-play="true" :loop="true" />
  </div>

  <!-- "Normale" Sessions -->
  <div class="standard-seite pointer-events-auto">
    <!-- Seitentitel und Buttons -->
    <div class="w-full flex justify-between items-center">
      <!-- Seiten Titel -->
      <PageTitle icon="grid" title="Gallerie" />

      <div class="flex justify-center items-center">
        <!-- "Filter Anzeigen / Ausblenden" Buttin -->
        <AktionsButtonPrimaer v-if="nutzerDarfSessionsImportieren === true && istPraxis === true" iconName="add"
          @gedrueckt="zuSessionHochladen" />

        <AktionsButtonSekundaer class="ml-3" iconName="filter-outline" @gedrueckt="filterAnzeigen = true" />
      </div>
    </div>

    <!-- Platzhalter, falls Gallerie noch leer -->
    <LeereSeiteVorlage v-if="platzhalterAnzeigen" :animationsDaten="frauKarton" titel="Gallerie"
      :body="istPraxis ? 'Laden Sie Ihre ersten Fotos über die Dentasnap iOS App hoch, oder klicken Sie auf das “+”' : 'Wenn Sie Partnerschaften abschließen, sehen Sie hier Fälle, die mit Ihnen geteilt werden.'"
      :appStoreQrAnzeigen="istPraxis" />

    <!-- Fälle -->
    <div v-else class="w-full h-full space-y-3">
      <div class="w-full flex flex-col" v-for="fall in faelle" :key="fall[0].fallId">
        <Fall :fall="fall" />
      </div>
    </div>

    <!-- Filter -->
    <Filter :anzeigen="filterAnzeigen" @schliessen="filterAnzeigen = false" @aktualisiert="filterAktualisiert" />
  </div>
</template>

<script>
//Alle benötigten Frameworks und Vue Komponenten werden importiert
import { computed, onMounted, ref, watch } from "@vue/runtime-core";
import store from "@/store";
import { GDialog } from "gitart-vue-dialog";
import ladenBlau from "@/assets/animations/laden-blau.json"

//Alle benötigten eigenen Komponenten werden importiert
import PageTitle from "@/components/PageTitle.vue";
import LeereSeiteVorlage from "@/components/LeereSeiteVorlage.vue";
import Fall from "@/components/fall/Fall.vue";
import AktionsButtonPrimaer from "@/components/AktionsButtonPrimaer.vue";
import AktionsButtonSekundaer from "@/components/AktionsButtonSekundaer.vue";
import SessionHochladen from "./components/SessionHochladen.vue";
import Filter from "./pages/filter/Filter.vue";
import { LottieAnimation } from "lottie-web-vue";
import frauKarton from "@/assets/animations/frau-karton.json"

//Alle benötigten Helferfunktionen und Konstanten werden importiert
import { dialogHintergrund } from "@/constants/konstanten";
import { useRoute, useRouter } from "vue-router";
import { faelleGenerieren } from "@/services/helfer";
import { berechtigungenPruefen } from "@/services/authentifizierungsDienst";
import { sessionsImportieren } from "@/constants/berechtigungen";

export default {
  components: {
    PageTitle,
    LeereSeiteVorlage,
    Fall,
    AktionsButtonPrimaer,
    AktionsButtonSekundaer,
    GDialog,
    SessionHochladen,
    LottieAnimation,
    Filter
  },
  setup(props, context) {
    /** Ob die Snaps aktuell (noch) Laden. Wenn ja wird die Ladeanimation angezeigt. */
    const snapsLaden = computed(() => {
      return store.state.snapsLaden ||
        store.state.kontenLaden ||
        store.state.patientenLaden ||
        store.state.themenLaden ||
        store.state.partnerschaftenLaden
    });

    const platzhalterAnzeigen = computed(() => !store.state.snapsLaden && (!store.state.snaps || !store.state.snaps.length))

    //Aktuelle Route
    const route = useRoute();
    const router = useRouter()

    //State für den Filter
    const filterAnzeigen = ref(false);
    const filter = ref(null);

    //Liste aller vorhandenen Fälle, anfangs ohne Filter
    const faelle = ref(faelleGenerieren());

    const istPraxis = computed(() => store.state.istPraxis)

    /**
     * Wird jedes Mal aufgerufen, wenn eine Änderung am Filter getätigt wird. Generiert die "Fälle" Liste mit den aktuellen Beschränkungen neu.
     * @param {{patient: string, themen: [string], zaehne: [{nummer: number, implantat: boolean}]}} neuerFilter Neue Version des Filters
     */
    function filterAktualisiert(neuerFilter) {
      filter.value = neuerFilter;
      faelle.value = faelleGenerieren(filter.value);
      console.log("Neuer Filter", filter.value);
    }

    function zuSessionHochladen() {
      router.push({ name: "SessionHochladen" })
    }

    //TODO: Hier wieder den aktuellen Filter übergeben!
    store.watch(
      (state) => state.sessions,
      () => (faelle.value = faelleGenerieren())
    );

    onMounted(() => {
      if (route.params && route.params.albumId) {
        filterAnzeigen.value = true;
        filterAktualisiert({ alben: [route.params.albumId] });
      }
    });

    // Watcher for filterAnzeigen
    watch(filterAnzeigen, nv => document.documentElement.style.overflow = nv === true ? 'hidden' : '');

    //State für Dialog zum Hinzufügen von Sessions
    let sessionHinzufuegenAnzeigen = ref(false);
    let nutzerDarfSessionsImportieren = computed(() => berechtigungenPruefen([sessionsImportieren]));

    return {
      //State für das UI
      faelle,
      filter,
      filterAnzeigen,
      snapsLaden,
      platzhalterAnzeigen,
      sessionHinzufuegenAnzeigen,
      nutzerDarfSessionsImportieren,
      ladenBlau,
      frauKarton,
      istPraxis,

      //Funktionen füt das UI
      filterAktualisiert,
      zuSessionHochladen,

      //Konstanten für das Dialog Ui
      dialogHintergrund,
    };
  },
};
</script>

<style scoped>
/* CSS class to disable scrolling */
.no-scroll {
  overflow: hidden;
}
</style>
