<template>
  <div v-if="fall && session"
    class="h-full flex justify-start items-between rounded-2xl bg-white border-l-8 border-white font-dm-sans"
    :class="{ 'border-l-GeteiltViolett': zusammenarbeit === true }" @mouseenter="hover = true"
    @mouseleave="hover = false">
    <!-- Informationsleiste -->
    <div class="w-85 flex flex-col space-y-8 justify-between items px-7 py-6">
      <div class="w-full h-full flex flex-col space-y-6 justify-start items-start">
        <!-- Patienten-Infos -->
        <div v-if="patient" class="flex flex-col space-y-2 justify-start items-start">
          <p v-if="typeof patient.name === 'string'" class="text-lg font-bold">{{ patient.name }}</p>
          <p v-else class="text-lg text-Mittelgrau italic">Kein Name</p>
          <div class="flex flex-col space-y-1">
            <div class="flex space-x-3">
              <img src="@/assets/icons/tag-schwarz.svg" alt="#" class="w-4 object-contain">
              <p>{{ patient.nummer }}</p>
            </div>
            <div class="flex space-x-3">
              <img src="@/assets/icons/kalender-schwarz.svg" alt="#" class="w-4 object-contain">
              <p v-if="(typeof patient.geburtsDatum === 'number')">{{
                dayjs.unix(patient.geburtsDatum).format("DD.MM.YYYY") }}</p>
              <p v-else class="text-Mittelgrau italic">Kein Geburtsdatum</p>
            </div>
          </div>
        </div>

        <!-- Zähne -->
        <div class="bg-Hintergrund px-5 py-5 rounded-2xl">
          <ZahnSchema :zaehne="zaehne" :interaktiv="false" />
        </div>

        <!-- Themen -->
        <div class="flex flex-col space-y-2">
          <div v-for="t in themen" :key="t.id" class="flex space-x-3">
            <img :src="themaIconAbfragen(t)" alt="icon" class="w-5 aspect-square object-contain">
            <p class="tracking-wide text-sm"
              :class="{ 'text-DentaBlue': t.standard === true, 'text-DunklesViolett': t.standard === false }">{{ t.name }}
            </p>
          </div>
        </div>
      </div>

      <div class="flex flex-col space-y-4 justify-start items-start">
        <div v-if="zusammenarbeit === true" class="flex items-center h-9 mb-2 text-GeteiltViolett">
          <ion-icon name="arrow-redo"></ion-icon>
          <p class="text-sm ml-2">Zusammenarbeit</p>
        </div>

        <!-- Datum und Erstellerinformationen -->
        <div class="w-72 flex justify-start items-center">
          <img v-if="erstellerBild" :src="erstellerBild" alt="url" class="w-9 h-9 rounded-full mr-2" />
          <div v-else class="w-9 h-9 bg-DentaBlueLight rounded-full mr-2"></div>

          <div class="flex flex-col justify-center items-start">
            <p class="text-xs text-LeichtesGrau">{{ getDateString() }}</p>
            <p class="text-sm text-Mittelgrau">{{ erstellerName }}</p>
          </div>
        </div>

        <!-- Aktionsleiste -->
        <div class="w-full py-2 bg-Hintergrund rounded-xl flex justify-center items-center space-x-3 text-Mittelgrau">
          <!-- Aktionsbuttons -->
          <div v-if="zusammenarbeit === false" class="w-full flex justify-evenly items-center">
            <button :disabled="nutzerDarfSessionsVerwalten === false" :class="{
              'transition duration-150 hover:opacity-70 hover:cursor-pointer': nutzerDarfSessionsVerwalten === true
            }" @click="bearbeiten('informationen')">
              <img src="@/assets/icons/zahnraeder-grau.png" alt="informationen" class="w-5 aspect-square object-contain">
            </button>

            <button :disabled="nutzerDarfZusammenarbeitVerwalten === false" :class="{
              'transition duration-150 hover:opacity-70 hover:cursor-pointer': nutzerDarfSessionsVerwalten === true
            }" @click="bearbeiten('partner')">
              <img src="@/assets/icons/nutzer-hinzufuegen-grau.png" alt="partner"
                class="w-5 aspect-square object-contain">
            </button>

            <button :disabled="nutzerDarfSessionsVerwalten === false" :class="{
              'transition duration-150 hover:opacity-70 hover:cursor-pointer': nutzerDarfSessionsVerwalten === true
            }" @click="bearbeiten('fotos')">
              <img src="@/assets/icons/foto-hinzufuegen-grau.png" alt="fotos" class="w-5 aspect-square object-contain">
            </button>
          </div>

          <div v-if="zusammenarbeit === false" class="h-full w-0.5 rounded-full bg-white"></div>

          <!-- Fall Navigation -->
          <div class="w-full flex justify-evenly items-center">
            <button @click="navigieren(false)" :disabled="index <= 0" class="w-5 aspect-square">
              <img v-if="index > 0" src="@/assets/icons/pfeil-links-kreis-blau.svg" alt="" class="object-contain">
              <img v-else src="@/assets/icons/pfeil-links-kreis-leichtes-grau.svg" alt="" class="object-contain">
            </button>

            <p class="w-5 text-center text-DentaBlue text-xl">{{ index + 1 }}</p>

            <button @click="navigieren(true)" :disabled="index >= fall.length - 1" class="w-5 aspect-square">
              <img v-if="index < fall.length - 1" src="@/assets/icons/pfeil-rechts-kreis-blau.svg" alt=""
                class="object-contain">
              <img v-else src="@/assets/icons/pfeil-rechts-kreis-leichtes-grau.svg" alt="" class="object-contain">
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Snaps -->
    <div id="snaps" class="h-full flex flex-wrap pt-3 pb-2 pr-3">
      <div v-for="id in snapIds" :key="id">
        <SessionSnapThumnail v-if="snaps && snaps.find((s) => s.id == id) != undefined"
          :snap="snaps.find((s) => s.id == id)" @click="snapGewaehlt(id)" />
        <div v-else
          class="h-44 w-44 mr-1 mb-1 bg-LeichtesGrau text-white flex flex-col justify-center items-center text-center">
          <ion-icon class="text-xl mb-2" name="cloud-upload"></ion-icon>
          <p>Wird<br />Hochgeladen...</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//Importieren aller anderen benötigten Komponenten
import SessionSnapThumnail from "./components/SessionSnapThumnail.vue";
import ZahnSchema from "../ZahnSchema.vue";

//Import aller benötigten Frameworks und Vue-Bestandteile
import { router } from "@/router";
import dayjs from "dayjs";
import { computed, ref, watch } from "@vue/runtime-core";
import store from "@/store";

//Import aller benötigten Konstanten und Helferfunktionen
import { nutzerProfilbildAbfragen, zahnIconAbfragen, themenIconAbfragen } from "@/services/helfer";
import {
  berechtigungenPruefen,
  nameAbfragen,
  nutzer,
} from "@/services/authentifizierungsDienst";
import {
  sessionsVerwalten,
  zusammenarbeitVerwalten,
} from "@/constants/berechtigungen";
import { geloeschterNutzerBild, standardGeraeteProfilBildUrl } from "@/constants/konstanten"
import { onMounted } from "vue";

export default {
  components: {
    SessionSnapThumnail,
    ZahnSchema,
  },

  props: ["fall"],
  emits: ["bearbeiten"],

  setup(props, context) {
    /** Gibt an, ob der Nutzer mit seinem Mauscursor aktuell über der Session ist, oder nicht.*/
    const hover = ref(false);

    /** Index der aktuell angezeigten Session aus dem Fall. Wird auf die letzte Session initialisiert. */
    const index = ref(props.fall.length - 1);

    watch(() => props.fall, fall => {
      if (fall != null && fall.length > 0) {
        index.value = fall.length - 1;
      }
    }
    );

    /**
     * Öffnet die "bearbeiten" Seite mit dem gewünschten Typ.
     * @param {string} bearbeitungsTyp 
     */
    function bearbeiten(bearbeitungsTyp) {
      router.push({ name: "SessionBearbeiten", params: { sessionId: session.value.id, typ: bearbeitungsTyp } })
    }

    /** Gibt anhand des index die Session zurück, die aktuell angezeigt werden soll. */
    const session = computed(() => {
      if (
        index.value != null &&
        props.fall != null &&
        props.fall.length != null
      ) {
        return props.fall[index.value];
      }
    });

    function themaIconAbfragen(t) {
      if (t.standard === true) {
        return themenIconAbfragen(t, false)
      } else {
        return require("@/assets/icons/ordner.svg")
      }
    }

    /**
     * Wird bei einem Klick auf ein Aufnahmethumnail aufgerufen. Zeigt die Aufnahmeseite mit passenden Session -und Snap ID Parametern an
     * @param {string} id id des Snaps, der geladen werden soll
     */
    function snapGewaehlt(id) {
      if (session.value != null && id != null) {
        router.push({
          name: "Aufnahme",
          params: { sessionId: session.value.id, snapId: id },
        });
      }
    }

    function navigieren(forwaerts) {
      if (forwaerts === true && index.value < props.fall.length - 1) {
        index.value++;
      } else if (forwaerts === false && index.value > 0) {
        index.value--;
      }
    }

    /**
     * Gibt einen Datumsstring im Format "Tag.Monat.Jahr, Stunde:Minute" für den Hochladezeitpunkt der aktuell angezeigten Session zurück.
     */
    const getDateString = () => {
      if (
        session.value !== undefined &&
        session.value.anfangsZeit !== undefined &&
        session.value.endZeit !== undefined
      ) {
        let startDate = dayjs.unix(Number.parseInt(session.value.anfangsZeit));
        let endDate = dayjs.unix(Number.parseInt(session.value.endZeit));

        if (startDate.isSame(endDate, "minute")) {
          //If all images were taken within the same minute, only show that time
          return startDate.format("DD.MM.YYYY, HH:mm");
        } else if (startDate.isSame(endDate, "day")) {
          //Sollten die aufnahmen zu verschiedenen Zeiten, aber am selben Tag erstellt worden sein,
          //Wird ein passender String generiert
          return `${startDate.format("DD.MM.YYYY")}, ${startDate.format(
            "HH:mm"
          )} - ${endDate.format("HH:mm")}`;
        } else {
          //Die Fotos wurden über verschiedene Tage erstellt, ein passender String wird erstellt
          return `${startDate.format("DD.MM.YYYY, HH:mm")} - ${endDate.format(
            "DD.MM.YYYY, HH:mm"
          )}`;
        }
      } else {
        return "Keine Datumsangabe";
      }
    };

    /**
     * Gibt für die Patienten-ID im Fall die entsprechende Patientennummer zurück.
     */
    const patient = computed(() => {
      if (props.fall && session.value && store.state.patienten.length) {
        return store.getters.patientAbfragen(session.value.patient);
      }
    });

    /**
     * Gibt alle Themen der aktuell angezeigten Session zurück.
     */
    const themen = computed(() => {
      if (props.fall && session.value) {
        var ergebnis = [];
        for (let i = 0; i < session.value.themen.length; i++) {
          const thema = store.getters.themaAbfragen(session.value.themen[i]);
          if (thema) {
            ergebnis.push(thema);
          }
        }
        return ergebnis;
      }
    });

    const zaehne = computed(() => {
      if (session.value) {
        return session.value.zaehne;
      }
    });

    const erstellerName = computed(() => {
      if (zusammenarbeit.value === true) {
        return partner.value.name
      } else {
        const nutzer = store.getters.kontoAbfragen(props.fall[0].behandler)
        if (nutzer != undefined) {
          if (nutzer.typ === "geraet") {
            return nutzer.geraeteName
          } else {
            return nameAbfragen(nutzer.nutzerName)
          }
        } else {
          return "Gelöschter Nutzer"
        }
      }
    })
    const erstellerBild = ref(null)

    let snapIds = computed(() => {
      if (session.value) {
        return session.value.snaps;
      }
    });

    let snaps = computed(() => {
      if (store.state.snaps && session.value) {
        return store.state.snaps.filter(
          (s) => session.value.snaps.find((si) => si == s.id) != undefined
        );
      }
    });

    /** Gibt an, ob die Session der Praxis gehört oder von einer anderen empfangen wurde. */
    const zusammenarbeit = computed(() => {
      if (session.value && store.getters.praxisAbfragen) {
        return session.value.eigentuemer != store.getters.praxisAbfragen.id;
      } else return false;
    });

    const partner = computed(() => {
      if (zusammenarbeit.value === true) {
        let partnerschaft = store.state.partnerschaften.find(p => p.teilnehmer[props.fall[0].eigentuemer] != undefined)
        return partnerschaft.teilnehmer[props.fall[0].eigentuemer]
      }
    })

    let nutzerDarfSessionsVerwalten = computed(() =>
      berechtigungenPruefen([sessionsVerwalten])
    );

    let nutzerDarfZusammenarbeitVerwalten = computed(() =>
      berechtigungenPruefen([zusammenarbeitVerwalten])
    );

    onMounted(() => {
      if (zusammenarbeit.value === true) {
        erstellerBild.value = partner.value.avatarUrl
      } else {
        const nutzer = store.getters.kontoAbfragen(props.fall[0].behandler)
        if (nutzer != null) {
          if (nutzer.typ === "geraet") {
            erstellerBild.value = standardGeraeteProfilBildUrl
          } else {
            //Nutzer nicht gelöscht
            nutzerProfilbildAbfragen(nutzer.uid).then(url => erstellerBild.value = url.url)
          }
        } else {
          erstellerBild.value = geloeschterNutzerBild
        }
      }
    })

    return {
      //State für das UI
      hover,
      index,
      session,
      patient,
      themen,
      zaehne,
      erstellerName,
      erstellerBild,
      snapIds,
      snaps,
      zusammenarbeit,
      nutzerDarfSessionsVerwalten,
      nutzerDarfZusammenarbeitVerwalten,
      dayjs,

      //Funktionen für das UI
      themaIconAbfragen,
      snapGewaehlt,
      getDateString,
      bearbeiten,
      zahnIconAbfragen,
      navigieren
    };
  },
};
</script>

<style scoped>
#snaps img {
  pointer-events: all;
}</style>
