//Enthält Helferfunktionen, die in der gesamten Anwendung verwendet werden können.

//Import aller benötigten Referenzen
import {
  standardBenutzerProfilBildUrl,
  standardGeraeteProfilBildUrl,
  webName,
} from "@/constants/konstanten";
import {
  agbErklaerung,
  passwortErstellen,
  praxisAngaben,
  profilAngaben,
  mfaVerifizierung,
  mitarbeiterEinrichten,
  profilBildHochladen,
  telefonnummerAngeben,
} from "@/constants/kontoKonfigRouten";
import { firestore, storage } from "@/firebase/config";
import store from "@/store";
import dayjs from "dayjs";
import {
  ref,
  getBlob,
  uploadBytes,
  getMetadata,
  getDownloadURL,
} from "firebase/storage";
import { resizeImage } from "./bildBearbeitungsDienst";
import { bildHochladen } from "./cloudStorageDienst";
import { einmaligenStringGenerieren, snapHochladen } from "./firestoreDienst";
import { standardToastAnzeigen } from "./toastDient";
import exifr from "exifr";
import { berechtigungenPruefen } from "./authentifizierungsDienst";
import { aufnahmenVerwalten } from "@/constants/berechtigungen";
import { fehlerSenden } from "./LogDienst";
import { collection, getDocs, query, where } from "firebase/firestore";
const axios = require("axios");
require("dayjs/locale/de");
dayjs.locale("de");

/** Migrieren in "Avatar.vue" Datei */
//Entnimmt der gegebenen Download URL für einen standard avatar
const avatarNamenUndFarbeFuerUrlAbfragen = (url) => {
  //Zunächst wird geprüft, dass es sich im einen standard avatar handelt. Wenn nicht, wird direkt ein leerer Wert zurückgegeben.
  if (url.includes("standard-nutzer-avatare")) {
    //Ein Array mit
    const urlBestandteile = url.split("/");
    const pfadBestandteile =
      urlBestandteile[urlBestandteile.length - 1].split("?");
    const namensBestandteile = pfadBestandteile[0].split("%2F");

    var farbKlasse;

    const farbName = namensBestandteile[2].split(".")[0];
    switch (farbName) {
      case "gelb":
        farbKlasse = "bg-avatarGelb";
        break;
      case "gruen":
        farbKlasse = "bg-avatarGruen";
        break;
      case "blau":
        farbKlasse = "bg-avatarBlau";
        break;
      case "lila":
        farbKlasse = "bg-avatarLila";
        break;
      case "rot":
        farbKlasse = "bg-avatarRot";
        break;
      default:
        break;
    }

    return {
      name: namensBestandteile[1],
      farbe: farbKlasse,
    };
  } else {
    return undefined;
  }
};

/** Migrieren in "PersönlicheAngaben" */
/**
 * Anhand der übergebenen Farbklasse wird der Name der Avatarfarbe bestimmt (für Download-URLs)
 * @param {String} klasse Tailwind-Klasse der Farbe, dessen Name bestimmt werden soll
 * @returns {String} Name der Farbe, wie er Avatar-URLs verwendet wird
 */
function avatarFarbeAbfragen(klasse) {
  return klasse.split("-avatar")[1].toLowerCase();
}

/** Migrieren in "PasswortZuruecksetzen.vue" Komponente */
//Gibt eine Version der E-Mail zurück, bei der Alle Zeichen vor dem '@' durch sterne ersetzt wurden.
const emailAdresseZensieren = (email) => {
  //Sollte der String keine E-Mail Adresse sein, wird er ohne Änderung zurückgegeben.
  if (!email.includes("@")) {
    return email;
  }

  const name = email.split("@")[0];
  const ersterBuchstabe = name.charAt(0);
  const sterne = "".padEnd(name.length - 1, "*");
  return ersterBuchstabe + sterne + "@" + email.split("@")[1];
};

//Gibt eine Version der Nummer zurück, die bis auf die Vorwahl und die letzten beiden Ziffern zensiert ist.
const telefonNummerZensieren = (nummer) => {
  const vorwahl = nummer.substring(0, 3);
  const endZiffern = nummer.substring(nummer.length - 2, nummer.length);
  const sterne = "".padEnd(nummer.length - 5, "*");
  return vorwahl + sterne + endZiffern;
};

/** Löschen */
//Verarbeitet die Fehler, die beim Ändern des Passworts auftreten können
const passwortFehlerVerarbeiten = (error) => {
  switch (error.code) {
    case "auth/requires-recent-login":
      //Ein Erklärungs-Toast wird angezeigt
      standardToastAnzeigen(
        "text-Warning",
        "alert-circle",
        "Erneute Anmeldung Nötig",
        "Melden Sie sich mit ihrem aktuellen Passwort an, um fortzufahren!"
      );

      //Die aktuelle Route wird für nach der Anmeldung gespeichert
      const aktuelleRoute = router.currentRoute.value;
      store.commit("nachAnmeldungRouteSetzen", aktuelleRoute);

      //Die "Anmeldung" Seite wird angezeigt
      router.push({ name: "Anmeldung" });
      return "Erneute Anmeldung vor Änderung nötig.";
    case "auth/weak-password":
      return "Ihr Passwort muss mindestens 8 Zeichen enthalten.";
    default:
      return "Ein Fehler ist aufgetreten. Bitte versuchen Sie es erneut.";
  }
};

/** Verbleibt */
/**
 * Generiert eine Liste von Fällen, die alle Sessions beinhalten auf die die Praxis aktuell Zugriff hat.
 * @param {{patient: string, themen: [string], zaehne: [{nummer: number, implantat: boolean}], zeitraum: {anfang: dayjs, ende: dayjs}}} filter Optionale Filterinstanz. Wenn vorhanden werden nur Sessions beinhaltet, die dem Filter entsprechen.
 * @returns Liste von Listen von Sessions. Jede verschachtelte Liste stellt einen Fall dar.
 */
function faelleGenerieren(filter) {
  try {
    var ergebnis = [];

    var sessions = store.getters.sessionsAbfragen;

    if (filter != undefined) {
      if (filter.patient != undefined) {
        sessions = sessions.filter((s) => s.patient == filter.patient);
      }

      if (
        filter.zaehne != undefined &&
        filter.zaehne.length != undefined &&
        filter.zaehne.length > 0
      ) {
        for (let i = 0; i < filter.zaehne.length; i++) {
          const zahn = filter.zaehne[i];

          if (zahn.implantat === true) {
            sessions = sessions.filter(
              (s) =>
                s.zaehne.find(
                  (z) => z.nummer === zahn.nummer && z.implantat === true
                ) !== undefined
            );
          } else {
            sessions = sessions.filter(
              (s) =>
                s.zaehne.find((z) => z.nummer === zahn.nummer) !== undefined
            );
          }
        }
      }

      if (
        filter.themen != undefined &&
        filter.themen.length &&
        filter.themen.length > 0
      ) {
        for (let i = 0; i < filter.themen.length; i++) {
          sessions = sessions.filter(
            (s) => s.themen.find((t) => t === filter.themen[i]) !== undefined
          );
        }
      }

      if (filter.zeitraum != undefined) {
        sessions = sessions.filter(
          (s) =>
            s.anfangsZeit >= filter.zeitraum.anfang.unix() &&
            s.endZeit <= filter.zeitraum.ende.unix()
        );
      }

      if (filter.praxis != undefined) {
        sessions = sessions.filter((s) => s.eigentuemer == filter.praxis);
      }
    }

    if (sessions && sessions.length) {
      sessions.forEach((s) => {
        let index = ergebnis.findIndex(
          (l) => l.find((ls) => ls.fallId == s.fallId) != undefined
        );

        if (index != -1) {
          //Fall wurde bereits erfasst
          ergebnis[index].push(s);
        } else {
          //Fall wurde noch nicht erfasst
          ergebnis.push([s]);
        }
      });
    }

    for (let i = 0; i < ergebnis.length; i++) {
      ergebnis[i].sort(
        (a, b) => Number(a.hochladeZeit) - Number(b.hochladeZeit)
      );
    }

    ergebnis.sort(
      (a, b) =>
        Number(b[b.length - 1].hochladeZeit) -
        Number(a[a.length - 1].hochladeZeit)
    );

    for (let i = 0; i < ergebnis.length; i++) {
      ergebnis[i] = ergebnis[i].sort((a, b) => a.schritt - b.schritt);
    }

    return ergebnis;
  } catch (error) {
    fehlerSenden(
      error,
      "Fehler beim Generieren der Fälle.",
      "faelleGenerieren",
      "helfer"
    );
  }
}

/** Verbleibt */
function fileToDataUri(field) {
  return new Promise((resolve, reject) => {
    try {
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        resolve(reader.result);
      });
      reader.readAsDataURL(field);
    } catch (error) {
      fehlerSenden(
        error,
        "Fehler beim Konvertieren eines 'Blob' Objekts in einen Daten-URI",
        "fileToDataUri",
        "helfer"
      );
      reject(error);
    }
  });
}

/** Migrieren in "speicherDienst" oder "cloudSpeicherDienst" */
/**
 * Läd aus dem Cloudstorage die Bilddatei zum Snap als Thumnail oder Vollversion, und gibt sie als lokale Datei-URI zum Laden in "img" Tags zurück.
 * @param {String} snapId ID des gewünschten Snaps
 * @param {Boolean} istThumbnail Ob die thumnail- oder Vollversion geladen werden soll
 * @returns {Promise<String?>} Lokale URI der Datei, oder null wenn die Datei nicht gefunden wurde.
 */
async function snapUriAbfragen(snapId, istThumbnail) {
  try {
    const pfad = istThumbnail
      ? `snaps/${snapId}_thum.jpg`
      : `snaps/${snapId}_full.jpg`;
    var referenz = ref(storage, pfad);
    const dateiBlob = await getBlob(referenz);
    return window.URL.createObjectURL(dateiBlob);
  } catch (error) {
    try {
      const pfad = istThumbnail
        ? `snaps/${snapId}_thum.jpeg`
        : `snaps/${snapId}_full.jpeg`;
      var referenz = ref(storage, pfad);
      const dateiBlob = await getBlob(referenz);

      return window.URL.createObjectURL(dateiBlob);
    } catch (error) {
      fehlerSenden(
        error,
        "Fehler beim Laden einer Bilddatei zu einem Snap.",
        "snapUriAbfragen",
        "helfer"
      );
      return null;
    }
  }
}

/** Verbleibt */
const rueckgabeErstellen = (code, daten) => {
  var ergebnis = { code: code };

  if (daten != undefined) {
    ergebnis.daten = daten;
  }

  return ergebnis;
};

/** Verbleibt */
const versionAbfragen = () => {
  try {
    const version =
      process.env.VUE_APP_VERSION != null ? process.env.VUE_APP_VERSION : 0;
    return `${webName}-${version}`;
  } catch (error) {
    fehlerSenden(
      error,
      "Fehler beim Abfragen der lokalen Webapp Version",
      "versionAbfragen",
      "helfer"
    );
  }
};

/** Migrieren in neue "cloudSpeicherDienst" */
//Ersetzt den Snap hinter der gegebenen ID im Cloud Speicher mit dem gegebenen Blob
async function snapDateienErsetzen(id, blob) {
  try {
    if (berechtigungenPruefen([aufnahmenVerwalten]) === true) {
      var typ = blob.type.split("/")[1];

      //Sollte der Dateityp nicht unterstützt werden, bricht die Funktion ab
      if (typ != "jpg" && typ != "jpeg" && typ != "png") return;

      //Um Konsistenz zu bewahren, werden "jpeg" endungen in "jpg" vereinfacht
      if (typ == "jpeg") {
        typ = "jpg";
      }

      //Aus der zugeschnittenen Datei wird ein Thumnail generiert
      const thumnail = await resizeImage(blob, "thumnail");

      //Pfade für Thumnail und Vollversion werden erstellt
      const thumnailPfad = `snaps/${id}_thum.${typ}`;
      const vollerPfad = `snaps/${id}_full.${typ}`;

      //Die Dateien werden hochgeladen
      await uploadBytes(ref(storage, thumnailPfad), thumnail);
      await uploadBytes(ref(storage, vollerPfad), blob);

      //Die aktuell gespeicherten lokalen URL's werden abgefragt und gelöscht
      var thumnailLocal = store.getters.snapVorschauLinkAbfragen(id);
      var fullLocal = store.getters.snapVollLinkAbfragen(id);

      if (thumnailLocal != undefined) {
        window.URL.revokeObjectURL(thumnailLocal);
      }

      if (fullLocal != undefined) {
        window.URL.revokeObjectURL(fullLocal);
      }

      //Neue lokale URL's werden generiert und ersetzen die alten
      thumnailLocal = window.URL.createObjectURL(thumnail);
      fullLocal = window.URL.createObjectURL(blob);
      store.commit("snapVorschauLinkSetzen", { id: id, link: thumnailLocal });
      store.commit("snapVollLinkSetzen", { id: id, link: fullLocal });
    }
  } catch (error) {
    fehlerSenden(
      error,
      "Fehler beim Ersetzen der Bilddateien zu einem Snap.",
      "snapDateienErsetzen",
      "helfer"
    );
  }
}

/** Verbleibt zunächst */
/**
 *
 * Gibt passend zu der Zahnnummer das Zahn -oder Implantats Icon zurück.
 *
 * @param {number} nummer Die nummer des Zahns, zu dem das passende icon gegeben werden soll
 * @param {boolean} selected Ob der Zahn aufgewählt ist oder nicht
 * @param {boolean} implantat Ob ein Implantat oder ein normaler Zahn vorliegt (nur bei ausgewählten Zähnen relevant.)
 *
 */
function zahnIconAbfragen(nummer, selected, implantat) {
  //Zunächst wird der Implantatsfall geprüft und bearbeitet
  if (implantat) {
    return nummer > 30
      ? require("@/assets/implants/implant_down_fill.png")
      : require("@/assets/implants/implant_up_fill.png");
  } else {
    if ((nummer <= 18 && nummer >= 16) || (nummer >= 26 && nummer <= 28)) {
      return selected
        ? require("@/assets/teeth/double_wide_up_fill.png")
        : require("@/assets/teeth/double_wide_up.png");
    } else if (
      nummer == 15 ||
      nummer == 13 ||
      nummer == 12 ||
      nummer == 22 ||
      nummer == 23 ||
      nummer == 25
    ) {
      return selected
        ? require("@/assets/teeth/single_slim_up_fill.png")
        : require("@/assets/teeth/single_slim_up.png");
    } else if (nummer == 14 || nummer == 24) {
      return selected
        ? require("@/assets/teeth/double_slim_up_fill.png")
        : require("@/assets/teeth/double_slim_up.png");
    } else if (nummer == 11 || nummer == 21) {
      return selected
        ? require("@/assets/teeth/single_wide_up_fill.png")
        : require("@/assets/teeth/single_wide_up.png");
    } else if (
      (nummer <= 48 && nummer >= 46) ||
      (nummer >= 36 && nummer <= 38)
    ) {
      return selected
        ? require("@/assets/teeth/double_wide_down_fill.png")
        : require("@/assets/teeth/double_wide_down.png");
    } else if (nummer == 45 || nummer == 44 || nummer == 34 || nummer == 35) {
      return selected
        ? require("@/assets/teeth/single_wide_down_fill.png")
        : require("@/assets/teeth/single_wide_down.png");
    } else {
      return selected
        ? require("@/assets/teeth/single_slim_down_fill.png")
        : require("@/assets/teeth/single_slim_down.png");
    }
  }
}

/** Verbleibt zunächst */
/**
 * Erstellt thumnailversion, vollversion und ein passendes Snapobjekt.
 * Läd dann die Dateien und den Snap hoch.
 * @param {File} blob Blobdarstellung der originalen Fotodatei
 * @param {string} sessionId ID der session, zu der die Snaps gehören
 * @param {*} snapId Optionale Snap-ID, Wenn nicht gegeben wird eine zufällig generiert.
 * @return {{id:string, entstehungsZeitpunkt:*}} Die Daten des Snaps, die für das Fertigstellen der Session gebraucht werden.
 */
async function aufnahmeHochladen(blob, sessionId, snapId) {
  try {
    //Die Metadaten der Bilddatei werden abgefragt
    let bildMetaDaten = await exifr.parse(blob);

    //Es wird versucht, einen Zeitstempel zu finden (es wird geprüft, ob ein "CreateDate" oder ein "DateTimeOriginal" Feld in den Metadaten existiert)
    var entstehungsZeitpunkt;

    if (
      bildMetaDaten !== undefined &&
      bildMetaDaten.DateTimeOriginal !== undefined
    ) {
      entstehungsZeitpunkt = dayjs(bildMetaDaten.DateTimeOriginal).unix();
    } else if (
      bildMetaDaten !== undefined &&
      bildMetaDaten.CreateDate !== undefined
    ) {
      entstehungsZeitpunkt = dayjs(bildMetaDaten.CreateDate).unix();
    } else {
      entstehungsZeitpunkt = null;
    }

    //Thumnail und Vollversionen der Aufnahme werden erstellt
    let thumnail = await resizeImage(blob, true);
    let voll = await resizeImage(blob, false);

    //Ein Snapdokument mit den Daten der Aufnahme und einer zufälligen, einmaligen ID wird erstellt
    let snap = {
      id:
        snapId !== undefined && typeof snapId === "string"
          ? snapId
          : einmaligenStringGenerieren(),
      eigentuemer: store.getters.praxisAbfragen.id,
      entstehungsZeitpunkt: entstehungsZeitpunkt,
      session: sessionId,
      thumbnailGroesse: thumnail.size,
      volleGroesse: voll.size,
    };

    //Thumnail und Vollversion des Snaps werden hochgeladen
    await bildHochladen(thumnail, true, snap.id);
    await bildHochladen(voll, false, snap.id);

    //Das Snapdokument wird hochgeladen
    await snapHochladen(snap);

    return { id: snap.id, entstehungsZeitpunkt: entstehungsZeitpunkt };
  } catch (error) {
    fehlerSenden(
      error,
      "Fehler beim Hochladen der Aufnahmen zu einem Snap und dem Snap selbst in Firestore.",
      "aufnahmeHochladen",
      "helfer"
    );
  }
}

/** Migrieren in "KontoKonfiguration.vue" Dienst */
/**
 * Generiert auf basis des übergebenen "Konto Konfiguration" Objekts eine Liste von benötigten Konfigurationsrouten
 * @param {*} kk Konto-Konfigurationsobjekt des Nutzers
 * @param {Boolean} eigentuemer Ob der Nutzer Eigentümer der Praxis ist
 * @returns {String[] | undefined} Liste mit benötigten Seiten, oder undefined wenn keine Seiten benötigt werden.
 */
function konfigRoutenGenerieren(kk, eigentuemer) {
  try {
    var erg = [];

    //Pflichtrouten werden hinterlegt
    if (kk.agbAkzeptiert === false) {
      erg.push(agbErklaerung);
    }
    if (kk.passwortHinterlegt === false) {
      erg.push(passwortErstellen);
    }
    if (kk.kontoDatenHinterlegt === false) {
      erg.push(profilAngaben);
      erg.push(mfaVerifizierung);
      //erg.push(profilBildHochladen);
    } else if (kk.mfaKonfiguriert === false) {
      if (kk.mfaKonfiguriert === false) {
        erg.push(telefonnummerAngeben);
        erg.push(mfaVerifizierung);
      }
    }
    if (eigentuemer === true && kk.praxisDatenHinterlegt === false) {
      erg.push(praxisAngaben);
      erg.push(mitarbeiterEinrichten);
    }

    if (erg.length == 0) return undefined;
    else return erg;
  } catch (error) {
    fehlerSenden(
      error,
      "Fehler beim Generieren der Konfigrouten eines Nutzers.",
      "konfigRoutenGenerieren",
      "helfer"
    );
  }
}

/** Migrieren in "authentifizierungsDienst" */
/**
 *
 * @param {*} kk Konfigurationsobjekt des Nutzers
 * @param {Boolean} eigentuemer Ob der Nutzer Praxiseigentümer ist
 * @returns {Boolean} Ob mindestens eine Konfiguration beim Nutzer ausstehend ist
 */
function ausstehendeKonfigurationenPruefen(kk) {
  return Object.values(kk).some((v) => v === false);
}

/** Verbleibt */
/**
 * @returns Ob der Nutzer ein Gerät mit den Seitenverhältnissen eines mobilen Endgeräts verwendet.
 */
function mobileAbfragen() {
  return window.innerWidth < 640;
}

/** Verbleibt */
/**
 * Fragt die Platform ab, die der Nutzer verwendet.
 * @returns {String} Platform des Nutzers, oder "unknown" Wenn kein Ergebnis gefunden wird
 */
function platformAbfragen() {
  if (
    typeof navigator.userAgentData !== "undefined" &&
    navigator.userAgentData != null
  ) {
    return navigator.userAgentData.platform;
  }
  // Deprecated but still works for most of the browser
  if (typeof navigator.platform !== "undefined") {
    if (
      typeof navigator.userAgent !== "undefined" &&
      /android/.test(navigator.userAgent.toLowerCase())
    ) {
      // android device’s navigator.platform is often set as 'linux', so let’s use userAgent for them
      return "android";
    }
    return navigator.platform;
  }
  return "unknown";
}

/** Verbleibt */
/**
 *
 * @param {String} text String, der ins Clipboard kopiert werden soll
 */
function inClipboardSchreiben(text) {
  return navigator.clipboard.writeText(text);
}

/**
 * Allgemeiner Helfer zum Abfragen und Konvertieren von Datei-Direktlinks (z.B zur Abfrage von Dateien aus CMS!)
 * @param {string} url URL für die Abfrage
 * @param {string} contentType "Content-Type" Für den Ergebnis-Blob, z.B "image/png".
 * @returns {Promise<Blob>} Abgefragte Datei im Blobformat
 */
async function httpDateiAbfragen(url, contentType) {
  const response = await axios.get(url, {
    responseType: "arraybuffer", // This ensures the response is a buffer, suitable for conversion to Blob
  });

  // Convert the ArrayBuffer response to a Blob and cache it.
  return new Blob([response.data], { type: contentType });
}

/**
 * Fragt den "Data-URL" für das Icon des Themas ab, wenn es sich um ein Standardthema handelt.
 * Sollte noch kein entsprechender Data-URL generiert worden sein, wird das Icon zuerst abgefragt und gespeichert, dann zurückgegeben
 * @param {{id: string, iconUrl: string}} thema Thema, zu dem die Icondaten abgefragt werden
 * @returns {Promise<string>} Daten-URL des Icons
 */
async function standardThemaIconCacheAbfragen(thema) {
  if (!thema.iconUrl || !thema.id) return;

  if (typeof store.state.standardThemenIconCache[thema.id] === "string") {
    return store.state.standardThemenIconCache[thema.id];
  } else {
    const blob = await httpDateiAbfragen(thema.iconUrl, "image/png");
    const uri = await fileToDataUri(blob);
    store.commit("standardThemaIconCacheSetzen", { id: thema.id, url: uri });

    //Recursively call the function again, this time returning the previously cached URI.
    return await standardThemaIconCacheAbfragen(thema);
  }
}

/**
 * Fragt den Avatar der gegebenen UID ab. Existiert er, wird ein direktlink zum Avatar zurückgegeben.
 * Andernfalls wird ein Direktlink zum Standard Profilbild zurückgegeben.
 * @param {string} uid UID des Nutzers
 * @param {boolean} geraet Ob es sich im ein Gerätekonto handelt. Wird benötigt, um das richtige Standardbild anzuzeigen.
 * @returns {Promise<{url:string, standard: boolean}>} Link zur Datei und Bool der angibt, ob es sich um das Standardfoto handelt.
 */
async function nutzerProfilbildAbfragen(uid, geraet = false) {
  try {
    const pfad = `nutzer-avatare/${uid}.jpg`;
    const dateiRef = ref(storage, pfad);

    //Interner "catch" Block, weil der Fehler hier bedeutet, dass die Datei nicht existiert und erwartet ist!
    try {
      await getMetadata(dateiRef);
    } catch {
      const standard =
        geraet === true
          ? standardGeraeteProfilBildUrl
          : standardBenutzerProfilBildUrl;
      return {
        url: standard,
        standard: true,
      };
    }

    // URL wird generiert und zurückgegen
    const url = await getDownloadURL(dateiRef);
    return {
      url: url,
      standard: false,
    };
  } catch (error) {
    fehlerSenden(
      error,
      "Fehler beim Abfragen eines Nutzer Profilbilds.",
      "profilBildUrlAbfragen",
      "authentifizierungsDienst.js"
    );
  }

  return standardBenutzerProfilBildUrl;
}

/**
 * Konvertiert den Namen eines Themas in einen gültigen Namen für ein passendes Icon, nach den folgenden Regeln:
 * - nur kleinbuchstaben
 * - leerzeichen werden durch "-" ersetzt
 * - umlaute werden durch "_e" ersetzt
 * - "ß" wird durch "ss" ersetzt
 * @param {string} name Regulärer Name des Themas
 * @returns {string} konvertierter Name
 */
function themenIconNameGenerieren(name) {
  if (typeof name !== "string") {
    throw new Error("Der Eingabewert muss eine Zeichenkette sein.");
  }

  let result = name.toLowerCase();
  result = result.replace(/\s+/g, "-"); // Ersetzt alle Leerzeichen durch '-'
  result = result.replace(/ä/g, "ae"); // Umlaute ersetzen
  result = result.replace(/ö/g, "oe");
  result = result.replace(/ü/g, "ue");
  result = result.replace(/ß/g, "ss"); // Ersetzt 'ß' durch 'ss'

  return result;
}

/**
 * Fragt die Icondatei aus dem passenden Ordner ab, und gibt sie wenn vorhanden zurück.
 * Sollte keine passende Datei gefunden werden, wird die passende "standard" Datei zurückgegeben.
 * @param {{name: string}} thema Themenobjekt.
 * @param {boolean} weiss Ob die weiße Version des Icons, oder die reguläre abgefragt werden soll.
 */
function themenIconAbfragen(thema, weiss) {
  //Macht für den Compiler die Ordner für reguläre und weiße Icons sichtbar
  const regulaererIconContext = require.context(
    "@/assets/icons/themen/regulaer",
    false,
    /\.svg$/
  );
  const weisserIconContext = require.context(
    "@/assets/icons/themen/weiss",
    false,
    /\.svg$/
  );

  const iconName = themenIconNameGenerieren(thema.name);

  try {
    return weiss
      ? weisserIconContext(`./${iconName}.svg`)
      : regulaererIconContext(`./${iconName}.svg`);
  } catch (error) {
    return weiss
      ? weisserIconContext(`./standard.svg`)
      : regulaererIconContext(`./standard.svg`);
  }
}

function stringListenGleich(listeA, listeB) {
  if (listeA.length != listeB.length) {
    return false;
  } else {
    listeA.sort();
    listeB.sort();

    for (let i = 0; i < listeA.length; i++) {
      if (listeB[i] !== listeA[i]) {
        return false;
      }
    }

    return true;
  }
}

/**
 * Rechnet einen ganzzahligen Preis (in Cent) in einen Komma getrennten "Currency String" um.
 * @param {number} preis Gannzahliger Preis (in Cents)
 * @returns {string} Preis als "Currency String"
 */
function preisStringGenerieren(preis) {
  return preis != 0 ? preis.toFixed(2).replaceAll(".", ",") : "0,00";
}

/**
 * Entnimmt der gegebenen Liste von Rechnungspositionen den Bruttowert, und konvertiert Ihn in einen anzeigbaren String, gerundet auf 2 Dezimalstellen.
 * @param {{index: number, art: string, beschreibung: string, wert: number}[]} rechnungsPositionen Rechnungspositionen
 * @return {string} Bruttobetrag als String, der an Mollie Zahlung übergeben werden kann
 */
function bruttoStringAusPositionen(rechnungsPositionen) {
  return preisStringGenerieren(
    rechnungsPositionen.find((p) => p.art === "brutto").wert
  );
}

function aboNeuLaden() {
  getDocs(
    query(
      collection(firestore, "abonnements"),
      where("praxisId", "==", store.getters.praxisAbfragen.id)
    )
  ).then((sn) => {
    if (sn.empty === false) {
      const abo = sn.docs[0].data();
      store.commit("abonnementSetzen", abo);
    }
  });
}

export {
  avatarNamenUndFarbeFuerUrlAbfragen,
  emailAdresseZensieren,
  telefonNummerZensieren,
  passwortFehlerVerarbeiten,
  fileToDataUri,
  snapUriAbfragen,
  rueckgabeErstellen,
  versionAbfragen,
  snapDateienErsetzen,
  zahnIconAbfragen,
  faelleGenerieren,
  aufnahmeHochladen,
  konfigRoutenGenerieren,
  ausstehendeKonfigurationenPruefen,
  avatarFarbeAbfragen,
  mobileAbfragen,
  platformAbfragen,
  inClipboardSchreiben,
  standardThemaIconCacheAbfragen,
  nutzerProfilbildAbfragen,
  themenIconAbfragen,
  stringListenGleich,
  preisStringGenerieren,
  bruttoStringAusPositionen,
  aboNeuLaden,
};
