<template>
    <div class="onboarding-komponente">
        <OnboardingTitel v-if="fehler === false" :titel="bearbeitungLaedt === true ? 'Bearbeitung ' : 'Fertig'"
            :untertitel="bearbeitungLaedt === true ? 'Wir bearbeiten Ihre Bestellung. Bitte aktualisieren Sie die Seite nicht.' : 'Ihre Organisation wurde entsperrt.'" />
        <OnboardingTitel v-else titel="Fehler Aufgetreten"
            untertitel="Entschuldigung, ein Fehler ist aufgetreten. Bitte versuchen Sie es noch einmal oder kontaktieren Sie unseren Support, falls Sie Unterstützung benötigen." />
        <div class="w-80 h-80 flex justify-center items-center">
            <div v-if="bearbeitungLaedt === false"
                class="w-full h-full flex flex-col space-y-8 justify-center items-center">
                <LottieAnimation :animation-data="fehler === true ? fehlerAnim : entsperrt" class="w-32" />
                <span v-if="fehler === false" class="text-center text-sm">Vielen Dank für Ihre
                    Bestellung.<br /><br />Alle
                    Nutzer können jetzt
                    wieder auf
                    die Organisation
                    zugreifen.</span>
            </div>
            <LottieAnimation v-else :animation-data="ladenBlau" :loop="true" class="w-32" />
        </div>
        <OnboardingWeiterButton :deaktiviert="bearbeitungLaedt" :text="buttonText" @click="neuLaden" />
    </div>
</template>
<script>
import OnboardingTitel from '@/components/OnboardingTitel.vue';
import OnboardingWeiterButton from '@/components/OnboardingWeiterButton.vue';
import { LottieAnimation } from 'lottie-web-vue';
import entsperrt from "@/assets/animations/entsperrt.json"
import ladenBlau from "@/assets/animations/laden-blau.json"
import fehlerAnim from "@/assets/animations/fehler.json"
import { computed, onMounted, ref } from 'vue';
import { useCloudFunction } from '@/services/cloudFunctionDienst';
import { fehlerAnzeigen } from '@/services/toastDient';
export default {
    components: {
        OnboardingTitel,
        OnboardingWeiterButton,
        LottieAnimation
    },
    props: ["fehler"],
    setup(props) {

        const buttonText = computed(() => props.fehler === true ? "Erneut Versuchen" : "Zur Praxis")

        const bearbeitungLaedt = ref(false)

        const { error: reaktivierungFehler, call: reaktivieren } = useCloudFunction("praxisReaktivieren")

        function bearbeiten() {
            if (bearbeitungLaedt.value === false && props.fehler === false) {
                reaktivieren()
                    .then(() => {
                        if (reaktivierungFehler.value === false) {
                            bearbeitungLaedt.value = false
                        } else {
                            fehlerAnzeigen("Bei der Bearbeitung ist ein Fehler aufgetreten.")
                        }
                    })
            }
        }

        onMounted(bearbeiten)

        const neuLaden = () => window.location.href = "/"

        return {
            bearbeitungLaedt,
            entsperrt,
            ladenBlau,
            fehlerAnim,
            buttonText,
            neuLaden
        }
    }
}
</script>
<style lang="">

</style>