<template>
    <form :class="{ 'onboarding-komponente': !istMobile, 'onboarding-komponente-mobile': istMobile }">
        <div class="w-full h-full flex flex-col" :class="{ 'justify-between': istMobile, 'justify-start': !istMobile }">
            <div class="w-full flex flex-col space-y-4">
                <OnboardingTitel titel="Passwort Erstellen"
                    untertitel="Wählen Sie ein sicheres Passwort für ihr DentaSnap Konto." />

                <input type="password"
                    :class="{ 'default-textfield': !istMobile, 'default-textfield-dynamic-length': istMobile }"
                    placeholder="Passwort" v-model="pwt">
                <input type="password"
                    :class="{ 'default-textfield': !istMobile, 'default-textfield-dynamic-length': istMobile }"
                    placeholder="Passwort Wiederholen" v-model="pwtWdh">
                <div class="w-full flex flex-wrap"
                    :class="{ 'justify-start items-start': !istMobile, 'justify-center items-center': istMobile }">
                    <OnboardingPasswortBedingung :vorraussetzung="grossUndKleinschreibung"
                        text="Groß -und Kleinschreibung" />
                    <OnboardingPasswortBedingung :vorraussetzung="sonderzeichen" text="Ein Sonderzeichen" />
                    <OnboardingPasswortBedingung :vorraussetzung="ziffer" text="Eine Ziffer" />
                    <OnboardingPasswortBedingung :vorraussetzung="korrekteLaenge" text="Zwischen 8 und 30 Zeichen" />
                </div>
            </div>
            <OnboardingWeiterButton :deaktiviert="!gueltigeEingabe" :laden="laedt" @click="speichern" />
        </div>
    </form>
</template>
<script>
import OnboardingTitel from '@/components/OnboardingTitel.vue';
import OnboardingPasswortBedingung from '@/components/OnboardingPasswortBedingung.vue';
import OnboardingWeiterButton from '@/components/OnboardingWeiterButton.vue';
import { computed, ref } from 'vue';
import { updatePassword, confirmPasswordReset } from 'firebase/auth';
import { auth } from '@/firebase/config';
import { useRoute, useRouter } from 'vue-router';
import { erfolgAnzeigen } from '@/services/toastDient';
import { useCloudFunction } from '@/services/cloudFunctionDienst';
import { fehlerSenden } from '@/services/LogDienst';
import store from '@/store';
export default {
    components: {
        OnboardingTitel,
        OnboardingPasswortBedingung,
        OnboardingWeiterButton
    },
    setup(props, context) {
        const istMobile = computed(() => store.state.mobile)

        const laedt = ref(false)

        const pwt = ref("")
        const pwtWdh = ref("")

        const router = useRouter()
        const pfadProvider = useRoute()
        const urlParameter = computed(() => pfadProvider.query);
        const passwortZurücksetzung = computed(() => urlParameter.value.mode && urlParameter.value.mode == "resetPassword" && typeof urlParameter.value.oobCode === "string")

        //Vorraussetzungen und Gültigkeitscheck für Passwort
        const grossUndKleinschreibung = computed(() => /^(?=.*[a-z])(?=.*[A-Z]).*$/.test(pwt.value))
        const sonderzeichen = computed(() => /[\!\@\#\$\%\^\&\*\(\)\-\_\=\+\[\]\{\}\\\|\;\:\'\"\<\,\>\.\?\/\~\`]/.test(pwt.value))
        const korrekteLaenge = computed(() => /^.{8,30}$/.test(pwt.value))
        const ziffer = computed(() => /\d/.test(pwt.value))
        const eingabenGleich = computed(() => pwt.value === pwtWdh.value)

        const gueltigeEingabe = computed(() => grossUndKleinschreibung.value && sonderzeichen.value && ziffer.value && korrekteLaenge.value && eingabenGleich.value)

        const { error, call } = useCloudFunction("claimsAktualisieren")

        function speichern() {
            if (gueltigeEingabe.value === true) {
                laedt.value = true

                if (passwortZurücksetzung.value === true) {
                    console.log(urlParameter.value.oob);
                    confirmPasswordReset(auth, urlParameter.value.oobCode, pwt.value)
                        .catch(err => {
                            //TODO: Richtiges Fehlerlogging implementieren
                            console.log("Fehler beim Zurücksetzen des Passworts: ", err);
                        })
                        .then(() => {
                            router.push({ name: "Anmeldung" })
                            laedt.value = false
                            erfolgAnzeigen("Passwort Aktualisiert.")
                        })
                } else {
                    updatePassword(auth.currentUser, pwt.value)
                        .catch(err => {
                            //TODO: Fehler behandeln (z.B Nutzer zum Verwenden eines neuen Anmeldelinks auffordern)
                            console.log("Fehler beim Ändern des Passworts: ", err);
                        })
                        .then(() => {

                            //Der "passwort" Claim des Nutzers wird nach 
                            //erfolgreichem Passwort setzen aktualisiert.
                            call({ passwort: true })
                                .catch(err => fehlerSenden(
                                    err,
                                    "Fehler beim Aufrufen der 'claimsAktualisieren' Cloud Funktion.",
                                    "speichern",
                                    "PasswortErstellen.vue"
                                ))
                                .then(() => {
                                    laedt.value = false
                                    context.emit("weiter")
                                })
                        })
                }
            }
        }

        return {
            laedt,
            pwt,
            pwtWdh,
            grossUndKleinschreibung,
            sonderzeichen,
            korrekteLaenge,
            ziffer,
            eingabenGleich,
            gueltigeEingabe,
            updatePassword,
            istMobile,

            speichern
        }
    }
}
</script>