<template>
  <div class="standard-seite">
    <!-- Seitentitel -->
    <div class="flex items-center justify-start mt-6 mb-10 text-black text-3xl">
      <ion-icon class="transition duration-150 hover:cursor-pointer hover:scale-110 mr-10" name="arrow-back-outline"
        @click="zurueck"></ion-icon>
      <img v-if="partnerAvatar != null" class="w-12 h-12 rounded-full mr-5" :src="partnerAvatar" />
      <h1>{{ titel }}</h1>
    </div>

    <!-- Fälle -->
    <div class="w-full flex flex-col" v-for="fall in faelle" :key="fall[0].fallId">
      <div class="my-3">
        <Fall :fall="fall" />
      </div>
    </div>
  </div>
</template>

<script>
import PageTitle from "@/components/PageTitle.vue";
import Fall from "@/components/fall/Fall.vue";
import { computed } from "vue";
import { router } from "@/router";
import store from "@/store";
import { faelleGenerieren } from "@/services/helfer";
export default {
  components: { PageTitle, Fall },

  setup() {
    /**
     * Je nach Gruppe der angezeigten Aufnahmen wird der entsprechende Titel (Patientennummer, Themenname, Praxisname) zurückgegeben
     */
    const titel = computed(() => {
      let name = router.currentRoute.value.name;
      let id = router.currentRoute.value.params.id;

      if (!name || !id) return null;
      else {
        switch (name) {
          case "AufnahmenPatient":
            const patient = store.getters.patientAbfragen(id);
            if (patient != null) return typeof patient.name === "string" ? patient.name : patient.nummer;
            break;
          case "AufnahmenThema":
            const thema = store.getters.themaAbfragen(id);
            if (thema != null) return thema.name;
            break;
          case "AufnahmenPartner":
            const partner = store.getters.partnerAbfragen(id);
            if (partner != null) return partner.name;
            break;
          default:
            break;
        }
        return null;
      }
    });

    /**
     * Wenn die Sessions einer Partnerpraxis angezeigt werden, wird der Avatar-URL der Partnerpraxis zurückgegeben
     * */
    const partnerAvatar = computed(() => {
      const name = router.currentRoute.value.name;
      const id = router.currentRoute.value.params.id;

      if (!name || !id || name != "AufnahmenPartner") return null;

      const partner = store.getters.partnerAbfragen(id);

      if (!partner) return null;

      return partner.avatarUrl;
    });

    /**
     * Gibt je nach Kontext alle Fälle zurück, die zur Anfrage passen
     */
    const faelle = computed(() => {
      const name = router.currentRoute.value.name;
      const id = router.currentRoute.value.params.id;

      if (!name || !id) return null;

      var filter;

      switch (name) {
        case "AufnahmenThema":
          filter = { themen: [id] };
          break;
        case "AufnahmenPartner":
          const partner = store.getters.partnerAbfragen(id);
          if (partner) {
            filter = { praxis: partner.id };
          }
          break;
        case "AufnahmenPatient":
          filter = { patient: id };
          break;
        default:
          break;
      }

      if (!filter) return null;
      return faelleGenerieren(filter);
    });

    /**
     * Navigiert zur vorherigen Sete
     */
    function zurueck() {
      router.back();
    }

    return {
      titel,
      partnerAvatar,
      zurueck,
      faelle,
    };
  },
};
</script>