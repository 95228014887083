//Datei enthält Werte und Funktionen, die Authentifizierung mit dem Firebase-Auth Framework ermöglichen.

//Import benötigter Referenzen aus der Anwendung
import { auth, firestore, storage, IdTokenResult } from "@/firebase/config";
import {
  getStorage,
  ref as storageRef,
  getDownloadURL,
  getMetadata,
} from "firebase/storage";
import { ref } from "vue";
import store from "@/store";

//Import benötigter Dienste, Helfer und Konstanten
import { praxisZuhoeren } from "./firestoreDienst";
const {
  eigentuemer,
  verwalter,
  bearbeiter,
  fotograf,
  standardBenutzerProfilBildUrl,
} = require("../constants/konstanten");
const { webAppZugriff } = require("../constants/berechtigungen");
const { nutzerProfilbildAbfragen } = require("@/services/helfer");

//Import der benötigten Funktionen aus dem Firebase Auth Framework
import {
  onAuthStateChanged as anmeldeStatusGeändert,
  signInWithEmailAndPassword,
  signOut as authSignOut,
  checkActionCode,
  applyActionCode,
  updatePassword,
  getIdTokenResult,
} from "firebase/auth";
import { standardToastAnzeigen } from "./toastDient";
import { router } from "@/router";
import { collection, getDocs, query, where } from "firebase/firestore";
import { kontoKonfiguration } from "@/constants/kontoKonfigRouten";
import { ausstehendeKonfigurationenPruefen } from "./helfer";
import { fehlerSenden } from "./LogDienst";

//Referenzen für den resultierenden Nutzer, den Ladestatus und mögliche Fehler werden erstellt.
//Da diese in der gesamten Anwendung nur einmal existieren dürfen, werden sie dateiweit angelegt.
const nutzer = ref(null);
const nutzerClaims = ref(null);
const authLaed = ref(false);
const authFehler = ref(null);

//Wird als Listener für Änderungen im Authentifizierungsstatus eingesetzt.
anmeldeStatusGeändert(auth, (updatedUser) => {
  //Der Nutzer selbst wird gesetzt
  nutzer.value = updatedUser;

  //In jedem Fall werden aktuell gespeicherte Nutzer, Praxis und Snaps gelöscht.
  store.commit("praxisSetzen", null);
  store.commit("snapsSetzen", null);
  store.commit("sessionsSetzen", null);
  store.commit("patientenSetzen", null);
  store.commit("eigeneThemenSetzen", null);
  store.commit("standardThemenSetzen", null);
  store.commit("kontoKonfigurationSetzen", null);

  if (updatedUser) {
    updatedUser.getIdTokenResult().then((result) => {
      getDocs(
        query(
          collection(firestore, "praxen"),
          where("konten", "array-contains", updatedUser.uid)
        )
      ).then((snapshot) => {
        //Nutzerdaten werden aktualisiert
        nutzerClaims.value = result.claims;
        nutzer.value = updatedUser;

        //Die Konfigurationsdaten des Nutzers werden abgefragt
        let praxisDaten = snapshot.docs[0].data();

        let kontoKonfig = kontoKonfigurationGenerieren(praxisDaten, result);
        store.commit("kontoKonfigurationSetzen", kontoKonfig);

        //Hat der Nutzer keinen Webapp-Zugriff, darf er nur sein Konto Konfigurieren, und wird sonst abgemeldet
        if (berechtigungenPruefen([webAppZugriff]) === false) {
          //Nutzer ohne Webappzugriff, die ihr Konto noch nicht konfiguriert haben, werden zur Konfigseite weitergeleitet
          if (ausstehendeKonfigurationenPruefen(kontoKonfig, false) == true) {
            router.push({ name: kontoKonfiguration });
            return;
          } else {
            standardToastAnzeigen(
              "text-Warning",
              "shield",
              "Kein Webapp Zugriff!",
              "Praxis-Administrator kontaktieren."
            );
            abmelden().then(() => router.push({ name: "Anmeldung" }));
          }
        }
        //Hat der Nutzer Webapp Zugriff, wird ein Listener für die Praxis eingerichtet
        else {
          praxisZuhoeren(updatedUser.uid);
        }
      });
    });
  } else {
    nutzer.value = null;
    router.push({ name: "Anmeldung" });
  }
});

//Ermöglicht eine Anmeldung in einem Firebase Auth Konto per E-Mail und Passwort.
const perEmailAnmelden = async (email, password) => {
  try {
    //Der Ladezustand wird markiert. Mögliche Fehler von vorherigen Anmeldeversuchen werden auf "null" gesetzt.
    authLaed.value = true;
    authFehler.value = null;

    //Der Anmeldevorgang im Auth-Framework wird gestartet, und das Credential abgespeichert
    await signInWithEmailAndPassword(auth, email, password);

    //Sollte bei der Anmeldung kein Fehler aufgetreten sein, ist sie erfolgt.
    //Der Ladezustand wird beendet, und der Fehlerwert auf "null" gesetzt.
    authFehler.value = null;
    authLaed.value = false;
  } catch (error) {
    fehlerSenden(
      error,
      "Fehler beim Anmelden per E-Mail",
      "perEmailAnmelden",
      "authentifizierungsDienst"
    );
    //Bei der Anmeldung ist ein Fehler aufgetreten. Der Ladevorgang wird beendet, und der "authFehler" wert
    //auf die Fehlermeldung des Auth-Frameworks gesetzt.
    authLaed.value = false;
    authFehler.value = error;
  }
};

//Ermöglicht eine abmeldung eines Kontos aus dem Auth-Framework.
const abmelden = async () => {
  try {
    //Der Ladezustand wird markiert, und mögliche vorherige Fehlermeldungen auf "null" gesetzt
    authLaed.value = true;
    authFehler.value = null;

    //Wenn vorhanden wird der aktuelle Nutzer aus dem Auth-Framework abgemeldet.
    await authSignOut(auth);

    //Sollte kein Fehler aufgetreten sein, wird der Ladezustand beendet und der "authFehler" wert
    //verbleibt auf "null".
    nutzerClaims.value = null;
    store.state.kontoKonfiguration = null;
    authLaed.value = false;
    authFehler.value = null;
  } catch (error) {
    fehlerSenden(
      error,
      "Beim Abmelden des Nutzers ist ein Fehler aufgetreten.",
      "abmelden",
      "authentifizierungsDienst"
    );
    //Der aufgetretene Fehler aus dem Auth-Framework wird dem "authFehler" Wert übergeben.
    //Auch hier wird der Ladezustand beendet.
    authFehler.value = error.message;
    authLaed.value = false;
  }
};

async function nutzerAktualisieren(konfigAktualisieren) {
  if (auth.currentUser) {
    await auth.currentUser.reload();
    let idTokenRes = await auth.currentUser.getIdTokenResult(true);
    nutzer.value = auth.currentUser;
    nutzerClaims.value = idTokenRes.claims;

    if (konfigAktualisieren !== false) {
      let konfigNeu = kontoKonfigurationGenerieren(
        store.getters.praxisAbfragen,
        idTokenRes
      );
      store.commit("kontoKonfigurationSetzen", konfigNeu);
    }
  }
}

/**
 * Anhand der aktuellen Nutzerdaten wird ein "Konto Konfiguration" Objekt erstellt
 * @param {*} praxisDaten Die aktuellen Praxisdaten
 * @param {IdTokenResult} Aktuelles ID-Token Ergebnis des Nutzers
 * @returns {{agbAkzeptiert: Boolean, kontoDatenHinterlegt: Boolean, passwortHinterlegt: Boolean, emailVerifiziert: Boolean, mfaKonfiguriert: Boolean, praxisDatenHinterlegt: Boolean, praxisKontenErstellt: Boolean}} Das neue Konfigobjekt
 */
function kontoKonfigurationGenerieren(praxisDaten, tokenRes) {
  if (praxisDaten && tokenRes) {
    const res = {
      agbAkzeptiert:
        nutzerClaims.value.agb != null && nutzerClaims.value.agb === true,
      kontoDatenHinterlegt:
        nutzer.value.displayName != undefined &&
        nutzer.value.displayName !== "",
      passwortHinterlegt:
        nutzerClaims.value.passwort != null &&
        nutzerClaims.value.passwort == true,
      emailVerifiziert: nutzer.value.emailVerified != null,
      mfaKonfiguriert:
        tokenRes.signInSecondFactor != null &&
        tokenRes.signInSecondFactor.toLowerCase() == "phone",
      praxisDatenHinterlegt: praxisDaten.avatarUrl != null,
    };

    console.log("kk");
    console.log(res);
    return res;
  } else {
    return null;
  }
}

//Formatiert einen Rollenwert aus der Datenstruktur in einen Wert anzeigbar auf der Oberfläche.
const rolleFormatieren = (role) => {
  switch (role) {
    case eigentuemer:
      return "Eigentümer";
    case verwalter:
      return "Verwalter";
    case bearbeiter:
      return "Bearbeiter";
    case fotograf:
      return "Fotograf";
    default:
      return "";
  }
};

//Gleicht die als parameter gegebene Liste von Parametern mit der des Nutzers ab.
//Hat der Nutzer alle angegebenen Berechtigungen, wird "true" zurückgegeben.
const berechtigungenPruefen = (berechtigungen) => {
  if (nutzerClaims && nutzerClaims.value && nutzerClaims.value.berechtigungen) {
    //Wenn der Nutzer die "eigentuemer" Berechtigung hat, wird "true" zurückgegeben
    if (
      nutzerClaims.value.berechtigungen.find((b) => b == eigentuemer) !=
      undefined
    )
      return true;

    for (let index = 0; index < berechtigungen.length; index++) {
      if (
        nutzerClaims.value.berechtigungen.find(
          (b) => b == berechtigungen[index]
        ) != undefined
      ) {
        return true;
      }

      return false;
    }
  }

  return false;
};

/**
 * Gibt einen formattierten Namensstring, basierend auf dem "displayName" Feld im Authnutzer, zurück.
 * @param {String} name Name, der Formattiert werden soll. Bleibt der Wert leer, wird der Name des angemeldeten Nutzers formattiert.
 * @returns {String?} Formattierter Name
 */
function nameAbfragen(name = null) {
  if (
    name != null ||
    (nutzer.value != null && nutzer.value.displayName != null)
  ) {
    let split = (name != null ? name : nutzer.value.displayName).split("-");
    let anrede = split[0].toLowerCase();
    var anredeTransformiert;

    if (anrede.includes("dr")) {
      anredeTransformiert = "Dr. ";
    } else if (anrede.includes("prof")) {
      anredeTransformiert = "Prof. ";
    } else {
      anredeTransformiert = "";
    }

    return `${anredeTransformiert} ${split[1]} ${split[2]}`;
  } else return null;
}

/**
 * Gibt einen Direkt-URl zum Profilbild zurück, falls eins vorhanden ist. Ansonsten wird der Standard-URl zurückgegeben.
 */
async function profilBildUrlAbfragen() {
  if (nutzer.value) {
    return await nutzerProfilbildAbfragen(nutzer.value.uid);
  }

  return { url: standardBenutzerProfilBildUrl, standard: true };
}

export {
  nutzer,
  authFehler as authError,
  authLaed as authPending,
  perEmailAnmelden,
  abmelden,
  rolleFormatieren as formatRole,
  nutzerAktualisieren,
  nutzerClaims,
  berechtigungenPruefen,
  kontoKonfigurationGenerieren,
  nameAbfragen,
  profilBildUrlAbfragen,
};
