<template>
  <div class="w-full h-full flex flex-col justify-between items-center">
    <!-- Themen -->
    <div
      class="w-full flex flex-wrap justify-start items-start mt-7 overflow-y-auto"
    >
      <div
        v-for="thema in themenListe"
        :key="thema.id"
        class="px-4 py-1.5 rounded-md text-sm mr-1.5 mb-1.5 transition duration-150 hover:cursor-pointer hover:bg-opacity-50 flex"
        :class="{
          'bg-DentaBlue text-white':
            themen.find((t) => t == thema.id) != undefined,
          'bg-TextField text-Mittelgrau':
            themen.find((t) => t == thema.id) == undefined,
        }"
        @click="themaGewaehlt(thema)"
      >
        <p>{{ thema.name }}</p>

        <transition type="fade"
          ><img
            v-if="thema.standard === true"
            :src="
              themen.find((t) => t == thema.id) != undefined
                ? require('@/assets/thema_standard_weiss.svg')
                : require('@/assets/thema_standard_grau.svg')
            "
            alt=""
            class="ml-2 w-4 aspect-square rounded-full"
        /></transition>
      </div>
    </div>

    <div class="w-full h-1/3 flex justify-evenly items-center mb-5">
      <!-- Schrittauswahl -->
      <div class="w-72 flex flex-col">
        <div class="w-full flex justify-center items-center text-3xl">
          <ion-icon
            v-if="schritt > 1"
            name="chevron-back-circle-outline"
            class="text-DentaBlue transition duration-150 hover:cursor-pointer hover:scale-105"
            @click="schrittGewaehlt(-1)"
          ></ion-icon>
          <ion-icon
            v-else
            name="chevron-back-circle-outline"
            class="text-Mittelgrau"
          ></ion-icon>
          <p class="w-14 flex justify-center text-DentaBlue">{{ schritt }}</p>
          <ion-icon
            name="chevron-forward-circle-outline"
            class="text-DentaBlue transition duration-150 hover:cursor-pointer hover:scale-105"
            @click="schrittGewaehlt(1)"
          ></ion-icon>
        </div>

        <div class=""></div>
      </div>

      <!-- Zahnauswahl -->
      <ZahnSchema
        :zaehne="zaehne"
        :auswahlMoeglich="true"
        @zaehneAktualisiert="zaehneAktualisiert"
      />
    </div>
  </div>
</template>

<script>
//Alle benötigten Frameworks und Vue-Komponenten werden importiert
import { computed, ref } from "@vue/runtime-core";
import store from "@/store";

//Alle benötigten eigenen Komponenten werden importiert
import ZahnSchema from "@/components/ZahnSchema.vue";
import DialogTitel from "../DialogTitel.vue";

export default {
  components: { ZahnSchema, DialogTitel },

  props: ["themen", "schritt", "zaehne"],
  emits: ["themenGewaehlt", "schrittGewaehlt", "zaehneGewaehlt"],

  setup(props, context) {
    /** Gibt alle Themen der Praxis zurück */
    let themenListe = computed(() => store.state.themen);

    let ausgewaehlt = computed(() => props.themen);

    /**
     * Wird aufgerufen, wenn ein Thema angeklickt wird.
     * Ist das Thema bereits ausgewählt, wird es von der liste gelöscht, und andernfalls hinzugefügt.
     * @param {*} thema Das ausgewählte Thema
     */
    function themaGewaehlt(thema) {
      var aktuell = ausgewaehlt.value;

      //Die aktuelle Liste wird entsprechend der Auswahl erweitert bzw reduziert.
      if (aktuell.find((t) => t == thema.id) != undefined) {
        aktuell = ausgewaehlt.value.filter((t) => t != thema.id);
      } else {
        aktuell.push(thema.id);
      }

      //Die neue Themenliste wird als Event abgegeben
      context.emit("themenGewaehlt", aktuell);
    }

    /**
     * Feuert ein Event mit der veränderten Schrittzahl ab.
     * @param {number} aenderung + / - 1, wird auf den aktuellen wert gerechnet
     */
    function schrittGewaehlt(aenderung) {
      if (props.schritt != undefined) {
        let ergebnis =
          props.schritt + aenderung > 0 ? props.schritt + aenderung : 1;
        context.emit("schrittGewaehlt", ergebnis);
      }
    }

    function zaehneAktualisiert(aenderung) {
      context.emit("zaehneGewaehlt", aenderung);
    }

    return {
      //State für das UI
      themenListe,

      //Funktionen für das UI
      themaGewaehlt,
      schrittGewaehlt,
      zaehneAktualisiert,
    };
  },
};
</script>