<template>
    <div class="onboarding-komponente">
        <OnboardingTitel titel="DentaSnap ID"
            untertitel="Geben Sie die ID der Praxis an, die Sie zur Partnerschaft einladen möchten. Unten steht Ihre eigene DentaSnap ID." />
        <div class="w-80 px-6 py-2 flex flex-col items-start space-y-10">
            <form @submit.prevent
                class="default-textfield-dynamic-length w-10 h-12 flex justify-center items-center space-x-3">
                <p>DS</p>
                <p>-</p>
                <input type="text" ref="buchstabenFeld" maxlength="4" v-model="buchstaben"
                    class="w-10 bg-transparent text-center focus:outline-none" placeholder="xxxx" @paste="pasteCode">
                <p>-</p>
                <input type="text" ref="zahlenFeld" maxlength="4" v-model="zahlen"
                    class="w-10 bg-transparent text-center focus:outline-none" placeholder="yyyy" @paste="pasteCode">
            </form>
            <div class="flex text-sm">
                <span class="mr-1">Ihre DentaSnap-ID:</span>
                <button class="flex items-center space-x-1 transition duration-100 hover:opacity-70 text-DentaBlue"
                    @click="praxisCodeKopieren">
                    <span class="underline font-bold">{{ praxisCode }}</span>
                    <img src="@/assets/icons/kopieren-blau.svg" alt="copy" class="w-3 aspect-square object-contain">
                </button>
            </div>
        </div>

        <OnboardingWeiterButton :deaktiviert="!gueltigeEingabe" :laedt="laedt" @click="codePruefen" />
    </div>
</template>

<script>
import OnboardingTitel from "@/components/OnboardingTitel.vue"
import OnboardingWeiterButton from "@/components/OnboardingWeiterButton.vue"
import { useCloudFunction } from "@/services/cloudFunctionDienst"
import { inClipboardSchreiben } from "@/services/helfer"
import { erfolgAnzeigen, fehlerAnzeigen } from "@/services/toastDient"
import store from "@/store"
import { computed, ref, watch } from "vue"
export default {
    components: {
        OnboardingTitel,
        OnboardingWeiterButton
    },
    emits: ["partnerNameAktualisiert", "partnerCodeAktualisiert", "weiter"],
    setup(props, context) {
        /** Ladezustand */
        const laedt = ref(false)

        /** Referenz zum DOM Element für das Textfeld, in das die 4 Buchstaben des Praxiscodes eingegeben werden. */
        const buchstabenFeld = ref(null)

        /** Referenz zum DOM Element für das Textfeld, in das die 4 Zahlen des Praxiscodes eingegeben werden. */
        const zahlenFeld = ref(null)

        /** Speichert die Eingabe in das Textfeld, das die Buchstaben des Praxiscodes empfängt. */
        const buchstaben = ref("")

        /** Speichert die Eingabe in das Textfeld, das die Zahlen des Praxiscodes empfängt. */
        const zahlen = ref("")

        /** Prüft, ob die Buchstaben und Zahlenwerte die richtige Länge und richtigen Zeichen beinhalten. */
        const gueltigeEingabe = computed(() => buchstaben.value.length == 4 && /^[A-Za-z]*$/.test(buchstaben.value) && zahlen.value.length == 4 && /^[0-9]*$/.test(zahlen.value))

        const praxisCode = computed(() => store.getters.praxisAbfragen.code)

        const praxisCodeRegex = /^DS-[A-Za-z]{4}-\d{4}$/

        watch(buchstaben, val => {
            if (val.length == 4) {
                zahlenFeld.value.focus()
            }
        })

        watch(zahlen, val => {
            if (val.length == 4) {
                fokusEntfernen()
            }
        })

        function fokusEntfernen() {
            document.activeElement.blur()
        }

        function eingabeZuruecksetzen() {
            zahlen.value = ""
            buchstaben.value = ""
            context.emit("partnerCodeAktualisiert", null)
            context.emit("partnerNameAktualisiert", null)
            buchstaben.value.focus()
        }

        function praxisCodeKopieren() {
            if (praxisCode.value) {
                inClipboardSchreiben(praxisCode.value).then(() =>
                    erfolgAnzeigen("Code Kopiert."));
            }
        }

        /**
         * Wenn ein gültiger Praxiscode gegeben ist, wird er in die Textfelder eingsetzt
         * @param {string} code Praxiscode
         * @returns {boolean} Ob ein Code im Clipboard gefunden und eingsetzt wurde.
         */
        function praxisCodeEinsetzen(code) {
            if (praxisCodeRegex.test(code)) {
                const split = code.split("-");
                buchstaben.value = split[1];
                zahlen.value = split[2];
                fokusEntfernen()
                return true
            }

            return false
        }

        function pasteCode(event) {
            const codeText = event.clipboardData.getData("text");
            if (codeText != null) {
                praxisCodeEinsetzen(codeText)
            }
        }

        const { result: pruefungErgebnis, error: pruefungFehler, call: pruefenAufrufen } = useCloudFunction("praxisCodePruefen")

        /**
         * Wenn ein Code vorhanden ist, wird er geprüft. Ist der code gültig wird zur JCV Unterzeichnung navigiert, ansonsten passiert nichts
         */
        function codePruefen() {
            if (gueltigeEingabe.value === true) {
                laedt.value = true
                const eingabe = `DS-${buchstaben.value}-${zahlen.value}`
                pruefenAufrufen({ code: eingabe })
                    .then(() => {
                        if (pruefungFehler.value === false) {
                            console.log(pruefungErgebnis.value);
                            context.emit("partnerCodeAktualisiert", eingabe)
                            context.emit("partnerNameAktualisiert", pruefungErgebnis.value)
                            context.emit("weiter")
                        } else {
                            fehlerAnzeigen("Bitte geben Sie eine gültige DentaSnap ID an.")
                            eingabeZuruecksetzen()

                        }
                    })
                    .finally(() => laedt.value = false)
            }
        }

        return {
            //State für das UI
            buchstabenFeld,
            zahlenFeld,
            buchstaben,
            zahlen,
            gueltigeEingabe,
            praxisCode,
            laedt,

            //Funktionen für das UI
            praxisCodeKopieren,
            pasteCode,
            codePruefen,
        }
    }
}
</script>