<template>
    <div class="onboarding-komponente">
        <OnboardingTitel titel="Email Bestätigen"
            untertitel="Geben Sie zur Bestätigung die E-Mail Adresse ein, unter der Sie den Link zu dieser Seite erhalten haben." />
        <input type="email" class="default-textfield" placeholder="E-Mail Adresse" v-model="email">
        <OnboardingWeiterButton :deaktiviert="!emailGueltig" :laden="laedt" @click="bestaetigen" />
    </div>
</template>

<script>
import OnboardingTitel from '@/components/OnboardingTitel.vue';
import OnboardingWeiterButton from '@/components/OnboardingWeiterButton.vue';
import { auth } from '@/firebase/config';
import store from '@/store';
import { applyActionCode } from 'firebase/auth';
import { computed, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
export default {
    components: {
        OnboardingTitel,
        OnboardingWeiterButton
    },
    setup(props, context) {
        const istMobile = computed(() => store.state.mobile)

        //Logik zum Abfragen des oobCodes
        const route = useRoute()
        const router = useRouter()
        const oobCode = computed(() => route.query.oobCode)

        const laedt = ref(false)
        const email = ref("")
        const emailGueltig = computed(() => /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(email.value))

        const fehler = ref(null)


        //Nutzer bestätigt seine E-Mail, und daraufhin wird zunächst die reguläre Startseite geladen
        function bestaetigen() {
            if (emailGueltig.value === true && oobCode.value != null) {
                fehler.value = null
                laedt.value = true
                applyActionCode(auth, oobCode.value)
                    .catch(err => {
                        console.log("Fehler beim Bestätigen der E-Mail Adresse: ", err);
                    })
                    .then(() => {
                        laedt.value = false
                        router.push({ path: "/" })
                    })
            }
        }

        return { laedt, email, emailGueltig, istMobile, bestaetigen }
    }
}
</script>