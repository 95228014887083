<template>
    <OptionenKarte titel="Auftragsverarbeitung">
        <YsDokumentZumDownload :signatureRequestId="avvSrId"/>
    </OptionenKarte>
</template>

<script>
import { computed, onMounted } from 'vue';
import YsDokumentZumDownload from './YsDokumentZumDownload.vue';
import OptionenKarte from '@/components/OptionenKarte.vue';
import store from '@/store';
export default {
    components: {YsDokumentZumDownload, OptionenKarte},
    setup() {
        const avvSrId = computed(() => {
            return store.getters.praxisAbfragen.avvSignatureRequestId
        })

        onMounted

        return {
            avvSrId
        }
    }
}
</script>