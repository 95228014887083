<template>
  <!-- Einladung zur Partnerschaft -->
  <div v-if="einladung === true"
    class="w-96 h-32 flex flex-col justify-between items-start bg-white p-2 rounded-lg mr-5 mb-5">
    <div v-if="gesehen === false" class="h-5 flex justify-start items-center">
      <lottie-animation class="w-5 h-5 mr-2" ref="anim" :animationData="require('@/assets/pulsing_dot.json')" :loop="true"
        :autoPlay="true" :speed="0.7" />
      <p class="text-xs tracking-widest font-extralight text-LeichtesGrau">
        NEU
      </p>
    </div>
    <div class="w-full h-full flex justify-between items-center px-2">
      <img :src="partner.avatarUrl" class="w-14 h-14 border border-LeichtesGrau rounded-full" />
      <div class="w-full px-3">
        <p class="w-full">
          {{
            partner.name.length > 22
            ? `${partner.name.substring(0, 22)}…`
            : partner.name
          }}
        </p>
        <p class="text-xs text-Mittelgrau">Anfrage für Zusammenarbeit</p>
      </div>
      <div class="w-24 flex justify-end items-center space-x-2">
        <div
          class="w-9 h-9 rounded-full text-Mittelgrau text-2xl flex justify-center items-center transition duration-150 hover:cursor-pointer hover:bg-TextField"
          @click="partnerschaftLoeschen">
          <ion-icon name="close-outline"></ion-icon>
        </div>
        <div
          class="w-9 h-9 rounded-full border border-DentaBlue text-DentaBlue text-2xl flex justify-center items-center transition duration-150 hover:cursor-pointer hover:bg-DentaBlueUltraLight"
          @click="partnerschaftAnnehmen">
          <ion-icon name="checkmark-outline"></ion-icon>
        </div>
      </div>
    </div>
    <div v-if="gesehen === false" class="h-5"></div>
  </div>

  <!-- Ausstehende Partnerschaft -->
  <div v-else class="w-96 h-32 flex flex-col justify-between items-start bg-white p-2 rounded-lg mr-5 mb-5">
    <ion-icon
      class="h-7 rounded-full text-xl text-Mittelgrau transition duration-150 hover:cursor-pointer hover:text-opacity-70"
      name="close-outline" @click="partnerschaftLoeschen"></ion-icon>
    <div class="w-full h-full flex justify-between items-center px-2">
      <img :src="partner.avatarUrl" class="w-14 h-14 border border-LeichtesGrau rounded-full" />
      <div class="w-full px-3">
        <p class="w-full">
          {{
            partner.name.length > 22
            ? `${partner.name.substring(0, 22)}…`
            : partner.name
          }}
        </p>
        <p class="text-xs text-Mittelgrau">Anfrage gesendet</p>
      </div>
      <div class="w-24 flex justify-end items-center text-DentaBlue space-x-1">
        <ion-icon class="text-lg" name="ellipsis-horizontal-outline"></ion-icon>
        <p class="text-xs">Ausstehend</p>
      </div>
    </div>
    <div class="h-7"></div>
  </div>
</template>

<script>
import { computed, ref } from "vue";
import store from "@/store";
import { router } from "@/router";
import { useCloudFunction } from "@/services/cloudFunctionDienst";
export default {
  props: ["id"],
  setup(props, context) {
    /** Partnerschaft zur gegebenen ID */
    const partnerschaft = computed(() => {
      if (props.id) {
        return store.getters.partnerschaftAbfragen(props.id);
      }
    });

    /** Ob es sich um eine Einladung, oder eine ausstehende Partnerschaft handelt */
    const einladung = computed(() => {
      if (partnerschaft.value) {
        return (
          partnerschaft.value.teilnehmer[store.getters.praxisAbfragen.id]
            .angenommen === false
        );
      }
    });

    /** Ob die Einladung durch die Eigene Praxis bereits gesehen wurde.*/
    const gesehen = ref(
      !props.id || !store.getters.partnerschaftAbfragen(props.id)
        ? true
        : store.getters.partnerschaftAbfragen(props.id).teilnehmer[
          store.getters.praxisAbfragen.id
        ].gesehen
    );

    /** Gibt die Partnerpraxis in der Partnerschaft zurück (Name und Avatar-URl) */
    const partner = computed(() => {
      if (partnerschaft.value) {
        return Object.values(partnerschaft.value.teilnehmer).find(
          (t) => t.id !== store.getters.praxisAbfragen.id
        );
      }
    });

    const { error: annahmeFehler, call: annehmen } = useCloudFunction(
      "partnerschaftAnnehmen"
    );
    const { error: loeschenFehler, call: loeschen } = useCloudFunction(
      "partnerschaftLoeschen"
    );

    /** Öffnet die Seite zum Annehmen einer empfangenen Einladung zur Partnerschaft */
    function partnerschaftAnnehmen() {
      console.log(partnerschaft.value);
      router.push({ name: "AnfrageAnnehmen", params: { partnerschaftId: props.id } })
    }

    function partnerschaftLoeschen() {
      if (props.id) {
        loeschen({ id: props.id }).then(() => { });
      }
    }

    return {
      einladung,
      gesehen,
      partner,
      partnerschaftAnnehmen,
      partnerschaftLoeschen,
    };
  },
};
</script>

<style>
</style>