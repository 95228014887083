import axios from "axios";
import { fehlerSenden } from "./LogDienst";
import { versionAbfragen } from "./helfer";
import { cmsTld } from "@/constants/konstanten";

function assetUrlGenerieren(asset) {
  if (
    asset &&
    asset.data &&
    asset.data.attributes &&
    asset.data.attributes.url
  ) {
    const path = asset.data.attributes.url;
    return cmsTld + path;
  }
  return null; // Return null if the asset object is not properly structured
}

async function updateLogAbfragen() {
  try {
    //Zunächst wird die "vereinfachte Versionsnummer" erstellt
    const versionsNummerStellen = versionAbfragen().split("-")[1].split(".");
    const vereinfachteVersion = `${versionsNummerStellen[0]}.${versionsNummerStellen[1]}`;

    const url = `${cmsTld}/api/update-logs/?populate=deep&filters[webVersion][$eq]=${vereinfachteVersion}`;

    // Axios GET request
    const res = await axios.get(url);

    // Überprüfen, ob die Antwort ein nicht-leeres "data"-Array enthält
    if (res.data && Array.isArray(res.data.data) && res.data.data.length > 0) {
      // Find the newest entry
      const newestEntry = res.data.data.reduce((latest, current) => {
        return new Date(latest.attributes.createdAt) >
          new Date(current.attributes.createdAt)
          ? latest
          : current;
      });

      // Create the UpdateLog object
      let updateLog = {
        ...newestEntry.attributes,
        coverFullsize: assetUrlGenerieren(newestEntry.attributes.coverFullsize),
        coverThumnail: assetUrlGenerieren(newestEntry.attributes.coverThumnail),
        features: newestEntry.attributes.features.map((feature) => ({
          ...feature,
          icon: assetUrlGenerieren(feature.icon),
        })),
      };

      return updateLog;
    }
  } catch (error) {
    fehlerSenden(
      error,
      "Fehler beim Abfragen eines Update Logs.",
      "updateLogAbfragen",
      "cmsDienst"
    );
  }

  return null;
}

export { updateLogAbfragen };
