<template>
    <form class="onboarding-komponente">
        <OnboardingTitel 
            titel="Email Adresse Ändern" 
            untertitel="Geben Sie Ihre neue E-Mail Adresse an. Sie erhalten an Ihre alte Adresse eine Information zu dieser Änderung."/>
        <input type="email" class="default-textfield" v-model="email" placeholder="Neue E-Mail Adresse">
        <input type="email" class="default-textfield" v-model="emailBestaetigung" placeholder="Neue E-Mail Adresse Bestätigen">

        <OnboardingWeiterButton :deaktiviert="false" :laden="true"/>
    </form>
</template>
<script>
import OnboardingTitel from '@/components/OnboardingTitel.vue';
import OnboardingWeiterButton from '@/components/OnboardingWeiterButton.vue';
import { ref } from 'vue';
export default {
    components: {
        OnboardingTitel,
        OnboardingWeiterButton
    },
    setup() {
        const email = ref("")
        const emailBestaetigung = ref("")

        return {
            email,
            emailBestaetigung
        }
    }
}
</script>