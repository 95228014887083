<template>
    <div v-if="gueltigeAenderung === true">

        <BestaetigungsDialog :state="alertState" :laedt="pending" titel="Änderung rückgängig machen?"
            body="Ihre Abonnement wird regulär zu den bisherigen Konditionen weitergeführt."
            bestaetigungsText="Rückgängig Machen" @abgebrochen="alertState = false" @bestaetigt="rueckgaengigMachen" />

        <OptionenKarte titel="Ausstehende Änderung">
            <span v-html="infoText"></span>
            <button
                class="w-full py-3 rounded-xl text-sm font-bold bg-DentaBlue text-DentaBlue bg-opacity-10 transition duration-100 hover:bg-opacity-5"
                @click="alertState = true">
                Rückgängig
                Machen</button>
        </OptionenKarte>
    </div>
</template>
<script>
import OptionenKarte from '@/components/OptionenKarte.vue';
import BestaetigungsDialog from '@/components/dialoge/BestaetigungsDialog.vue';
import { useCloudFunction } from '@/services/cloudFunctionDienst';
import { erfolgAnzeigen, fehlerAnzeigen } from '@/services/toastDient';
import { aboNeuLaden } from '@/services/helfer';
import store from '@/store';
import dayjs from 'dayjs';
import { computed, ref } from 'vue';
export default {
    components: { OptionenKarte, BestaetigungsDialog },
    setup(props, context) {
        const { pending, error, call } = useCloudFunction("abonnementAenderungEntfernen")

        const alertState = ref(false)

        const gueltigeAenderung = computed(() => store.state.abonnementLaedt === false && store.state.abonnement.aenderung != null && store.state.abonnement.aenderung.art == "beendet")

        const aenderungsDatum = computed(() => {
            if (store.state.abonnementLaedt === false) {
                return dayjs.unix(store.state.abonnement.naechsteZahlung).format("DD.MM.YYYY")
            }
        })

        const infoText = computed(() => {
            if (gueltigeAenderung.value === true) {
                return `Ihr Abonnement wird zum <b>${aenderungsDatum.value}</b> beendet.<br/>Sie verlieren danach Zugriff auf Ihre Organisation. `
            }
        })

        function rueckgaengigMachen() {
            call()
                .then(() => {
                    if (error.value === false) {
                        aboNeuLaden()
                        erfolgAnzeigen("Änderung rückgängig gemacht.")
                    } else {
                        fehlerAnzeigen("Änderung konnte nicht rückgängig gemacht werden.")
                    }
                })
                .finally(() => alertState.value = false)
        }

        return { gueltigeAenderung, infoText, alertState, pending, rueckgaengigMachen }
    }
}
</script>