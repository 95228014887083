<template>
    <div class="w-full h-screen flex flex-col justify-center items-center">
        <div class="flex flex-col justify-center items-center mb-10" @click="zurueck">
            <img src="@/assets/icon_weiss.png" alt="icon" class="w-16 rounded-lg shadow-DentaBlueLight shadow-lg mb-6">

            <img src="@/assets/logo_text.png" alt="dentasnap" class="w-44">
        </div>

        <div class="flex flex-col justify-center items-center text-center">
            <p class="text-3xl font-bold">Upps!</p>
            <p class="text-lg text-Mittelgrau mt-1 mb-5">Diese Seite ist<br />nicht vorhanden.</p>
            <div class="standard-button bg-DentaBlue hover:bg-opacity-70 hover:cursor-pointer" @click="zurueck">
                <p>Zurück</p>
            </div>
        </div>
    </div>
</template>

<script>
import { router } from '@/router';

export default {
    setup() {
        const zurueck = () => router.push({ name: "Gallerie" })

        return { zurueck }
    }
}
</script>

<style>

</style>