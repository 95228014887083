<template>
    <div class="w-full h-screen flex justify-start items-center p-5 bg-white">
        <OnboardingFortschrittsAnzeige class="w-1/3 h-full mr-40" :schritte="schritte" :index="index"
            :fehler="fehler" />
        <component v-if="laedt === false" :is="komponente" :zahlungsMethode="zahlungsMethode" :fehler="fehler"
            @zahlungsMethodeAktualisiert="zm => zahlungsMethode = zm" @weiter="() => navigieren(true)"
            @zurueck="() => navigieren(false)"></component>
    </div>
</template>
<script>
import OnboardingFortschrittsAnzeige from '@/components/OnboardingFortschrittsAnzeige.vue';
import Information from './components/information/Information.vue';
import Zahlung from './components/zahlung/Zahlung.vue';
import Zusammenfassung from './components/zusammenfassung/Zusammenfassung.vue';
import Fertig from './components/fertig/Fertig.vue';
import { eigentuemer } from '@/constants/berechtigungen';
import { berechtigungenPruefen } from '@/services/authentifizierungsDienst';
import { computed, onMounted, ref } from 'vue';
import { zahlungsMethoden } from '@/constants/zahlungsMethoden';
import { useRoute } from 'vue-router';
export default {
    components: {
        OnboardingFortschrittsAnzeige,
        Information,
        Zahlung,
        Zusammenfassung,
        Fertig
    },
    setup() {
        const laedt = ref(true)

        const route = useRoute()

        const fehler = ref(false)

        /** Gibt an, ob der angemeldete Nutzer der Praxiseigentümer ist */
        const nutzerIstEigentuemer = computed(() => berechtigungenPruefen([eigentuemer]))

        const zahlungsMethode = ref(zahlungsMethoden[0])

        //Aktueller Prozessindex
        const index = ref(null)

        const schritte = computed(() => {
            if (nutzerIstEigentuemer.value === true) {
                return [
                    {
                        index: 0,
                        titel: "Information",
                        untertitel: "Ihre Organisation wurde gesperrt."
                    },
                    {
                        index: 1,
                        titel: "Zahlung",
                        untertitel: "Wählen Sie eine neue Zahlungsmethode."
                    },
                    {
                        index: 2,
                        titel: "Zusammenfassung",
                        untertitel: "Zusammenfassung Ihrer Abonnementfortsetzung."
                    },
                    {
                        index: 3,
                        titel: "Fertig",
                        untertitel: "Ihre Organisation wurde entsperrt."
                    }
                ]
            } else {
                return [{
                    index: 0,
                    titel: "Information",
                    untertitel: "Die Organisation wurde gesperrt."
                }]
            }
        })

        const komponente = computed(() => {
            switch (index.value) {
                case 1: return Zahlung
                case 2: return Zusammenfassung
                case 3: return Fertig
                default: return Information
            }
        })

        function navigieren(forwaerts) {
            if (forwaerts === true && index.value < schritte.value.length - 1) {
                index.value++;
            } else if (index.value > 0) {
                index.value--;
            }
        }

        function initialisieren() {
            laedt.value = true
            if (route.query.erfolg === "true" || route.query.erfolg === "false") {
                index.value = 3
                fehler.value = route.query.erfolg === "false"
            } else {
                index.value = 0
            }
            laedt.value = false
        }

        onMounted(initialisieren)

        return {
            zahlungsMethode,
            index,
            schritte,
            komponente,
            laedt,
            fehler,
            navigieren
        }
    }
}
</script>