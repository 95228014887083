//Holds code for enrolling and using SMS based second factors for auth accounts.
import { auth } from "@/firebase/config";
import store from "@/store";
import {
  multiFactor,
  PhoneAuthProvider,
  PhoneMultiFactorGenerator,
  getMultiFactorResolver,
  ApplicationVerifier,
} from "firebase/auth";
import { ref } from "vue";
import { useCloudFunction } from "./cloudFunctionDienst";
import { fehlerSenden } from "./LogDienst";

//Eine aufrufbare Instanz der "claimsAktualisieren" Cloud Funktion wird erstellt
const { error: aktualisierungFehler, call: claimsAktualisieren } =
  useCloudFunction("claimsAktualisieren");

//Eine aufrufbare Instanz der "registrierteFaktorenAbfragen" Cloud Funktion wird erstellt
const {
  result: abfrageErgebnis,
  error: abfrageFehler,
  call: faktorenAbfragen,
} = useCloudFunction("registrierteFaktorenAbfragen");

//Speichert die Verification ID, die zusammen mit dem Code zum Verifizieren der Nummer benötigt wird.
var verificationId = ref(null);

//Speichert den "resolver", mit dem beim Login wenn nötig ein Code angefragt werden kann.
const multiFactorResolver = ref(null);

//Wird verwendete, um MFA-Verifizierungscodes anzufragen
const phoneAuthProv = new PhoneAuthProvider(auth);

/**
 * Startet die Registrierung der angegebenen Telefonnummer, indem ein Code zur Verifizierung an Sie gesendet wird.
 * @param {ApplicationVerifier} reCaptchaVerifier
 * @param {String} telefonNummer Korrekt Formatierte Telefonnummer
 */
async function registrierungStarten(reCaptchaVerifier, telefonNummer) {
  try {
    //Sollte von zuvor eine verificationId gespeichert sein, wird dieser zunächst zurückgesetzt.
    verificationId.value = null;

    //Mit dem aktuell angemeldeten Auth Nutzer wird eine Multi Faktor Session erstellt
    var multiFactorSession = await multiFactor(auth.currentUser).getSession();

    //Ein Datenobjekt mit der Session und der zu nutzenden Telefonnummer wird erstellt
    const phoneInfoOptions = {
      phoneNumber: telefonNummer,
      session: multiFactorSession,
    };

    verificationId.value = await phoneAuthProv.verifyPhoneNumber(
      phoneInfoOptions,
      reCaptchaVerifier
    );
  } catch (error) {
    fehlerSenden(
      error,
      "Fehler beim Registrieren einer neuen MFA Nummer.",
      "registrierungStarten",
      "mfaDienst"
    );
    throw error;
  }
}

/**
 * Nutzt den gegebenen Verifzierungscode und vorher erstellte Credentials, um die MFA Methode zu bestätigen und dem Nutzer hinzuzufügen
 * @param {String} code 6-Stelliger MFA Verifizierungscode
 * @param {String} typ Typ des Faktors, der hiermit registriert wird (Primär oder Sekundär). Standard ist Primär.
 */
async function registrierungAbschliessen(code, typ = "primaer") {
  try {
    if (verificationId.value) {
      //Mithilfe des empfangenen Codes und der "verificationId" die den Code zum Nutzer zuordnet, wird
      //ein Credential erstellt und die Identität des Nutzers somit bestätigt
      const cred = PhoneAuthProvider.credential(verificationId.value, code);

      //Mithilfe des zuvor erstellten Credential wird das Eigentum über die Telefonnummer tatsächlich bestätigt.
      //Die Nummer kann nun im nächsten Schritt als Faktor registriert werden.
      const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(cred);

      const faktorName = typ == "primaer" ? "a" : "b";

      //Die Nummer wird als Faktor hinterlegt
      await multiFactor(auth.currentUser).enroll(
        multiFactorAssertion,
        faktorName
      );

      if (aktualisierungFehler.value == false) {
        verificationId.value = null;

        await faktorenAbfragen();

        if (abfrageFehler.value === false) {
          store.commit("registrierteFaktorenSetzen", abfrageErgebnis.value);
        }
      }
    } else {
      throw new Error("Keine gültige Verification-ID gefunden");
    }
  } catch (error) {
    fehlerSenden(
      error,
      "Fehler beim Verifizieren einer neuen Nummer für MFA.",
      "registrierungAbschliessen",
      "mfaDienst"
    );
    throw error;
  }
}

//Basierend auf der vom Loginprozess zurückgegebenen Fehlermeldung, wird ein MFA-Code beantragt
const mfaCodeAnfragen = async (reCaptchaVerifier, error) => {
  try {
    if (reCaptchaVerifier && error) {
      //Verbleibende "verificationId's" von vorherigen Multi Factor registrierungen / verifizierungen
      //werden gelöscht
      verificationId.value = null;
      multiFactorResolver.value = null;

      //Mithilfe des Fehlercodes wird ein Multi Faktor "resolver" erstellt, mit dessen Hilfe
      //Ein Code angefragt werden kann.
      multiFactorResolver.value = getMultiFactorResolver(auth, error);

      //Ein Informationsobjekt für den ERSTEN Faktor des Nutzers wird erstellt.
      const phoneInfoOptions = {
        multiFactorHint: multiFactorResolver.value.hints.find(
          (f) => f.displayName == "a"
        ),
        session: multiFactorResolver.value.session,
      };

      //Ein Provider für Telefonbasierte Authorisierung wird erstellt, und damit ein Code an
      //die hinterlegte Nummer des Nutzers gesendet.
      const phoneAuthProvider = new PhoneAuthProvider(auth);
      verificationId.value = await phoneAuthProvider.verifyPhoneNumber(
        phoneInfoOptions,
        reCaptchaVerifier
      );

      return true;
    }
  } catch (error) {
    fehlerSenden(
      error,
      "Fehler beim Beantragen eines Codes für die MFA eines Nutzers.",
      "mfaCodeAnfragen",
      "mfaDienst"
    );
  }
  return false;
};

//Verifiziert einen zuvor angefragten MFA Code, um damit einen Login zu vervollständigen.
const mfaCodeVerifizieren = async (code) => {
  //Ein "Credential", mit dem eine Identität über verschiedene Wege bestätigt wird, wird erstellt
  const cred = PhoneAuthProvider.credential(verificationId.value, code);

  //Mit dem Credential wird eine "assertion" erstellt, die letztendlich bestätigt,
  //dass der Nutzer Zugriff auf die Telefonnummer hat.
  const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(cred);

  //Mit dem zuvor erstellten "resolver" wird der Login vollendet, womit ein neuer
  //Auth Nutzer angemeldet wird.
  await multiFactorResolver.value.resolveSignIn(multiFactorAssertion);
};

export {
  registrierungStarten,
  registrierungAbschliessen,
  mfaCodeAnfragen,
  mfaCodeVerifizieren,
};
