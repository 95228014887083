<template>
    <div class="w-full px-10 py-6 rounded-2xl bg-TextField flex flex-col space-y-3 items-center">
        <p v-if="(typeof titel === 'string')" class="mb-2 w-full font-bold">{{ titel }}
        </p>
        <!-- Grundpreis -->
        <div v-if="laedt === false" class="w-full flex justify-between">
            <span>{{ gp.beschreibung }}:</span>
            <span>{{ positionZuWertString(gp) }}</span>
        </div>
        <div v-else class="w-full flex justify-between">
            <LadePlatzhalter class="w-[40%]" />
            <LadePlatzhalter class="w-24" />
        </div>

        <!-- Preisminderungen -->
        <div v-for="w in pm" :key="w.index" class="w-full flex justify-between">
            <span>{{ w.beschreibung }}:</span>
            <span>- {{ positionZuWertString(w) }}</span>
        </div>

        <Divider />

        <!-- Netto -->
        <div v-if="laedt === false" class="w-full flex justify-between">
            <span>{{ netto.beschreibung }}:</span>
            <span>{{ positionZuWertString(netto) }}</span>
        </div>
        <div v-else class="w-full flex justify-between">
            <LadePlatzhalter class="w-28" />
            <LadePlatzhalter class="w-24" />
        </div>

        <!-- MwST -->
        <div v-if="laedt === false" class="w-full flex justify-between">
            <span>{{ mwst.beschreibung }}:</span>
            <span>+ {{ positionZuWertString(mwst) }}</span>
        </div>
        <div v-else class="w-full flex justify-between">
            <LadePlatzhalter class="w-28" />
            <LadePlatzhalter class="w-24" />
        </div>

        <Divider />

        <!-- Brutto -->
        <div v-if="laedt === false" class="w-full flex justify-between font-bold">
            <span>{{ brutto.beschreibung }}:</span>
            <span v-if="einmalig === true">{{ positionZuWertString(brutto) }}</span>
            <span v-else>{{ positionZuWertString(brutto) }} / {{ jaehrlich === true ? "Jahr" : "Monat" }}{{ pmInfoText ==
                null ? ''
                : ' *' }}</span>
        </div>
        <div v-else class="w-full flex justify-between">
            <LadePlatzhalter class="w-28" />
            <LadePlatzhalter class="w-24" />
        </div>

        <span v-if="laedt === false && pmInfoText != null && !einmalig" class="w-full pt-2 text-xs"
            v-html="pmInfoText"></span>
        <div class="w-full flex flex-col space-y-2 pt-3" v-else-if="laedt === true && pmInfoText != null">
            <LadePlatzhalter class="w-full" />
            <LadePlatzhalter class="w-1/3" />
        </div>
    </div>
</template>
<script>
import { preisStringGenerieren } from "@/services/helfer";
import LadePlatzhalter from "../LadePlatzhalter.vue";
import Divider from "./components/Divider.vue";
import { computed } from 'vue';
export default {
    components: { LadePlatzhalter, Divider },
    props: ["zusammenstellung", "jaehrlich", "titel", "einmalig"],
    setup(props, context) {

        /** Konvertiert ein "Rechnungsposition" Objekt in einen "x,yz €" String*/
        function positionZuWertString(rechnungsposition) {
            return `${preisStringGenerieren(rechnungsposition.wert)} €`
        }

        const laedt = computed(() => props.zusammenstellung == null)

        const gp = computed(() => {
            if (laedt.value === false) {
                return props.zusammenstellung.find(k => k.art === "grundpreis")
            }
        })

        const pm = computed(() => {
            if (laedt.value === false) {
                return props.zusammenstellung.filter(k => k.art === "preisminderung").sort((a, b) => a.index - b.index)
            }
        })

        const netto = computed(() => {
            if (laedt.value === false) {
                return props.zusammenstellung.find(k => k.art === "netto")
            }
        })

        const mwst = computed(() => {
            if (laedt.value === false) {
                return props.zusammenstellung.find(k => k.art === "mwst")
            }
        })

        const brutto = computed(() => {
            if (laedt.value === false) {
                return props.zusammenstellung.find(k => k.art === "brutto")
            }
        })

        const pmInfoText = computed(() => {
            if (laedt.value === false && pm.value && pm.value.length > 0) {
                return "* Hierbei handelt es sich um den reduzierten Preis. Nach Ablauf des Gutscheincodes erhöht sich der Betrag automatisch auf den regulären Preis des gewählten Pakets. Es gelten die <a>allgemeinen Geschäftsbedingungen</a>"
            }
        })

        return {
            laedt,
            gp,
            pm,
            netto,
            mwst,
            brutto,
            pmInfoText,
            positionZuWertString
        }
    }
}
</script>