<!-- Dialog wird angezeigt, um zu bestätigen, dass ein Snap gelöscht werden soll. -->

<template>
    <GDialog
        v-model="state"
        border-radius=12
        :max-width="dialogBreite"
        :background="dialogHintergrund"
    >
    <div class="standard-dialog">
        <DialogTitel
            titel="Snap Löschen"
            iconname="trash-outline"
            @schliessen="schliessenEventAbfeuern"
        />
         <!-- Explanation Text -->
         <p class="mt-4 mb-6">Diese Aktion kann nicht rückgängig gemacht werden. <strong>Wenn Sie Snaps in geteilten Alben löschen, verlieren alle Teilnehmer Zugriff darauf.</strong></p>

         <!-- Delete Button -->
         <div
            v-if="loeschenLaed == false"
            class="w-full py-2 flex justify-center items-center bg-Warning rounded-md text-white transition duration-100 hover:cursor-pointer hover:bg-WarningHintergrund"
            @click="snapLoschen">
            <p>Löschen</p>
         </div>
         <div
            v-else
            class="w-full py-2 flex justify-center items-center bg-Mittelgrau rounded-md text-white">
            <p>Löschen</p>
         </div>
    </div>
    </GDialog>
</template>

<script>

//Import aller benötigten Frameworks und Vue Komponenten
import { GDialog } from 'gitart-vue-dialog'

//Import aller benötigten eigenen Komponenten
import DialogTitel from '@/components/DialogTitel.vue'
import { standardToastAnzeigen } from '@/services/toastDient'

//Import aller benötigten Helferfunktionen und Konstanten
const {dialogBreite, dialogHintergrund} = require("@/constants/konstanten")
const { useCloudFunction } = require('@/services/cloudFunctionDienst')

export default {
    components: {GDialog, DialogTitel},

    props: ["state", "snap"],
    emits: ["geloescht", "schliessen"],

    setup(props, context) {

        //Eine Aufrufbare Instanz der "Snap Löschen" Cloud Funktion wird erstellt
        const {
            pending: loeschenLaed, 
            error: loschenFehler, 
            call: loeschen 
            } = useCloudFunction("snapLoeschen")

        const schliessenEventAbfeuern = () => context.emit("schliessen")

        const snapLoschen = async () => {
            if(props.snap) {
                await loeschen({id: props.snap.id })
                    
                if(loschenFehler.value == false) {
                    context.emit('geloescht', props.snap)
                    context.emit("schliessen")
                    standardToastAnzeigen("text-ErfolgsGruen", "trash", "Erfolgreich!", "Snap gelöscht.")
                }
            }
        }

        return {
            //State für das UI
            loeschenLaed,

            //Funktionen für das UI
            schliessenEventAbfeuern,
            snapLoschen,
            
            //Konstanten für das Dialog UI
            dialogBreite,
            dialogHintergrund
        }
    }

}
</script>