//Enthält Funktionen die die Nutzung der "Firestore" Datebank ermöglichen.

//Referenzen aus der Anwendung und des "Vue" Framework werden importiert
import { firestore } from "@/firebase/config";
import store from "@/store";
import { watchEffect } from "vue";

//Benötigten Funktionen der "Firbase Firestore" Bibliothek werden importiert.
import {
  collection,
  onSnapshot,
  query,
  where,
  doc,
  setDoc,
  getDoc,
  getDocs,
} from "firebase/firestore";
import { useCloudFunction } from "./cloudFunctionDienst";
import {
  rueckgabeErstellen,
  standardThemaIconCacheAbfragen,
  stringListenGleich,
  versionAbfragen,
} from "./helfer";
import { berechtigungenPruefen } from "./authentifizierungsDienst";
import { patientenVerwalten } from "@/constants/berechtigungen";
import { fehlerSenden } from "./LogDienst";

//Erstellt einen "Listener" für das Praxisdokument der Praxis, aus der die gegebene Nutzer ID stammt.
const praxisZuhoeren = (userId) => {
  const {
    result: abfrageErgebnis,
    error: abfrageFehler,
    call: kontenAbfragen,
  } = useCloudFunction("kontenAbfragen");

  //Eine Anfrage für das Dokument der Praxis wird erstellt
  let anfrage = query(
    collection(firestore, "praxen"),
    where("konten", "array-contains", userId)
  );

  //Speichert nach Registrierung den "unsubscribe" Handler für die Themen
  var eigeneThemenAbmelden;
  var standardThemenAbmelden;
  let abmelden = onSnapshot(
    anfrage,
    async (snapshot) => {
      if (snapshot.docChanges()[0].type === "modified") {
        const neuePraxisDaten = snapshot.docs[0].data();
        const altePraxisDaten = store.getters.praxisAbfragen;

        if (
          stringListenGleich(neuePraxisDaten.konten, altePraxisDaten.konten) ===
          false
        ) {
          await kontenAbfragen();
          if (abfrageFehler.value === false) {
            store.commit("kontenSetzen", abfrageErgebnis.value);
          }
        }

        store.commit("praxisSetzen", neuePraxisDaten);
      } else if (snapshot.docChanges()[0].type === "added")
        await kontenAbfragen();
      if (!abfrageFehler.value) {
        //Das Praxisdokument wird dem Snapshot entnommen
        let praxis = snapshot.docs[0].data();

        //Den Themen der Praxis wird zugehört, und bei aktualisierungen die lokale Liste entsprechend gesetzt
        eigeneThemenAbmelden = onSnapshot(
          query(
            collection(firestore, "themen"),
            where("eigentuemer", "==", praxis.id)
          ),
          (ts) => {
            const themen = ts.docs.map((d) => d.data());
            store.commit("eigeneThemenSetzen", themen);
          }
        );

        //Den Themen der Praxis wird zugehört, und bei aktualisierungen die lokale Liste entsprechend gesetzt
        standardThemenAbmelden = onSnapshot(
          query(collection(firestore, "themen"), where("standard", "==", true)),
          async (ts) => {
            const themen = ts.docs.map((d) => d.data());

            //Der Data-URL für das Thema wird abgefragt und gecached.
            for (let i = 0; i < themen.length; i++) {
              await standardThemaIconCacheAbfragen(themen[i]);
            }
            store.commit("standardThemenSetzen", themen);
          }
        );

        //Das Abonnement der Praxis wird abgefragt
        const aboQuery = query(
          collection(firestore, "abonnements"),
          where("praxisId", "==", praxis.id)
        );
        const aboSnapshot = await getDocs(aboQuery);
        if (!aboSnapshot.empty) {
          store.commit("abonnementSetzen", aboSnapshot.docs[0].data());
        }

        //Die Konten der Praxis werden im Store gespeichert
        store.commit("kontenSetzen", abfrageErgebnis.value);

        //Die Praxis selbst wird gesetzt
        store.commit("praxisSetzen", praxis);
      }
    },
    (error) =>
      fehlerSenden(
        error,
        "Fehler im Empfangen von Praxisdaten durch den Firestore-Listener.",
        "praxisZuhoeren",
        "firestoreDienst"
      )
  );

  //Ein weiterer Zuhörer wird erstellt, der aufgerufen wird, wenn der Praxiszuhörer nicht mehr
  //benötigt wird.
  watchEffect((onInvalidate) => {
    //Wenn der zuhörer nicht mehr gebraucht wird, wird die "abmelden" Funktion aufgerufen.
    onInvalidate(() => {
      eigeneThemenAbmelden();
      abmelden();
    });
  });

  //TODO: Name des Rückgabewerts auf Deutsch übersetzen.
  return { unsub: abmelden };
};

/**
 * Richtet einen Firestore-Listener ein, der den Sessions zuhört, deren Eigentümer die Praxis ist.
 *
 * @param {string} praxisId Die ID der Praxis, dessen Sessions abgehört werden sollen
 */
function sessionsZuhoeren(praxisId) {
  //Die Anfrage für die Sessions wird konstruiert
  let anfrage = query(
    collection(firestore, "sessions"),
    where(`teilnehmer.${praxisId}`, "!=", "")
  );

  //Ein Listener wird registriert, der jedes Mal aufgerufen wird wenn neue Sessions vorhanden sind.
  let abmelden = onSnapshot(
    anfrage,
    { includeMetadataChanges: true },
    async (snapshot) => {
      if (snapshot.metadata.hasPendingWrites === false) {
        var ergebnis = [];
        for (
          let sessionIndex = 0;
          sessionIndex < snapshot.size;
          sessionIndex++
        ) {
          var session = snapshot.docs[sessionIndex].data();

          //Wenn der Patient fremd ist und nicht automatisch geladen wurde,
          if (store.getters.patientAbfragen(session.patient) == undefined) {
            let patient = await patientenAbfragen(session.patient);

            if (patient) {
              store.commit("patientSetzen", patient);
            }
          }

          //Es wird sichergestellt, dass die Praxis Zugriff auf alle Themen der Session hat.
          //Wenn nicht werden die fehlenden hier geladen
          for (let i = 0; i < session.themen.length; i++) {
            if (store.getters.themaAbfragen(session.themen[i]) == undefined) {
              let thema = await themaAbfragen(session.themen[i]);
              if (thema != undefined) {
                store.commit("themaSetzen", thema);
              } else {
                //Ordner / privates Thema von Praxis darf nicht abgefragt werden, und wird aus der Session entfernt
                session.themen = session.themen.filter(
                  (t) => t != session.themen[i]
                );
              }
            }
          }

          //Ein temporärer Listener für die Snaps der Session wird eingerichtet
          snapsEinerSessionZuhoeren(session);

          ergebnis.push(session);
        }

        store.commit("sessionsSetzen", ergebnis);
      }
    },
    (error) =>
      fehlerSenden(
        error,
        "Fehler beim Empfangen von Sessiondaten durch den Firestore-Listener.",
        "sessionsZuhoeren",
        "firestoreDienst"
      )
  );

  watchEffect((onInvalidate) => {
    //Wenn der zuhörer nicht mehr gebraucht wird, wird die "abmelden" Funktion aufgerufen.
    onInvalidate(() => abmelden());
  });

  return { abmelden };
}

/**
 * Richtet einen Listener ein, der die Snaps in einer bestimmten Session abhört, und die ergebnisse im Store abspeichert.
 * Soll verwendet werden, um bei neu hochgeladenen Sessions nacheinander die hochgeladenen Snaps im Store zu hinterlegen.
 * Wenn alle Snaps der Session heruntergeladen wurden, meldet sich der Listener automatisch ab
 * @param {*} session Die Session-Instanz, deren Snaps zugehört werden sollen.
 */
function snapsEinerSessionZuhoeren(session) {
  let anfrage = query(
    collection(firestore, "snaps"),
    where("session", "==", session.id)
  );
  let abmelden = onSnapshot(
    anfrage,
    (snapshot) => {
      for (let i = 0; i < snapshot.docs.length; i++) {
        const snap = snapshot.docs[i].data();

        store.commit("snapsHinzufuegen", [snap]);
      }

      //Wenn alle Snaps der Session hochgeladen wurden, wird der Listener abgemeldet
      if (snapshot.size == session.snaps.length) {
        abmelden();
      }
    },
    (error) =>
      fehlerSenden(
        error,
        "Fehler beim Empfangen der Snaps zu einer Session durch den Firestore-Listener",
        "snapsEinerSessionZuhoeren",
        "firestoreDienst"
      )
  );
}

/**
 * Richtet einen Firestore Listener ein, der den Partnerschaften zuhört, bei denen die Praxis mit der gegebenen Praxis-ID Teilnehmer ist.
 * @param {string} praxisId die ID der Praxis, deren Partnerschaften abgehört werden sollen.
 */
function partnerschaftenZuhoeren(praxisId) {
  //Eine Anfrage an alle Partnerschaftsdokumente, auf dessen Teilnehmerliste die Praxis mit der ID steht, wird erstellt.
  let anfrage = query(
    collection(firestore, "partnerschaften"),
    where(`teilnehmer.${praxisId}`, "!=", "")
  );

  let abmelden = onSnapshot(
    anfrage,
    (sn) => {
      //Die Daten aller Dokumente werden in einer Liste gesammelt
      let dokumentDaten = sn.docs.map((d) => d.data());

      //Die Partnerschaftsobjekte werden der Liste im lokalen Speicher hinzugefügt
      store.commit("partnerschaftenSetzen", dokumentDaten);

      //Ein Zuhörer wird erstellt, der den Listener abmeldet, sollte er nicht mehr benötigt werden.
      watchEffect((onInvalidate) => {
        //Wenn der zuhörer nicht mehr gebraucht wird, wird die "abmelden" Funktion aufgerufen.
        onInvalidate(() => abmelden());
      });
    },
    (error) =>
      fehlerSenden(
        error,
        "Fehler beim Empfangen von Partnerschaftsdaten durch den Firestore-Listener.",
        "partnerschaftenZuhoeren",
        "firestoreDienst"
      )
  );
}

/**
 * Richtet einen Listener ein, der die Patienten abhört, die die Praxis selber erstellt hat.
 * @param {string} praxisId ID der Praxis, dessen Patienten abgehört werden sollen.
 */
function patientenZuhoeren(praxisId) {
  //Eine Anfrage an alle Patientendokumente, auf die die Praxis mit der gegebenen ID Zugriff hat,
  //wird erstellt.
  let anfrage = query(
    collection(firestore, "patienten"),
    where("eigentuemer", "==", praxisId)
  );

  let abmelden = onSnapshot(
    anfrage,
    (sn) => {
      //Die Daten aller Dokumente werden in einer Liste gesammelt
      let dokumentDaten = sn.docs.map((d) => d.data());

      //Die Patientenobjekte werden der Liste im lokalen Speicher hinzugefügt
      store.commit("patientenSetzen", dokumentDaten);

      //Ein Zuhörer wird erstellt, der den Listener abmeldet, sollte er nicht mehr benötigt werden.
      watchEffect((onInvalidate) => {
        //Wenn der zuhörer nicht mehr gebraucht wird, wird die "abmelden" Funktion aufgerufen.
        onInvalidate(() => abmelden());
      });
    },
    (error) =>
      fehlerSenden(
        error,
        "Fehler beim Empfangen von Patientendaten durch den Firestore-Listener.",
        "patientenZuhoeren",
        "firestoreDienst"
      )
  );
}

/**
 * Fragt explizit einen Patienten ab.
 * Wird benötigt, wenn eine Session einen Patienten beinhaltet der nicht der Praxis gehört
 * und damit nicht automatisch heruntergeladen wird.
 * @param {string} patientenId ID des Patienten, der abgefragt werden soll.
 */
async function patientenAbfragen(patientenId) {
  try {
    let patientenDoc = await getDoc(doc(firestore, `patienten/${patientenId}`));

    if (patientenDoc.exists() === true) {
      return patientenDoc.data();
    }
  } catch (error) {
    fehlerSenden(
      error,
      "Fehler beim Abfragen eines Patienten aus Firestore.",
      "patientenAbfragen",
      "firestoreDienst"
    );
  }
}

/**
 * Fragt das einzelne Thema per ID ab, und gibt die Dokumentdaten zurück.
 * @param {string} themenId die ID des Themas, das abgefragt werden soll
 * @return die Dokumentendaten des Themas, oder undefined wenn kein Thema gefunden wurde.
 */
async function themaAbfragen(themenId) {
  try {
    let thema = await getDoc(doc(firestore, `themen/${themenId}`));
    return thema.exists() === true ? thema.data() : undefined;
  } catch (error) {
    //"Permisson Denied" Fehler werden erwartet, da Abfragen nach Ordnern / eigenen Themen von Partnern immer abgelehnt werden.
    if (error.code !== "permission-denied") {
      fehlerSenden(
        error,
        "Fehler beim Abfragen eines Themas aus Firestore.",
        "themaAbfragen",
        "firestoreDienst"
      );
    }
  }
  return undefined;
}

//Fügt dem Snap eine neue ID hinzu, und läd ihn dann unter der gleichen ID hoch.
const snapHochladen = async (snap) => {
  try {
    //Ein neues Dokument in der Eigentümercollection wird erstellt
    const dokument = doc(firestore, `snaps/${snap.id}`);

    //Die Snapdaten werden in das Dokument hochgeladen
    await setDoc(dokument, snap);

    return rueckgabeErstellen(0);
  } catch (error) {
    fehlerSenden(
      error,
      "Fehler beim Hochladen eines Snap-Objekts in Firestore.",
      "snapHochladen",
      "firestoreDienst"
    );
    return rueckgabeErstellen(11);
  }
};

const einmaligenStringGenerieren = () => doc(collection(firestore, "snaps")).id;

const instandhaltungPruefen = async () => {
  try {
    const version = versionAbfragen().split("-");

    const name = version[0];
    const nummer = version[1];

    const versionenDaten = (
      await getDoc(doc(firestore, "instandhaltung/versionen"))
    ).data();
    const wartungsDaten = (
      await getDoc(doc(firestore, "instandhaltung/wartung"))
    ).data();

    if (wartungsDaten[name] == undefined || wartungsDaten[name] == true) {
      store.commit("wartungSetzen", true);
      return rueckgabeErstellen(91);
    } else {
      store.commit("wartungSetzen", false);
    }

    if (versionenDaten[name].find((v) => v == nummer) == undefined) {
      store.commit("updateNoetigSetzen", true);
      return rueckgabeErstellen(92);
    } else {
      store.commit("updateNoetigSetzen", false);
    }

    return rueckgabeErstellen(0);
  } catch (error) {
    fehlerSenden(
      error,
      "Fehler bei der Instandhaltungsprüfung (Versionskompatibilität und Wartungsmodus).",
      "instandhaltungPruefen",
      "firestoreDienst"
    );
    return rueckgabeErstellen(11);
  }
};

/**
 * Kombiniert alle Sessions des betroffenen Patienten zu einer liste von Fällen, und gibt diese zurück.
 * @param {string} patient Die ID des Patienten, dessen Fälle abgefragt werden sollen.
 */
async function faelleFuerPatientAbfragen(patient) {
  try {
    //Zunächst wird eine Anfrage für alle Sessions erstellt, die den Patienten beinhalten
    let anfrage = query(
      collection(firestore, "sessions"),
      where("patient", "==", patient)
    );
    let snapshot = await getDocs(anfrage);

    if (snapshot.empty === true) return [];

    //Speichert die generierte Fallliste.
    var ergebnis = [];

    for (let i = 0; i < snapshot.docs.length; i++) {
      const session = snapshot.docs[i].data();

      var fall = ergebnis.find((f) => f.id == session.fallId);

      if (fall != undefined) {
        //Fall ist bereits vorhanden, wird erweitert
        let index = ergebnis.findIndex((f) => f.id == fall.id);

        if (session.hochladeZeit > fall.hochladeZeit) {
          ergebnis[index].hochladeZeit = session.hochladeZeit;
        }

        if (session.schritt > fall.schritt) {
          ergebnis[index].schritt = session.schritt;
        }

        session.themen.forEach((tId) => {
          if (fall.themen.find((t) => t.id == tId) == undefined) {
            let thema = store.getters.themaAbfragen(tId);
            ergebnis[index].themen.push(thema);
          }
        });

        session.zaehne.forEach((zahn) => {
          if (
            fall.zaehne.find(
              (z) => z.nummer === zahn.nummer && z.implantat === true
            ) == undefined
          ) {
            ergebnis[index].zaehne = ergebnis[index].zaehne.filter(
              (z) => z.nummer != zahn.nummer
            );
            ergebnis[index].zaehne.push(zahn);
          }
        });
      } else {
        //Fall ist noch nicht vorhanden, wird erstellt
        let themen = session.themen.map((tId) =>
          store.getters.themaAbfragen(tId)
        );

        var fall = {
          id: session.fallId,
          hochladeZeit: session.hochladeZeit,
          schritt: session.schritt,
          themen: themen,
          zaehne: session.zaehne,
        };

        ergebnis.push(fall);
      }
    }

    return ergebnis;
  } catch (error) {
    fehlerSenden(
      error,
      "Fehler beim Abfragen und Generieren der Fälle eines Patienten",
      "faelleFuerPatientAbfragen",
      "firestoreDienst"
    );
    return [];
  }
}

async function patientHochladen(patient) {
  try {
    if (berechtigungenPruefen([patientenVerwalten]) === true) {
      let document = doc(firestore, `patienten/${patient.id}`);
      await setDoc(document, patient);
    }
  } catch (error) {
    fehlerSenden(
      error,
      "Fehler beim Hochladen eines neuen Patienten in Firestore.",
      "patientHochladen",
      "firestoreDienst"
    );
  }
}

/**
 * Läd das gegebene Session-Objekt in die entsprechende Collection hoch.
 * @param {*} session Sessioninstanz, die hochgeladen werden soll
 */
async function sessionHochladen(session) {
  try {
    let dokument = doc(firestore, `sessions/${session.id}`);
    await setDoc(dokument, session);
  } catch (error) {
    fehlerSenden(
      error,
      "Fehler beim Hochladen eines Sessionobjekts in Firestore.",
      "sessionHochladen",
      "firestoreDienst"
    );
  }
}

//TODO: Rückgabewerte ins Deutsche übersetzen.
export {
  praxisZuhoeren,
  sessionsZuhoeren,
  partnerschaftenZuhoeren,
  patientenZuhoeren,
  themaAbfragen,
  snapHochladen,
  einmaligenStringGenerieren,
  instandhaltungPruefen,
  faelleFuerPatientAbfragen,
  patientHochladen,
  sessionHochladen,
};
