//Enthält Konstanten wie Cloud-Funktionsnamen oder Nutzerrollen-Namen.

//Rollen der Nutzerobjekte in Praxen
const eigentuemer = "eigentuemer";
const verwalter = "verwalter";
const bearbeiter = "bearbeiter";
const fotograf = "fotograf";

//Arten für "Angabe Felder", bestimmt die Farbe und das Icon des Feldes.
const behandlerFeld = "behandler-feld";
const albumFeld = "album-feld";
const geteiltesFeld = "geteiltes-feld";

//Werte für Dialoge
const dialogBreite = "600";
const dialogHintergrund = "#FFFFFF";

//Bis kontentypen mit richtigen Grenzwerten implementiert sind, dienen diese
//Werte als Platzhalter im UI
const platzhalterKontenGrenze = 8;
const platzhalterSpeicherGrenze = 200000000000;

//Mögliche Größen, die ein zu bearbeitendes Bild bekommen soll
const thumbnail = "thumbnail";
const klein = "klein";
const mittel = "mittel";
const gross = "gross";

//Stellt die maximale Zeit dar, die eine Benachrichtigung mit der aktuellen implementierung angezeigt werden kann (in ms)
const maxBenachrichtigungsZeit = 99999999999999;

/** Die oberen Zahnnummern in der richtigen Reihenfolge */
let zahnNummernOben = [
  18, 17, 16, 15, 14, 13, 12, 11, 21, 22, 23, 24, 25, 26, 27, 28,
];

/** Die unteren Zahnnummern in der richtigen Reihenfolge */
let zahnNummernUnten = [
  48, 47, 46, 45, 44, 43, 42, 41, 31, 32, 33, 34, 35, 36, 37, 38,
];

//Namen aller Monate, in der richtigen Reihenfolge. Benötigt für die "Kalender" Komponte
const monatsNamen = [
  "Januar",
  "Februar",
  "März",
  "April",
  "Mai",
  "Juni",
  "Juli",
  "August",
  "September",
  "Oktober",
  "November",
  "December",
];

const webName = "web";

const agbTemp =
  "Allgemeine Geschäftsbedingungen für die Nutzung der Web-App mit Benutzerauthentifizierung\n\nGeltungsbereich\nDiese Allgemeinen Geschäftsbedingungen gelten für die Nutzung der Web-App, einschließlich aller damit verbundenen Dienste und Funktionen, die von [Name des Anbieters] bereitgestellt werden.\n\nRegistrierung und Benutzerkonto\nUm die Web-App nutzen zu können, müssen Sie sich registrieren und ein Benutzerkonto erstellen. Die von Ihnen bereitgestellten Informationen müssen wahrheitsgemäß und aktuell sein.\n\nVerantwortung für das Benutzerkonto\nSie sind allein verantwortlich für die sichere Aufbewahrung Ihrer Anmeldeinformationen und für alle Aktivitäten, die über Ihr Benutzerkonto ausgeführt werden.\n\nÄnderungen der Allgemeinen Geschäftsbedingungen\n[Name des Anbieters] behält sich das Recht vor, diese Allgemeinen Geschäftsbedingungen jederzeit zu ändern. Die aktuelle Version wird stets auf der Web-App veröffentlicht.\n\nHaftungsausschluss\n[Name des Anbieters] übernimmt keine Haftung für Schäden, die aus der Nutzung der Web-App entstehen, sofern diese nicht auf einer grob fahrlässigen oder vorsätzlichen Pflichtverletzung von [Name des Anbieters] beruhen.\n\nBeendigung der Nutzung\nSie können Ihr Benutzerkonto jederzeit ohne Angabe von Gründen kündigen. [Name des Anbieters] behält sich das Recht vor, Ihr Benutzerkonto jederzeit und ohne Angabe von Gründen zu kündigen oder zu sperren.\n\nSchlussbestimmungen\nDiese Allgemeinen Geschäftsbedingungen unterliegen dem Recht der Bundesrepublik Deutschland. Gerichtsstand ist [Ort].\n\nStand: [Datum der Veröffentlichung]\n\n";

const strapiDevToken =
  "3d48b2fdadc49bc890993612f01cd2b9a22c9872d8429935d84f7749a14886ce6ca393249529602e8555113e2f173eaadc886c398ec2f984ae3830b3cdfe7e55d226ba0115ac5dd7458dbdef180ff599dd0dc88590c14f4224009ed6e497874e661c926f1e2e741700dcc40b29b62ef8d461a107d9c87069eecbe96b0fe58be1";

const emailPruefRegex =
  /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
const nummerPruefRegex = /^\d+$/;

const iosAppLink = "https://apps.apple.com/de/app/dentasnap/id1625327015";

/** Gibt an, ob sich die App in Entwicklung oder Release Modus befindet. */
const entwicklung = false;

//TODO: Gegen Production Domain ersetzen!
const cmsTld = "https://cms.dentasnap.de";

const standardPraxisAvatar =
  "https://cms.dentasnap.de/uploads/organisation_820a17c126.jpg";

const standardBenutzerProfilBildUrl =
  "https://cms.dentasnap.de/uploads/nutzer_8af95fdf12.jpg";

const standardGeraeteProfilBildUrl =
  "https://cms.dentasnap.de/uploads/geraete_340bdcf864.jpg";

const geloeschterNutzerBild =
  "https://cms.dentasnap.de/uploads/geloscht_de8a9fa3b3.jpg";

const mehrwertSteuerSatz = 1.19;

const anreden = ["Herr", "Frau", "Prof", "Dr", "Prof Dr"];

module.exports = {
  zahnNummernOben,
  zahnNummernUnten,
  eigentuemer,
  verwalter,
  bearbeiter,
  fotograf,
  behandlerFeld,
  albumFeld,
  geteiltesFeld,
  dialogBreite,
  dialogHintergrund,
  platzhalterKontenGrenze,
  platzhalterSpeicherGrenze,
  monatsNamen,
  thumbnail,
  klein,
  mittel,
  gross,
  maxBenachrichtigungsZeit,
  webName,
  agbTemp,
  emailPruefRegex,
  nummerPruefRegex,
  iosAppLink,
  entwicklung,
  strapiDevToken,
  standardBenutzerProfilBildUrl,
  standardGeraeteProfilBildUrl,
  standardPraxisAvatar,
  geloeschterNutzerBild,
  mehrwertSteuerSatz,
  cmsTld,
  anreden,
};
