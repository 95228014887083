<template>
    <OptionenKarte titel="Allgemein">
        <div class="w-full flex flex-col justify-start items-start space-y-4">
            <LokalesDokumentZumDownload
                v-for="dok in dokumente" 
                :key="dok.index"
                :dokument="dok"/>
        </div>
    </OptionenKarte>
</template>

<script>
import OptionenKarte from '@/components/OptionenKarte.vue';
import LokalesDokumentZumDownload from './LokalesDokumentZumDownload.vue';
import { dokumente } from '@/constants/lokale-dokumente';
export default {
    components: {OptionenKarte, LokalesDokumentZumDownload},
    setup() {

        return {dokumente}
    }
}
</script>