const axios = require("axios");

const graylogUrl = "https://log.dentasnap.de/gelf/web";

function graylogPostAnfrage(daten) {
  axios
    .post(graylogUrl, daten)
    .then((_) => console.log("Fehler gemeldet."))
    .catch((error) =>
      console.error("Aufgetretener Fehler konnte nicht gemeldet werden.", error)
    );
}

/**
 * Sendet einen Fehlerlog an den Graylog Input für DentaSnap Web.
 * @param {Error} fehler Aufgetretener Fehler
 * @param {String} kurzbeschreibung Kurze Zusammenfassung des Fehlers
 * @param {String} methode Methode, in der der Fehler aufgetreten ist
 * @param {String} datei Datei, in der die Methode implementiert ist, in der der Fehler aufgetreten ist.
 */
function fehlerSenden(fehler, kurzbeschreibung, methode, datei) {
  const gelfMessage = {
    version: "1.1",
    host: "web",
    level: 3,
    full_message: fehler,
    short_message: kurzbeschreibung,
    timestamp: Date.now() / 1000,
    _method: methode,
    _file: datei,
    _userAgent: userAgentAbfragen(),
  };

  graylogPostAnfrage(gelfMessage);
}

/**
 * Gibt den User-Agent String zurück, der Informationen zum verwendeten Browser und
 * @returns {String} User-Agent, generiert durch den Browser des Nutzers
 */
function userAgentAbfragen() {
  return navigator.userAgent;
}

export { fehlerSenden };
