<template>
    <div class="w-full h-screen flex justify-start items-center p-5 bg-white">
        <OnboardingFortschrittsAnzeige class="w-1/3 h-full mr-40" :schritte="titel" :index="index" :laedt="laedt" />

        <component :is="komponente" :laedt="laedt" :patient="patient" :faelle="faelle"
            :aktivePartnerschaften="aktivePartnerschaften" :fall="fall" :schritt="schritt" :zaehne="zaehne" :themen="themen"
            :ordner="ordner" :fotos="fotos" :gewaehltePartnerschaften="partnerschaften" :bearbeitungsModus="true"
            @navigieren="navigieren" @fotosAktualisiert="f => fotos = f" @patientAktualisiert="p => patient = p"
            @fallAktualisiert="f => fall = f" @faelleAktualisiert="f => faelle = f" @schrittAktualisiert="s => schritt = s"
            @zaehneAktualisiert="z => zaehne = z" @themenAktualisiert="t => themen = t"
            @ordnerAktualisiert="o => ordner = o" @partnerschaftenAktualisiert="p => partnerschaften = p">
        </component>
    </div>
</template>
<script>
import { computed, onMounted, ref, watch } from 'vue';
import OnboardingFortschrittsAnzeige from '@/components/OnboardingFortschrittsAnzeige.vue';
import Patient from '../session-hochladen/components/patient/Patient.vue';
import Fall from '../session-hochladen/components/fall/Fall.vue';
import SchrittUndZaehne from '../session-hochladen/components/schritt-und-zaehne/SchrittUndZaehne.vue';
import ThemenUndOrdner from '../session-hochladen/components/themen-und-ordner/ThemenUndOrdner.vue';
import Fotos from '../session-hochladen/components/fotos/Fotos.vue';
import Partnerschaften from '../session-hochladen/components/partnerschaften/Partnerschaften.vue';
import { useRoute } from 'vue-router';
import store from '@/store';
import { einmaligenStringGenerieren, faelleFuerPatientAbfragen, patientHochladen } from '@/services/firestoreDienst';
import { useCloudFunction } from '@/services/cloudFunctionDienst';
import { erfolgAnzeigen, fehlerAnzeigen } from '@/services/toastDient';
import { router } from '@/router';
import { aufnahmeHochladen } from '@/services/helfer';
export default {
    components: {
        OnboardingFortschrittsAnzeige,
        Patient,
        Fall,
        SchrittUndZaehne,
        ThemenUndOrdner,
        Fotos,
        Partnerschaften
    },
    props: ["sessionId", "typ"],
    setup(props, context) {
        const index = ref(0)
        const laedt = ref(false)

        const faelle = ref([])

        const fotos = ref([])
        const patient = ref(null)
        const fall = ref(null)
        const zaehne = ref([])
        const schritt = ref(null)
        const themen = ref([])
        const ordner = ref([])
        const partnerschaften = ref([])

        const route = useRoute()

        const bearbeitungsTyp = computed(() => route.params.typ)

        const bearbeiteteInformationen = computed(() => {
            const session = store.state.sessions.find(s => s.id === props.sessionId)

            const sessionThemen = session.themen.filter(t => store.state.themen.find(st => st.id === t).standard === true)
            const sessionOrdner = session.themen.filter(t => store.state.themen.find(st => st.id === t).standard === false)

            console.log(themen.va);
            console.log(sessionOrdner);

            const themaVorhanden = themen.value != null && themen.value.length > 0
            const ordnerVorhanden = ordner.value != null && ordner.value.length > 0

            return {
                fotos: fotos.value != null && fotos.value.length > 0,
                patient: patient.value != null && patient.value.id !== session.patient,
                fall: typeof fall.value === "string" && fall.value !== session.fallId,
                zaehne: zaehne.value != null && zaehne.value.length > 0 && sindZahnListenIdentisch(zaehne.value, session.zaehne) === false,
                schritt: typeof schritt.value === "number" && schritt.value > 0 && schritt.value !== session.schritt,
                themen: ordnerVorhanden === true || (themen.value != null && themen.value.length > 0 && sindStringListenIdentisch(sessionThemen, themen.value.map(t => t.id)) === false),
                ordner: themaVorhanden === true || (ordner.value != null && ordner.value.length > 0 && sindStringListenIdentisch(sessionOrdner, ordner.value.map(t => t.id)) === false),
            }
        })

        watch(bearbeiteteInformationen, nv => console.log("State Aktualisiert: ", nv))

        const titel = computed(() => {
            var erg = []
            var titel
            var untertitel

            switch (bearbeitungsTyp.value) {
                case "informationen":
                    for (let i = 0; i < 4; i++) {
                        switch (i) {
                            case 1:
                                titel = "Fall"
                                untertitel = "Wählen Sie einen neuen Fall"
                                break;
                            case 2:
                                titel = "Schritt & Lokalisierung"
                                untertitel = "Wählen Sie einen anderen Schritt oder andere Zähne"
                                break;
                            case 3:
                                titel = "Themen & Ordner"
                                untertitel = "Wählen Sie neue Themen oder neue Ordner"
                                break;
                            default:
                                titel = "Patient"
                                untertitel = "Wählen Sie einen neuen Patienten"
                                break
                        }
                        erg.push({
                            index: i,
                            titel: titel,
                            untertitel: untertitel,
                        })
                    }
                    break
                case "partner":
                    erg.push({
                        index: 0,
                        titel: "Zusammenarbeiten",
                        untertitel: "Ändern sie die Partner, mit denen Sie die Session teilen"
                    })
                    break;
                case "fotos":
                    erg.push({
                        index: 0,
                        titel: "Fotos Hinzufügen",
                        untertitel: "Fügen Sie der Session zusätzliche Fotos hinzu"
                    })
                    break;
            }

            return erg
        })

        const komponente = computed(() => {
            switch (bearbeitungsTyp.value) {
                case "fotos":
                    return Fotos
                case "partner":
                    return Partnerschaften
                default:
                    switch (index.value) {
                        case 1:
                            return Fall
                        case 2:
                            return SchrittUndZaehne
                        case 3:
                            return ThemenUndOrdner
                        default:
                            return Patient
                    }
            }
        })

        const aktivePartnerschaften = computed(() => {
            if (store.state.partnerschaften != null) {
                return store.state.partnerschaften.filter(p => p.aktiv === true)
            } else {
                return []
            }
        })

        function sindStringListenIdentisch(a, b) {
            // Compare lengths first
            if (a.length !== b.length) {
                return false;
            }

            // Sort both lists
            a.sort();
            b.sort();

            // Compare sorted lists element by element
            for (let i = 0; i < a.length; i++) {
                if (a[i] !== b[i]) {
                    return false;
                }
            }

            return true;
        }

        /**
         * Helfer zum Prüfen, ob zwei Zahnlisten identisch sind oder nicht
         * @param {{nummer: number, implantat: boolean}[]} a Erste Liste
         * @param {{nummer: number, implantat: boolean}[]} b Zweite Liste
         * @returns {boolean} Ob beide Listen identisch sind, oder nicht
         */
        function sindZahnListenIdentisch(a, b) {
            // Compare lengths first
            if (a.length !== b.length) {
                return false;
            }

            // Sort both lists based on the 'nummer' property
            a.sort((a, b) => a.nummer - b.nummer);
            b.sort((a, b) => a.nummer - b.nummer);

            // Compare each object in the lists
            for (let i = 0; i < a.length; i++) {
                if (a[i].nummer !== b[i].nummer || a[i].implantat !== b[i].implantat) {
                    return false;
                }
            }

            return true;
        }

        function initialisieren() {
            const session = store.state.sessions.find(s => s.id === props.sessionId)

            const sp = store.state.patienten.find(p => p.id === session.patient)
            if (sp) {
                patient.value = {
                    id: sp.id,
                    nummer: sp.nummer,
                    neu: false
                }

                faelleFuerPatientAbfragen(patient.value.id)
                    .then(f => faelle.value = f)
            }

            fall.value = session.fallId

            schritt.value = session.schritt
            zaehne.value = session.zaehne

            themen.value = store.state.themen.filter(t => session.themen.some(tId => tId === t.id && t.standard === true))
            ordner.value = store.state.themen.filter(t => session.themen.some(tId => tId === t.id && t.standard === false))

            var erg = []

            Object.values(session.teilnehmer).forEach(tn => {
                tn.zugriff.forEach(z => {
                    //Zugriffsstrings werden durchlaufen
                    const p = store.state.partnerschaften.find(p => p.id === z)
                    if (p) {
                        erg.push(p)
                    }
                })
            })

            if (erg.length > 0) {
                partnerschaften.value = erg
            }
        }

        const { pending: bearbeitenLaedt, error: bearbeitenFehler, call: bearbeitenAufrufen } = useCloudFunction("sessionBearbeiten")
        const { pending: aktualisierenLaedt, error: aktualisierenFehler, call: aktualisierenAufrufen } = useCloudFunction("sessionAktualisieren")

        async function infosBearbeiten() {
            //Aufruf funktioniert nur, falls mindestens eine Änderung vorliegt
            if (Object.values(bearbeiteteInformationen.value).some(v => v === true) === false) {
                return
            }

            laedt.value = true

            //Falls der Patient bearbeitet wird und ein neuer erstellt wurde, wird er hier zunächst hochgeladen
            if (bearbeiteteInformationen.value.patient === true && patient.value.neu === true) {
                const patientObj = {
                    id: einmaligenStringGenerieren(),
                    nummer: patient.value.nummer,
                    eigentuemer: store.getters.praxisAbfragen.id,
                }

                await patientHochladen(patientObj)

                //Für den Upload wird die ID des neuen Patienten auch lokal gesetzt!
                patient.value.id = patientObj.id
            }

            //Das Updateobjekt wird anhand aller bearbeiteten Felder erstellt
            var update = { id: props.sessionId }
            if (bearbeiteteInformationen.value.schritt === true) {
                update.schritt = schritt.value
            }

            if (bearbeiteteInformationen.value.patient === true) {
                update.patient = patient.value.id
            }

            if (bearbeiteteInformationen.value.fall === true) {
                update.fallId = fall.value
            }

            if (bearbeiteteInformationen.value.zaehne === true) {
                update.zaehne = zaehne.value
            }

            var themenUpdate = []
            if (bearbeiteteInformationen.value.themen === true) {
                themenUpdate.push(...themen.value.map(t => t.id))
            }

            if (bearbeiteteInformationen.value.ordner === true) {
                themenUpdate.push(...ordner.value.map(t => t.id))
            }

            if (themenUpdate.length > 0) {
                update.themen = themenUpdate
            }

            //Die Session wird mit allen aktualisierten Feldern aktualisiert
            await bearbeitenAufrufen(update)

            if (bearbeitenFehler.value === false) {
                erfolgAnzeigen("Informationen aktualisiert.")
            } else {
                fehlerAnzeigen("Fehler beim Aktualisieren der Informationen.")
            }

            router.back()
            laedt.value = false
        }

        async function partnerBearbeiten() {
            laedt.value == true

            if (partnerschaften.value == null) {
                partnerschaften.value = []
            }
            const pIds = partnerschaften.value.map(p => p.id)

            await bearbeitenAufrufen({ id: props.sessionId, partnerschaften: pIds })

            if (bearbeitenFehler.value === false) {
                erfolgAnzeigen("Partner aktualisiert.")
            } else {
                fehlerAnzeigen("Fehler beim Aktualisieren der Partner")
            }

            router.back()
            laedt.value = false
        }


        async function fotosHinzufuegen() {
            if (bearbeiteteInformationen.value.fotos === false) {
                return
            }

            laedt.value = true

            for (let i = 0; i < fotos.value.length; i++) {
                //Die Aufnahme wird hochgeladen, und ein passender Snap generiert und ebenfalls hochgeladen
                await aufnahmeHochladen(fotos.value[i], props.sessionId);
            }

            await aktualisierenAufrufen({ id: props.sessionId })

            if (aktualisierenFehler.value === false) {
                erfolgAnzeigen("Fotos hinzugefügt.")
            } else {
                fehlerAnzeigen("Fehler beim hinzufügen ")
            }

            router.back()

            laedt.value = false
        }

        function navigieren(forwarts) {
            if (forwarts === true) {
                switch (bearbeitungsTyp.value) {
                    case "informationen":
                        if (index.value == 0 && (faelle.value == null || faelle.value.length == 0)) {
                            index.value = 2
                        } else if (index.value == 3) {
                            infosBearbeiten()
                        } else if (index.value < 3) {
                            index.value++
                        }
                        break;
                    case "partner":
                        partnerBearbeiten()
                        break;
                    case "fotos":
                        fotosHinzufuegen()
                        break;
                    default:
                        break;
                }
            } else {
                if (bearbeitungsTyp.value === "informationen") {
                    if (index.value == 2 && (faelle.value == null || faelle.value.length == 0)) {
                        index.value = 0
                    } else if (index.value > 0) {
                        index.value--
                    }
                }
            }
        }

        onMounted(initialisieren)

        return {
            index,
            laedt,
            aktivePartnerschaften,
            fotos,
            patient,
            fall,
            faelle,
            zaehne,
            schritt,
            themen,
            ordner,
            partnerschaften,
            titel,
            komponente,
            navigieren
        }
    }
}
</script>