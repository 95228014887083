<template>
    <EingabeDialog :state="state" titel="Ordner Erstellen" body="Ordner sind nur innerhalb Ihrer Organisation sichtbar."
        :laedt="laedt" bestaetigungsText="Erstellen" :bestaetigungAktiv="nameGueltig" @bestaetigt="themaErstellen"
        @abgebrochen="schliessen">
        <form class="w-72" @submit.prevent @keydown.enter.prevent="themaErstellen">
            <input type="text" class="default-textfield-dynamic-length" ref="nameRef" v-model="name"
                placeholder="Ordner Name">
        </form>
    </EingabeDialog>
</template>

<script>
//Alle benötigten Vue-Frameworks und Komponenten werden importiert
import { ref } from '@vue/reactivity'
import { computed } from '@vue/runtime-core'
import store from '@/store'

//Alle benötigten Konstanten und Helferfunktionen werden importiert
import { useCloudFunction } from '@/services/cloudFunctionDienst'
import EingabeDialog from '@/components/dialoge/EingabeDialog.vue'
import { watch } from 'vue'

export default {
    props: ["state"],
    emits: ["schliessen"],
    components: { EingabeDialog, EingabeDialog },
    setup(props, context) {

        /** Speichert den eingegebenen Namen für das Thema */
        const name = ref("")

        const nameRef = ref(null)

        const laedt = ref(false)

        /** Ein Name ist gültig, wenn:
         * - Er nicht leer ist
         * - Kein anderes Thema mit gleichem Name bereits existiert
         */
        const nameGueltig = computed(() => name.value != "" && store.state.themen.find(t => t.name == name.value && t.eigentuemer == store.getters.praxisAbfragen.id) == undefined)

        const { pending: erstellenLaed, error: erstellenFehler, call: erstellen } = useCloudFunction("themaErstellen")

        function themaErstellen() {
            if (name.value != "") {
                laedt.value = true
                erstellen({ name: name.value })
                    .then(() => {
                        if (!erstellenFehler.value) {
                            schliessen()
                        }
                    })
                    .finally(() => laedt.value = false)
            }
        }

        watch(() => props.state, val => {
            if (val === true) {
                setTimeout(() => {
                    if (nameRef.value) {
                        nameRef.value.focus()
                    }
                }, 100)
            }
        })

        function schliessen() {
            name.value = ""
            context.emit("schliessen")
        }

        return {
            //State für das UI
            name,
            nameRef,
            laedt,
            erstellenLaed,
            nameGueltig,

            //Funktionen für das UI
            themaErstellen,
            schliessen
        }
    }
}
</script>