<template>
    <div class="w-full h-full flex justify-center items-center">
        <PraxisLogoHinzufuegen @weiter="erfolgsDialogAnzeigen" :einzeln="true" />
    </div>
</template>
<script>
import PraxisLogoHinzufuegen from '@/pages/konto-konfiguration/components/praxis-logo-hinzufuegen/PraxisLogoHinzufuegen.vue';
import { erfolgAnzeigen } from '@/services/toastDient';
export default {
    components: {
        PraxisLogoHinzufuegen
    },
    setup() {

        /** Nachdem in der Komponente eine Änderung durchgeführt wurde, wird ein Erfolgsdialog angezeigt */
        function erfolgsDialogAnzeigen() {
            erfolgAnzeigen("Änderung durchgeführt!")
        }

        return {
            erfolgsDialogAnzeigen
        }
    }
}
</script>