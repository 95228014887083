<template>
  <div class="w-full h-full flex flex-col justify-start items-center">
    <div
      class="w-full bg-Hintergrund rounded-lg pt-10 pb-5 flex flex-col justify-center items-center"
    >
      <!-- Titelicon -->
      <ion-icon name="images-outline" class="text-5xl"></ion-icon>

      <!-- Zähler und Löschen-Button -->
      <div v-if="aufnahmen.length > 0" class="flex my-3">
        <p class="mr-3">{{ aufnahmen.length }} Aufnahmen ausgewählt</p>
        <div
          class="px-2 py-1 rounded-full bg-WarningHintergrund text-xs text-white transition duration-150 hover:cursor-pointer hover:bg-opacity-70"
          @click="aufnahmenZuruecksetzen"
        >
          <p>Löschen</p>
        </div>
      </div>
      <p v-else class="my-3">Keine Aufnahmen gewählt.</p>

      <!-- "Hinzufügen Button" -->
      <input
        type="file"
        name="file"
        id="file"
        class="inputfile"
        accept="image/png, image/jpg, image/jpeg"
        multiple
        @change="aufnahmenGewaehlt"
      />
      <label
        class="py-2 px-6 bg-white rounded-full text-DentaBlue text-sm transition duration-150 hover:cursor-pointer hover:bg-opacity-50"
        for="file"
      >
        {{ aufnahmen.length > 0 ? "Weitere Aufnahmen" : "Aufnahmen Wählen" }}
      </label>
    </div>

    <div class="w-full h-full flex justify-center items-start">
      <PatientWahlFeld
        class="mt-8"
        :patient="patient"
        :neuErlaubt="true"
        @existierend="patientGewaehlt"
        @neu="patientErstellt"
      />
    </div>
  </div>
</template>

<script>
//Import aller eigenen Komponenten
import PatientWahlFeld from "@/components/PatientWahlFeld.vue";

//Import aller benötigten Frameworks und Vue-Komponenten
import { ref } from "@vue/reactivity";
import { computed, onMounted } from "@vue/runtime-core";
import store from "@/store";

export default {
  components: { PatientWahlFeld },
  props: ["aufnahmen", "patient"],
  emits: ["aufnahmeGewaehlt", "patientGewaehlt", "neuerPatient"],
  setup(props, context) {
    /** Suchbegriff im Patientenfeld, oder null wenn ein patient ausgewählt ist. */
    let suchbegriff = ref("");

    /** Speichert die vom Nutzer ausgewählten Aufnahmen */
    let aufnahmen = ref([]);

    /** Speichert den ausgewählten Patienten, oder null wenn keine wahl getroffen ist. */
    let patient = ref(props.patient);

    /** Gibt die Liste aller Patienten zurück, die der Praxis gehören. */
    let patienten = computed(() => store.state.patienten);

    /** Referenz zum DOM Element, dass die Dateiwahl ermöglicht */
    var input = null;
    onMounted(() => {
      input = document.getElementById("file");
      input.onclick = function () {
        this.value = null;
      };
    });

    let suchergebnisse = computed(() => {
      if (suchbegriff.value != "" && patienten.value.length > 0) {
        return patienten.value.filter((p) =>
          p.nummer.includes(suchbegriff.value)
        );
      }
    });

    function patientGewaehlt(wert) {
      patient.value = wert;
      context.emit("patientGewaehlt", patient.value);
    }

    function patientErstellt(wert) {
      patient.value = wert;
      context.emit("neuerPatient", wert);
    }

    function patientZuruecksetzen() {
      patient.value = null;
      suchbegriff.value = "";
      context.emit("patientGewaehlt", patient.value);
    }

    function aufnahmenGewaehlt(event) {
      //Aus der liste der gewählten Dateien werden alle herausgefiltert, die nicht den akzeptierten Formaten entsprechen.
      let dateien = Array.from(event.target.files).filter(
        (f) =>
          f.type === "image/jpg" ||
          f.type === "image/jpeg" ||
          f.type === "image/png"
      );
      for (let i = 0; i < dateien.length; i++) {
        aufnahmen.value.push(dateien[i]);
      }

      if (aufnahmen.value.length && aufnahmen.value.length > 0) {
        context.emit("aufnahmeGewaehlt", aufnahmen.value);
      }
    }

    function aufnahmenZuruecksetzen() {
      aufnahmen.value = [];
      context.emit("aufnahmeGewaehlt", aufnahmen.value);
    }

    return {
      //State
      suchbegriff,
      patienten,
      patient,
      aufnahmen,
      suchergebnisse,
      input,

      //Funktionen
      patientGewaehlt,
      patientErstellt,
      patientZuruecksetzen,
      aufnahmenGewaehlt,
      aufnahmenZuruecksetzen,
    };
  },
};
</script>