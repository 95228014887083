<template>
    <div class="w-full flex flex-col justify-start items-start font-dm-sans">
      <button
        v-if="hatZurueckButton === true" 
        class="flex space-x-2 justify-start items-center text-DentaBlue text-sm"
        @click="zurueckGeklickt">
        <img src="@/assets/icons/pfeil-links-blau.svg" alt="<" class="h-3 aspect-square object-contain">
        <p>Zurück</p>
      </button>
      <p class="mt-4 mb-2 text-4xl font-semibold text-start">{{ titel }}</p>
      <p class="text-MittelGrau font-thin text-start">{{ untertitel }}</p>
    </div>
  </template>
  
  <script>
  export default {
    props: ["hatZurueckButton","titel", "untertitel"],
    emits: ["zurueck"],
    setup(_, context) {
      const zurueckGeklickt = () => context.emit("zurueck")
      return {zurueckGeklickt}
    }
  };
  </script>