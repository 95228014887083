const kontoKonfiguration = "kontoKonfiguration";
const registrierungsLink = "registrierungsLink";

const emailBestaetigen = "emailBestaetigen";
const emailAendern = "emailAendern";
const agbErklaerung = "agbErklaerung";
const profilAngaben = "profilAngaben";
const profilBildHochladen = "profilBildHochladen";
const passwortErstellen = "passwortErstellen";
const telefonnummerAngeben = "telefonnummerAngeben";
const mfaVerifizierung = "mfaVerifizierung";
const praxisAngaben = "praxisAngaben";
const mitarbeiterEinrichten = "mitarbeiterEinrichten";

const ungueltigeAnmeldung = "ungueltigeAnmeldung";
const passwortAktualisiert = "passwortAktualisiert";
const ungueltigerLink = "ungueltigerLink";

module.exports = {
  kontoKonfiguration,
  registrierungsLink,
  emailBestaetigen,
  emailAendern,
  agbErklaerung,
  profilAngaben,
  profilBildHochladen,
  passwortErstellen,
  telefonnummerAngeben,
  mfaVerifizierung,
  praxisAngaben,
  mitarbeiterEinrichten,
  ungueltigeAnmeldung,
  passwortAktualisiert,
  ungueltigerLink,
};
