<template>
    <div class="w-full flex bg-white rounded-md py-5 my-4">
        <!-- Linker Teil -->
        <div class="w-3/12 flex justify-start items-center px-5">
            <img v-if="profilBild != null" :src="profilBild" alt="avatar" class="w-10 h-10 object-cover rounded-full mr-5">
            <div v-else class="w-10 h-10 bg-LeichtesGrau bg-opacity-30 rounded-full mr-5"></div>

            <p class="text-xl">{{ name }}</p>
        </div>

        <!-- Rechter Teil -->
        <div class="w-9/12 flex justify-between items-center text-Mittelgrau">
            <Checkbox v-for="berechtigung in berechtigungsListe" :key="berechtigung"
                :startWert="hatBerechtigung(berechtigung)" :inaktiv="istEigentuemer || istDeaktiviert"
                @gewaehlt="(wert) => aktualisieren(berechtigung, wert)" />
        </div>
    </div>
</template>

<script>
import { ref } from '@vue/reactivity';

//Import aller benötigten eigenen Komponenten
import Checkbox from './Checkbox.vue';
import { eigentuemer } from "@/constants/berechtigungen"

//Import aller benötigten Helfer und Konstanten
const { berechtigungsListe } = require("@/constants/berechtigungen")
import { nameAbfragen } from '@/services/authentifizierungsDienst';
import { computed, onMounted } from 'vue';
import { nutzerProfilbildAbfragen } from '@/services/helfer';
export default {
    components: {
        Checkbox
    },

    props: ["konto"],
    emits: ["aenderung"],

    setup(props, context) {
        const profilBild = ref(null)

        //Speichert die originale Version der Berechtigungen des Nutzers, alphabetisch geordnet.
        const berechtigungenCache = JSON.parse(JSON.stringify(props.konto.berechtigungen))

        //Alle Änderungen werden hier gespeichert, und die liste dann als event ausgegeben
        const neueBerechtigungen = ref([])

        if (berechtigungenCache) {
            for (let berechtigungsIndex = 0; berechtigungsIndex < berechtigungenCache.length; berechtigungsIndex++) {
                neueBerechtigungen.value.push(berechtigungenCache[berechtigungsIndex])
            }
        }

        //Wird bei der auswahl einer Berechtigung aufgerufen.
        const aktualisieren = (berechtigung, hinzugefuegt) => {
            if (neueBerechtigungen.value && istEigentuemer == false) {
                if (hinzugefuegt == true && neueBerechtigungen.value.find(b => b == berechtigung) == undefined) {
                    neueBerechtigungen.value.push(berechtigung)
                } else if (hinzugefuegt == false && neueBerechtigungen.value.find(b => b == berechtigung) != undefined) {
                    neueBerechtigungen.value = neueBerechtigungen.value.filter(b => b != berechtigung)
                }
                context.emit("aenderung", neueBerechtigungen.value)
            }
        }

        //Gibt an, ob das Konto Eigentümer ist.
        const istEigentuemer = props.konto.berechtigungen.find(b => b == eigentuemer) != undefined

        const istDeaktiviert = props.konto.deaktiviert == true

        //Gibt an, ob das gegebene Konto die Berechtigung hat oder nicht.
        const hatBerechtigung = (berechtigung) => props.konto.berechtigungen.find(b => b == berechtigung) != undefined ||
            props.konto.berechtigungen.find(b => b == eigentuemer) != undefined

        const name = computed(() => {
            if (typeof props.konto.nutzerName === "string") {
                return nameAbfragen(props.konto.nutzerName)
            } else {
                return props.konto.email
            }
        })

        onMounted(() => {
            //Abfrage des Profilbilds
            if (profilBild.value == null) {
                nutzerProfilbildAbfragen(props.konto.uid, false)
                    .then(url => profilBild.value = url.url)
            }
        })

        return {
            //State für das UI
            berechtigungsListe,
            istEigentuemer,
            istDeaktiviert,
            name,
            profilBild,

            //Funktionen für das UI
            hatBerechtigung,
            aktualisieren,
            nameAbfragen
        }
    }
}
</script>

<style>

</style>