<template>
  <div class="w-full h-screen flex bg-white" :class="{
    'justify-between items-center pl-32 py-7 pr-7': istMobile === false,
    'flex-col justify-start items-start space-y-3 p-5': istMobile === true
  }">

    <img v-if="istMobile" src="@/assets/laptop-home-quadrat.jpg" class="w-full aspect-creditcard rounded-lg object-cover">


    <div class="w-full h-screen flex flex-col space-y-5" :class="{
      'justify-between items-start py-20': istMobile === false,
      'justify-start items-start py-10': istMobile === true
    }">
      <!-- Logo -->
      <img src="@/assets/logo_text.png" class="w-32">

      <!-- Aktuelle Komponente -->
      <div class="w-full h-full flex justify-between items-center">
        <Transition name="slide-fade" mode="out-in">
          <component ref="aktuelleKomponente" :is="komponenteFuerIndex" :laedt="laedt" :mfaFehler="mfaFehler"
            :email="email" @emailAktualisiert="emailAktualisieren" @passwortAktualisiert="passwortAktualisieren"
            @passwortVergessen="passwortVergessenSetzen" @linkAngefragt="passwortZurücksetzenLinkAngefragtSetzen"
            @codeEingegeben="mfaVerifizierungAbschliessen" @anmelden="anmeldungVersuchen" @zurueck="index = 0">
          </component>
        </Transition>

        <!-- Leerer Container für den "reCAPTCHA Verifier" -->
        <div id="re-captcha-container"></div>
      </div>

      <!-- Leerer Container für gleichmäßige Verteilung -->
      <div></div>
    </div>

    <img v-if="!istMobile" src="@/assets/laptop-home-quadrat.jpg" class="h-full aspect-square rounded-lg">
  </div>
</template>

<script setup>
//Import aller benötigten Frameworks und Vue-Komponenten
import { ref, computed } from "@vue/reactivity";

//Import aller benötigten eigenen Komponenten
import EmailUndPasswort from "./components/EmailUndPasswort.vue";
import Mfa from "./components/Mfa.vue";
import PasswortVergessen from "./components/PasswortVergessen.vue";
import PasswortZurücksetzenLinkAngefragt from "./components/PasswortZurücksetzenLinkAngefragt.vue";

//Import aller benötigten Helferfunktionen und Konstanten
import {
  authError,
  perEmailAnmelden,
} from "@/services/authentifizierungsDienst";
import { mfaCodeAnfragen, mfaCodeVerifizieren } from "@/services/mfaDienst";
import { RecaptchaVerifier } from "@firebase/auth";
import { auth } from "@/firebase/config";
import { router } from "@/router";
import { anmeldeFehlerAnzeigen } from "@/services/toastDient"
import store from "@/store";

const istMobile = computed(() => store.state.mobile)

const laedt = ref(false)

//E-Mail und Passwort Werte, die aktuell eingegeben sind
const email = ref("");
const passwort = ref("");

const aktuelleKomponente = ref(null)

const emailAktualisieren = (wert) => (email.value = wert);
const passwortAktualisieren = (wert) => (passwort.value = wert);

//Speichert Fehlermeldungen, die dem Nutzer angezeigt werden sollen.
const mfaFehler = ref(null);

//Startet per E-Mail und Passwort einen Anmeldeversuch. Wenn der Nutzer die 2-FA eingeschaltet hat, wird ein entsprechender Fehler geworfen und verarbeitet.
const anmeldungVersuchen = async () => {
  if (email.value != "" && passwort.value != "") {
    laedt.value = true
    //Die Anmeldung wird gestartet
    await perEmailAnmelden(email.value, passwort.value);

    if (authError.value != null) {
      emailPasswortFehlerAnzeigen(authError.value)
    } else {
      //Anmeldung Erfolgreich
      anmeldungErfolgreich()
      laedt.value = false
    }
  }
};

//Startet die reCAPTCHA Verifizierung und fragt dann einen MFA Code an.
const mfaVerifizierungStarten = () => {
  laedt.value = true
  const reCaptchaVerifier = new RecaptchaVerifier(
    "re-captcha-container",
    {
      size: "invisible",
      callback: () => {
        mfaCodeAnfragen(reCaptchaVerifier, authError.value).then((ergebnis) => {
          indexErhoehen();
          laedt.value = false;
        });
      },
    },
    auth
  );

  reCaptchaVerifier.verify();
};

//Sendet den eingegebenen Code zur Verifizierung, und gibt dann
const mfaVerifizierungAbschliessen = async (code) => {
  try {
    laedt.value = true;
    await mfaCodeVerifizieren(code);
    //Nach erfolgreicher MFA Verifizierung ist die Anmeldung erfolgreich
    anmeldungErfolgreich()
  } catch (error) {
    mfaFehlerAnzeigen(error);
  }
  laedt.value = false;
};

//Wird nach erfolgreicher Anmeldung mit oder ohne MFA aufgerufen
const anmeldungErfolgreich = () => router.push({ path: "/" })

/**
 * Zeigt einen Toast für den Fehler im ersten Schritt, und setzt die entsprechenden Felder zurück
 * @param {*} error Grundlegender Fehler
 */
function emailPasswortFehlerAnzeigen(error) {
  if (error.code == "auth/multi-factor-auth-required") {
    //Falls der Fehler für die MFA Verifizierung geworfen wurde, wird der Prozess begonnen
    mfaVerifizierungStarten()
  } else {
    var fehler
    switch (error.code) {
      case "auth/user-not-found":
        fehler = "Der Nutzer existiert nicht.";
        passwortZuruecksetzen()
        emailZuruecksetzen()
        break;
      case "auth/wrong-password":
        fehler = "Das angegebene Passwort ist falsch.";
        passwortZuruecksetzen()
        break;
      case "auth/user-disabled":
        fehler = "Der Nutzer ist deaktiviert.";
        passwortZuruecksetzen()
        emailZuruecksetzen()
        break;
      case "auth/too-many-requests":
        fehler = "Die Anmeldung wurde aufgrund wiederholter falscher Eingaben gesperrt. Bitte Versuchen Sie es in einigen Minuten erneut."
        passwortZuruecksetzen()
        emailZuruecksetzen()
        break;
      default:
        fehler = "Ein Fehler ist aufgetreten."
    }
    laedt.value = false
    anmeldeFehlerAnzeigen(fehler)
  }
}

/**
 * Zeigt einen Toast für den Fehler an, und leert das MFA Feld
 * @param {*} error Grundlegender Fehler
 */
function mfaFehlerAnzeigen(error) {
  var fehler;
  switch (error.code) {
    case "auth/invalid-verification-code":
      fehler = "Der eingegebene Code ist ungültig.";
      break;
    case "auth/missing-verification-code":
      fehler = "Bitte geben Sie einen Code ein.";
      break;
    case "auth/code-expired":
      fehler =
        "Der Code ist abgelaufen, bitte versuchen Sie es erneut.";
      break;
    default:
      fehler = "Ein Fehler ist aufgetreten.";
      break;
  }
  mfaZuruecksetzen()
  anmeldeFehlerAnzeigen(fehler)
}

function emailZuruecksetzen() {
  if (aktuelleKomponente.value && aktuelleKomponente.value.emailZuruecksetzen) {
    aktuelleKomponente.value.emailZuruecksetzen()
  }
}

function passwortZuruecksetzen() {
  if (aktuelleKomponente.value && aktuelleKomponente.value.passwortZuruecksetzen) {
    aktuelleKomponente.value.passwortZuruecksetzen()
  }
}

function mfaZuruecksetzen() {
  if (aktuelleKomponente.value && aktuelleKomponente.value.ziffernFeldZuruecksetzen) {
    aktuelleKomponente.value.ziffernFeldZuruecksetzen()
  }
}

//----------------
// State und Funktionen für Carousel
//----------------

//Gibt den Index der aktuellen Komponente an
const index = ref(0);

//Gibt an, ob der Index erhöht oder verringert wird. Wird für die Übergangsanimation benötigt
const wirdVerringert = ref(false);

const eingangsPosition = computed(() =>
  wirdVerringert.value ? "translateX(10px)" : "translateX(-10px)"
);
const ausgangsPosition = computed(() =>
  wirdVerringert.value ? "translateX(-10px)" : "translateX(10px)"
);

//Gibt die Karte für den aktuellen Index an.
const komponenteFuerIndex = computed(() => {
  switch (index.value) {
    case 1:
      return Mfa;
    case 2:
      return PasswortVergessen;
    case 3:
      return PasswortZurücksetzenLinkAngefragt;
    default:
      return EmailUndPasswort;
  }
});

const indexErhoehen = () => {
  if (index.value < 1) {
    wirdVerringert.value = false;
    index.value++;
  }
};

const indexVerringern = () => {
  if (index.value > 0) {
    wirdVerringert.value = true;
    index.value--;
  }
};

const passwortVergessenSetzen = () => {
  if (index.value != 2) {
    wirdVerringert.value = false
    index.value = 2
  }
}

const passwortZurücksetzenLinkAngefragtSetzen = () => {
  if (index.value != 3) {
    wirdVerringert.value = false
    index.value = 3
  }
}

</script>

<!-- Styles needed for transition animation -->
<style scoped>
.slide-fade-enter-active {
  transition: all 0.1s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.1s ease-in;
}

.slide-fade-enter-from {
  transform: v-bind(ausgangsPosition);
  opacity: 0.5;
}

.slide-fade-enter-to {
  transform: translateX(0px);
  opacity: 1;
}

.slide-fade-leave-from {
  transform: translateX(0px);
  opacity: 1;
}

.slide-fade-leave-to {
  transform: v-bind(eingangsPosition);
  opacity: 0.5;
}
</style>