<!-- Seite für Verwaltung von Praxisdaten und Nutzern.  -->

<template>
  <div class="standard-seite">
    <div class="w-full flex justify-between items-center">
      <PageTitle title="Organisation" />
      <AktionsButtonPrimaer iconName="settings-outline" @gedrueckt="praxisVerwaltungOeffnen" />
    </div>
    <div class="w-full h-full flex">
      <!-- Benutzer & Geräte -->
      <div class="w-full h-full flex flex-col space-y-8">
        <!-- Benutzer -->
        <div class="w-full flex flex-col space-y-3 pr-10">
          <p class="tracking-wide font-bold" v-if="benutzer != null && benutzer.length">Benutzer</p>
          <Benutzer v-for="(wert, index) in benutzer" :key="wert.uid" :benutzer="wert"
            :last="index == benutzer.length - 1" />
          <!-- Instead of constant "true" add logic here to check if the index of the element is even (as in mod 2 == 0) -->
        </div>

        <!-- Geräte -->
        <div v-if="istPraxis === true && geraete.length > 0" class="w-full flex flex-col space-y-3 pr-10">
          <p class="tracking-wide font-bold" v-if="geraete != null && geraete.length">Geräte</p>
          <Geraet v-for="(wert, index) in geraete" :key="wert.uid" :geraet="wert" :last="index == geraete.length - 1" />
        </div>
      </div>

      <!-- Verwaltungsübersicht -->
      <PraxisVerwaltungUebersicht v-if="istPraxis === true" />
    </div>
  </div>
</template>

<script>
//Alle benötigten eigenen Komponenten werden importiert
import PageTitle from "@/components/PageTitle.vue";
import AktionsButtonPrimaer from "@/components/AktionsButtonPrimaer.vue";
import Geraet from "./components/Geraet.vue"
import Benutzer from "./components/Benutzer.vue"
import PraxisVerwaltungUebersicht from "./components/PraxisVerwaltungUebersicht.vue"

//Import aller benötigten Helferfunktionen und Konstanten
import { computed } from "@vue/runtime-core";
import store from "@/store";
import { router } from "@/router";

export default {
  components: {
    PageTitle,
    Geraet,
    Benutzer,
    PraxisVerwaltungUebersicht,
    AktionsButtonPrimaer
  },

  setup() {
    const kontenLaden = computed(() => store.state.kontenLaden)
    const istPraxis = computed(() => store.state.istPraxis)
    //Gibt die aktuell gespeicherte Liste von konten zurück
    const geraete = computed(() => {
      if (!store.state.kontenLaden) {
        return store.state.konten.filter(k => !k.typ || k.typ === "geraet")
      }
    })

    const benutzer = computed(() => {
      if (!store.state.kontenLaden) {
        return store.state.konten.filter(k => k.typ && k.typ === "nutzer")
      }
    })

    const praxisVerwaltungOeffnen = () => router.push({ name: "PraxisVerwaltungInformationen" })

    return {
      //State für das UI
      kontenLaden,
      benutzer,
      geraete,
      istPraxis,
      praxisVerwaltungOeffnen
    };
  },
};
</script>