<template>
    <button class="flex justify-start items-center space-x-2" @click="aktualisiert">
        <!-- Icon -->
        <div 
            class="w-10 aspect-square rounded-xl flex justify-center items-center"
            :class="{
                'bg-DentaBlue': istThema === true && gewaehlt === true,
                'bg-DunklesViolett': istThema === false && gewaehlt === true
            }">
            <img class="w-6 aspect-square object-contain" :src="icon" alt="icn">
        </div>

        <!-- Name -->
        <p class="text-xs" :class="{'text-Mittelgrau': gewaehlt === false, 'text-DentaBlue': istThema === true && gewaehlt === true, 'text-DunklesViolett': istThema === false && gewaehlt === true}">{{ name }}</p>
    </button>
</template>
<script>
import { themenIconAbfragen } from '@/services/helfer';
import { computed } from 'vue';

export default {
    props:["thema", "gewaehlt"],
    emits: ["aktualisiert"],
    setup(props, context) {
        //Gibt an, ob es sich um ein Standardthema oder ein eigenes (Ordner) handelt
        const istThema = computed(() => props.thema.standard === true)

        //Gibt je nach "gewaehlt" Zustand und Art des themas das passende Icon zurück
        const icon = computed(() => {
            if(istThema.value === true) {
                return themenIconAbfragen(props.thema, props.gewaehlt)
            } else {
                return props.gewaehlt ? 
                require("@/assets/icons/ordner-weiss.svg") : 
                require("@/assets/icons/ordner.svg")
            }
        })

        const name = computed(() => {
            if(props.thema.name.length > 24) {
                return `${props.thema.name.substring(0, 23)}...`
            } else {
                return props.thema.name
            }
        })

        const aktualisiert = () => context.emit("aktualisiert")

        return {
            istThema,
            icon,
            name,
            aktualisiert
        }
    }
}
</script>