<template>
  <EingabeDialog
    :state="state"
    :titel="dialogTitel"
    body="Geben Sie einen neuen Namen für Ihren Ordner an."
    bestaetigenText="Speichern"
    :laedt="laedt"
    :bestaetigungAktiv="gueltigerName"
    @bestaetigt="themaBearbeiten"
    @abgebrochen="schliessen"
    >
    <form 
      class="w-72" 
      @submit.prevent @keydown.enter.prevent="themaBearbeiten">
      <input 
        type="text" 
        ref="nameRef" 
        placeholder="Ordner Name" 
        class="default-textfield-dynamic-length"
        v-model="name"
      >
    </form>
  </EingabeDialog>
</template>

<script>
//Alle benötigten Vue Komponenten und Frameworks werden importiert
import { ref } from '@vue/reactivity'
import { computed, watch } from '@vue/runtime-core'
import store from '@/store'

//Alle benötigten Helfer werden importiert
import { useCloudFunction } from '@/services/cloudFunctionDienst'
import { erfolgAnzeigen, standardToastAnzeigen } from '@/services/toastDient'
import {themaAbfragen} from "@/services/firestoreDienst"
import EingabeDialog from '@/components/dialoge/EingabeDialog.vue'

export default {
  props:["state", "thema"],
  emits:["schliessen"],
  components: { EingabeDialog },
  setup(props, context) {

    /** Aufrufbare Instanz und dazugehöriger State der "themaBearbeiten" Cloud-Funktion */
    const {pending: bearbeitenLaed, error: bearbeitenFehler, call: bearbeiten} = useCloudFunction("themaBearbeiten")

    const dialogTitel = computed(() => props.thema && props.thema.name 
        ? `"${props.thema.name.length > 11 ? props.thema.name.substring(0,11) + '…' : props.thema.name}" Bearbeiten` 
        : "");

    /** Speichert den eingegebenen Wert ins Namenstextfeld. Wird auf den aktuellen Namen des übergebenen Themas initialisiert */
    const name = ref(props.thema != null ? props.thema.name : "")

    const nameRef = ref(null)

    /** Überwacht das "thema" Prop, um den Vor-Ausgefüllten Namen korrekt zu aktualisieren. */
    watch(() => props.thema, () => {
      if(props.thema && props.thema.name) {
        name.value = props.thema.name
      }
    })

    //Fokussiert das "name" Textfeld, wenn der Dialog angezeigt wird.
    watch(() => props.state, val => {
      if(val === true) {
          setTimeout(() => {
              if(nameRef.value) {
                  nameRef.value.focus()
              }
          }, 100)
      } 
    })

    const gueltigerName = computed(() => 
    props.thema && 
    name.value != null && 
    name.value != "" && 
    name.value != props.thema.name && !
    store.state.themen.find(t => t.name === name.value))

    const laedt = ref(false)

    /** Wenn ein gültiger Name eingegeben ist, wird hier der Bearbeitungsprozess gestartet. */
    async function themaBearbeiten() {
      if(gueltigerName.value === true) {
        laedt.value = true

        //Das Thema vor der Bearbeitung wird gespeichert, um später prüfen zu können ob manuelles Neuladen nötig ist. 
        const themaKopie = props.thema
        
        //Für den Statustoast nach der Bearbeitung wird der gewählte Name gespeichert.
        const nameKopie = name.value

        await bearbeiten({ id: props.thema.id, name: name.value })

        if(!bearbeitenFehler.value) {
            //Wenn ein Praxiseigenes Thema (standard == false) bearbeitet wurde, wird es manuell neu geladen, 
            //weil die Änderung das Praxisdokument nicht betrifft und daher sonst nicht sofort sichbar würde.
            if(themaKopie.standard === false) {
              let aktualisiertesThema = await themaAbfragen(themaKopie.id)
              if(aktualisiertesThema != undefined) {
                  store.commit("themaSetzen", aktualisiertesThema)
              }
            }

            erfolgAnzeigen(`Ordnername bearbeitet: "${nameKopie}"`)
          } 

          laedt.value = false
          schliessen()
      }
    }

    /** Gibt das "schliessen" Event ab und setzt das Namensfeld auf einen leeren Wert zurück. */
    const schliessen = () => {
      context.emit("schliessen")
      name.value = ""
    }

    return {
      //State für das UI
      name,
      nameRef,
      laedt,
      bearbeitenLaed,
      gueltigerName,
      dialogTitel,

      //Funktionen für das UI
      themaBearbeiten,
      schliessen
    }
  }
}
</script>