<template>
  <BestaetigungsDialog :state="aufnahmeLoeschenState" titel="Snap Löschen"
    body="Das Foto wird für alle Organisationen mit Zugriff auf die Session gelöscht. Diese Aktion ist nicht widerrufbar."
    bestaetigungsText="Löschen" :laedt="aufnahmeLoeschenLaedt" :destruktiv="true"
    @stateChanged="val => aufnahmeLoeschenState = val" @bestaetigt="snapLoeschen" />

  <GDialog v-if="aufnahmeUrl != null" v-model="zuschneidenState" max-width="800" border-radius="15">
    <AufnahmeZuschneidenDialog :istAvatar="false" :id="id" :url="aufnahmeUrl" :dialogState="zuschneidenState"
      @schliessen="zuschneidenState = false" />
  </GDialog>

  <!-- Es wird nicht die Standard Seitenvorlage verwendet, da hier keine Menüleiste vorhanden ist. -->
  <div class="w-full h-screen bg-white flex justify-between items-center p-5">
    <!-- Großes Bild auf linker Seite -->
    <AufnahmeVollbild :url="aufnahmeUrl" />

    <!-- Infopanel -->
    <div class="h-full flex flex-col justify-between items-start pl-10 pt-5">

      <!-- Informationen (oberer Teil) -->
      <div class="w-full flex flex-col justify-start items-start space-y-6">
        <div class="w-full flex justify-between items-center">
          <div class="flex flex-col">
            <p v-if="datum != null" class="text-sm text-Mittelgrau">
              {{ datum }}
            </p>

            <!-- Patienteninfos -->
            <div v-if="patient" class="flex flex-col space-y-2 justify-start items-start">
              <p v-if="typeof patient.name === 'string'" class="text-lg font-bold">{{ patient.name }}</p>
              <p v-else class="text-lg text-Mittelgrau italic">Kein Name</p>
              <div class="flex flex-col space-y-1">
                <div class="flex space-x-3">
                  <img src="@/assets/icons/tag-schwarz.svg" alt="#" class="w-4 object-contain">
                  <p>{{ patient.nummer }}</p>
                </div>
                <div class="flex space-x-3">
                  <img src="@/assets/icons/kalender-schwarz.svg" alt="#" class="w-4 object-contain">
                  <p v-if="(typeof patient.geburtsDatum === 'number')">{{
                    dayjs.unix(patient.geburtsDatum).format("DD.MM.YYYY") }}</p>
                  <p v-else class="text-Mittelgrau italic">Kein Geburtsdatum</p>
                </div>
              </div>
            </div>

          </div>
          <ion-icon name="close-outline"
            class="text-4xl text-DunklesGrau transition duration-150 hover:opacity-60 hover:cursor-pointer"
            @click="schliessen"></ion-icon>
        </div>

        <!-- Löschen -und Zuschneiden Button -->
        <div v-if="eigeneSession === true" class="flex w-full justify-start">
          <div
            class="px-5 py-2 rounded-sm bg-BlauGrau text-DunklesGrau text-lg flex justify-center items-center mr-2 transition duration-150 hover:cursor-pointer hover:bg-opacity-50"
            @click="zuschneidenState = true">
            <ion-icon name="crop-outline" class="pr-2"></ion-icon>
            <p class="text-sm">Zuschneiden</p>
          </div>
          <div
            class="px-5 py-2 rounded-sm bg-BlauGrau text-DunklesGrau flex justify-center items-center transition duration-150 hover:cursor-pointer hover:bg-opacity-50"
            @click="aufnahmeLoeschenState = true">
            <ion-icon name="trash-outline"></ion-icon>
          </div>
        </div>

        <!-- Zähne -->
        <div class="bg-Hintergrund px-5 py-5 rounded-2xl w-80">
          <ZahnSchema :zaehne="zaehne" :interaktiv="false" />
        </div>

        <!-- Themen -->
        <div class="w-full flex flex-col justify-start items-start space-y-2 overflow-y-auto">
          <div v-for="t in themen" :key="t.id" class="flex space-x-2 text-sm">
            <img :src="themenIconAbfragen(t)" alt="icon" class="w-5 aspect-square object-contain">
            <p :class="{
              'text-DentaBlue': t.standard === true,
              'text-DunklesViolett': t.standard === false
            }">{{ t.name }}</p>
          </div>
        </div>
      </div>

      <!-- Session Navigation -->
      <div v-if="snaps && snap" id="thumnails"
        class="transition duration-150 w-80 flex flex-wrap-reverse overflow-auto">
        <div v-for="sessionSnap in snaps" :key="sessionSnap.id" class="w-16 aspect-square mr-2 mb-2"
          @click="navigateToImage(sessionSnap.id)">
          <AufnahmeThumnail :snap="sessionSnap" :angezeigterSnap="snap" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//Import aller benötigten Frameworks und Vue Komponenten
import { ref } from "@vue/reactivity";
import { computed, watch } from "@vue/runtime-core";
import dayjs from "dayjs";
import store from "@/store";

//Import aller benötigten eigenen Komponenten
import { GDialog } from "gitart-vue-dialog";
import ZahnSchema from "@/components/ZahnSchema.vue";
import PageTitle from "@/components/PageTitle.vue";
import Spinner from "@/components/Spinner.vue";
import SnapLoeschenDialog from "./components/SnapLoeschenDialog.vue";
import AufnahmeZuschneidenDialog from "./components/AufnahmeZuschneidenDialog.vue";
import AufnahmeVollbild from "./components/AufnahmeVollbild.vue";
import AufnahmeThumnail from "./components/AufnahmeThumnail.vue";
import BestaetigungsDialog from "@/components/dialoge/BestaetigungsDialog.vue";

//Import aller benötigten Helferfunktionen und Konstanten
import { snapUriAbfragen, themenIconAbfragen as themenIconAbfragenHelfer } from "@/services/helfer";
import { useRoute, useRouter } from "vue-router";
import { useCloudFunction } from "@/services/cloudFunctionDienst";
import { fehlerSenden } from "@/services/LogDienst";
import { erfolgAnzeigen, fehlerAnzeigen } from "@/services/toastDient";

export default {
  components: {
    GDialog,
    ZahnSchema,
    Spinner,
    PageTitle,
    SnapLoeschenDialog,
    AufnahmeZuschneidenDialog,
    AufnahmeVollbild,
    AufnahmeThumnail,
    BestaetigungsDialog,
  },

  setup() {
    const route = useRoute();
    const router = useRouter();

    /** Session, deren Aufnahmen aktuell angezeigt werden */
    const session = ref(null);

    /** Snap, der aktuell fokussiert wird */
    const snap = ref(null);

    let id = computed(() => {
      if (snap.value !== null) {
        return snap.value.id;
      }
    });

    /** Index (innerhalb der Session) des Snaps, der aktuell fokussiert wird. */
    const snapIndex = computed(() => {
      if (snap.value && session.value) {
        return session.value.snaps.findIndex((s) => s.id == snap.value.id);
      }
    });

    if (
      route.params.sessionId &&
      typeof route.params.sessionId == "string" &&
      route.params.snapId &&
      typeof route.params.snapId == "string"
    ) {
      session.value = store.getters.sessionAbfragen(route.params.sessionId);
      snap.value = store.getters.snapAbfragen(route.params.snapId);
    } else {
      router.push({ name: "Gallerie" });
      return;
    }

    /** Liste aller Snap-Instanze, die Teil der Session sind. */
    const snaps = computed(() => {
      if (session.value) {
        var ergebnis = [];
        for (let i = 0; i < session.value.snaps.length; i++) {
          const snap = store.getters.snapAbfragen(session.value.snaps[i]);
          if (snap) {
            ergebnis.push(snap);
          }
        }
        return ergebnis;
      }
    });

    /** Die beiden Parameter der Route werden überwacht, und die entsprechenden Felder lokal gesetzt. */
    watch(
      () => route.params,
      (fromParams, toParams) => {
        if (
          toParams.sessionId != null &&
          typeof toParams.sessionId == "string" &&
          fromParams.sessionId != toParams.sessionId
        ) {
          session.value = store.getters.sessionAbfragen(toParams.sessionId);
        }

        if (
          toParams.snapId != null &&
          typeof toParams.snapId == "string" &&
          fromParams.snapId != toParams.snapId
        ) {
          snap.value = store.getters.snapAbfragen(toParams.snapId);
        }
      }
    );

    //State für den "AufnahmeZuschneiden" Dialog
    const zuschneidenState = ref(false);

    //Speichert den lokalen URL zur Vollversion des Bildes
    const aufnahmeUrl = computed(() => {
      if (snap.value) {
        return store.getters.snapVollLinkAbfragen(snap.value.id);
      }
    });

    if (!aufnahmeUrl.value && snap.value) {
      snapUriAbfragen(snap.value.id, false).then((uri) => {
        store.commit("snapVollLinkSetzen", { id: snap.value.id, link: uri });
      });
    }

    //State für den "Löschen" Dialog
    const aufnahmeLoeschenState = ref(false);

    const aufnahmeLoeschenLaedt = ref(false)

    const {
      error: loschenFehler,
      call: loeschen
    } = useCloudFunction("snapLoeschen")

    /** Ruft die Cloud Funktion zum Löschen des angezeigten Fotos auf, und löscht die Aufnahme dann aus dem lokalen Speicher */
    function snapLoeschen() {
      if (id.value) {
        aufnahmeLoeschenLaedt.value = true
        loeschen({ id: id.value })
          .catch(err => {
            fehlerSenden(err, "Fehler beim Löschen eines Snaps", "snapLoeschen", "Aufnahme.js")
            aufnahmeLoeschenLaedt.value = false
          })
          .then(() => {
            if (loschenFehler.value) {
              fehlerAnzeigen("Der Snap konnte nicht gelöscht werden.")
              aufnahmeLoeschenState.value = false
            } else {
              snapGeloescht()
            }
          })
      }
    }

    /** Löscht den Snap nach der Löschung aus dem Cloudspeicher auch aus dem lokalen speicher.
     * Jenachdem, welchen Index der Snap in der Session hatte, wird zum nächsten oder letzten Snap navigiert. */
    function snapGeloescht() {
      //Der Index des eben gelöschten Snaps innerhalb der lokal gespeicherten Session wird abgefragt
      let geloeschterIndex = session.value.snaps.findIndex(
        (s) => s == snap.value.id
      );
      let naechsterIndex =
        geloeschterIndex == session.value.snaps.length - 1
          ? geloeschterIndex - 1
          : geloeschterIndex + 1;
      let naechsterSnap = snaps.value[naechsterIndex];

      if (session.value.snaps.length == 1) {
        //Der eben gelöschte Snap war der letzte der Session
        schliessen();
      } else {
        navigateToImage(naechsterSnap.id);
      }

      //Der Snap wird explizit aus dem lokalen Speicher gelöscht
      store.commit("snapLoeschen", snap.value.id);
      erfolgAnzeigen("Snap erfolgreich gelöscht.")
      aufnahmeLoeschenState.value = false
      aufnahmeLoeschenLaedt.value = false
    }

    /**
     * Navigiert zur letzten Route in der Router-vergangenheit, die nicht die "aufnahme" Route ist.
     * Wird keine gefunden, wird zur gallerie navigiert
     */
    function schliessen() {
      if (store.state.previousRoute != null) {
        router.push({
          name: store.state.previousRoute.name,
          params: store.state.previousRoute.params,
        });
      } else {
        router.push({
          name: "Gallerie",
        });
      }
    }

    function themenIconAbfragen(t) {
      if (t.standard === true) {
        return themenIconAbfragenHelfer(t, false)
      } else {
        return require("@/assets/icons/ordner.svg")
      }
    }

    //Öffnet die "Aufnahme" Seite mit der gegebenen ID
    const navigateToImage = (id) => {
      if (session.value) {
        router.push({
          name: "Aufnahme",
          params: { sessionId: session.value.id, snapId: id },
        });
      }
    };

    /** Gibt den Datumsstring der Aufnahme im Format "tag.monat.jahr - stunde:minute" zurück. */
    const datum = computed(() => {
      if (snap.value && snap.value.entstehungsZeitpunkt != null) {
        return dayjs
          .unix(snap.value.entstehungsZeitpunkt)
          .format("DD.MM.YY - HH:mm");
      } else {
        return "Keine Datumsangabe";
      }
    });

    const patient = computed(() => {
      if (session.value) {
        return store.getters.patientAbfragen(session.value.patient);
      }
    });

    const zaehne = computed(() => {
      if (session.value) {
        return session.value.zaehne;
      }
    });

    const themen = computed(() => {
      if (session.value) {
        var ergebnis = [];
        for (let i = 0; i < session.value.themen.length; i++) {
          const thema = store.getters.themaAbfragen(session.value.themen[i]);
          if (thema) {
            ergebnis.push(thema);
          }
        }
        return ergebnis;
      }
    });

    const eigeneSession = computed(() => session.value && session.value.eigentuemer === store.getters.praxisAbfragen.id)

    return {
      //State für das UI
      snap,
      id,
      snapIndex,
      snaps,
      session,
      navigateToImage,
      eigeneSession,
      datum,
      patient,
      zaehne,
      themen,
      aufnahmeLoeschenState,
      aufnahmeLoeschenLaedt,
      zuschneidenState,
      aufnahmeUrl,
      dayjs,

      //Funktionen für das UI
      themenIconAbfragen,
      snapLoeschen,
      snapGeloescht,
      schliessen,
    };
  },
};
</script>

<style scoped>
#thumnails img {
  pointer-events: all;
}
</style>