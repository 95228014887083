<template>
    <div class="onboarding-komponente">
        <OnboardingTitel v-if="fehler === true" titel="Fehler Aufgetreten"
            untertitel="Entschuldigung, ein Fehler ist aufgetreten. Bitte versuchen Sie es noch einmal oder kontaktieren Sie unseren Support, falls Sie Unterstützung benötigen." />
        <OnboardingTitel v-else titel="Fertig"
            untertitel="Der Wechsel Ihrer Zahlungsmethode wird jetzt vorgenommen. Sie bekommen eine Bestätigung per E-Mail, sobald die Änderung abgeschlossen ist." />

        <LottieAnimation :animation-data="fehler === true ? fehlerAnim : haken" class="w-40" />
        <OnboardingWeiterButton :deaktiviert="false" :laedt="false" text="Zur Praxis" @click="zurPraxis" />
    </div>
</template>
<script>
import OnboardingTitel from '@/components/OnboardingTitel.vue';
import OnboardingWeiterButton from '@/components/OnboardingWeiterButton.vue';
import { LottieAnimation } from 'lottie-web-vue';
import haken from "@/assets/animations/haken.json"
import fehlerAnim from "@/assets/animations/fehler.json"
import { router } from '@/router';
export default {
    components: {
        OnboardingTitel,
        OnboardingWeiterButton,
        LottieAnimation,
        OnboardingWeiterButton
    },
    props: ["fehler"],
    setup() {

        const zurPraxis = () => router.push({ name: "PraxisVerwaltungZahlungen" })

        return {
            haken,
            fehlerAnim,
            zurPraxis
        }
    }
}
</script>