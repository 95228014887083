<template>
  <div class="w-full h-56">
    
    <!-- Titel und Navigationsbuttons -->
    <div class="w-full h-10 mb-6 flex justify-center items-center text-md">
      <ion-icon
        name="chevron-back-outline"
        class="transition duration-100 hover:scale-125 hover:cursor-pointer"
        @click="monatsDifferenzVerringern"
      ></ion-icon>
      <p class="mx-4">{{ kalenderTitel }}</p>
      <ion-icon
        name="chevron-forward-outline"
        class="transition duration-100 hover:scale-125 hover:cursor-pointer"
        @click="monatsDifferenzErhoehen"
      ></ion-icon>
    </div>

    <!-- Tagesgitter -->
    <div class="w-full flex flex-wrap">
      <div
        v-for="tag in tage"
        :key="tag"
        class="
          w-10
          h-10
          text-sm
          flex
          justify-center
          items-center
          rounded-sm
          hover:bg-TextField hover:cursor-pointer
          aspect-square
        "
        :class="{
          'bg-DentaBlue text-white hover:bg-DentaBlue hover:text-white':
            istZeitraumAnfangOderEnde(tag),
          ' bg-TextField': istHeute(tag) && !istDatumImZeitraum(tag) && !istZeitraumAnfangOderEnde(tag),
          'bg-DentaBlueLight rounded-none':
            istDatumImZeitraum(tag) && !istZeitraumAnfangOderEnde(tag),
        }"
        @click="zeitraumAktualisieren(tag)"
      >
        <p>
          {{ tag }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
//Import aller benötigten Vue-Komponenten und Frameworks
import dayjs from "dayjs";
import { ref } from "@vue/reactivity";
import { computed } from "@vue/runtime-core";

//Import benötigter Helferfunktionen und Konstanten
import { monatsNamen } from '@/constants/konstanten';

export default {
  emits: ["auswahl"],
  
  setup(props, context) {

    const zeitraumAnfang = ref(null)
    const zeitraumEnde = ref(null)

    //Speichert die Differenz des angezeigten Monats zum aktuellen
    const monatsDifferenz = ref(0);

    //Methoden, um die Monatsdifferenz um +/- 1 zu verändern.
    const monatsDifferenzErhoehen = () => monatsDifferenz.value++;
    const monatsDifferenzVerringern = () => monatsDifferenz.value--;

    //Gibt den Monat zurück, der mittels der "monatsDifferenz" aktuell ausgewählt ist.
    //Genauer wird ein Datum zurückgegeben, dass den heutigen Tag +/- der gegebenen Monatsdifferenz darstellt.
    const monat = computed(() => {
      return dayjs(Date.now()).add(monatsDifferenz.value, "month");
    });

    //Gibt einen Titelstring zurück, der den ausgewählten Monat und das Jahr darstellt.
    //Bsp: "Juli 2022"
    const kalenderTitel = computed(() => {
      return `${monatsNamen[monat.value.month()]} ${monat.value.format("YYYY")}`;
    });

    //Nimmt eine Tageszahl an, und prüft ob zusammen mit dem aktuell ausgewählten Monat
    //das resultierende Datum heute ist.
    const istHeute = (day) => {
      let today = dayjs();
      return (
        monat.value.year() == today.year() &&
        monat.value.month() == today.month() &&
        today.date() == day
      );
    };

    //Nimmt eine Tageszahl, und prüft ob, zusammen mit dem ausgewählten Monat,
    //das resultierende Datum der Anfang oder das Ende des aktuell
    //ausgewählten Zeitraums ist.
    const istZeitraumAnfangOderEnde = (day) => {
      if(zeitraumAnfang.value && monat.value) {
        let isStart = zeitraumAnfang.value != null
        ? monat.value.year() == zeitraumAnfang.value.year() &&
          monat.value.month() == zeitraumAnfang.value.month() &&
          zeitraumAnfang.value.date() == day
        : false;
      let isEnd = zeitraumEnde.value != null 
        ? monat.value.year() == zeitraumEnde.value.year() &&
          monat.value.month() == zeitraumEnde.value.month() &&
          zeitraumEnde.value.date() == day
        : false;
      return isStart || isEnd;
      }
    };

    //Gibt eine Liste zurück, die eine Zahl für jeden tag des aktuell ausgewählten Monats
    //enthält
    const tage = computed(() => {
      var result = [];
      for (let index = 1; index <= monat.value.daysInMonth(); index++) {
        result.push(index);
      }
      return result;
    });

    //Die "zeitraumAnfang" referenz wird auf ein Datum gesetzt, dass den Tag mit der
    //gegebenen Zahl im gewählten Monat zur Stunde 00:00:00:000 darstellt.
    const startSetzen = (tageszahl) => {
      let ergebnis = dayjs(monat.value)
        .date(tageszahl)
        .hour(0)
        .minute(0)
        .second(0)
        .millisecond(0);
      
      zeitraumAnfang.value = ergebnis
    };

    //Die "zeitraumEnde" Referenz wird auf ein Datum gesetzt, dass den Tag mit der
    //gegebenen Zahl und dem gewählten Monat zur Stunde 23:59:59:999 darstellt.
    const endeSetzen = (tageszahl) => {
      let date = monat.value
        .clone()
        .date(tageszahl)
        .hour(23)
        .minute(59)
        .second(59)
        .millisecond(999);

      if (zeitraumAnfang.value != null && zeitraumAnfang.value.isBefore(date)) {
        zeitraumEnde.value = date
      }
    };

    
    const zeitraumAktualisieren = (day) => {
      if (zeitraumEnde.value != null && zeitraumAnfang.value != null) {
        //Zeitraum bereits gesetzt, der Kalender wird zurückgesetzt
        context.emit("auswahl", null)
        zeitraumAnfang.value = null
        zeitraumEnde.value = null
      } else if (zeitraumAnfang.value != null) {
        //Der Anfang existiert bereits, es soll jetzt das Ende des Zeitraums festgelegt werden,
        //und daraufhin ein "auswahl" Event mit dem nun vollständigen Zeitraum abgefeuert werden.

        endeSetzen(day);

        context.emit("auswahl", {
          anfang: zeitraumAnfang.value,
          ende: zeitraumEnde.value,
        });

      } else {
        //Es besteht noch keine Auswahl, und es wird daher der Startpunkt gesetzt.
        startSetzen(day);
      }
    };

    //Wenn ein Zeitraum besteht, wird geprüft, ob der Tag kombiniert mit dem gewählten Monat
    //in diesem Zeitraum liegt.
    const istDatumImZeitraum = (day) => {
      if (zeitraumAnfang.value != null && zeitraumEnde.value != null) {
        let date = monat.value.clone().date(day);
        return date.isAfter(zeitraumAnfang.value) && date.isBefore(zeitraumEnde.value);
      } else return false;
    };

    return {
      //State für das UI
      kalenderTitel,
      tage,

      //Funktionen für das UI
      monatsDifferenzErhoehen,
      monatsDifferenzVerringern,
      istHeute,
      zeitraumAktualisieren,
      istZeitraumAnfangOderEnde,
      istDatumImZeitraum,
    };
  },
};
</script>

<style>
</style>