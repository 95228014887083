const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
        legend: {
            display: false,
        },
    },
    layout: {
        padding: 20,
    },
    elements: {
        point: {
            radius: 0,
        },
        line: {
            borderCapStyle: "round",
        },
    },
    scales: {
        x: {
            // Set this to false to hide the x-axis
            grid: {
                drawBorder: false, // Set this to false to hide the x-axis grid border
                drawOnChartArea: true, // Set this to false to hide the x-axis grid lines
            },
            ticks: {
                color: "#CCCCCC",
            },
            border: {
                display: false,
            },
        },
        y: {
            display: false, // Set this to false to hide the y-axis
            grid: {
                drawBorder: false, // Set this to false to hide the y-axis grid border
                drawOnChartArea: false, // Set this to false to hide the y-axis grid lines
            },
        },
    },
};

export { options }