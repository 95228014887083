<template>
    <div class="w-full">
        <div v-if="laedt === true" class="aspect-creditcard bg-LeichtesGrau bg-opacity-30 rounded-xl relative">
        </div>
        <div v-else class="aspect-creditcard rounded-xl relative">
            <!-- Hintergrundbild -->
            <img :src="hintergrund" alt="" class="absolute rounded-xl w-full h-full">

            <!-- Karteninhalt -->
            <div class="absolute w-full h-full z-10 flex flex-col justify-end items-start p-6 font-dm-sans font-light text-white text-lg tracking-wide">
                <p>{{ identifier }}</p>
                <p>{{ name }}</p>
            </div>
        </div>
    </div>
</template>
<script>
import { computed } from 'vue';
export default {
    props: ["methode", "laedt"],
    setup(props, context) {
        const hintergrund = computed(() => {
            if(props.methode) {
                if(props.methode.art == "creditcard") {
                    switch (props.methode.netzwerk) {
                        case "Visa":
                            return require("@/assets/zm-hintergruende/visa.jpg")
                        case "Mastercard":
                            return require("@/assets/zm-hintergruende/mastercard.jpg")
                        case "American Express":
                            return require("@/assets/zm-hintergruende/amex.jpg")
                    }
                } else {
                    return require(`@/assets/zm-hintergruende/${props.methode.art}.jpg`)
                }
            }
        })
        const identifier = computed(() => {
            if(props.methode) {
                switch (props.methode.art) {
                    case "creditcard":
                        return `**** **** **** ${props.methode.nummer}`
                    case "directdebit":
                        return props.methode.iban
                    case "paypal":
                        return props.methode.email
                    default:
                        break;
                }
            }
        })
        const name = computed(() => {
            if(props.methode) {
                return props.methode.name
            }
        })
        
        return {
            hintergrund,
            identifier,
            name
        }
    }
}
</script>