<template>
    <div class="w-full py-7 px-5 flex justify-start items-center bg-white font-dm-sans">

        <!-- "Zurück" Button -->
        <div class="flex justify-center items-center space-x-2 mr-10 px-3 py-2 rounded-lg transition duration-100 hover:cursor-pointer hover:bg-TextField"
            @click="zurueck">
            <img src="@/assets/icons/arrow-backward-black.svg" class="w-5 aspect-square object-contain">
            <p>{{ vorherigeRoute.name }}</p>
        </div>

        <!-- Nav-Elemente -->
        <div class="w-full flex justify-start space-x-2">
            <router-link v-for="route in routen" :key="route.routenName" :to="{ name: route.routenName }"
                class="px-3 py-2 rounded-md transition duration-100 hover:cursor-pointer hover:bg-TextField hover:bg-opacity-50"
                active-class="bg-TextField hover:bg-opacity-100">
                <p>{{ route.name }}</p>
            </router-link>
        </div>
    </div>
</template>
<script>
import { useRouter } from "vue-router";
export default {
    props: ["vorherigeRoute", "routen"],
    setup(props, _) {

        const router = useRouter()

        function zurueck() {
            console.log("Zurück");
            router.push({ path: props.vorherigeRoute.pfad })
        }

        return {
            zurueck
        }
    }
}
</script>