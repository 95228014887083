<template>
    <OptionenKarte titel="Passwort Ändern">
        <BestaetigungsDialog 
            :state="bestaetigungsState"
            titel="Passwort Zurücksetzen?"
            body="Wir senden Ihnen eine E-Mail mit allen weiteren Schritten."
            :destruktiv="false"
            bestaetigungsText="E-Mail Senden"
            :laedt="sendenLaedt"
            @abgebrochen="bestaetigungsState = false"
            @bestaetigt="mailSenden"/>
        <span class="text-lg text-Mittelgrau">Klicken Sie "Passwort Zurücksetzen", um einen Link zum Zurücksetzen ihres Passworts an {{ email }} zu senden.</span>
        <button class="text-DentaBlue text-lg" @click="bestaetigungsState = true">Passwort Zurücksetzen</button>
    </OptionenKarte>
</template>
<script>
import { nutzer } from '@/services/authentifizierungsDienst';
import { useCloudFunction } from '@/services/cloudFunctionDienst';
import { computed, ref } from 'vue';
import BestaetigungsDialog from '@/components/dialoge/BestaetigungsDialog.vue';
import OptionenKarte from '@/components/OptionenKarte.vue';
import { erfolgAnzeigen, fehlerAnzeigen } from '@/services/toastDient';
export default {
    components: {BestaetigungsDialog, OptionenKarte},
    setup() {
        const bestaetigungsState = ref(false)

        const email = computed(() => {
            if(nutzer.value != null) {
                return nutzer.value.email
            } else {
                return ""
            }
        })

        const {pending: sendenLaedt, error: sendenFehler, call: sendenAufrufen} = useCloudFunction("passwortZuruecksetzenLinkSenden")

        function mailSenden() {
            if(email.value !== "") {
                sendenAufrufen({email: email.value})
                .then(() => {
                    if(sendenFehler.value === false) {
                        erfolgAnzeigen("E-Mail Versendet.")
                    } else {
                        fehlerAnzeigen("Fehler beim Versenden der E-Mail.")
                    }
                })
                .finally(() => bestaetigungsState.value = false)
            } 
        }

        return {email, bestaetigungsState,sendenLaedt, mailSenden}
    }
}
</script>