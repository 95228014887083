<template>
    <div v-if="laedt === true" class="w-full h-full flex flex-col space-y-3 bg-white rounded-xl px-5 py-7">
        
    </div>
    <div v-else-if="laedt === false && letzteAktivitaeten.length === 0" class="w-full h-full flex space-y-3 bg-white rounded-xl">
        <p>Keine Aktivitäten</p>
    </div>
    <div v-else class="w-full h-full flex flex-col justify-start items-start space-y-3 bg-white rounded-xl px-5 py-7">
        <AktivitaetsZeile 
            v-for="session in letzteAktivitaeten" 
            :key="session.id" 
            :session="session"/>
    </div>
</template>
<script>
import store from '@/store';
import AktivitaetsZeile from "./AktivitaetsZeile.vue"
import { computed } from 'vue';
export default {
    components: {AktivitaetsZeile},
    setup() {
        const laedt = computed(() => store.state.sessionsLaden || store.state.praxisLaedt || store.state.kontenLaden)
        const platzhalterRange = computed(() => Array.from({length: 5}))
        const letzteAktivitaeten = computed(() => {
            if(laedt.value === false && store.state.sessions != null) {
                const sessions = store.state.sessions.slice()
                return sessions.sort((a,b) => b.hochladeZeit - a.hochladeZeit).splice(0, 5)
            }
            return []
        })

        return {
            laedt,
            letzteAktivitaeten,
            platzhalterRange
        }
    }
}
</script>