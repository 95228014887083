<template>
    <div class="w-full h-full flex flex-col justify-start items-center space-y-8 mt-14">
        <DokumenteRechnungsHinweis v-if="istPraxis" />
        <DokumenteAllgemein />
        <DokumenteAvv />
        <DokumenteJcv />
    </div>
</template>

<script>
import DokumenteRechnungsHinweis from './components/DokumenteRechnungsHinweis.vue';
import DokumenteAllgemein from './components/DokumenteAllgemein.vue';
import DokumenteAvv from './components/DokumenteAvv.vue';
import DokumenteJcv from './components/DokumenteJcv.vue';
import { computed } from 'vue';
import store from '@/store';
export default {
    components: { DokumenteRechnungsHinweis, DokumenteAvv, DokumenteJcv, DokumenteAllgemein },
    setup() {
        const istPraxis = computed(() => store.state.istPraxis)
        return { istPraxis }
    }
}
</script>