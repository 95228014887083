<template>
    <div class="w-[37rem] rounded-3xl p-10 space-y-8 flex flex-col justify-center items-start font-dm-sans bg-Hintergrund">
        <p class="text-3xl font-bold">{{titel}}</p>
        <slot></slot>
    </div>
</template>
<script>
export default {
    props: ["titel"]
}
</script>