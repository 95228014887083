//Enthält Funktionen, die die Nutzung von "Firebase Cloud Functions" ermöglicht.

//Import benötigter Referenzen der Anwendung und des "Vue" Frameworks
import { functions } from "@/firebase/config";
import { router } from "@/router";
import store from "@/store";
import { httpsCallable } from "firebase/functions";
import { ref } from "vue";
import { versionAbfragen } from "./helfer";
import { fehlerSenden } from "./LogDienst";

//Erstellt Instanzen der "fehler", "laed", "ergebnis" und "aufrufen" Werte, nutzbar um
//"Firebase Cloudfunctions" aufzurufen und deren Erebnis zu verwenden, und gibt sie zurück.
const useCloudFunction = (name) => {
  //Abhörbare Werte für Fehler, Ladezustand und Aufrufergebnis werden erstellt.
  const fehler = ref(false);
  const laed = ref(false);
  const ergebnis = ref(null);

  //Ruft die "Cloudfunction" gegeben durch den "name" Wert auf.
  const aufrufen = async (data) => {
    //Der Ladezustand wird gestartet, und mögliche vorherige Fehler auf "null" gesetzt.
    laed.value = true;
    fehler.value = false;

    //Eine aufrufbare Instanz der "Cloudfunction" wird mit dem gegebenen namen erstellt.
    let aufrufbar = httpsCallable(functions, name);

    //Wenn das "data" Objekt null ist, wird eins nur mit der Version erstellt.
    //Andernfalls wird die version angehängt.
    const version = versionAbfragen();
    if (data == undefined) data = { version: version };
    else data.version = version;

    try {
      //Die Instanz wird aufgerufen, und die zurückgegebenen Daten im "ergebnis" Wert gespeichert
      let res = await aufrufbar(data);

      if (res.data.code != 0) {
        fehler.value = true;

        //Prüfung für einen Instandhaltungsmodus wird durchgeführt
        if (res.data.code == 91) {
          store.commit("wartungSetzen", true);
          router.push({ name: "Wartungsmodus" });
        } else if (res.data.code == 92) {
          store.commit("updateNoetigSetzen", true);
          router.push({ name: "UpdateNoetig" });
        } else {
          console.log(
            `Fehler ${res.data.code} ist aufgetreten. Aufrufdatum: ${res.data.daten}`
          );
        }
      } else {
        fehler.value = false;
        ergebnis.value = res.data.daten;
      }

      laed.value = false;
    } catch (err) {
      console.log(err);

      fehlerSenden(
        err,
        `Fehler beim Aufrufen der Cloudfunktion '${name}'.`,
        "useCloudFunction",
        "cloudFunctionDienst"
      );
      //Der Ladezustand wird auch hier beendet.
      fehler.value = true;
      laed.value = false;
    }
  };

  return { error: fehler, pending: laed, result: ergebnis, call: aufrufen };
};

//TODO: Zurückgegebene Funktionsnamen ins Deutsche übersetzen.
export { useCloudFunction };
