<template>
    <div class="w-full h-screen flex justify-start items-center p-5">

        <BestaetigungsDialog :state="dialogState" titel="Kündigung Bestätigen"
            body="Möchten Sie Ihr Abonnement wirklich jetzt kündigen?" bestaetigungsText="Abonnement Kündigen"
            :destruktiv="true" :laedt="laedt" @bestaetigt="kuendigungStarten" @abgebrochen="dialogState = false" />

        <OnboardingFortschrittsAnzeige class="w-1/3 h-full mr-40" :schritte="[]" :index="0" :fehler="false" />

        <div class="onboarding-komponente h-screen justify-center">
            <OnboardingTitel titel="Abonnement Kündigen"
                :untertitel="`Ihr Abonnement läuft weiter bis Ende des aktuellen Abrechnungszeitraums, dem ${kuendigungsDatum}. Danach wird Ihre Organisation deaktiviert. `" />
            <div class="w-full max-h-[70%] flex flex-col overflow-y-auto space-y-6 pr-6">
                <span>Bei der Kündigung gelten unsere <a class="text-DentaBlue hover:opacity-70" target="_blank"
                        href="https://dentasnap.de/rechtliches/dokumente">Allgemeinen Geschäftsbedingungen.</a> Das
                    Wichtigste ist hier für Sie
                    zusammengefasst:</span>
                <WarnungKasten titel="Verlust Ihres Zugriffs"
                    body="Sobald Ihre Organisation gesperrt wurde, verlieren Sie Zugriff auf alle Inhalte von Dentasnap (u.a. iOS App und Web App). Somit verlieren Sie den Zugriff zu Ihren Inhalten, wie u.a. Ihre Patientenfotos, Fotos von Ihren externen Partnern und die dazugehörigen Daten." />
                <WarnungKasten titel="Datenlöschung nach 60 Tagen"
                    body="Wenn Sie Ihr Abonnement nicht innerhalb von 60 Tagen reaktivieren, werden alle Daten dauerhaft gelöscht. Dies schließt Patientenfotos und alle zugehörigen Informationen ein." />
                <InfoKasten titel="Abonnement reaktivieren innerhalb von 60 Tagen"
                    body="Sie haben die Möglichkeit, Ihr Abonnement innerhalb von 60 Tagen nach der Kündigung zu reaktivieren. Bei Fragen und Hilfe stehen wir Ihnen gerne zur Verfügung über kontakt@dentasnap.de" />
            </div>
            <OnboardingWeiterButton :destruktiv="true" :deaktiviert="false" text="Abonnement jetzt kündigen"
                @click="dialogState = true" />

        </div>
    </div>
</template>
<script>
import OnboardingTitel from "@/components/OnboardingTitel.vue";
import OnboardingWeiterButton from "@/components/OnboardingWeiterButton.vue";
import OnboardingFortschrittsAnzeige from "@/components/OnboardingFortschrittsAnzeige.vue";
import WarnungKasten from "@/components/text-kaesten/WarnungKasten.vue";
import InfoKasten from "@/components/text-kaesten/InfoKasten.vue";
import BestaetigungsDialog from "@/components/dialoge/BestaetigungsDialog.vue";
import { computed, ref } from "vue";
import store from "@/store";
import dayjs from "dayjs";
import { useCloudFunction } from "@/services/cloudFunctionDienst";
import { aboNeuLaden } from "@/services/helfer";
import { erfolgAnzeigen } from "@/services/toastDient";
import { router } from "@/router";
export default {
    components: { OnboardingTitel, OnboardingWeiterButton, OnboardingFortschrittsAnzeige, WarnungKasten, InfoKasten, BestaetigungsDialog },
    setup(_, context) {

        const laedt = ref(false)
        const dialogState = ref(false)

        const kuendigungsDatum = computed(() => {
            if (store.state.abonnementLaedt === false) {
                return dayjs.unix(store.state.abonnement.naechsteZahlung).format("DD.MM.YYYY")
            } else return ""
        })


        const { error: beendenFehler, call: beendenAufrufen } = useCloudFunction("abonnementBeendenStarten")
        function kuendigungStarten() {
            if (laedt.value === false) {
                laedt.value = true
                beendenAufrufen()
                    .then(() => {
                        if (beendenFehler.value === false) {
                            aboNeuLaden()
                            erfolgAnzeigen("Ihr Abonnement wurde gekündigt.")
                            router.back()
                        }
                    })
                    .finally(() => laedt.value = false)
            }
        }

        return { kuendigungsDatum, laedt, dialogState, kuendigungStarten }
    }
}
</script>