<template>
    <div
        class="px-2.5 py-1.5 rounded-md bg-LeichtesBlau text-DentaBlue flex justify-center items-center text-sm font-dm-sans mr-2 mb-2">
        <p class="mr-2">{{ email }}</p>
        <button class="w-5 aspect-square object-cover transform duration-100 hover:cursor-pointer hover:opacity-80"
            @click="entfernen">
            <img src="@/assets/icons/schliessen-grau.svg" class="object-contain">
        </button>
    </div>
</template>
<script>
export default {
    props: ["email"],
    emits: ["entfernen"],
    setup(_, context) {
        const entfernen = () => context.emit("entfernen")

        return { entfernen }
    }
}
</script>