<template>
    <BestaetigungsDialog :state="pwtLinkSendenState" titel="Passwort Zurücksetzen"
        body="Wir senden eine E-Mail mit allen nötigen Informationen an den Benutzer." bestaetigungsText="E-Mail Senden"
        :laedt="pwtLinkLaedt" @abgebrochen="pwtLinkSendenState = false" @bestaetigt="linkSenden" />
    <BestaetigungsDialog :state="nutzerSperrenState" :titel="`${nutzername} Sperren?`"
        body="Der Benutzer kann nicht mehr auf Ihre Organisation zugreifen, bis Sie die Sperrung aufheben."
        bestaetigungsText="Benutzer Sperren" :laedt="sperrenLaedt" :destruktiv="true"
        @abgebrochen="nutzerSperrenState = false" @bestaetigt="nutzerSperren" />
    <BestaetigungsDialog :state="nutzerEntsperrenState" :titel="`${nutzername} Entsperren?`"
        body="Der Benutzer kann wieder, entsprechend seiner Berechtigungen, auf Ihre Organisation zugreifen."
        bestaetigungsText="Benutzer Entsperren" :laedt="entsperrenLaedt" :destruktiv="true"
        @abgebrochen="nutzerEntsperrenState = false" @bestaetigt="nutzerEntsperren" />
    <BestaetigungsDialog :state="nutzerLoeschenState" :titel="`${email} Löschen?`"
        body="Diese Aktion kann nicht rückgängig gemacht werden." bestaetigungsText="Benutzer Löschen"
        :laedt="loeschenLaedt" :destruktiv="true" @abgebrochen="nutzerLoeschenState = false"
        @bestaetigt="nutzerLoeschen" />
    <div class="w-full flex flex-col justify-center items-center space-y-3">
        <div class="w-full pt-3 px-5 mb-3 flex justify-between items-center rounded-md">
            <div class="w-full flex justify-start items-center space-x-12 text-lg font-dm-sans">
                <div class="w-1/3 flex justify-start items-center space-x-7">
                    <img :src="avatarUrl" class="w-10 aspect-square rounded-full">
                    <p>{{ nutzername }}</p>
                </div>
                <p class="w-1/3">{{ email }}</p>
                <div class="w-1/3 flex">
                    <div class="px-3 py-1 flex space-x-1.5 text-sm text-white rounded-full"
                        :class="{ 'bg-Green': eigentuemer, 'bg-Yellow': ausstehend, 'bg-Red': gesperrt }">
                        <img v-if="eigentuemer" src="@/assets/icons/eigentuemer.svg" alt="key"
                            class="w-2.5 aspect-square">
                        <img v-else-if="gesperrt" src="@/assets/icons/gesperrt.svg" alt="key"
                            class="w-2.5 aspect-square">
                        <img v-else-if="ausstehend" src="@/assets/icons/ausstehend.svg" alt="key"
                            class="w-2.5 aspect-square">
                        <p v-if="eigentuemer">Eigentümer</p>
                        <p v-else-if="gesperrt">Gesperrt</p>
                        <p v-else-if="ausstehend">Ausstehend</p>
                    </div>
                </div>
            </div>
            <button class="transition duration-100 hover:opacity-75" @click="nutzerOptionenAnzeigen">
                <img src="@/assets/icons/punkte-vertikal-grau.svg" class="h-6 aspect-square">
            </button>
        </div>

        <!-- Seperator, wenn der Eintrag nicht der Letzte der Liste ist -->
        <div v-if="!last" class="w-[92%] h-[0.025rem] bg-[#EBECEF] rounded-full"></div>
    </div>
</template>
<script>
import { computed, onMounted, ref } from 'vue';
import { berechtigungenPruefen, nameAbfragen } from "@/services/authentifizierungsDienst";
import { nutzerProfilbildAbfragen } from '@/services/helfer';
import { kontextMenueAnzeigen } from '@/services/kontextMenue';
import { useCloudFunction } from '@/services/cloudFunctionDienst';
import { erfolgAnzeigen, fehlerAnzeigen } from '@/services/toastDient';
import BestaetigungsDialog from '@/components/dialoge/BestaetigungsDialog.vue';
import { eigentuemer } from '@/constants/berechtigungen';
import store from '@/store';
export default {
    components: { BestaetigungsDialog },
    props: ["benutzer", "last"],
    setup(props) {

        const pwtLinkSendenState = ref(false)
        const nutzerSperrenState = ref(false)
        const nutzerEntsperrenState = ref(false)
        const nutzerLoeschenState = ref(false)
        const ausstehend = computed(() => {
            if (props.benutzer) {
                return props.benutzer.ausstehend
            }
        })

        const gesperrt = computed(() => {
            if (props.benutzer) {
                return props.benutzer.deaktiviert
            }
        })

        const eigentuemer = computed(() => {
            if (props.benutzer) {
                return store.getters.praxisAbfragen.eigentuemer == props.benutzer.uid
            }
        })

        const nutzername = computed(() => {
            if (props.benutzer) {
                if (props.benutzer.nutzerName) {
                    return nameAbfragen(props.benutzer.nutzerName)
                } else {
                    //Nutzer hat (noch) keinen Nutzernamen eingerichtet
                    return ""
                }
            }
        })

        const email = computed(() => {
            if (props.benutzer) {
                return props.benutzer.email
            }
        })

        const nutzerOptionen = computed(() => {
            if (props.benutzer && typeof eigentuemer.value === "boolean") {
                if (gesperrt.value) {
                    return [
                        {
                            id: 0,
                            text: "Entsperren",
                            destruktiv: true,
                            onClick: () => nutzerEntsperrenState.value = true
                        },
                    ]
                } else if (ausstehend.value) {
                    return [
                        {
                            id: 0,
                            text: "Löschen",
                            destruktiv: true,
                            onClick: () => nutzerLoeschenState.value = true
                        }
                    ]
                }

                var optionen = [
                    {
                        id: 0,
                        text: "Passwort Zurücksetzen",
                        destruktiv: false,
                        onClick: () => pwtLinkSendenState.value = true
                    },
                ]

                if (!eigentuemer.value) {
                    optionen.push(...[
                        {
                            id: 1,
                            text: "Sperren",
                            destruktiv: true,
                            onClick: () => nutzerSperrenState.value = true
                        },
                        {
                            id: 0,
                            text: "Löschen",
                            destruktiv: true,
                            onClick: () => nutzerLoeschenState.value = true
                        }
                    ])
                }

                return optionen
            }
        })

        const avatarUrl = ref(null)

        const { error: pwtLinkFehler, pending: pwtLinkLaedt, call: pwtLinkSenden } = useCloudFunction("passwortZuruecksetzenLinkSenden")

        const { error: sperrenFehler, pending: sperrenLaedt, call: sperren } = useCloudFunction("nutzerDeaktivieren")

        const { error: entsperrenFehler, pending: entsperrenLaedt, call: entsperren } = useCloudFunction("nutzerAktivieren")

        const { error: loeschenFehler, pending: loeschenLaedt, call: loeschen } = useCloudFunction("kontoLoeschen")

        function nutzerSperren() {
            if (!sperrenLaedt.value && props.benutzer && typeof nutzername.value == "string") {
                sperren({ uid: props.benutzer.uid })
                    .then(() => {
                        if (!sperrenFehler.value) {
                            erfolgAnzeigen(`"${nutzername.value}" gesperrt.`)
                        } else {
                            fehlerAnzeigen("Fehler beim Sperren des Nutzers.")
                        }
                        nutzerSperrenState.value = false
                    })
            }
        }

        function nutzerEntsperren() {
            if (!entsperrenLaedt.value && props.benutzer && typeof nutzername.value == "string") {
                entsperren({ uid: props.benutzer.uid })
                    .then(() => {
                        if (!entsperrenFehler.value) {
                            erfolgAnzeigen(`${nutzername.value} entsperrt.`)
                        } else {
                            fehlerAnzeigen("Fehler beim Entsperren des Nutzers.")
                        }

                        nutzerEntsperrenState.value = false
                    })
            }
        }

        function linkSenden() {
            if (!pwtLinkLaedt.value && typeof email.value === "string") {
                pwtLinkSenden({ email: email.value })
                    .then(() => {
                        if (pwtLinkFehler.value === false) {
                            erfolgAnzeigen("Nachricht versendet.")
                        } else {
                            fehlerAnzeigen("Fehler beim Versenden der Nachricht.")
                        }
                        pwtLinkSendenState.value = false
                    })
            }
        }

        function nutzerLoeschen() {
            if (loeschenLaedt.value === false) {
                const emailAdresse = email.value
                loeschen({ uid: props.benutzer.uid })
                    .then(() => {
                        if (loeschenFehler.value === false) {
                            erfolgAnzeigen(`${emailAdresse} erfolgreich gelöscht.`)
                        } else {
                            fehlerAnzeigen('Fehler beim Löschen des Benutzers.')
                        }
                    })
            }
        }

        function nutzerOptionenAnzeigen(event) {
            if (nutzerOptionen.value && nutzerOptionen.value.length) {
                kontextMenueAnzeigen(event, nutzerOptionen.value)
            }
        }

        onMounted(() => {
            if (props.benutzer) {
                nutzerProfilbildAbfragen(props.benutzer.uid).then(url => avatarUrl.value = url.url)
            }
        })

        return {
            eigentuemer,
            ausstehend,
            gesperrt,
            nutzername,
            email,
            avatarUrl,
            nutzerOptionenAnzeigen,
            linkSenden,
            pwtLinkSendenState,
            pwtLinkLaedt,
            nutzerSperrenState,
            sperrenLaedt,
            nutzerSperren,
            entsperrenLaedt,
            nutzerEntsperrenState,
            nutzerEntsperren,
            nutzerLoeschenState,
            loeschenLaedt,
            nutzerLoeschen
        }
    }
}
</script>