//Strings für alle Berechtigungen, die ein Nutzer haben kann.

/** Höchstes Berechtigungslevel, erlaubt Zugriff auf alle Bereiche und Funktionen des Systems.
 * Kann nicht erteilt werden, sondern wird automatisch dem Eigentümer der Praxis bei Erstellung der Praxis gegeben.*/
let eigentuemer = "eigentuemer";

/** Erlaubt Nutzern das Verwenden der Web App, und darauf das einsehen der “home” und “Gallerie” Seiten. */
let webAppZugriff = "web-app-zugriff";

/** Erlaubt Nutzern das Verwenden der iOS App, und allen darin enthaltenen Funktionen,
 * bis auf das Erstellen von Patienten und Hinzufügen von Sessionteilnehmern. */
let iosAppZugriff = "ios-app-zugriff";

/** Erlaubt Nutzern neuen Sessions durch Importieren von Aufnahmen in der Web App zu erstellen. */
let sessionsImportieren = "sessions-importieren";

/** Erlaubt Nutzern folgende Änderungen an Sessions:
 * - Patientennummer
 * - Themenliste
 * - Betroffene Zähne
 * - Behandlungsschritt
 * - Hinzufügen von Aufnahmen
 * */
let sessionsVerwalten = "sessions-verwalten";

/** Erlaubt Nutzern Zuschneiden und Löschen von Aufnahmen */
let aufnahmenVerwalten = "aufnahmen-verwalten";

/** Erlaubt Nutzern das Erstellen, umbenennen und Löschen von Themen. */
let themenVerwalten = "themen-verwalten";

/** Erlaubt Nutzern Einladungen zur Zusammenarbeit mit anderen  zu versenden, anzunehmen und zu löschen.
 * Weiterhin erlaubt die Berechtigung, Teilnehmer aus neuen und vorhandenen Sessions zu entfernen und hinzuzufügen. */
let zusammenarbeitVerwalten = "zusammenarbeit-verwalten";

/** Erlaubt Nutzern neue Patienten zu erstellen */
let patientenVerwalten = "patienten-verwalten";

const berechtigungsListe = [
  iosAppZugriff,
  webAppZugriff,
  sessionsImportieren,
  sessionsVerwalten,
  aufnahmenVerwalten,
  themenVerwalten,
  zusammenarbeitVerwalten,
  patientenVerwalten,
];

export {
  eigentuemer,
  webAppZugriff,
  iosAppZugriff,
  sessionsImportieren,
  sessionsVerwalten,
  aufnahmenVerwalten,
  themenVerwalten,
  zusammenarbeitVerwalten,
  patientenVerwalten,
  berechtigungsListe,
};
