import { POSITION, TYPE, useToast } from "vue-toastification";
import StandardToast from "@/components/toasts/StandardToast.vue";
import ToastSchliessenButton from "@/components/ToastSchliessenButton.vue";
import "@/assets/toast_styles.css";

const toast = useToast();

const standardToastAnzeigen = (iconFarbklasse, icon, titel, inhalt) => {
  const toastInhalt = {
    component: StandardToast,
    props: {
      iconFarbklasse: iconFarbklasse,
      icon: icon,
      titel: titel,
      inhalt: inhalt,
    },
  };

  const toastEinstellungen = {
    //Der Toast wird nach 4 Sekunden automatisch geschlossen.
    timeout: 4000,
  };

  toast(toastInhalt, toastEinstellungen);
};

/**
 * Zeigt an der unteren, linken Seite einen Erfolgspopup mit gegebenem Text an.
 * @param {String} text Inhalt des Popups
 */
function erfolgAnzeigen(text) {
  toast.success(text);
}

/**
 * Zeigt an der unteren, linken Seite einen Fehlerpopup mit gegebenem Text an.
 * @param {String} text Inhalt des Popups
 */
function fehlerAnzeigen(text) {
  toast.error(text);
}

/**
 * Zeigt den anders positionierten und gestylten Toast für Anmeldefehler.
 * @param {string} text Anzuzeigender Text
 */
function anmeldeFehlerAnzeigen(text) {
  toast(text, {
    type: TYPE.ERROR,
    position: POSITION.BOTTOM_LEFT,
    hideProgressBar: true,
    timeout: 4000,
    closeButton: ToastSchliessenButton,
  });
}

export {
  standardToastAnzeigen,
  erfolgAnzeigen,
  fehlerAnzeigen,
  anmeldeFehlerAnzeigen,
};
