<template>
    <div 
        class="w-24 h-24 relative mr-3 mb-3"
        @mouseenter="hover = true"
        @mouseleave="hover = false"
        @click="loeschen">
        <div v-if="!fotoUri" 
            class="absolute z-0 w-full h-full rounded-xl bg-DentaBlue bg-opacity-[0.06]">
        </div>
        <img 
            v-else
            class="absolute z-0 w-full aspect-square object-cover rounded-xl" 
            :src="fotoUri" 
            alt="foto">
        
        <div 
            v-if="hover" 
            class="absolute z-10 w-full h-full rounded-xl bg-opacity-20 flex justify-center items-center p-3 transition duration-100 hover:cursor-pointer hover:bg-black hover:bg-opacity-20"
            style="backdrop-filter: blur(1px);">
            <img class="w-6 aspect-square object-contain" src="@/assets/icons/loeschen-rot.svg" alt="löschen">
        </div>
    </div>
</template>
<script>
import { fileToDataUri } from '@/services/helfer';
import { nextTick, onMounted, ref } from 'vue';

export default {
    props: ["foto"],
    emits: ["loeschen"],
    setup(props, context) {
        const hover = ref(false)
        const fotoUri = ref(null)

        const loeschen = () => {
            if(hover.value === true) {
                context.emit("loeschen")
            }
        }

        onMounted(() => {
            nextTick(() => {
                if(props.foto) {
                    fileToDataUri(props.foto)
                    .then(f => fotoUri.value = f)
                }
            })
        })

        return {hover, fotoUri, loeschen}
    }
}
</script>