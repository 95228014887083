<!-- Vorlage für "Aktionsbuttons", die oben rechts auf Seiten zu finden sind. -->

<template>
  <div
    class="bg-white px-4 h-11 rounded-xl flex justify-center items-center text-xl text-DentaBlue transition duration-150 hover:shadow-md hover:bg-opacity-70 hover:cursor-pointer"
    @click="buttonGedrueckt">
    <!-- Icon -->
    <ion-icon :name="iconName" class="text-2xl"></ion-icon>
  </div>
</template>
  
<script>
export default {
  props: ["iconName"],
  emits: ["gedrueckt"],
  setup(props, context) {

    //Wird bei einem Click auf den Button aufgerufen
    const buttonGedrueckt = () => context.emit("gedrueckt")

    return { buttonGedrueckt }
  }
}
</script>
  
<style>
  
  </style>