<template>
  <div
    class="
      flex
      items-center
      justify-center
      space-x-2
      animate-pulse
    "
  >
    <div class="w-4 h-4 bg-black rounded-full"></div>
    <div class="w-4 h-4 bg-black rounded-full"></div>
    <div class="w-4 h-4 bg-black rounded-full"></div>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>