<template>
  <div class="bg-Hintergrund w-screen h-screen flex">
    <NavigationsLeiste />
    <div class="w-full h-screen overflow-auto">
      <router-view class="w-full h-full" :key="$route.fullPath" />
    </div>
  </div>
</template>

<script>
import Navbar from "./components/navigationbar/Navbar.vue";
import NavigationsLeiste from "./components/navigations-leiste/NavigationsLeiste.vue"
import store from "./store";
import {
  berechtigungenPruefen
} from "@/services/authentifizierungsDienst";
import {
  sessionsZuhoeren,
  patientenZuhoeren,
  partnerschaftenZuhoeren,
  instandhaltungPruefen,
} from "@/services/firestoreDienst";
import { onMounted, watchEffect } from "@vue/runtime-core";
import { useRouter, useRoute } from "vue-router";
import { ausstehendeKonfigurationenPruefen } from "./services/helfer";
import { eigentuemer } from "./constants/konstanten";
import { routenNamen, pfade } from "./constants/mobile-sperrung-ausnahmen";
export default {
  components: { NavigationsLeiste },
  setup() {
    let router = useRouter()
    let route = useRoute()

    //Überwachung für Displaygröße wird eingerichtet, und die Erste Prüfung durchgeführt
    addEventListener("resize", (event) => {
      store.commit("mobileSetzen", event.target.innerWidth < 640)
      if (event.target.innerWidth < 640 && !(routenNamen.some(v => v === route.name) || pfade.find(p => route.fullPath.includes(p)) != undefined)) {
        router.push({ name: "MobileSperrung" })
      }
    }
    );
    store.commit("mobileSetzen", window.innerWidth < 640);

    //Die beiden Instandhaltungsausnahmen werden geprüft, und wenn einer zutrifft die
    //entsprechende Seite geladen
    onMounted(() => instandhaltungPruefen());

    //Wenn sich die aktuelle Route und Konto Konfiguration des Nutzers ändert, wird geprüft
    //ob ausstehende Konfigurationsschritte vorliegen (Boolean ak)
    watchEffect(() => {
      let rt = router.currentRoute.value;
      let kk = store.state.kontoKonfiguration;
      var ak =
        kk != null
          ? ausstehendeKonfigurationenPruefen(
            kk,
            berechtigungenPruefen([eigentuemer])
          )
          : null;

      // Wenn erkannt wurde, das ausstehende Konfigurationsschritte vorliegen, 
      // wird der entsprechende Dialog geöffnet
      if (
        kk != null &&
        ak != null &&
        ak === true &&
        rt.path.includes("anmeldung") === false &&
        rt.path.includes("kontoKonfiguration") === false &&
        rt.path.includes("update") === false &&
        rt.path.includes("wartung") === false &&
        rt.path.includes("praxis-gesperrt") === false &&
        rt.path.includes("laden") === false
      ) {
        console.log("Nutzer wurde aufgrund der Konfig zur Konto Konfiguration weitergeleitet");
        router.push({ name: "KontoKonfiguration" })
      }
    });

    watchEffect(() => {
      if (
        store.state.praxis &&
        store.state.praxis.id &&
        !store.state.sessions &&
        !store.state.snaps
      ) {
        //Den Patienten der angemeldeten Praxis wird zugehört
        patientenZuhoeren(store.state.praxis.id);

        //Den Partnerschaften der Praxis wird zugehört
        partnerschaftenZuhoeren(store.state.praxis.id);

        //Den Sessions der angemeldeten Praxis wird zugehört
        sessionsZuhoeren(store.state.praxis.id);
      }
    });
  },
};
</script>

<style>
html {
  width: 100vw;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.inputfile {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

img {
  pointer-events: none;
}
</style>
