<template>
    <div class="w-full px-10 py-6 rounded-2xl bg-TextField flex flex-col space-y-3 items-start">
        <p class="w-full text-sm text-MittelGrau">Zahlungsmethode</p>
        <div class="flex space-x-4 justify-center items-center">
            <!-- Icon -->
            <img :src="logo" :alt="name" class="w-10 aspect-auto object-contain">

            <!-- Name -->
            <p class="text-xl tracking-wide">{{ name }}</p>
        </div>
        <div class="w-full flex flex-col space-y-2 pt-3 justify-start items-start">
            <p class="text-sm font-bold">Wichtiger Hinweis:</p>
            <span class="text-xs" v-html="abrechnungsHinweis"></span>
        </div>

        <div v-if="zahlungsmethode == 'giropay'" class="w-full flex flex-col space-y-2 pt-3 justify-start items-start">
            <p class="text-sm font-bold">SEPA-Lastschriftmandat:</p>
            <span class="text-xs">Ich ermächtige die JMS Invent GmbH, Zahlungen von meinem Konto mittels Lastschrift
                einzuziehen. Zugleich weise ich mein Kreditinstitut an, die von der JMS Invent GmbH auf mein Konto
                gezogenen
                Lastschriften einzulösen.<br /><br />Hinweis: Ich kann innerhalb von acht Wochen, beginnend mit dem
                Belastungsdatum, die Erstattung des belasteten Betrages verlangen. Es gelten dabei die mit meinem
                Kreditinstitut vereinbarten Bedingungen.</span>
        </div>
    </div>
</template>
<script>
import { computed } from 'vue';
export default {
    props: ["zahlungsmethode", "jaehrlich"],
    setup(props) {
        const name = computed(() => {
            switch (props.zahlungsmethode) {
                case "creditcard": return "Kreditkarte"
                case "giropay": return "GiroPay"
                case "paypal": return "PayPal"
                default: return ""
            }
        })

        const logo = computed(() => {
            switch (props.zahlungsmethode) {
                case "creditcard": return require("@/assets/pm-logos/kreditkarte.png")
                case "giropay": return require("@/assets/pm-logos/giropay.png")
                case "paypal": return require("@/assets/pm-logos/paypal.png")
                default: return ""
            }
        })

        /** Generiert mit der Frist einen allgemeinen Abrechnungshinweis für jede Zahlungsmethode. Muss als HTML Element eingebunden werden! */
        const allgemeinenAbrechnungsHinweisGenerieren = (jaehrlich) =>
            `Mit Ihrem Abonnementstart autorisieren Sie die JMS Invent GmbH zur ${jaehrlich === true ? "jährlichen" : "monatlichen"
            } automatischen Fakturierung bis zur Kündigung. Preisänderungen werden vorab kommuniziert. Details zur automatischen Verlängerung finden Sie in den Einstellungen Ihrer Organisation. Es gelten die <a>allgemeinen Geschäftsbedingungen.</a>`;


        const abrechnungsHinweis = computed(() => allgemeinenAbrechnungsHinweisGenerieren(props.frist))

        return {
            name,
            logo,
            abrechnungsHinweis
        }
    }
}
</script>