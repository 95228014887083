<template>
  <div v-if="laedt === true" class="w-full h-full flex rounded-xl bg-white p-5">

  </div>
  <div v-else class="w-full h-full relative font-dm-sans">
    <img :src="log.coverThumnail" alt="" class="absolute z-0 w-full h-full object-cover rounded-xl">
    <div class="absolute z-10 w-full h-full bg-black bg-opacity-30 rounded-xl"></div>
    <div class="absolute z-20 w-full h-full flex flex-col space-y-4 items-center py-5 px-8">
      <!-- Titel und "Button" -->
      <div class="w-full h-1/5 flex justify-between items-center space-x-3">
        <div class="flex flex-col justify-start items-start text-white">
          <p class="font-bold text-lg sm:text-xl">{{ log.titel }}</p>
          <p class="text-xs">Version {{ log.webVersion }}</p>
        </div>
        <!-- Wenn Klickbare Logs vorhanden sind, wieder einschalten! -->
        <!-- <div
          class="max-w-[6rem] w-1/3 h-8 rounded-full bg-white bg-opacity-20 flex justify-center items-center text-white text-sm"
          style="backdrop-filter: blur(5px);">
          <p>Entdecken</p>
        </div> -->
      </div>

      <div class="w-full h-full flex space-x-3 items-center py-4">
        <div v-for="feat in featurePreview" :key="feat.id"
          class="w-full h-full bg-white bg-opacity-20 rounded-xl flex flex-col justify-start items-center text-center text-white text-sm space-y-3 pt-8"
          style="backdrop-filter: blur(5px);">
          <img :src="feat.icon" alt="icon" class="w-1/3 aspect-square object-contain">
          <p class="px-3">{{ feat.titel }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, onMounted, ref } from 'vue';
import store from '@/store';
import { updateLogAbfragen } from '@/services/cmsDienst';
import { useRouter } from 'vue-router';
export default {
  setup() {
    const router = useRouter()
    const laedt = computed(() => store.state.updateLogLaedt)
    const log = computed(() => store.state.updateLog)

    const featurePreview = computed(() => {
      if (log.value != null) {
        return log.value.features.splice(0, 3)
      }
    })

    /** Lädt das Update Log passend zur Appversion und speichert es, falls vorhanden, im Store. */
    function logLaden() {
      updateLogAbfragen().then(l => store.commit("updateLogSetzen", l))
    }

    // const toUpdate = () => {
    //   if(laedt.value === false) {
    //     //TODO: Hier Link zur "updates" Seite der DentaSnap Homepage generieren und öffnen
    //     const url = 'https://dentasnap.de/guides';
    //     window.open(url, '_blank');
    //   }
    // }

    onMounted(logLaden)

    return {
      laedt,
      log,
      featurePreview,
    }
  },
};
</script>