<template>
  <div class="w-full h-full flex flex-col justify-center items-center p-5">

    <!-- Container mit Fall-Karten -->
    <div class="w-full h-full flex flex-wrap justify-start items-start pt-5 pl-5 pb-2 pr-2 rounded-md bg-TextField mb-5">

      <!-- Fall-Karte -->
      <div class="w-1/3" v-for="fall in faelle" :key="fall.id">
          <div class="w-full aspect-video pr-3 pb-3">
            <div 
              class="bg-white rounded-md p-3 flex flex-col w-full aspect-video justify-start items-start transition duration-150 hover:cursor-pointer hover:bg-opacity-50"
              @click="vorhandenenFallGewaehlt(fall)">
            
            <div class="w-full h-1/2 flex justify-between items-start">

              <!-- Themen -->
              <div class="w-full h-full flex flex-wrap justify-start items-start overflow-hidden">
                <div v-for="thema in fall.themen" :key="thema.id" class="text-xs rounded-md bg-Hintergrund text-DentaBlue px-2 py-1 mr-2 mb-2">{{thema.name}}</div>
              </div>

              <!-- Datum -->
              <p class="text-sm ml-2 text-Mittelgrau">{{datumAbfragen(fall.hochladeZeit)}}</p>

            </div>

            <div class="h-1/2 w-full flex justify-between items-end">

              <!-- Schritt -->
              <div class="flex flex-col justify-start items-start">
                <p class="text-xs text-Mittelgrau">Schritt</p>

                <div class="flex justify-start items-start">
                  <div 
                    v-for="index in fall.schritt + 1" 
                    :key="index" 
                    class="w-6 aspect-square rounded-md text-sm flex justify-center items-center"
                    :class="{
                      'bg-TextField text-black mr-1' : index < fall.schritt + 1,
                      'bg-DentaBlue text-white': index == fall.schritt + 1
                    }">
                    <p>{{index}}</p>
                  </div>
                </div>
              </div>

              <div class="flex flex-wrap">
              <div
                v-for="zahn in fall.zaehne"
                :key="zahn.nummer"
                class="flex flex-col items-center mr-1">
                <img
                  class="w-full h-8 pb-1 object-contain"
                  :src="zahnIconAbfragen(zahn.nummer, true, zahn.implantat)"
                  :alt="zahn.nummer">

                  <p class="text-DentaBlue text-xxs">{{zahn.nummer}}</p>
              </div>
            </div>

            </div>
        </div>
          </div>
      </div>
    </div>
    
    <div 
      class="px-6 py-3 bg-DentaBlue text-white rounded-md transition duration-150 hover:cursor-pointer hover:bg-opacity-70"
      @click="neuenFallGewaehlt">
      <p>Neuer Fall</p>
    </div>
  </div>
</template>

<script>
//Alle benötigten Frameworks und Vue-Komponenten werden importiert
import dayjs from 'dayjs'

//Alle benötigten Helfer und Konstanten werden importiert
import { zahnIconAbfragen } from '@/services/helfer'
import { einmaligenStringGenerieren } from '@/services/firestoreDienst'

export default {
    props: ["faelle", "patientNummer"],
    emits: ["gewaehlt"],

    setup(props, context) {

      function datumAbfragen(zeitstempel) {
        return dayjs.unix(zeitstempel).format("DD.MM.YYYY").toString()
      }

      function vorhandenenFallGewaehlt(fall) {
        context.emit("gewaehlt", fall.id)
      }

      function neuenFallGewaehlt() {
        context.emit("gewaehlt", einmaligenStringGenerieren())
      }

      return {
        //Funktionen
        datumAbfragen,
        zahnIconAbfragen,
        vorhandenenFallGewaehlt,
        neuenFallGewaehlt
      }
    }
}
</script>