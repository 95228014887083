<template>
    <div class="w-full h-screen flex flex-col space-y-3 justify-center items-center font-dm-sans">
        <LottieAnimation class="w-72 aspect-square" :animation-data="ladenBlau" :auto-play="true" :loop="true" />
        <p class="text-xl font-bold text-DentaBlue">Ansicht wird vorbereitet...</p>
    </div>
</template>
<script>
import ladenBlau from "@/assets/animations/laden-blau.json"
import store from "@/store";
import { useRouter } from "vue-router";
import { LottieAnimation } from "lottie-web-vue";
import { watchEffect, nextTick, onMounted } from "vue";
export default {
    components: {
        LottieAnimation
    },
    setup() {

        const router = useRouter()

        watchEffect(() => {
            if (
                !store.state.kontoKonfigurationLaedt &&
                !store.state.praxisLaedt &&
                !store.state.abonnementLaedt &&
                !store.state.wartungLaedt &&
                !store.state.updateNoetigLaedt) {
                const weiterleitung = store.state.ladeWeiterleitung

                debugger;

                console.log("Von Ladeseite weitergeleitet.");

                nextTick().then(() => router.push(weiterleitung ?? { path: "/" }))
            }
        })
        return {
            ladenBlau
        }
    }
}
</script>