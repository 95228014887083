<template>
  <div class="w-full h-10 flex justify-between items-center">
    <!-- Linker Button -->
    <div
      v-if="konfiguration && konfiguration.links"
      class="w-full sm:w-48 h-10 bg-TextField text-sm flex justify-center items-center rounded-md"
      :class="{
        'mr-2': konfiguration.rechts != null,
        'text-DentaBlue transition duration-150 hover:cursor-pointer hover:bg-opacity-70 pointer-events-auto':
          konfiguration.links.aktiv === true &&
          konfiguration.links.laed === false,
        'text-Mittelgrau pointer-events-none':
          konfiguration.links.aktiv === false ||
          konfiguration.links.laed === false,
      }"
      @click="linkeAktion"
    >
      <p v-if="!konfiguration.links.laed || konfiguration.links.laed === false">
        {{ konfiguration.links.text }}
      </p>
      <LottieAnimation
        v-else
        class="w-16 h-16"
        ref="anim"
        :animationData="
          konfiguration.links.aktiv === true ? laedBlau : laedGrau
        "
        :loop="true"
        :autoPlay="true"
        :speed="1"
      />
    </div>

    <div></div>

    <!-- Rechter Button -->
    <div
      v-if="konfiguration && konfiguration.rechts"
      class="w-full sm:w-48 h-10 bg-TextField text-sm flex justify-center items-center rounded-md"
      :class="{
        'ml-2': konfiguration.links && konfiguration.rechts.text,
        'text-DentaBlue transition duration-150 hover:cursor-pointer hover:bg-opacity-70 pointer-events-auto':
          konfiguration.rechts.aktiv === true &&
          konfiguration.rechts.laed === false,
        'text-Mittelgrau pointer-events-none':
          konfiguration.rechts.aktiv === false ||
          konfiguration.rechts.laed === true,
      }"
      @click="rechteAktion"
    >
      <p
        v-if="!konfiguration.rechts.laed || konfiguration.rechts.laed === false"
      >
        {{ konfiguration.rechts.text }}
      </p>
      <LottieAnimation
        v-else
        class="w-16 h-16"
        ref="anim"
        :animationData="
          konfiguration.rechts.aktiv === true ? laedBlau : laedGrau
        "
        :loop="true"
        :autoPlay="true"
        :speed="1"
      />
    </div>
  </div>
</template>

<script>
import { LottieAnimation } from "lottie-web-vue";
const laedBlau = require("../assets/laed_blau.json");
const laedGrau = require("../assets/laed_grau.json");
export default {
  components: { LottieAnimation },
  props: ["konfiguration"],

  setup(props) {
    function rechteAktion() {
      if (
        props.konfiguration != undefined &&
        props.konfiguration.rechts != undefined &&
        props.konfiguration.rechts.aktiv === true
      ) {
        props.konfiguration.rechts.aktion();
      }
    }

    function linkeAktion() {
      if (
        props.konfiguration != undefined &&
        props.konfiguration.links != undefined &&
        props.konfiguration.links.aktiv === true
      ) {
        props.konfiguration.links.aktion();
      }
    }

    return { rechteAktion, linkeAktion, laedBlau, laedGrau };
  },
};
</script>