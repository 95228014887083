<template>
    <div class="w-full h-screen flex flex-col justify-between py-12 px-8 font-dm-sans">
        <span class="text-4xl font-bold">Gerät<br />inkompatibel.</span>
        <div class="w-full flex flex-col justify-center items-center space-y-10">
            <div class="flex space-x-2 items-end">
                <img src="@/assets/icons/desktop-blau.svg" alt="desktop" class="h-24">
                <img src="@/assets/icons/laptop-blau.svg" alt="desktop" class="h-16">
            </div>
            <span class="text-center">DentaSnap Web wurde für den großen
                Bildschirm entwickelt. Bitte kehren Sie auf
                einem Desktop oder Laptop zurück.</span>
        </div>
        <div></div>
    </div>
</template>
<script>
import router from '@/router';
import { onMounted } from 'vue';

export default {
    setup() {
        function checkWindowSize() {
            // Define the breakpoint for non-mobile size
            const nonMobileWidth = 768; // Adjust this value as needed
            if (window.innerWidth > nonMobileWidth) {
                // Redirect to the root route if the window size is non-mobile
                router.push('/');
            }
        }

        onMounted(() => {
            window.addEventListener("resize", checkWindowSize)
            checkWindowSize()
        })
    }
}
</script>
<style lang="">
    
</style>