<!-- Genereller Zuschneidedialog. Gibt mit dem "bearbeitet" event den fertigen Dateiblob aus. -->

<template>
  <div class="w-full h-full flex flex-col justify-center items-center"
    :class="{ 'p-5': istMobile, 'py-10 px-14': !istMobile }">
    <!-- Titel -->
    <div class="w-full flex justify-center items-between">
      <p class="w-full text-2xl font-bold mb-5">Foto Bearbeiten</p>
      <ion-icon v-if="!istAvatar || istAvatar === false"
        class="text-3xl transition duration-150 hover:cursor-pointer hover:scale-110" name="close-outline"
        @click="schliessen"></ion-icon>
    </div>

    <!-- Zuschneidewidget -->
    <Cropper ref="cropper" class="w-full" :class="{ 'aspect-3/2': !istMobile, 'aspect-[9/16]': istMobile }" :src="url"
      :stencil-props="stencilProps"
      :stencil-component="istAvatar == true ? $options.components.CircleStencil : $options.components.RectangleStencil"
      :default-size="defaultSize" backgroundClass="imageBackground" :auto-zoom="true" :move-image="false" />

    <!-- Buttonleiste -->
    <div class="w-full h-14 flex justify-between items-center text- mt-5">
      <!-- "Zurücksetzen" Button -->
      <div
        class="w-16 h-16 flex flex-col text-center justify-center items-center rounded-md transition duration-150 hover:cursor-pointer hover:bg-UltraLeichtesGrau"
        @click="zurueckSetzen">
        <ion-icon name="trash" class="text-lg overflow-clip"></ion-icon>
        <p class=" text-xs">Zurück-<br />setzen</p>
      </div>

      <!-- Spacer -->
      <div v-if="istAvatar == false" class="w-0.5 h-5 rounded-full bg-Mittelgrau"></div>

      <!-- "Drehen" Buttons -->
      <div class="flex">

        <!-- Links -->
        <div class="w-16 h-16 flex flex-col justify-center items-center mr-5 rounded-md transition duration-150" :class="{
      'hover:cursor-pointer hover:bg-UltraLeichtesGrau': drehenAktiviert == true,
      'text-gray-400': drehenAktiviert == false
    }" @click="drehen(true)">
          <ion-icon name="return-up-back" class="text-lg"></ion-icon>
          <p class="text-xs">Links</p>
        </div>

        <!-- Rechts -->
        <div class="w-16 h-16 flex flex-col justify-center items-center rounded-md transition duration-150" :class="{
      'hover:cursor-pointer hover:bg-UltraLeichtesGrau': drehenAktiviert == true,
      'text-gray-400': drehenAktiviert == false
    }" @click="drehen(false)">
          <ion-icon name="return-up-forward" class="text-lg"></ion-icon>
          <p class=" text-xs">Rechts</p>
        </div>
      </div>

      <!-- Spacer -->
      <div v-if="istAvatar == false" class="w-0.5 h-5 rounded-full bg-DunklesGrau"></div>

      <!-- Zuschneidekontrollen -->
      <div v-if="istAvatar == false" class="flex">
        <div
          class="w-16 h-16 flex flex-col justify-center items-center mr-5 rounded-md transition duration-150 hover:cursor-pointer"
          :class="{
      'hover:bg-UltraLeichtesGrau': seitenVerhaeltnis != 3 / 2,
      'bg-DentaBlueLight text-DentaBlue': seitenVerhaeltnis == 3 / 2
    }" @click="seitenverhaeltnisSetzen(3 / 2)">
          <ion-icon class="text-lg mb-1" name="tablet-landscape-outline"></ion-icon>
          <p class="text-xs">3 : 2</p>
        </div>

        <div
          class="w-16 h-16 flex flex-col justify-center items-center mr-5 rounded-md transition duration-150 hover:cursor-pointer"
          :class="{
      'hover:bg-UltraLeichtesGrau': seitenVerhaeltnis != 2 / 3,
      'bg-DentaBlueLight text-DentaBlue': seitenVerhaeltnis == 2 / 3
    }" @click="seitenverhaeltnisSetzen(2 / 3)">
          <ion-icon class="text-xl mb-1" name="tablet-portrait-outline"></ion-icon>
          <p class="text-xs">2 : 3</p>
        </div>

        <div
          class="w-16 h-16 flex flex-col justify-center items-center rounded-md transition duration-150 hover:cursor-pointer"
          :class="{
      'hover:bg-UltraLeichtesGrau': seitenVerhaeltnis != -1,
      'bg-DentaBlueLight text-DentaBlue': seitenVerhaeltnis == -1
    }" @click="seitenverhaeltnisSetzen(-1)">
          <ion-icon class="text-xl mb-1" name="scan"></ion-icon>
          <p class="text-xs">Frei</p>
        </div>
      </div>

      <!-- Spacer -->
      <div v-if="istAvatar == false" class="w-0.5 h-5 rounded-full bg-Mittelgrau"></div>

      <!-- Anwenden -->
      <div v-if="!istMobile"
        class="px-4 py-2 flex justify-center items-center rounded-full bg-DentaBlue text-white transition duration-150 hover:cursor-pointer hover:bg-opacity-70"
        @click="speichern">
        <p>Anwenden</p>
      </div>
      <button v-else class="w-12 h-12 rounded-full bg-DentaBlue text-white flex justify-center items-center"
        @click="speichern">
        <img src="@/assets/icons/haken-weiss.svg" alt="ok" class="w-5">
      </button>
    </div>

  </div>
</template>

<script>
//Alle benötigten Vue-Komponenten und Frameworks werden importiert
import { Cropper, CircleStencil, RectangleStencil } from 'vue-advanced-cropper';
import 'vue-advanced-cropper/dist/style.css';
import { ref, computed } from '@vue/reactivity';
import { watch } from '@vue/runtime-core';
import store from '@/store';

export default {
  components: {
    Cropper,
    CircleStencil,
    RectangleStencil
  },

  props: ["url", "istAvatar"],
  emits: ["gespeichert", "schliessen"],

  setup(props, context) {
    const istMobile = computed(() => store.state.mobile)

    //Referenz zum "cropper" Element selbst
    const cropper = ref(null)

    //Gibt an, ob drehungen des Bildes aktuell möglich sind (nur eine Drehung darf auf einmal getätigt werden!)
    const drehenAktiviert = ref(true)

    //Gibt entweder das seitenverhältnis an, auf das die Vorlage beschränkt sein soll, 
    //oder -1 wenn keine Beschränkung festgelegt ist.
    const seitenVerhaeltnis = ref(null)

    //Speichert die Konfiguration für die Vorlage
    const stencilProps = ref({ aspectRatio: seitenVerhaeltnis.value })

    //Überwacht änderungen am Seitenverhältnis, und wendet sie entsprechend auf die Vorlageoptionen an.
    watch(seitenVerhaeltnis, (neuerWert) => {
      if (neuerWert == -1) {
        delete stencilProps.value.aspectRatio
      } else if (neuerWert != null && typeof neuerWert == "number") {
        stencilProps.value.aspectRatio = neuerWert
      }
      cropper.value.refresh()

      cropper.value.setCoordinates(({ coordinates, imageSize }) => ({
        width: imageSize.width,
        height: imageSize.height
      }))
    })

    //Wird vom "cropper" Widget genutzt, um die Vorlage auf die Größe des Vorhandenen Bildes zu initialisieren
    function defaultSize({ imageSize, visibleArea }) {
      return {
        width: imageSize.width,
        height: imageSize.height,
      };
    }

    //Wird aufgerufen, um das bild um 90° (links == true) oder -90° (links == false) zu drehen
    function drehen(links) {
      seitenVerhaeltnis.value = -1
      if (drehenAktiviert.value == true) {
        drehenAktiviert.value = false
        cropper.value.rotate(links ? -90 : 90)

        setTimeout(() => {
          cropper.value.setCoordinates(({ coordinates, imageSize }) => ({
            width: imageSize.width,
            height: imageSize.height
          }))
          drehenAktiviert.value = true
        }, 350)
      }
    }

    //Setzt das "cropper" Widget auf seinen Ursprung zurück
    function zurueckSetzen() {
      cropper.value.reset()
    }

    //Setzt das Seitenverhältnis auf den gegebenen Wert
    function seitenverhaeltnisSetzen(wert) {
      seitenVerhaeltnis.value = wert
    }

    //Gibt das "bearbeitet" Event ab, mit dem Blob der fertigen Datei
    function speichern() {
      const { canvas } = cropper.value.getResult();
      canvas.toBlob(
        blob => {
          context.emit("gespeichert", blob)
          schliessen()
        }, "image/jpeg", 0.75)
    }

    //Gibt das "schliessen" Event ab
    function schliessen() {
      context.emit("schliessen")
    }

    return {
      //State für das UI
      cropper,
      drehenAktiviert,
      stencilProps,
      seitenVerhaeltnis,
      istMobile,

      //Funktionen für das UI
      drehen,
      zurueckSetzen,
      speichern,
      seitenverhaeltnisSetzen,
      defaultSize,
      schliessen,
    }
  }
}
</script>

<style>
  .cropperBackground {
    background-color: transparent;
  }
  
  .imageBackground {
    background-color: white;
  }
  
  .bg {
    opacity: 0%;
  }
    
  </style>