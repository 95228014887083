<template>
    <div>
      <p
        class="px-3 py-1 text-xs rounded-lg flex mr-2 mb-2"
        :class="{
          'text-DentaBlue bg-LeichtesBlau': vorraussetzung === true,
          'text-Mittelgrau bg-TextField': vorraussetzung === false,
        }"
      >
        {{ text }}
      </p>
    </div>
  </template>
  
  <script>
  export default {
    props: ["vorraussetzung", "text"]
  };
  </script>