<template>
    <div class="w-full h-full flex justify-center items-center">
        <div class="w-100 flex flex-col justify-start items-center">
            <LottieAnimation class="h-83 mb-3" :animation-data="animationsDaten" :loop="true" />
            <div class="px-10 flex flex-col justify-center items-center space-y-2">
                <p class="text-4xl font-bold">{{ titel }}</p>
                <p class="text-lg text-Mittelgrau text-center">{{ body }}</p>
            </div>
            <img v-if="appStoreQrAnzeigen === true" src="@/assets/images/appstore-qr.png" alt="download-on-appstore"
                class="w-20 mt-6">
        </div>
    </div>
</template>
<script>
import { LottieAnimation } from 'lottie-web-vue';
export default {
    components: { LottieAnimation },
    props: ["animationsDaten", "titel", "body", "appStoreQrAnzeigen"],
}
</script>