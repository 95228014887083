<template>
  <div class="relative">
    <div
      class="w-full h-full absolute flex justify-center items-center rounded-lg bg-white space-x-4"
    >
      <VideoPlatzhalter v-for="i in 5" :key="i" class="opacity-30" />
    </div>

    <div
      class="w-full h-full absolute flex flex-col justify-center items-center"
    >
      <p class="text-2xl font-bold">Coming Soon.</p>
      <p>Video Guides und mehr bald verfügbar!</p>
    </div>
  </div>
</template>
  
  <script>
import VideoPlatzhalter from "./VideoPlatzhalter.vue";
export default {
  components: {
    VideoPlatzhalter,
  },
};
</script>