<template>
    <EingabeDialog :state="state" titel="Patient Bearbeiten" bestaetigungsText="Bearbeiten" :bestaetigungAktiv="gueltig"
        :laedt="laedt" @abgebrochen="schliessen" @bestaetigt="bearbeiten">
        <form class="w-full flex flex-col space-y-2" @submit="bearbeiten">
            <input ref="nameRef" class="default-textfield-dynamic-length" type="text" v-model="name"
                placeholder="Vor -und Nachname">
            <div class="w-full flex space-x-2">
                <!-- Geburtsdatum -->
                <div class="w-64">
                    <VueDatePicker text-input format="dd.MM.yyyy" :placeholder="placeHolder" v-model="gb" ref="gbRef"
                        :hide-input-icon="true" :auto-apply="true" @focus="gbFokussiert = true" @open="closeMenu">
                    </VueDatePicker>
                </div>

                <!-- Patientennummer -->
                <input class="default-textfield-dynamic-length w-full" type="text" v-model="nummer"
                    placeholder="Patientennummer">
            </div>
        </form>
    </EingabeDialog>
</template>
<script>
import EingabeDialog from '@/components/dialoge/EingabeDialog.vue';
import { nummerPruefRegex } from '@/constants/konstanten';
import { firestore } from '@/firebase/config';
import { useCloudFunction } from '@/services/cloudFunctionDienst';
import { erfolgAnzeigen, fehlerAnzeigen } from '@/services/toastDient';
import dayjs from 'dayjs';
import { doc, setDoc } from 'firebase/firestore';
import { computed, nextTick, onMounted, ref, watch } from 'vue';
export default {
    components: { EingabeDialog },
    props: ["state", "patient"],
    emits: ["schliessen", "erstellt"],
    setup(props, context) {
        const { pending: laedt, error: fehler, result: erg, call: aufrufen } = useCloudFunction("patientBearbeiten")

        const name = ref(props.patient && props.patient.name ? props.patient.name : "")
        const nameRef = ref(null)

        const nummer = ref(props.patient && props.patient.nummer ? props.patient.nummer : "")

        const gb = ref(props.patient && props.patient.geburtsDatum ? new Date(props.patient.geburtsDatum * 1000) : null)
        const gbRef = ref(null)
        const gbText = ref("")
        const gbFokussiert = ref(false)

        const placeHolder = computed(() => gbFokussiert.value ? "tt.mm.jjjj" : "Geb. Datum")

        const closeMenu = () => {
            if (gbRef.value) {
                gbRef.value.closeMenu()
            }
        }

        const geburtsDatum = computed(() => {
            if (gb.value != null) {
                const date = dayjs(gb.value)

                if (date.isValid()) {
                    return date.format("DD.MM.YYYY")
                }
            }
        })

        const gueltig = computed(() => {
            return props.patient != null && typeof name.value === "string" &&
                typeof nummer.value === "string" &&
                nummerPruefRegex.test(nummer.value) &&
                typeof geburtsDatum.value === "string" &&
                dayjs(gb.value).isValid()
                && ((name.value != "" && name.value != props.patient.name) || nummer.value != props.patient.nummer || (typeof props.patient.geburtsDatum !== "number" || dayjs.unix(props.patient.geburtsDatum).format("DD.MM.YYYY") != geburtsDatum.value))
        })

        function bearbeiten() {
            if (gueltig.value) {
                aufrufen({
                    id: props.patient.id,
                    nummer: nummer.value,
                    name: name.value,
                    geburtsDatum: geburtsDatum.value
                }).then(() => {
                    if (fehler.value === false) {
                        erfolgAnzeigen("Änderungen Gespeichert.")
                        context.emit("bearbeitet")
                    } else {
                        fehlerAnzeigen("Bei der Bearbeitung ist ein Fehler aufgetreten.")
                    }
                })
                    .finally(() => context.emit("schliessen"))
            }
        }

        watch(() => props.patient, val => {
            if (val != null) {
                name.value = val.name ?? ""
                nummer.value = val.nummer ?? ""
                gb.value = typeof val.geburtsDatum === "number" ? new Date(val.geburtsDatum * 1000) : null
            }
        })

        const schliessen = () => context.emit("schliessen")

        return { name, nummer, gb, gbRef, gbText, gueltig, gbFokussiert, placeHolder, laedt, closeMenu, bearbeiten, schliessen }
    }
}
</script>