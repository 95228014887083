<template>
  <form class="flex space-x-2" @submit.prevent="submitForm">
    <input v-for="digit in digits" :key="digit" :ref="el => { digitRefs[digit] = el; }"
      @input="event => eingabe(digit, event)" @keydown="event => zeichenGeloescht(digit, event)"
      @click="event => clickBehandeln(digit, event)" maxlength="1"
      class="w-12 py-4 bg-UltraLeichtesGrau rounded-md focus:outline-none text-center font-dm-sans text-2xl"
      v-model="digitTexts[digit].value" />
  </form>
</template>
  
<script>
import { ref, reactive, nextTick, computed, onMounted, watch, watchEffect } from 'vue';

export default {
  props: ["zuruecksetzen"],
  emits: ["aktualisiert"],
  setup(props, context) {
    const digits = Array.from({ length: 6 }, (_, i) => i);
    const digitTexts = Array.from({ length: 6 }, (_, i) => ref(''));
    const digitRefs = reactive({}); // Use a reactive object instead of an array

    //Setzt das Ziffernfeld vollständig zurück, und fokussiert das erste Feld.
    function zuruecksetzen() {
      for (let i = 0; i < digitTexts.length; i++) {
        digitTexts[i].value = ''
      }
      digitRefs[0].focus()
    }

    function eingabe(index, event) {
      // Nur Zahlen sind erlaubt
      event.target.value = event.target.value.replace(/[^0-9]/g, '');

      // Fokussiere das nächste Feld, wenn möglich
      if (event.target.value != "") {
        nextTick(() => {
          if (index < digits.length - 1 && digitRefs[index + 1]) {
            // Nicht letztes Feld
            digitRefs[index + 1].focus();
          } else if (digitRefs[index]) {
            // Letztes Feld
            digitRefs[index].blur();
          }
        });
      }
    }

    const zeichenGeloescht = (index, event) => {
      if (event.key === 'Backspace' && digitTexts[index].value === '') {
        nextTick(() => {
          if (index > 0) {
            digitRefs[index - 1].focus();
          }
        });
      }
    }


    function clickBehandeln(index, event) {
      event.target.setSelectionRange(1, 1);
    }

    const code = computed(() => {
      var res = ""
      for (let i = 0; i < digitTexts.length; i++) {
        res += digitTexts[i].value
      }
      return res
    }, { deep: true })

    watch(code, val => context.emit("aktualisiert", val))
    watchEffect(() => {
      if (typeof props.zuruecksetzen === "boolean" && props.zuruecksetzen === true) {
        zuruecksetzen()
      }
    })

    /** Nachdem die Komponente auf der DOM angezeigt wird, wird das erste Feld fokussiert. */
    onMounted(() => digitRefs[0].focus())

    return {
      digits,
      digitRefs,
      digitTexts,
      code,
      eingabe,
      zeichenGeloescht,
      clickBehandeln,
      zuruecksetzen
    };
  },
};
</script>
  