<template>
    <div 
        class="h-full aspect-square rounded-2xl border border-UltraLeichtesGrau" :class="{'bg-DentaBlueUltraLight border-none': overflowZahl != null}">
            <div v-if="overflowZahl != null" class="w-full h-full flex justify-center items-center">
                <p class="text-lg text-DentaBlue font-bold ">+{{ overflowZahl }}</p>
            </div>
            <div v-else class="w-full h-full flex flex-col justify-center items-center py-1">
                    <div class="w-full h-1/2 flex justify-center items-end">
                        <img :src="themaIconUrl" alt="icon" class="h-full aspect-square object-contain">
                    </div>
                    <div class="w-full h-1/2 flex justify-center items-center">
                        <p class="text-xxs">{{ themenName }}</p>
                    </div>
            </div>
    </div>
</template>
<script>
import { themenIconAbfragen } from '@/services/helfer';
import { computed } from 'vue';

export default {
    props: ["overflowZahl","thema"],
    setup(props, context) {
        const themaIconUrl = computed(() => {
            if(props.thema.standard === true) {
                return themenIconAbfragen(props.thema, false)
            } else {
                return require("@/assets/icons/ordner.svg")
            }
        })

        const themenName = computed(() => {
            if(props.thema.name.length <= 20) {
                return props.thema.name
            }
            else {
                return `${props.thema.name.substring(0, 19)}...`}
        })
        return {themaIconUrl, themenName}
    }
}
</script>