<!-- Stellt den Titel für Dialoge dar. -->
<template>
  <div class="w-full flex justify-between items-center text-black text-xl">
    <!-- Titeltext -->
    <p class="text-2xl">{{ text }}</p>

    <!-- "schließen" Button -->
    <ion-icon 
      v-if="schliessenVorhanden && schliessenVorhanden === true" 
      name="close" 
      class="transition duration-150 hover:cursor-pointer hover:text-Mittelgrau" 
      @click="schliessen">
    </ion-icon>
  </div>
</template>

<script>
export default {
    props: ["text", "schliessenVorhanden"],
    emits: ["schliessen"],
    setup(props, context) {

        //Wird aufgerufen, wenn der "x" Button rechts gedrückt wird.
        function schliessen() {
          context.emit("schliessen")
        }

        return { schliessen }
    }
}
</script>

<style>
</style>