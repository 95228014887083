<template>
    <div class="w-44 aspect-square rounded-3xl p-6 flex flex-col justify-start items-start space-y-3"
        :style="`background-color: #${paket.backgroundColor}; border: 1px solid #${paket.primaryColor}`">
        <p class="text-xl font-bold tracking-wide">{{ paket.name }}</p>
        <div class=" ml-3 w-[50%] h-[2px] rounded-full" :style="`background-color: #${paket.primaryColor}; `"></div>
        <div class="space-x-3 w-full flex text-sm tracking-wide">
            <div class="w-full flex flex-col justify-start items-end space-y-1 font-bold"
                :style="`color: #${paket.primaryColor};`">
                <p>{{ paket.kapazitaet }} GB</p>
                <p>{{ paket.nutzer }}</p>
                <p>{{ paket.geraete }}</p>
            </div>
            <div class="w-full flex flex-col justify-start items-start space-y-1 text-MittelGrau">
                <p>Fotos</p>
                <p>Benutzer</p>
                <p>Geräte</p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ["paket"],
}
</script>