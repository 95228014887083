<template> 
    <div class="w-full flex justify-center items-center">
        <div 
          @click="zustandWechseln" 
          :class="{
            'hover:cursor-pointer': !inaktiv || inaktiv == false,
            'pointer-events-none': inaktiv || inaktiv == true
            }">
            <Transition name="fade" mode="out-in">
                <component :is="widgetFuerZustand"></component>
            </Transition>
        </div>
    </div>
</template>

<script>
//Alle eigenen Komponenten werden importiert
import CheckboxEmpty from './CheckboxEmpty.vue';
import CheckboxChecked from './CheckboxChecked.vue';
import CheckboxInactive from './CheckboxInactive.vue';
import { computed, ref } from '@vue/runtime-core';

export default {
    components: {
        CheckboxEmpty,
        CheckboxChecked,
        CheckboxInactive
    },

    props: ["startWert", "inaktiv"],
    emits: ["gewaehlt"],

    setup(props, context) {
        const checked = ref(props.startWert)

        const widgetFuerZustand = computed(() => {
          switch (checked.value) {
                case true: return props.inaktiv == true ? CheckboxInactive : CheckboxChecked
                default: return CheckboxEmpty
              }
        })

        const zustandWechseln = () => {
          checked.value = !checked.value
          context.emit("gewaehlt", checked.value)
        }

        return {
            //State für das UI
            checked,
            widgetFuerZustand,

            //Funktionern für das UI
            zustandWechseln
        }
    }
}
</script>

<!-- Styles needed for transition animation -->
<style scoped>
.fade-enter-active {
  transition: all 0.1s ease-out;  
}

.fade-leave-active {
  transition: all 0.1s ease-in;  
}

.fade-enter-from{
  opacity: 0.5;
}

.fade-enter-to{
  opacity: 1;
}

.fade-leave-from{
  opacity: 1;
}

.fade-leave-to{
  opacity: 0.5;
}
</style>