<template>
    <div class="onboarding-komponente h-screen justify-center">
        <OnboardingTitel titel="Information" untertitel="Durch den Wechsel Ihres Pakets erhalten Sie:" />
        <div class="w-full max-h-[70%] flex flex-col overflow-y-auto space-y-6 pr-6">
            <PaketKarteKlein :paket="paket" />
            <WarnungKasten titel="Zeitraum des Upgrades"
                body="Aus technischen Gründen kann es einige Tage dauern, bis ein Paketwechsel vollständig bearbeitet ist und durchgeführt wird." />
        </div>
        <OnboardingWeiterButton :deaktiviert="false" :laden="false" @click="weiter" />
    </div>
</template>
<script>
import OnboardingTitel from '@/components/OnboardingTitel.vue';
import OnboardingWeiterButton from '@/components/OnboardingWeiterButton.vue';
import PaketKarteKlein from '@/components/PaketKarteKlein.vue';
import WarnungKasten from '@/components/text-kaesten/WarnungKasten.vue';
import { computed } from 'vue';
export default {
    components: { OnboardingTitel, OnboardingWeiterButton, PaketKarteKlein, WarnungKasten },
    props: ["paket"],
    emits: ["weiter"],
    setup(props, context) {
        const stichpunkte = computed(() => {
            const speicher = `${props.paket.kapazitaet} GB Speicher`
            const nutzer = `${props.paket.nutzer} Benutzer`
            const geraete = `${props.paket.geraete} Geräte`
            return [speicher, nutzer, geraete]
        })

        const weiter = () => context.emit("weiter")
        return {
            stichpunkte,
            weiter
        }
    }
}
</script>