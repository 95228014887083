<template>
  <div 
    ref="widget" 
    class="w-full flex flex-col space-y-1.5"
    @mousemove="mehrfachWahlAktualisieren"
    :draggable="false">
    <div class="w-full h-full flex">
      <button
        v-for="zahnNummer in zahnNummernOben"
        :key="zahnNummer"
        :ref="el => zahnWidgetRefHinzufuegen(zahnNummer, el)"
        :disabled="interaktiv === false"
        class="w-full flex flex-col items-center text-[0.5rem] text-Mittelgrau"
        @click="zahnGewaehlt(zahnNummer)">
        <p :class="{
          'opacity-0': zahnAusgewaehlt(zahnNummer) === false && interaktiv === false,
            'text-DentaBlue font-bold': zahnAusgewaehlt(zahnNummer),
            'text-Mittelgrau': !zahnAusgewaehlt(zahnNummer),
          }">
          {{ zahnNummer }}
        </p>

        <img
            class="w-full aspect-[1/2] pt-1 object-contain"
            :class="{'opacity-50': !zahnAusgewaehlt(zahnNummer)}"
            :src="iconFuerNummerAbfragen(zahnNummer)"
            :alt="zahnNummer"/>
      </button>
    </div>

    <div class="w-full h-full flex">
      <button
        v-for="zahnNummer in zahnNummernUnten"
        :ref="el => zahnWidgetRefHinzufuegen(zahnNummer, el)"
        :key="zahnNummer"
        :disabled="interaktiv === false"
        class="w-full flex flex-col items-center text-[0.5rem] text-Mittelgrau"
        @click="zahnGewaehlt(zahnNummer)">
        <img
          class="w-full aspect-[1/2] pb-1 object-contain"
          :class="{'opacity-50': !zahnAusgewaehlt(zahnNummer)}"
          :src="iconFuerNummerAbfragen(zahnNummer)"
          :alt="zahnNummer"/>

        <p
          :class="{
            'opacity-0': zahnAusgewaehlt(zahnNummer) === false && interaktiv === false,
            'text-DentaBlue font-bold': zahnAusgewaehlt(zahnNummer) == true,
            'text-Mittelgrau': zahnAusgewaehlt(zahnNummer) == false,
          }"
        >
          {{ zahnNummer }}
        </p>
      </button>
    </div>
  </div>
</template>
  
  <script>
//Alle benötigten Konstanten -und Helferfunktionen werden importiert
import { zahnIconAbfragen } from "@/services/helfer";
import { zahnNummernOben, zahnNummernUnten } from "@/constants/konstanten";
import { computed, onMounted, ref } from "vue";

export default {
  props: ["zaehne", "interaktiv"],
  emits: ["aktualisiert"],
  setup(props, context) {
    
    //Refs zum Parentwidget und den Zahnwidgets, für die Mehrfachauswahl
    const widget = ref(null)
    const zahnWidgetRefs = ref([])

    /** Speichert Objekte der Form {nummer: number, x: number, y:number} für die Positionen der Zahnwidgets. */
    const zahnWidgetPositionen = ref([])

    /** Speichert die letzte Zahnnummer, die bei der Mehrfachwahl aktualisiert wurde. 
     * Verhindert, dass das widget mit einem Drag event wiederholt gewählt und aktualisiert wird. */
    const mehrfachWahlLetzteNummer = ref(-1)

    function zahnWidgetRefHinzufuegen(nummer, element) {
      zahnWidgetRefs.value.push({nummer: nummer, ref: element})
    }

    function zahnPositionenGenerieren() {
      for (let i = 0; i < zahnWidgetRefs.value.length; i++) {
        const zw = zahnWidgetRefs.value[i];
        
        const parentPos = widget.value.getBoundingClientRect()
        const zahnRect = zw.ref.getBoundingClientRect()

        const res = {
          nummer: zw.nummer,
          ol: {
            x: zahnRect.left - parentPos.left,
            y: zahnRect.top - parentPos.top
          },
          ur: {
            x: zahnRect.right - parentPos.left,
            y: zahnRect.bottom - parentPos.top
          }
        }

        zahnWidgetPositionen.value.push(res)
      }
    }

    onMounted(() => zahnPositionenGenerieren())

    /**
     * Gibt einen boolean zurück, der angibt ob sich die Zahnnummer in der übergebenen "zaehne" Liste befindet.
     * @param {number} nummer die zu prüfende Zahnnummer
     * @return {boolean} Ob der Zahn auf der Liste gefunden wurde, oder nicht.
     */
    function zahnAusgewaehlt(nummer) {
      return props.zaehne.find((z) => z.nummer == nummer) != undefined
    }

    /** Wenn der Nutzer die Maus über das Zahnschema bewegt, wärend er den linken Button drückt, wird hier die Mehrfachwahl aktualisiert */
    function mehrfachWahlAktualisieren(event) {
      if(event.buttons === 1) {
        const parentRect = widget.value.getBoundingClientRect()
        const mausRelativeX = event.clientX - parentRect.left
        const mausRelativeY = event.clientY - parentRect.top
        //console.log(`Relative X: ${mausRelativeX}, Relative Y: ${mausRelativeY}`);

        for (let i = 0; i < zahnWidgetPositionen.value.length; i++) {
          const pos = zahnWidgetPositionen.value[i];

          const ux = pos.ol.x
          const uy = pos.ol.y
          const ox = pos.ur.x
          const oy = pos.ur.y

          if (mausRelativeX >= ux && mausRelativeX <= ox && mausRelativeY >= uy && mausRelativeY <= oy && mehrfachWahlLetzteNummer.value != pos.nummer) {
              mehrfachWahlLetzteNummer.value = pos.nummer
              zahnGewaehlt(pos.nummer)
          }
        }
      }
    }

    /**
     *
     * Gibt mithilfe des "zahnIconAbfragen" helfers das passende Icon für den Zahn zurück. Wenn in der übergebenen "zaehne" Liste die nummer vorhanden ist,
     * wird das "ausgewählt" Icon für den Zahn / das Implantat an der Stelle zurückgegeben. Ansonsten wird das standard icon für die Nummer zurückgegeben.
     *
     * @param {int} nummer die Nummer des Zahns, für die das passende Icon abgefragt werden soll.
     */
    function iconFuerNummerAbfragen(nummer) {
      let suchErgebnis = props.zaehne.find(z => z.nummer == nummer);
      if (suchErgebnis) {
        return zahnIconAbfragen(suchErgebnis.nummer,true, suchErgebnis.implantat);
      } else {
        return zahnIconAbfragen(nummer, false, false);
      }
    }

    /**
     * Wird aufgerufen, wenn der nutzer mit der Maus einen Zahn anklickt.
     * Ist der Zahn bereits gewählt, wird ein implantat eingesetzt. Ist ein implantat vorhanden,
     * wird der zahn zurückgesetzt.
     * @param {number} nummer Die Nummer des Zahns, der ausgewählt wurde.
     */
    function zahnGewaehlt(nummer) {
      if (!props.interaktiv || props.zaehne == undefined || props.zaehne.length == undefined) { return }

      var aktuelleZaehne = props.zaehne;

      //Ein index mit dem gewählten Zahn wird gesucht
      let index = aktuelleZaehne.findIndex((z) => z.nummer == nummer);

      if (index == -1) {
        //Noch kein Zahn mit der Nummer ausgewählt
        aktuelleZaehne.push({ nummer: nummer, implantat: false });
      } else if (aktuelleZaehne[index].implantat === false) {
        // Zahn an der Stelle bereits vorhanden, wird gegen ein Implantat getauscht.
        aktuelleZaehne[index].implantat = true;
      } else {
        //Implantat an der Stelle existiert bereits, der Zahn wird gelöscht.
        aktuelleZaehne = aktuelleZaehne.filter((z) => z.nummer != nummer);
      }

      context.emit("aktualisiert", aktuelleZaehne);
    }

    return {
      //State für das UI
      widget,
      zahnNummernOben,
      zahnNummernUnten,

      //Funktionen für das UI
      zahnAusgewaehlt,
      iconFuerNummerAbfragen,
      zahnGewaehlt,
      zahnWidgetRefHinzufuegen,
      mehrfachWahlAktualisieren
    };
  },
};
</script>