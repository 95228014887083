<template>
    <div class="onboarding-komponente h-screen justify-center">
        <OnboardingTitel titel="Zahlung" untertitel="Durch den Wechsel Ihres Pakets entstehen folgende Kosten." />
        <div class="w-full max-h-[70%] flex flex-col overflow-y-auto space-y-6 pr-6">
            <!-- Kosten Sofort -->
            <BestellungKosten :zusammenstellung="positionenSofort" :jaehrlich="abonnement.jaehrlich"
                titel="Upgradepreis, sofort fällig:" :einmalig="true" />
            <BestellungKosten :zusammenstellung="positionenRegelmaessig" :jaehrlich="abonnement.jaehrlich"
                :titel="`Ab dem ${zahlungsDatum}, ${abonnement.jaehrlich === true ? 'jährlich' : 'monatlich'}:`" />

            <AbrechnungsHinweisAutomatisiert :zahlungsmethode="zm" :jaehrlich="abonnement.jaehrlich" />
            <BestellungRechnungsAdresse :adresse="abonnement.adresse" />
        </div>
        <OnboardingWeiterButton :deaktiviert="weiterDeaktiviert" :laden="laedt"
            text="Upgrade Kostenpflichtig Durchführen" @click="upgradeStarten" />
    </div>
</template>
<script>
import OnboardingTitel from '@/components/OnboardingTitel.vue';
import OnboardingWeiterButton from '@/components/OnboardingWeiterButton.vue';
import BestellungKosten from '@/components/bestellung-kosten/BestellungKosten.vue';
import BestellungRechnungsAdresse from '@/components/BestellungRechnungsAdresse.vue';
import AbrechnungsHinweisAutomatisiert from './components/AbrechnungsHinweisAutomatisiert.vue';
import { useCloudFunction } from '@/services/cloudFunctionDienst';
import { computed, onMounted, ref } from 'vue';
import store from '@/store';
import dayjs from 'dayjs';
import { aboNeuLaden } from '@/services/helfer';
import { fehlerAnzeigen } from '@/services/toastDient';
import { router } from '@/router';
export default {
    components: { OnboardingTitel, OnboardingWeiterButton, BestellungKosten, BestellungRechnungsAdresse, AbrechnungsHinweisAutomatisiert },
    props: ["istUpgrade", "paket"],
    emits: ["weiter"],
    setup(props, context) {

        const laedt = ref(false)
        const abonnement = computed(() => store.state.abonnement)

        const zahlungsDatum = computed(() => {
            if (abonnement.value != null) {
                return dayjs.unix(abonnement.value.naechsteZahlung).format("DD.MM.YYYY")
            }
        })

        const weiterDeaktiviert = computed(() => {
            if (props.istUpgrade === true) {
                return !positionenSofort.value || !positionenRegelmaessig.value || !zm.value
            } else return false
        })

        const positionenSofort = ref(null)
        const positionenRegelmaessig = ref(null)
        const zm = ref(null)

        const { error: berechnungsFehler, result: berechnungsErgebnis, call: berechnen } = useCloudFunction("upgradeKostenBestimmen")
        const { error: zmAbfrageFehler, result: zmAbfrageErgebnis, call: zmAbfragen } = useCloudFunction("zahlungsMethodeAbfragen")
        const { error: upgradeFehler, call: upgradeAufrufen } = useCloudFunction("abonnementUpgradeStarten")

        async function datenLaden() {
            if (laedt.value === false) {
                laedt.value = true
                await berechnen({ paket: props.paket.id })

                if (berechnungsFehler.value === false) {
                    positionenSofort.value = berechnungsErgebnis.value.einmalig
                    positionenRegelmaessig.value = berechnungsErgebnis.value.regelmaessig
                }

                await zmAbfragen()

                if (zmAbfrageFehler.value === false) {
                    zm.value = zmAbfrageErgebnis.value.art
                }

                laedt.value = false
            }
        }

        onMounted(datenLaden)

        function upgradeStarten() {
            if (laedt.value === false && props.paket) {
                laedt.value = true
                upgradeAufrufen({ paket: props.paket.id })
                    .then(() => {
                        if (upgradeFehler.value === false) {
                            context.emit("weiter")
                            aboNeuLaden()
                        } else {
                            fehlerAnzeigen("Fehler beim Durchführen des Upgrades. Es wurde keine Änderung oder Abbuchung durchgeführt.")
                            router.push({ name: "PraxisVerwaltungAbonnement" })
                        }
                    })
                    .finally(() => laedt.value = false)
            }
        }

        return {
            laedt,
            weiterDeaktiviert,
            positionenSofort,
            positionenRegelmaessig,
            zahlungsDatum,
            zm,
            abonnement,
            upgradeStarten
        }
    }
}
</script>