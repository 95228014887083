<template>
    <LadenInline v-if="paketeLaden" />
    <div class="w-full" v-else>
        <div v-if="tester === true" class="w-full flex flex-col justify-start items-center space-y-8 mt-20">
            <div
                class="w-full sm:w-3/4 md:w-1/2 py-5 px-6 rounded-xl bg-DentaBlueUltraLight flex justify-center items-center text-sm space-x-5 text-DentaBlue">
                <img src="@/assets/icons/information-full-blau.svg" alt="info-kreis" class="w-10 aspect-square">

                <span>Sie befinden sich in einer DentaSnap Partner Organisation. Hierfür sind keine Abonnementoptionen
                    vorhanden.
                    Wenden Sie sich bei Fragen an unseren Support.</span>
            </div>

            <Paket :paket="paket" :wechselButton="false" />

        </div>
        <div v-else class="w-full h-full flex flex-col space-y-20 mt-20 justify-center items-center font-dm-sans">

            <AusstehendeAenderung />

            <InfoKasten class="w-[26rem]" v-if="abonnement.upgradeDurchgefuehrt === true" titel="Limit Erreicht"
                body="Pro Abrechnungszeitraum kann nur ein Paketwechsel durchgeführt werden." />

            <div class="flex space-x-10">
                <Paket v-for="paket in pakete" :key="paket.id" :paket="paket" />
            </div>

            <div class="pb-20">
                <button class="text-Mittelgrau hover:cursor-pointer" @click="abonnementBeenden">Abo Beenden</button>
            </div>
        </div>
    </div>
</template>
<script>
import { computed, onMounted, ref } from 'vue';
import { getDocs, collection } from 'firebase/firestore';
import { firestore } from '@/firebase/config';
import { fehlerSenden } from '@/services/LogDienst';
import { router } from '@/router';
import store from '@/store';
import InfoKasten from '@/components/text-kaesten/InfoKasten.vue';
import Paket from "./components/Paket.vue"
import LadenInline from '@/components/LadenInline.vue';
import AusstehendeAenderung from './components/AusstehendeAenderung.vue';
export default {
    components: { LadenInline, Paket, AusstehendeAenderung, InfoKasten },
    setup() {
        const paketeLaden = computed(() => store.state.paketeLaden || store.state.abonnementLaedt)

        /** Speichert alle Pakete, die aktuell verfügbar sind */
        const pakete = computed(() => store.state.pakete)

        const abonnement = computed(() => store.state.abonnement)

        const paket = computed(() => pakete.value.find(p => p.id == abonnement.value.paket))

        const tester = computed(() => store.getters.istTesterAbo)

        function paketeAbfragen() {
            if (paketeLaden.value) {
                getDocs(collection(firestore, "pakete"))
                    .catch(err => {
                        //TODO: Fehlermeldung an Nutzer anzeigen
                        fehlerSenden(err, "Fehler beim Abfragen der Pakete", "paketeAbfragen", "Abonnement.vue")
                        store.commit("paketeSetzen", null)
                    })
                    .then(snap => store.commit("paketeSetzen", snap.docs.map(d => d.data()).sort((a, b) => a.rang - b.rang)))
            }

        }

        onMounted(paketeAbfragen)

        function paketAendern(paket) {
            router.push({ name: "PaketAendern", query: { p: paket.id } })
        }

        function abonnementBeenden() {
            router.push({ name: "AbonnementKuendigen" })
        }

        return {
            paketeLaden,
            pakete,
            paket,
            abonnement,
            tester,
            paketAendern,
            abonnementBeenden
        }
    }
}
</script>