<template>
  <div class="w-96 bg-white rounded-lg p-5 mr-5 mb-5 relative flex flex-col justify-between items-start" :class="{
    'transition duration-150 hover:cursor-pointer hover:shadow-md':
      snaps.length > 0,
  }" @mouseenter="hover = true" @mouseleave="hover = false" @click="partnerAufnahmenAnzeigen">
    <!-- Avatar -->
    <div class="w-full flex justify-between items-start">
      <img v-if="partner" :src="partner.avatarUrl"
        class="w-16 h-16 rounded-full border border-Mittelgrau border-opacity-50 object-cover" />

      <transition name="fade">
        <div v-if="hover === true"
          class="bg-WarningHintergrund text-Warning w-7 h-7 rounded-lg flex justify-center items-center transition duration-150 hover:cursor-pointer hover:opacity-70"
          @mouseenter="loeschenHover = true" @mouseleave="loeschenHover = false" @click="ausPartnerschaftAustreten">
          <ion-icon name="trash-outline"></ion-icon>
        </div>
      </transition>
    </div>

    <!-- Name und Fotozahl -->
    <div class="flex flex-col justify-start items-start my-4">
      <p class="w-80 truncate">
        {{ partner.name }}
      </p>
      <div class="h-5">
        <p v-if="snaps.length > 0" class="text-sm text-Mittelgrau">
          {{ snaps.length }} Aufnahmen
        </p>
      </div>
    </div>

    <!-- Fotovorschau für mehr als 2 Aufnahmen-->
    <div v-if="thumnailUris.length == 3"
      class="w-full h-44 bg-Hintergrund rounded-lg flex justify-center items-center text-center">
      <img :src="thumnailUris[0].link" class="w-1/2 h-full object-cover rounded-l-lg border-r-2 border-white" />
      <div class="w-1/2 h-full flex flex-col justify-center items-center rounded-l-lg border-l-2 border-white">
        <img :src="thumnailUris[1].link" class="w-full h-1/2 object-cover rounded-tr-lg border-b-2 border-white" />
        <div class="w-full h-1/2 rounded-br-lg border-t-2 border-white overflow-hidden relative">
          <img :src="thumnailUris[2].link" class="absolute z-0 w-full h-full object-cover blur-sm opacity-30" />
          <div
            class="absolute z-10 w-full h-full flex flex-col justify-center items-center text-center text-DentaBlue text-sm">
            <ion-icon class="text-xl" name="copy-outline"></ion-icon>
            <p class="-mb-1">Alle</p>
            <p>Aufnahmen</p>
          </div>
        </div>
      </div>
    </div>

    <!-- Fotovorschau für genau 2 Aufnahmen -->
    <div v-else-if="thumnailUris.length == 2"
      class="w-full h-44 bg-Hintergrund rounded-lg flex justify-center items-center text-center">
      <img :src="thumnailUris[0].link" class="w-1/2 h-full object-cover rounded-l-lg border-r-2 border-white" />
      <div class="w-1/2 h-full rounded-r-lg border-l-2 border-white overflow-hidden relative">
        <img :src="thumnailUris[1].link" class="absolute z-0 w-full h-full object-cover blur-sm opacity-30" />
        <div
          class="absolute z-10 w-full h-full flex flex-col justify-center items-center text-center text-DentaBlue text-sm">
          <ion-icon class="text-xl mb-2" name="copy-outline"></ion-icon>
          <p class="-mb-1">Alle</p>
          <p>Aufnahmen</p>
        </div>
      </div>
    </div>
    <div v-else-if="thumnailUris.length == 1"
      class="w-full h-44 bg-Hintergrund rounded-lg flex flex-col justify-center items-center text-center">
      <img :src="thumnailUris[0].link" class="w-full h-44 rounded-lg object-cover" />
    </div>
    <div v-else class="w-full h-44 bg-Hintergrund rounded-lg flex flex-col justify-center items-center text-center">
      <ion-icon class="text-xl" name="globe-outline"></ion-icon>
      <p>Bereit zu teilen</p>
      <p class="text-Mittelgrau text-sm">
        Sie können nun sicher Fotos<br />austauschen.
      </p>
    </div>
  </div>
</template>

<script>
import { computed, ref } from "vue";
import store from "@/store";
import { faelleGenerieren, snapUriAbfragen } from "@/services/helfer";
import { router } from "@/router";
export default {
  props: ["id"],
  emits: ["austreten"],
  setup(props, context) {
    const hover = ref(false);
    const loeschenHover = ref(false);
    const austretenState = ref(false);

    const partnerschaft = computed(() =>
      store.getters.partnerschaftAbfragen(props.id)
    );

    const partner = computed(() => {
      if (partnerschaft.value) {
        return Object.values(partnerschaft.value.teilnehmer).find(
          (t) => t.id != store.getters.praxisAbfragen.id
        );
      }
    });

    /** Speichert (bis zu) den letzten 3 Aufnahmen der Partnerpraxis */
    const thumnailUris = ref([]);

    /** Gibt alle Snapdokumente zurück, die von der Praxis stammen */
    const snaps = computed(() => {
      if (partner.value) {
        const faelle = faelleGenerieren({ praxis: partner.value.id });

        var erg = [];

        for (let i = 0; i < faelle.length; i++) {
          const fall = faelle[i];
          for (let y = 0; y < fall.length; y++) {
            const snaps = fall[y].snaps;
            for (let z = 0; z < snaps.length; z++) {
              erg.push(snaps[z]);
            }
          }
        }

        erg = erg.sort(
          (a, b) => a.entstehungsZeitpunkt < b.entstehungsZeitpunkt
        );

        thumnailUris.value = [];
        let cnt = Math.min(3, erg.length);
        for (let i = 0; i < cnt; i++) {
          const link = store.getters.snapVorschauLinkAbfragen(erg[i]);
          if (link != null) {
            thumnailUris.value.push({ id: erg[i], link: link });
          } else {
            snapUriAbfragen(erg[i], true).then((uri) => {
              if (uri != null) {
                //Bild konnte geladen werden
                store.commit("snapVorschauLinkSetzen", {
                  id: props.snap.id,
                  link: uri,
                });
                thumnailUris.value.push({ id: erg[id], link: uri });
              }
            });
          }
        }

        //Alle Snaps, für die keine passende Bilddatei geladen werden konnte, wird wieder gelöscht
        return erg;
      }
    });

    function partnerAufnahmenAnzeigen() {
      if (props.id && snaps.value.length > 0 && loeschenHover.value === false) {
        router.push({ name: "AufnahmenPartner", params: { id: props.id } });
      }
    }

    function ausPartnerschaftAustreten(params) {
      context.emit("austreten")
    }

    return {
      hover,
      loeschenHover,
      austretenState,
      partnerschaft,
      partner,
      snaps,
      thumnailUris,
      partnerAufnahmenAnzeigen,
      ausPartnerschaftAustreten,
    };
  },
};
</script>