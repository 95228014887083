<template>
  <div id="progress-bar-container">
    <div class="progress-bar-child progress"></div>
    <div id="abdeckung" class="progress-bar-child shrinker"></div>
  </div>
</template>

<script>
import { computed } from "@vue/runtime-core";
export default {
  props: ["fortschritt"],
  setup(props) {
    let fortschrittsWert = computed(() => 100 - props.fortschritt);
    let fortschritt = computed(() => `${fortschrittsWert.value}%`);

    return { fortschritt };
  },
};
</script>

<style>
#progress-bar-container {
  width: 100%;
  height: 5px;
  padding: 0px;
  margin: 0px;
  position: relative;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  overflow: hidden;
}

.progress-bar-child {
  width: 100%;
  height: 100%;
}

.progress {
  color: white;
  background: transparent;

  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right, #72d7dd 0%, #007aff 100%);
}

.shrinker {
  background-color: rgb(246, 248, 250);
  position: absolute;
  top: 0;
  right: 0;
  width: v-bind(fortschritt);
}
</style>