<template>
  <div
    v-if="daten"
    class="w-full h-full bg-white rounded-lg flex justify-center items-center py-5 px-8"
  >
    <div class="w-1/6 h-full flex flex-col justify-center items-start">
      <p class="text-xl text-Mittelgrau font-bold">Heute</p>
      <div class="w-full h-full flex flex-col justify-center items-start pl-3">
        <div class="flex justify-start items-center mb-1">
          <div class="w-3 h-3 rounded-full bg-DentaBlue mr-4"></div>
          <div class="flex flex-col justify-center items-start">
            <p class="text-3xl font-bold">{{ nutzungHeute.fotos }}</p>
            <p class="text-xs text-Mittelgrau">Fotos</p>
          </div>
        </div>
        <div class="flex justify-start items-center mt-1">
          <div class="w-3 h-3 rounded-full bg-DentaMint mr-4"></div>
          <div class="flex flex-col justify-center items-start">
            <p class="text-3xl font-bold">{{ nutzungHeute.sessions }}</p>
            <p class="text-xs text-Mittelgrau">Sessions</p>
          </div>
        </div>
      </div>
    </div>
    <div class="w-3/6 h-full flex flex-col justify-center items-start">
      <p class="text-xl text-Mittelgrau font-bold">Woche</p>
      <div class="w-full h-full">
        <Line :data="chartData" :options="options" />
      </div>
    </div>
    <div class="w-2/6 h-full flex flex-col justify-center items-start">
      <p class="text-xl text-Mittelgrau font-bold">Top Nutzer</p>
      <div
        class="w-full h-full flex flex-col justify-center items-start space-y-2 pl-3"
      >
        <div
          v-for="konto in konten"
          :key="konto.uid"
          class="flex justify-start items-center"
        >
          <img
            class="w-12 h-12 rounded-full object-cover mr-4 font-bold"
            :src="konto.url"
            alt="foto"
          />
          <p>{{ konto.name }}</p>
        </div>
      </div>
    </div>
  </div>
  <div
    v-else
    class="w-full h-full bg-white rounded-lg flex justify-center items-center py-5 px-8"
  >
    <p>Statistik Lädt...</p>
  </div>
</template>

<script>
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { nameAbfragen } from "@/services/authentifizierungsDienst";
import { options } from "@/constants/chartConfiguration";
import { Line } from "vue-chartjs";
import { computed, onMounted, ref } from "vue";
import { useCloudFunction } from "@/services/cloudFunctionDienst";
import store from "@/store";
import { nutzerProfilbildAbfragen } from "@/services/helfer";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export default {
  components: { Line },
  setup() {
    const {
      result: ergebnis,
      error: fehler,
      call: abfragen,
    } = useCloudFunction("nutzungsStatistikAbfragen");

    const daten = ref(null);

    onMounted(() =>
      abfragen().then(() => {
        if (fehler.value == false && ergebnis.value) {
          daten.value = ergebnis.value;
          kontenDatenInitialisieren()
        }
      })
    );

    const nutzungHeute = computed(() => {
      if (daten.value) {
        return {
          fotos: daten.value.woche.fotos[daten.value.woche.fotos.length - 1],
          sessions:
            daten.value.woche.sessions[daten.value.woche.sessions.length - 1],
        };
      }
    });

    const konten = ref(null)

    const chartData = computed(() => {
      if (daten.value) {
        return {
          labels: daten.value.woche.tage,
          datasets: [
            {
              borderColor: "#007AFF",
              data: daten.value.woche.fotos,
              tension: 0.25,
            },
            {
              borderColor: "#72D7DD",
              data: daten.value.woche.sessions,
              tension: 0.25,
            },
          ],
        };
      }
    });

    function kontenDatenInitialisieren() {
      if(daten.value && daten.value.nutzer) {
        konten.value = []
        for (let i = 0; i < daten.value.nutzer.length; i++) {
          const konto = store.getters.kontoAbfragen(daten.value.nutzer[i].uid)
          if(konto) {
            nutzerProfilbildAbfragen(konto.uid)
            .then(url => {
              konten.value.push({
                uid: konto.uid,
                name: konto.typ == "geraet" ? konto.geraeteName : nameAbfragen(konto.nutzerName),
                url: url.url
              })
            })
          }
        }
      }
    }

    return { chartData, options, nutzungHeute, konten, daten, nameAbfragen };
  },
};
</script>