import { createApp } from "vue";
import tooltip from "./directives/tooltip";
import "@/assets/tooltip.css";
import App from "./App.vue";
import { router } from "./router";
import store from "./store";
import { browserLocalPersistence, onAuthStateChanged } from "firebase/auth";
import "./assets/tailwind.css";
import { auth } from "./firebase/config";
import "gitart-vue-dialog/dist/style.css";
require("dayjs/locale/de");
var relativeTime = require("dayjs/plugin/relativeTime");
var customParseFormat = require("dayjs/plugin/customParseFormat");
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import "@/assets/toast_styles.css";
import { Cropper } from "vue-advanced-cropper";
import "vue-advanced-cropper/dist/style.css";
import LottieAnimation from "lottie-web-vue";
import "@imengyu/vue3-context-menu/lib/vue3-context-menu.css";
import ContextMenu from "@imengyu/vue3-context-menu";
import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import "@/assets/date-picker.css";
import dayjs from "dayjs";
dayjs.extend(relativeTime);
dayjs.extend(customParseFormat);

let app;

//Optionen für den standard toast (dialog)
let toastEinstellungen = {
  closeButton: false,
  icon: false,
  transition: "Vue-Toastification__bounce",
  position: "bottom-right",
  draggable: false,
  closeOnClick: false,
};

auth.setPersistence(browserLocalPersistence);

onAuthStateChanged(auth, (usr) => {
  if (!app) {
    app = createApp(App)
      .use(store)
      .use(router)
      .use(Toast, toastEinstellungen)
      .component("Cropper", Cropper)
      .component("VueDatePicker", VueDatePicker)
      .directive("tooltip", tooltip)
      .use(LottieAnimation)
      .use(ContextMenu)
      .mount("#app");
  }
});
