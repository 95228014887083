<template>
    <div class="w-full h-screen flex justify-start items-center p-5">
        <OnboardingFortschrittsAnzeige class="h-full w-1/3 mr-40" :schritte="schritte" :index="index" :fehler="false"
            :laedt="laedt" />

        <component :is="komponenteFuerIndex" :typ="nummerTyp" @telefonNummerAktualisiert="n => nummer = n"
            @weiter="navigieren" :nummer="nummer">
        </component>
    </div>
</template>
<script>
import OnboardingFortschrittsAnzeige from '@/components/OnboardingFortschrittsAnzeige.vue';
import TelefonnummerAngeben from '@/pages/konto-konfiguration/components/TelefonnummerAngeben/TelefonnummerAngeben.vue';
import MfaVerifizieren from '@/pages/konto-konfiguration/components/MfaVerifizierung/MfaVerifizieren.vue';
import { computed, onMounted, ref } from 'vue';
import { useRoute } from 'vue-router';
import { router } from '@/router';
import { erfolgAnzeigen } from '@/services/toastDient';
export default {
    components: {
        OnboardingFortschrittsAnzeige,
        TelefonnummerAngeben,
        MfaVerifizieren
    },
    setup() {
        const route = useRoute()
        const nummerTyp = computed(() => {
            const typ = route.params.typ
            if (typeof typ == "string" && (typ == "primaer" || typ == "sekundaer")) {
                return typ
            } else {
                return "primaer"
            }
        })

        const index = ref(0)
        const laedt = ref(false)
        const komponenteFuerIndex = computed(() => {
            switch (index.value) {
                case 1:
                    return MfaVerifizieren
                default:
                    return TelefonnummerAngeben
            }
        })

        function fortschrittsTitelSetzen() {
            var erg = []

            for (let i = 0; i < 2; i++) {
                var titel
                var untertitel

                switch (i) {
                    case 0:
                        titel = "Telefonnummer"
                        untertitel = "Geben Sie die gewünschte Telefonnummer an."
                        break;
                    case 1:
                        titel = "Bestätigung"
                        untertitel = "Geben Sie den Code an, den wir Ihnen gesendet haben."
                        break;
                    default:
                        break;
                }

                erg.push({
                    index: i,
                    titel: titel,
                    untertitel: untertitel
                })
            }

            return erg
        }

        const schritte = fortschrittsTitelSetzen()

        //State für Komponenten
        const nummer = ref("")

        function navigieren() {
            if (index.value == 1) {
                router.push({ name: "KontoSicherheit" })
                erfolgAnzeigen(`${nummerTyp.value === "primaer" ? "Primäre" : "Sekundäre"} Nummer für 2-FA hinzugefügt.`)
            } else {
                index.value++
            }
        }

        return {
            index,
            laedt,
            schritte,
            komponenteFuerIndex,
            nummer,
            nummerTyp,
            navigieren
        }
    }
}
</script>