<template>
    <button
        class="w-full py-4 px-6 rounded-xl flex justify-start items-center space-x-3 text-sm transition duration-100 hover:cursor-pointer hover:bg-opacity-70"
        :class="{
            'bg-Hintergrund text-DentaBlue': gewaehlt === false, 
            'bg-DentaBlue text-white': gewaehlt === true}
        "
        @click="aktualisiert">
        <img :src="iconUrl" alt="icon" class="h-6 aspect-square object-contain">
        <p>{{ name }}</p>   
    </button>
</template>

<script>
import { themenIconAbfragen } from '@/services/helfer';
import { computed, onMounted, ref } from 'vue';

export default {
    props: ["thema", "gewaehlt"],
    emits: ["aktualisiert"],
    setup(props, context) {
        const aktualisiert = () => context.emit("aktualisiert")

        const name = computed(() => {
            if(props.thema.name.length > 20) {
                return `${props.thema.name.substring(0, 20)}...`
            } else {
                return props.thema.name
            }
        })

        const iconUrl = computed(() => {
            if(props.thema && typeof props.thema.name == "string") {
                return themenIconAbfragen(props.thema, props.gewaehlt)
            } else {
                return ""
            }
        })

        return {
            iconUrl,
            name,
            aktualisiert
        }
    }
}
</script>