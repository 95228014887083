<template>
    <div class="onboarding-komponente">
        <OnboardingTitel titel="Zahlung" untertitel="Geben Sie an, wie sie ab jetzt bezahlen möchten." />
        <ZahlungsMethodenWahl class="w-96" @aktualisiert="aktualisiert" />
        <OnboardingWeiterButton :deaktiviert="false" :laedt="false" @click="weiter" />
    </div>
</template>
<script>
import OnboardingTitel from '@/components/OnboardingTitel.vue';
import OnboardingWeiterButton from '@/components/OnboardingWeiterButton.vue';
import ZahlungsMethodenWahl from '@/components/zahlungs-methoden-wahl/ZahlungsMethodenWahl.vue';
import { zahlungsMethoden } from '@/constants/zahlungsMethoden';
export default {
    components: {
        OnboardingTitel,
        OnboardingWeiterButton,
        ZahlungsMethodenWahl
    },
    emits: ["zahlungsMethodeAktualisiert", "weiter"],
    setup(props, context) {


        const aktualisiert = (zm) => context.emit("zahlungsMethodeAktualisiert", zm)
        const weiter = () => context.emit("weiter")
        return {
            zahlungsMethoden,
            aktualisiert,
            weiter
        }
    }
}
</script>