<template>
    <div class="onboarding-komponente">
        <OnboardingTitel 
            :hatZurueckButton="true"
            titel="Informationen" 
            untertitel="Geben Sie Behandlungsschritt und Lokalisierung für Ihre Session an."
            @zurueck="navigieren(false)"/>
            <!-- Schritt -->
            <p>Therapieschritt</p>
            <div class="flex items-center space-x-10">
                <button 
                    v-if="schritt < 2" 
                    class="p-3 flex bg-UltraLeichtesGrau bg-opacity-60 justify-center items-center rounded-lg" disabled>
                    <img src="@/assets/icons/pfeil-links-grau.svg" class="w-4 aspect-square" alt="<">
                </button>
                <button 
                    v-else 
                    class="p-3 bg-DentaBlueUltraLight flex justify-center items-center rounded-lg transition duration-100 hover:opacity-70" 
                    @click="schrittAktualisieren(false)">
                    <img src="@/assets/icons/pfeil-links-blau.svg" class="w-4 aspect-square" alt="<">
                </button>
                <p class="text-2xl text-DentaBlue">{{ schritt }}</p>
                <button 
                    class="p-3 bg-DentaBlueUltraLight flex justify-center items-center rounded-lg transition duration-100 hover:opacity-70" 
                    @click="schrittAktualisieren(true)">
                    <img src="@/assets/icons/pfeil-rechts-blau.svg" class="w-4 aspect-square" alt=">">
                </button>
            </div>

            <!-- Lokalisierung -->
            <p>Lokalisierung</p>
            <div class="w-96">
                <ZahnSchema :zaehne="zaehne" :interaktiv="true" @aktualisiert="z => zaehneAktualisieren(z)"/>
            </div>
        <OnboardingWeiterButton :deaktiviert="!weiterAktiv" :laedt="false" @click="navigieren(true)"/>
    </div>
</template>
<script>
import OnboardingTitel from '@/components/OnboardingTitel.vue';
import OnboardingWeiterButton from '@/components/OnboardingWeiterButton.vue';
import ZahnSchema from '@/components/ZahnSchema.vue';
import { computed } from 'vue';
export default {
    components: {
        OnboardingTitel,
        OnboardingWeiterButton,
        ZahnSchema
    },
    props: ["schritt", "zaehne"],
    emits: ["schrittAktualisiert", "zaehneAktualisiert", "navigieren"],
    setup(props, context) {
        function schrittAktualisieren(groesser) {
            if(typeof props.schritt !== "number") {
                context.emit("schrittAktualisiert", 1)
            } else {
                context.emit("schrittAktualisiert", groesser === true ? props.schritt + 1 : props.schritt - 1)
            }
        }

        function zaehneAktualisieren(zaehne) {
            context.emit("zaehneAktualisiert", zaehne)
        }

        const weiterAktiv = computed(() => props.schritt != null && props.zaehne != null && props.zaehne.length > 0)

        function navigieren(forwaerts) {
            if(forwaerts === true && weiterAktiv.value === true) {
                context.emit("navigieren", true)
            } else if (forwaerts === false) {
                context.emit("navigieren", false)
            }
        }

        return {
            schrittAktualisieren,
            zaehneAktualisieren,
            navigieren,
            weiterAktiv
        }
    }
}
</script>