<template>
    <div
        :class="{ 'onboarding-komponente': !istMobile, 'onboarding-komponente-mobile': istMobile, 'items-center': einzeln === true }">
        <!-- Zuschneidedialog -->
        <GDialog v-model="zuschneideDialogState" max-width="800" :persistent="true">
            <ZuschneidenDialog :url="dateiUrl" :istAvatar="true" @gespeichert="dateiBeschnitten"
                @schliessen="zuschneideDialogState = false" />
        </GDialog>

        <div class="w-full h-full flex flex-col"
            :class="{ 'justify-start space-y-8': !istMobile, 'justify-between items-center': istMobile }">
            <OnboardingTitel titel="Logo" untertitel="Setzen Sie das Logo Ihrer Organisation." />

            <!-- Praxisavatar -->
            <input type="file" name="file" id="file" class="inputfile"
                accept="image/png, image/jpg, image/jpeg, image/gif" @change="dateiGewaehlt" />

            <div class="w-min flex justify-start items-center space-x-6 py-6">
                <label for="file">
                    <div
                        class="w-28 aspect-square relative transition duration-100 hover:cursor-pointer hover:opacity-80">
                        <div v-if="!gewaehlteDatei" class="w-full h-full bg-TextField rounded-full absolute"></div>
                        <img v-else class="w-full h-full rounded-full absolute opacity-30" :src="dateiUrl" />

                        <div
                            class="w-full h-full flex flex-col justify-center items-center absolute z-10 transition duration-100 hover:scale-105">
                            <img src="@/assets/icons/hinzufuegen-kreis-blau.svg" class="w-8 aspect-square object-cover">
                            <p class="text-dm-sans text-sm text-DentaBlue">Logo</p>
                        </div>
                    </div>
                </label>

                <!-- Button zum Löschen eines gewählten Bildes -->
                <button v-if="gewaehlteDatei"
                    class="w-6 h-6 rounded-full bg-WarningHintergrund bg-opacity-40 flex justify-center items-center"
                    @click="dateiZuruecksetzen">
                    <img src="@/assets/icons/schliessen-rot.svg" class="w-2.5 aspect-square object-cover">
                </button>
            </div>

            <OnboardingWeiterButton :deaktiviert="gewaehlteDatei == null && einzeln === true" :laden="laedt"
                @click="speichern" :text="gewaehlteDatei == null && !einzeln ? 'Überspringen' : null" />
        </div>

    </div>
</template>
<script>
import OnboardingTitel from '@/components/OnboardingTitel.vue';
import OnboardingWeiterButton from '@/components/OnboardingWeiterButton.vue';
import ZuschneidenDialog from '@/components/ZuschneidenDialog.vue';
import { fehlerSenden } from '@/services/LogDienst';
import { profilBildZuschneiden } from '@/services/bildBearbeitungsDienst';
import { useCloudFunction } from '@/services/cloudFunctionDienst';
import { praxisAvatarHochladen } from '@/services/cloudStorageDienst';
import { fileToDataUri } from '@/services/helfer';
import { fehlerAnzeigen } from '@/services/toastDient';
import store from '@/store';
import { GDialog } from 'gitart-vue-dialog';
import { computed, ref } from 'vue';
export default {
    components: {
        OnboardingTitel,
        OnboardingWeiterButton,
        ZuschneidenDialog,
        GDialog
    },
    props: ["einzeln"],
    emits: ["weiter"],
    setup(props, context) {
        const istMobile = computed(() => store.state.mobile)

        /** "true" wärend die Änderungen gespeichert werden. */
        const laedt = ref(false)

        /** Ob es sich bei der Organisation um eine Praxis oder ein Labor handelt */
        const istPraxis = computed(() => store.state.istPraxis)

        /** Ob der Dialog zum Zuschneiden aktuell geöffnet ist, oder nicht */
        const zuschneideDialogState = ref(false)

        /** Wenn vorhanden wird hier der "Data URI" für die ausgewählte Datei gespeichert.*/
        const dateiUrl = ref(null)

        /** Nachdem eine Datei zugeschnitten wurde, wird sie fertig für den Upload hier gespeichert */
        const gewaehlteDatei = ref(null)

        function dateiGewaehlt(event) {
            if (event.target.files && event.target.files.length) {
                //Die Datei wird in einen Daten-URI umgewandelt
                fileToDataUri(event.target.files[0])
                    .catch(err => fehlerSenden(err, "Fehler beim Verarbeiten einer Praxis-Logo-Datei", "dateiGewaehlt", "PraxisEinrichten.vue"))
                    .then(uri => {
                        dateiUrl.value = uri
                        zuschneideDialogState.value = true
                    })
            }
        }

        function dateiZuruecksetzen() {
            gewaehlteDatei.value = null
            dateiUrl.value = null
        }

        function dateiBeschnitten(blob) {
            gewaehlteDatei.value = blob
        }

        const { error, call } = useCloudFunction("praxisAvatarAktualisieren")

        /** Wenn ein gültiger Name gegeben wurde, wird zunächst (wenn nötig) der Praxisavatar in den Cloud-Storage hochgeladen.
         * Dann wird mit dem neuen Namen die "praxisAktualisieren" Cloudfunktion aufgerufen
         */
        async function speichern() {
            laedt.value = true

            var update = {}

            //Nur wenn ein Avatar ausgesucht wurde, wird dieser hochgeladen und im Update mitgesendet
            if (gewaehlteDatei.value != null) {
                const zugeschnitten = await profilBildZuschneiden(gewaehlteDatei.value)
                const url = await praxisAvatarHochladen(zugeschnitten, store.getters.praxisAbfragen.id)
                update["avatarUrl"] = url
            }

            //Die Cloudfunktion zum Aktualisieren der Praxisdaten wird aufgerufen. Wenn der Nutzer kein Bild ausgewählt hat, wird im Backend das Standardfoto gesetzt.
            await call(update)

            laedt.value = false

            if (!error.value) {
                context.emit("weiter")
            } else {
                fehlerAnzeigen("Fehler beim Hinzufügen Ihres Praxislogos.")
            }
        }

        return { laedt, zuschneideDialogState, dateiUrl, gewaehlteDatei, istMobile, istPraxis, dateiGewaehlt, dateiZuruecksetzen, dateiBeschnitten, speichern }
    }
}
</script>