<template>
    <div class="onboarding-komponente">
        <OnboardingTitel
            titel="Fotos Wählen"
            untertitel="Wählen Sie Fotos für Ihre Session aus."
        />

        <div class="w-full h-80">
            <!-- Unsichtbarer "input" Button -->
            <input
                class="inputfile"
                name="fotos"
                id="fotos"
                type="file"
                multiple
                accept="image/jpg, image/jpeg, image/png"
                @change="fotosGewaehlt"
            />

            <!-- Noch keine Auswahl -->
            <div 
                v-if="weiterDeaktiviert"
                class="w-full h-full rounded-xl bg-DentaBlue bg-opacity-[0.06] flex justify-center items-center">

                    <label for="fotos" ref="fotoRef" class="bg-DentaBlue text-white px-6 py-2 space-x-2 rounded-full flex items-center transition duration-100 hover:cursor-pointer hover:bg-opacity-70">
                        <img src="@/assets/icons/plus-weiss.svg" alt="+" class="w-4 aspect-square object-contain">
                        <p>Fotos Auswählen</p>
                    </label>
            </div>
            <div v-else class="w-full h-full flex flex-col space-y-4 justify-start items-start">
                <p>{{ fotos.length }} Fotos gewählt.</p>
                <div class="w-full h-full overflow-y-auto">
                    <div class="flex flex-wrap justify-start items-start">
                        <FotoThumnail 
                            v-for="foto in fotos"
                            :key="foto.name"
                            :foto="foto"
                            @loeschen="() => fotoLoeschen(foto)"/>
                    </div>
                </div>
                <div class="flex space-x-5">
                    <label
                        for="fotos"
                        class="bg-DentaBlue bg-opacity-[0.11] text-DentaBlue px-6 py-2 space-x-2 rounded-lg flex items-center transition duration-100 hover:cursor-pointer hover:bg-opacity-[0.08]">
                        <p>Fotos Hinzufügen</p> 
                    </label>
                    <button
                        for="fotos"
                        class="bg-Hintergrund text-Warning px-6 py-2 space-x-2 rounded-lg flex items-center transition duration-100 hover:cursor-pointer hover:bg-opacity-[0.08]"
                        @click="alleFotosLoeschen">
                        <p>Alle Löschen</p> 
                </button>
                </div>
            </div>

        </div>

        <OnboardingWeiterButton
            :deaktiviert="weiterDeaktiviert"
            :laden="laedt != null ? laedt : false"
            @click="weiter"
        />
    </div>
</template>
<script>
import OnboardingTitel from '@/components/OnboardingTitel.vue';
import FotoThumnail from './components/FotoThumnail.vue';
import OnboardingWeiterButton from '@/components/OnboardingWeiterButton.vue';
import { computed, ref } from 'vue';
export default {
    components: { OnboardingTitel, FotoThumnail, OnboardingWeiterButton },
    props: ["laedt","fotos"],
    emits: ["fotosAktualisiert","navigieren"],
    setup(props, context) {
        const weiterDeaktiviert = computed(() => props.fotos == null || props.fotos.length == 0)

        function fotoLoeschen(foto) {
            if(props.fotos && props.fotos.length) {
                const res = props.fotos.filter(f => f.name !== foto.name)
                context.emit("fotosAktualisiert", res)
            }
        }

        function alleFotosLoeschen() {
            if(props.fotos && props.fotos.length) {
                context.emit("fotosAktualisiert", [])
            }
        }

        function fotosGewaehlt(event) {
            if(event.target.files.length) {
                var res = props.fotos ?? []
                for (let i = 0; i < event.target.files.length; i++) {
                    const file = event.target.files[i];
                    if(file.type == "image/jpeg" || file.type == "image/jpg" || file.type == "image/png") {
                        res.push(file)
                    }
                }

                context.emit("fotosAktualisiert", res)
            }
        }

        function weiter() {
            if(weiterDeaktiviert.value === false) {
                context.emit("navigieren", true)
            }
        }

        return {
            weiter,
            weiterDeaktiviert, 
            fotosGewaehlt, 
            fotoLoeschen, 
            alleFotosLoeschen
        }
    }
}
</script>