<template>
  <form @submit.prevent class="flex flex-col space-y-10 font-dm-sans"
    :class="{ 'w-85': istMobile === false, 'w-full h-full justify-start': istMobile === true }">
    <OnboardingTitel titel="Identität Bestätigen"
      untertitel="Bitte geben Sie den Code ein, den wir Ihnen per SMS gesendet haben." />
    <div class="w-full flex" :class="{ 'justify-start': istMobile === false, 'justify-center': istMobile === true }">
      <ZiffernFeld ref="ziffernFeldRef" @aktualisiert="aktualisiert" />
    </div>
  </form>
</template>

<script>
//Alle benötigten Vue-Komponenten und Frameworks werden importiert
import { ref } from '@vue/reactivity'

//Alle benötigten eigenen Komponenten werden importiert
import OnboardingTitel from '@/components/OnboardingTitel.vue'
import ZiffernFeld from '@/pages/konto-konfiguration/components/MfaVerifizierung/components/ZiffernFeld.vue'
import store from '@/store'
import { computed } from 'vue'

export default {
  components: {
    ZiffernFeld,
    OnboardingTitel,
    ZiffernFeld
  },

  props: ["mfaFehler"],
  emits: ["codeEingegeben"],

  setup(props, context) {
    const istMobile = computed(() => store.state.mobile)

    const codeLokal = ref("")
    const ziffernFeldRef = ref(null)

    function ziffernFeldZuruecksetzen() {
      if (ziffernFeldRef.value && ziffernFeldRef.value.zuruecksetzen) {
        ziffernFeldRef.value.zuruecksetzen()
      }
    }

    //Wird aufgerufen, wenn ein Code in das "Ziffernfeld" eingegeben wurde
    const aktualisiert = (wert) => {
      codeLokal.value = wert
      if (codeLokal.value.length == 6) {
        context.emit("codeEingegeben", codeLokal.value)
      }
    }

    return {
      //State für das UI
      aktualisiert,
      istMobile,
      ziffernFeldRef,
      ziffernFeldZuruecksetzen
    }
  }
}
</script>

<style>

</style>