<template>
    <PatientErstellen :state="erstellenState" @schliessen="erstellenState = false" />
    <PatientBearbeiten :state="bearbeitenState" :patient="bearbeitenZiel" @schliessen="bearbeitenState = false" />

    <LadenInline v-if="laedt" />
    <div v-else class="standard-seite">
        <div class="w-full flex justify-between items-center">
            <PageTitle title="Patienten" />
            <div class="flex space-x-5">
                <input type="text" name="suche" id="suche"
                    class="bg-[#EEEEEE] w-80 px-4 h-11 rounded-xl flex justify-start items-center text-sm outline-none text-black hover:cursor-text"
                    placeholder="Name oder Patientennummer" v-model="suchtext">
                <AktionsButtonPrimaer v-if="istPraxis === true && darfPatientenVerwalten === true" iconName="add"
                    @click="erstellenState = true" />
            </div>
        </div>
        <LeereSeiteVorlage v-if="laedt === false && patienten && patienten.length == 0 && (!suchtext || suchtext == '')"
            :animationsDaten="leerePatienten" titel="Patienten"
            :body="istPraxis ? 'Hier sehen Sie Ihre erstellten Patienten oder die aus Fällen, die mit Ihnen geteilt werden.' : 'Hier sehen Sie die Patienten aller Fälle, die mit Ihnen geteilt wurden.'"
            :appStoreQrAnzeigen="false" />
        <div v-else class="w-full rounded-xl bg-white flex flex-col py-6 px-8">
            <div v-if="patienten.length > 0" class="w-full">
                <div class="w-full flex text-Mittelgrau text-sm font-bold pl-2 pr-6">
                    <p class="w-4/12">Name</p>
                    <p class="w-2/12">Patientennummer</p>
                    <p class="w-2/12">Geburtsdatum</p>
                    <p class="w-1/12">Fälle</p>
                    <p class="w-3/12">Geteilt von</p>
                </div>
                <Patient v-for="(p, i) in patienten" :key="p.id" :patient="p" :divider="i !== 0"
                    @bearbeiten="bearbeitenOeffnen(p)" />
            </div>
            <p v-else class="w-full text-center text-LeichtesGrau">Keine Surchergebnisse</p>
        </div>
    </div>
</template>
<script>
import leerePatienten from "@/assets/animations/leere-patienten.json"
import LeereSeiteVorlage from '@/components/LeereSeiteVorlage.vue';
import LadenInline from '@/components/LadenInline.vue';
import PageTitle from '@/components/PageTitle.vue';
import AktionsButtonPrimaer from '@/components/AktionsButtonPrimaer.vue';
import Patient from './components/Patient.vue';
import PatientErstellen from './components/PatientErstellen.vue';
import PatientBearbeiten from './components/PatientBearbeiten.vue';
import { computed, ref } from 'vue';
import store from '@/store';
import { berechtigungenPruefen } from '@/services/authentifizierungsDienst';
import { patientenVerwalten } from '@/constants/berechtigungen';
export default {
    components: { LadenInline, PageTitle, PatientErstellen, PatientBearbeiten, Patient, AktionsButtonPrimaer, LeereSeiteVorlage },
    setup() {

        const erstellenState = ref(false)

        const bearbeitenState = ref(false)
        const bearbeitenZiel = ref(null)

        const istPraxis = computed(() => store.state.istPraxis)
        const darfPatientenVerwalten = computed(() => berechtigungenPruefen([patientenVerwalten]))
        const laedt = computed(() => store.state.patientenLaden)

        const suchtext = ref("")

        const patienten = computed(() => {
            var res;
            if (!suchtext.value || suchtext.value == "") {
                res = store.state.patienten;
            } else {
                res = store.state.patienten.filter(p =>
                    p.nummer.includes(suchtext.value) ||
                    (typeof p.name === "string" && p.name.toLowerCase().includes(suchtext.value.toLowerCase()))
                );
            }

            // First sort by name alphabetically where name exists
            res.sort((a, b) => {
                if (typeof a.name === "string" && typeof b.name === "string") {
                    return a.name.localeCompare(b.name);
                }
                return 0; // If one or both names do not exist, do not change the order
            });

            // Then sort by nummer (as numbers)
            res.sort((a, b) => {
                const numA = parseInt(a.nummer, 10);
                const numB = parseInt(b.nummer, 10);
                return numA - numB;
            });

            return res;
        });

        function bearbeitenOeffnen(patient) {
            bearbeitenZiel.value = patient
            bearbeitenState.value = true
        }

        return {
            istPraxis,
            suchtext,
            laedt,
            patienten,
            darfPatientenVerwalten,
            leerePatienten,
            suchtext,

            erstellenState,

            bearbeitenState,
            bearbeitenZiel,
            bearbeitenOeffnen
        }
    }
}
</script>