<template>
    <div class="w-full h-full flex flex-col justify-start items-center space-y-14">
        <MfaNummern class="w-[37rem]" />
        <PasswortZuruecksetzen class="w-[37rem]" />
    </div>
</template>
<script>
import { router } from '@/router';
import MfaNummern from './components/MfaNummern.vue';
import PasswortZuruecksetzen from './components/PasswortZuruecksetzen.vue';
export default {
    components: {
        MfaNummern,
        PasswortZuruecksetzen
    },
    setup() {
        const nummerHinzufuegen = () => {
            router.push({ name: "KontoNummerHinzufuegen", params: { typ: "primaer" } })
        }
        return {
            nummerHinzufuegen
        }
    }
}
</script>