<template>
    <div class="onboarding-komponente">
        <OnboardingTitel 
            titel="Fall Wählen" 
            untertitel="Fügen Sie die Session einem vorhandenen oder neuen Fall hinzu."
            :hatZurueckButton="true"
            @zurueck="navigieren(false)"/>
        <div class="w-full max-h-96 overflow-y-auto">
            <div class="w-full flex flex-wrap">
                <div 
                    v-for="fall in faelle" 
                    class="w-72 min-w-[18rem] sm:w-1/2 pr-4 pb-3">
                    <FallKarte 
                        :key="fall.id"
                        :fall="fall"
                        @click="vorhandenenFallGewaehlt(fall)"/>
                </div>
            </div>
        </div>
        <OnboardingWeiterButton 
            :deaktiviert="false" 
            :laedt="false" 
            text="Neuer Fall" 
            @click="neuenFallGewaehlt"/>
    </div>
</template>
<script>
import OnboardingTitel from '@/components/OnboardingTitel.vue';
import OnboardingWeiterButton from '@/components/OnboardingWeiterButton.vue';
import FallKarte from './components/FallKarte.vue';
import { einmaligenStringGenerieren } from '@/services/firestoreDienst';
export default {
    props: ["faelle"],
    emits: ["fallAktualisiert", "navigieren"],
    components: { OnboardingTitel, OnboardingWeiterButton, FallKarte },
    setup(_, context) {
        
        function vorhandenenFallGewaehlt(fall) {
            context.emit("fallAktualisiert", fall.id)
            navigieren(true)
        }

        function neuenFallGewaehlt() {
            context.emit("fallAktualisiert", null)
            navigieren(true)
        }

        const navigieren = (forwaerts) => context.emit("navigieren", forwaerts)

        return {
            vorhandenenFallGewaehlt,
            neuenFallGewaehlt,
            navigieren
        }
    }
}
</script>