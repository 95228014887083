<template>
    <button class="w-28 h-40 flex flex-col justify-between items-center" @click="aktualisiert">
        <div class="flex flex-col items-center space-y-2">
            <div class="w-[5.5rem] aspect-square relative justify-center items-center">
                <transition name="fade">
                    <div v-if="gewaehlt === true" class="absolute z-0 w-full h-full rounded-full bg-gradient-to-r from-GeteiltViolett to-DentaBlue"></div>  
                </transition>
                <div class="absolute z-10 w-full h-full flex justify-center items-center">
                    <img class="w-20 aspect-square object-cover rounded-full" :src="partner.avatarUrl">
                </div>
            </div>
            <p class="text-sm text-center px-1">{{ partnerName }}</p>
        </div>

        <div class="w-10 h-5 flex justify-center items-center">
            <div v-if="gewaehlt === false" class="h-full bg-gradient-to-r from-GeteiltViolett to-DentaBlue text-transparent bg-clip-text text-xs">Teilen</div>
            <div v-else class="h-full px-3 rounded-full bg-gradient-to-r from-GeteiltViolett to-DentaBlue flex justify-center items-center">
                <img src="@/assets/icons/haken-weiss.svg" alt="check" class="h-3 aspect-square object-contain">
            </div>
        </div>
    </button>
</template>
<script>
import store from '@/store';
import { computed } from 'vue';

export default {
    props: ["partnerschaft", "gewaehlt"],
    emits: ["aktualisiert"],
    setup(props, context) {
        const partner = computed(() => {
            return Object.values(props.partnerschaft.teilnehmer).find(t => t.id != store.getters.praxisAbfragen.id)
        })

        const partnerName = computed(() => {
            if(partner.value.name.length > 18) {
                return `${partner.value.name.substring(0, 17)}...`
            } else {
                return partner.value.name
            }
        })

        const aktualisiert = () => context.emit("aktualisiert")

        return {
            partner,
            partnerName,
            aktualisiert
        }
    }
}
</script>