<template>
    <div class="w-full h-screen flex justify-between items-center relative">
      <div class="z-10 w-full md:w-1/4 flex justify-center md:justify-end items-center">
        <div class="flex flex-col justify-center items-center md:items-start">
          <img src="@/assets/icon_weiss.png" alt="icon" class="w-14 aspect-square rounded-lg shadow-lg shadow-DentaBlueLight">
          <p class="text-4xl font-bold mt-6 mb-4">Wir bringen<br/>neuen Glanz!</p>
          <p class="text-Mittelgrau">DentaSnap befindet sich in Wartung.<br/>Sie können die Webapp<br/>gleich wieder nutzen!</p>
        </div>
      </div>

      <img src="@/assets/wartung_farbe.png" alt="farbe" class="h-screen aspect-square absolute right-0 top-0 z-0">
    </div>
  </template>