<template>
    <div class="w-full h-screen flex justify-start items-center p-5">
        <OnboardingFortschrittsAnzeige class="h-full w-1/3 mr-40" :index="index" :fehler="false" :schritte="schrittTitel" />
        <component :is="schrittKomponente" :abonnement="abonnement" :laedt="laedt" :paket="neuesPaket"
            @weiter="weiterNavigieren" />
    </div>
</template>
<script>
import { onMounted, ref, computed } from 'vue';
import store from '@/store';
import { useRoute, useRouter } from 'vue-router';
import OnboardingFortschrittsAnzeige from '@/components/OnboardingFortschrittsAnzeige.vue';
import Information from './components/information/Information.vue';
import Zahlung from './components/zahlung/Zahlung.vue';
import Bestaetigung from './components/bestaetigung/Bestaetigung.vue';
export default {
    components: {
        OnboardingFortschrittsAnzeige,
        Information,
        Zahlung,
        Bestaetigung
    },
    setup(_, context) {

        const route = useRoute()
        const router = useRouter()

        /** Aktueller interner Navigationsindex */
        const index = ref(0)

        const laedt = ref(false)

        const abonnement = computed(() => store.state.abonnement)

        /** Paket, auf das der Nutzer aktualisieren möchte */
        const neuesPaket = computed(() => store.state.pakete.find(p => p.id === route.query.p))

        const schrittTitel = [
            {
                index: 0,
                titel: "Information",
                untertitel: "Wechsel ihres DentaSnap Pakets."
            },
            {
                index: 1,
                titel: "Zahlung",
                untertitel: "Kosten Ihres Paketwechels."
            },
            {
                index: 2,
                titel: "Fertig",
                untertitel: "Die Änderung Ihres Pakets wurde begonnen."
            }
        ]

        const schrittKomponente = computed(() => {
            switch (index.value) {
                case 1:
                    return Zahlung
                case 2:
                    return Bestaetigung
                default:
                    return Information
            }
        })

        function weiterNavigieren() {
            if (index.value < 2) {
                index.value++;
            }
        }

        onMounted(() => {
            if (store.state.paketeLaden || store.state.abonnementLaedt || !route.query.p) {
                router.push({ name: "PraxisVerwaltungAbonnement" })
            }
        })

        return {
            index,
            laedt,
            neuesPaket,
            abonnement,
            schrittTitel,
            schrittKomponente,
            weiterNavigieren
        }
    }
}
</script>