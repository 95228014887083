<template>
    <div class="w-full h-8 flex justify-between items-center space-x-2">
        <p class="w-1/2 text-xl tracking-wider">{{ faktor.phoneNumber }}</p>
        <div class="w-1/2 flex justify-between items-center">
            <div 
            class="h-min bg-DentaBlueUltraLight text-DentaBlue text-xs tracking-widest px-3 py-1 rounded-md" 
            :class="{
                'opacity-0': istPrimaer === false, 
                'opacity-100': istPrimaer === true
            }">
            PRIMÄR
        </div>
        <button :disabled="mfaState === 1" :class="{'hover:cursor-not-allowed': mfaState === 1}" @click="menueAnzeigen">
            <img src="@/assets/icons/punkte-vertikal-grau.svg" class="h-5 aspect-square" :class="{'opacity-40': mfaState === 1}">
        </button>
        </div>
    </div>
</template>
<script>
import { computed } from 'vue';
import { kontextMenueAnzeigen } from '@/services/kontextMenue';
export default {
    props: ["mfaState","faktor"],
    emits: ["primaerSetzen", "loeschen"],
    setup(props, context) {
        const istPrimaer = computed(() => props.faktor.displayName == "a")

        const menueOptionen = computed(() => {
            var res = [
                {
                    index: 0,
                    text: "Löschen",
                    destruktiv: true,
                    onClick: () => context.emit("loeschen", props.faktor.uid)
                }
            ]

            if(istPrimaer.value === false) {
                res.push({
                    index: 1,
                    text: "Primär Setzen",
                    destruktiv: false,
                    onClick: () => context.emit("primaerSetzen", props.faktor.uid)
                })
            }

            return res
        })

        function menueAnzeigen(event) {
            kontextMenueAnzeigen(event, menueOptionen.value)
        }

        return {istPrimaer, menueAnzeigen}
    }
}
</script>