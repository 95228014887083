const routenNamen = [
  "Aufnahme",
  "Anmeldung",
  "EmailAktion",
  "Berechtigungen",
  "NichtVorhanden",
  "UpdateNoetig",
  "Wartungsmodus",
  "Laden",
  "MobileSperrung",
  "NichtBerechtigt",
  "PraxisGesperrt",
  "GerätAnmelden",
  "SessionBearbeiten",
  "AnfrageSenden",
  "AnfrageAnnehmen",
];

const pfadBestandteile = [
  "/registrierungAbschliessen/",
  "/organisation/verwaltung/",
  "/konto",
  "/session-hochladen",
];

export { routenNamen, pfadBestandteile };
