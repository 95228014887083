<template>
    <button class="tracking-wider text-start" @click="gewaehlt">{{ patient.nummer }}</button>
</template>
<script>
export default {
    props: ["patient"],
    emits: ["gewaehlt"],
    setup(_, context) {
        const gewaehlt = () => context.emit("gewaehlt")
        return {gewaehlt}
    }
}
</script>