//Beinhaltet funktionen zum manipulieren von Bildern

import { klein, mittel, gross, thumbnail } from "@/constants/konstanten";
import { fileToDataUri } from "@/services/helfer";

/**
 * Schneidet die Aufnahme wenn nötig zu, und wandelt Sie in das JPG Format um.
 * @param {File} bildBlob Bilddatei die verarbeitet wird
 * @param {boolean} thumnail Ob die Datei auf volle Größe oder Thumnailgröße zugeschnitten werden soll.
 * @returns {File} Verarbeitete Datei als Blob, im JPG Format.
 */
function resizeImage(bildBlob, thumnail) {
  return new Promise((resolve) => {
    const img = new Image();
    fileToDataUri(bildBlob).then((url) => {
      img.src = url;
      img.onload = () => {
        const canvas = document.createElement("canvas");
        const context = canvas.getContext("2d");

        const istHochkant = img.width < img.height;

        var limit = thumnail === true ? 540 : 4096;

        //Bilder größer als 2K (2160 x 1440 bei 3:2, 1440 x 2160 bei 2:3) werden auf 2k herunterskaliert.
        if (
          (istHochkant === true && img.height > limit) ||
          (istHochkant === false && img.width > limit)
        ) {
          //Das Seitenverhältnis des Bildes wird bestimmt, um daraus und mit der
          //neuen Höhe die passende breite zu bestimmen.
          var seitenverhaeltnis = img.width / img.height;

          //Die neue Höhe wird mittels der gegeben Bildgröße bestimmt
          var neueHoehe;

          if (thumnail === true) {
            neueHoehe = istHochkant === true ? 540 : 360;
          } else {
            neueHoehe = istHochkant === true ? 4096 : 2160;
          }

          //Mittels des Seitenverhätlnisses wird die neue Breite berechnet.
          var neueBreite = neueHoehe * seitenverhaeltnis;

          canvas.width = neueBreite;
          canvas.height = neueHoehe;

          context.drawImage(img, 0, 0, neueBreite, neueHoehe);
          canvas.toBlob((blob) => resolve(blob), "image/jpeg");
        } else {
          canvas.width = img.width;
          canvas.height = img.height;

          //Das Bild wird nur als JPG gerendert, aber sonst in keiner Weise verändert.
          context.drawImage(img, 0, 0, img.width, img.height);
          canvas.toBlob((blob) => resolve(blob), "image/jpeg");
        }
      };
    });
  });
}

/**
 * Schneidet die gegebene Bilddatei quadratisch zu, und gibt Sie im "jpg" Format zurück.
 * @param {Blob} blob Bilddatei, die zugeschnitten wird
 * @param {number} groesse Optionale größe des Quadrats. Standard ist 300.
 * @returns {Promise<Blob>} Zugeschnittene und konvertierte Bilddatei
 */
async function profilBildZuschneiden(blob, groesse = 300) {
  return new Promise((resolve) => {
    const img = new Image();
    fileToDataUri(blob).then((url) => {
      img.src = url;
      img.onload = () => {
        const canvas = document.createElement("canvas");
        const context = canvas.getContext("2d");

        const portrait = img.height > img.width;

        var width;
        var height;

        if (portrait) {
          width = groesse;
          height = groesse * (img.height / img.width);
        } else {
          width = groesse * (img.width / img.height);
          height = groesse;
        }
        const distanceToCenter =
          (Math.max(width, height) - Math.min(width, height)) / 2;
        canvas.width = groesse;
        canvas.height = groesse;

        context.drawImage(
          img,
          portrait ? 0 : -distanceToCenter,
          portrait ? -distanceToCenter : 0,
          width,
          height
        );

        canvas.toBlob((res) => resolve(res), "image/jpeg", 0.8);
      };
    });
  });
}

export { resizeImage, profilBildZuschneiden };
