<template>
    <GDialog 
        v-model="state"
        border-radius="10"
        max-width="400"
        background="#FFFFFF"
        overlay-background="rgba(0, 0, 0, 0.25)"
        :depressed="true"
        :persistent="true"
        @update:modelValue="stateChanged"
        >
        <div class="w-full py-7 px-5 flex flex-col justify-center items-center font-dm-sans">
            <p v-if="titel" class="text-2xl font-medium tracking-wide mb-2">{{ titel }}</p>
            <p v-if="body" class="text-sm text-center font-medium mb-6">{{ body }}</p>
            <div v-else class="h-6"></div>
            <slot></slot>
            <div 
                class="px-6 py-2 w-32 h-11 mt-6 rounded-lg text-sm font-semibold tracking-wide flex justify-center items-center"
                :class="{
                    'bg-DentaBlueUltraLight text-DentaBlue transition duration-100 hover:cursor-pointer hover:bg-opacity-70': bestaetigungAktiv == undefined || bestaetigungAktiv === true,
                    'bg-TextField text-Mittelgrau': bestaetigungAktiv === false,
                    'pointer-events-none': bestaetigungAktiv === false || laedt === true
                }"
                @click="bestaetigungSenden">
                <p v-if="!laedt || laedt === false">{{ bestaetigungsText ?? "Weiter" }}</p>
                <LottieAnimation
                    v-else
                    class="h-24 aspect-square"
                    :animation-data="ladenBlau"
                    :auto-play="true"
                    :loop="true"
                />
            </div>
            <p 
                class="text-sm text-Mittelgrau mt-3 tracking-wide transition duration-100 hover:cursor-pointer hover:opacity-70"
                @click="abgebrochenSenden">{{ abbruchText ?? "Abbrechen" }}</p>
        </div>
    </GDialog>
</template>
<script>
import { GDialog } from 'gitart-vue-dialog';
import ladenBlau from "@/assets/animations/laden-blau.json"
export default {
    props: ["state", "titel", "body", "laedt", "bestaetigungsText", "bestaetigungAktiv", "abbruchText"],
    events: ["stateChanged", "bestaetigt", "abgebrochen"],
    components: {GDialog},
    setup(props, context) {
        const stateChanged = (val) => context.emit("stateChanged", val);
        
        function bestaetigungSenden() {
            if(props.bestaetigungAktiv == undefined || props.bestaetigungAktiv === true) {
                context.emit("bestaetigt")
            }
        }

        function abgebrochenSenden() {
            context.emit("abgebrochen")
            stateChanged(false)
        }
        
        return { stateChanged, bestaetigungSenden, abgebrochenSenden, ladenBlau }
    }
}
</script>