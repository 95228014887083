<template>
    <div class="w-full h-2 relative rounded-full bg-LeichtesBlau">
        <div :style="{ width: fortschritt + '%' }" class="absolute h-full rounded-full bg-DentaBlue"></div>
    </div>
  </template>
  
  <script>
import { computed } from 'vue';

  export default {
    props: ["fortschritt"],
    setup(props) {
        const fortschritt = computed(() => {
            if(props.fortschritt && typeof props.fortschritt && props.fortschritt >= 0 && props.fortschritt <= 1) {
                return props.fortschritt * 100
            } else {
                return 0
            }
        })

        return { fortschritt };
    }
  };
  </script>
