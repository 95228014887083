<template>
    <div
    class="h-full w-full flex justify-center items-center">
        <img
        v-if="url != null"
        :src="url" 
        alt="snap"
        class=" max-w-full max-h-full aspect-auto">

        <!-- TODO: Richtigen Ladeindikator einbauen -->
        <Spinner v-else/>
    </div>
</template>

<script>
//Alle benötigten Vue Komponenten werden importiert
import store from '@/store';
import { ref } from '@vue/reactivity'

//Alle benötigten eigenen Komponenten werden importiert
import Spinner from '@/components/Spinner.vue';

//Alle benötigten Helferfunktionen und Konstanten werden importiert
import { snapUriAbfragen } from '@/services/helfer';
import { computed } from '@vue/runtime-core';
export default {

    components: {
        Spinner
    },

    props: ["url"],
}
</script>
