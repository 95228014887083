<template>
    <div class="w-full h-screen flex justify-start items-center p-5 bg-white">
        <OnboardingFortschrittsAnzeige class="h-full w-1/3 mr-40" />
        <div class="onboarding-komponente">
            <OnboardingTitel titel="Joint Controll Vertrag"
                :untertitel="`Um die Anfrage zur Partnerschaft anzunehmen, öffnen, lesen und unterschreiben sie den Joint Controll Vertrag nach Art. 28 DSGVO, den Sie mit ${partner.name} schließen.`" />
            <DokumentZurUnterschrift :laedt="dokumentLaedt" :laedtText="dokumentLaedtText" :name="dokumentName"
                :signingUrl="signingUrl" @unterzeichnet="anfrageAnnehmen" />
        </div>
    </div>
</template>
<script>
import OnboardingFortschrittsAnzeige from "@/components/OnboardingFortschrittsAnzeige.vue"
import OnboardingTitel from "@/components/OnboardingTitel.vue";
import DokumentZurUnterschrift from "@/components/DokumentZurUnterschrift.vue";
import { useRoute } from "vue-router";
import { onMounted, computed, ref } from "vue";
import store from "@/store";
import { useCloudFunction } from "@/services/cloudFunctionDienst";
import { router } from "@/router";
import { erfolgAnzeigen, fehlerAnzeigen } from "@/services/toastDient";
export default {
    components: {
        OnboardingFortschrittsAnzeige,
        OnboardingTitel,
        DokumentZurUnterschrift
    },
    setup() {
        const route = useRoute()

        const dokumentLaedt = ref(true)
        const dokumentLaedtText = ref(null)
        const signingUrl = ref(null)
        const dokumentName = ref(null)

        const partnerschaft = computed(() => {
            if (typeof route.params.partnerschaftId === "string") {
                return store.getters.partnerschaftAbfragen(route.params.partnerschaftId)
            }
        })

        const partner = computed(() => {
            if (partnerschaft.value != null) {
                return Object.values(partnerschaft.value.teilnehmer).find(v => v.id !== store.getters.praxisAbfragen.id)
            }
        })

        const { result: dokErgebnis, error: dokFehler, call: vorbereiten } = useCloudFunction("jcvPartnerSigningUrlAbfragen")
        const { result: annehmenErgebnis, error: annehmenFehler, call: annehmen } = useCloudFunction("partnerschaftAnnehmen")


        function dokumentVorbereiten() {
            if (partnerschaft.value != null) {
                dokumentLaedtText.value = "JCV wird vorbereitet..."
                dokumentLaedt.value = true

                vorbereiten({ partnerschaftsId: partnerschaft.value.id })
                    .then(() => {
                        if (dokFehler.value === false) {
                            dokumentName.value = dokErgebnis.value.name
                            signingUrl.value = dokErgebnis.value.signingUrl
                        }
                    })
                    .finally(() => {
                        dokumentLaedt.value = false
                        dokumentLaedtText.value = null
                    })
            }
        }

        function anfrageAnnehmen() {
            if (dokumentLaedt.value === false && typeof signingUrl.value === "string") {
                dokumentLaedt.value = true
                dokumentLaedtText.value = "Anfrage wird angenommen..."

                annehmen({ id: partnerschaft.value.id })
                    .then(() => {
                        if (annehmenFehler.value === false) {
                            erfolgAnzeigen("Anfrage Angenommen.")
                            router.push({ name: "Partner" })
                        } else {
                            fehlerAnzeigen("Fehler beim Annehmen der Partnerschaft. Bitte versuchen Sie es erneut.")
                        }
                    })
                    .finally(() => {
                        dokumentLaedt.value = false
                        dokumentLaedtText.value = null
                    })
            }
        }

        onMounted(dokumentVorbereiten)

        return {
            partner,
            dokumentLaedt,
            dokumentLaedtText,
            signingUrl,
            dokumentName,
            anfrageAnnehmen
        }
    }
}
</script>