<template>
    <div class="w-96 h-full bg-white rounded-lg p-8 flex flex-col justify-between font-dm-sans">
        <!-- Praxisinfos und Verwaltung -->
        <div class="w-full h-full flex flex-col justify-start items-center mt-5 space-y-5">
            <img :src="praxisAvatar" class="w-24 aspect-square rounded-full">
            <p class=" text-xl">{{ praxisName }}</p>

            <div class="w-full flex justify-evenly pb-2">
                <!-- Speicher -->
                <div class="w-1/3 flex flex-col justify-center items-center space-y-2 text-sm">
                    <p>Speicher</p>
                    <Fortschritt :fortschritt="speicherVerbrauch" />
                </div>

                <!-- Nutzer -->
                <div class="w-1/3 flex flex-col justify-center items-center space-y-2 text-sm">
                    <p>Nutzer</p>
                    <Fortschritt :fortschritt="kontoVerbrauch" />
                </div>
            </div>

            <!-- "Verwalten" Button -->
            <div class="w-full py-2 flex justify-center items-center bg-TextField text-DentaBlue text-sm rounded-lg transition duration-100 hover:cursor-pointer hover:bg-opacity-75"
                @click="verwaltungOeffnen">Verwalten
            </div>
        </div>

        <!-- Geräte-Registrierungskarte -->
        <GeraeteRegistrierungsKarte class="w-full aspect-square" />
    </div>
</template>
<script>
import { computed, onMounted } from 'vue';
import store from "@/store"
import GeraeteRegistrierungsKarte from '@/components/geraete-registrierungs-karte/GeraeteRegistrierungsKarte.vue';
import Fortschritt from './Fortschritt.vue';
import { router } from '@/router';
export default {
    components: {
        GeraeteRegistrierungsKarte,
        Fortschritt
    },
    setup() {
        const praxisAvatar = computed(() => {
            if (store.getters.praxisAbfragen) {
                return store.getters.praxisAbfragen.avatarUrl
            }
        })

        const praxisName = computed(() => {
            if (store.state.praxis) {
                return store.getters.praxisAbfragen.name
            }
        })

        const speicherVerbrauch = computed(() => {
            if (!store.state.praxisLaedt && !store.state.paketLaedt) {
                return store.state.praxis.gespeicherteBytes / store.state.paket.kapazitaet
            } else {
                return 0
            }
        })

        const kontoVerbrauch = computed(() => {
            if (!store.state.praxisLaedt && !store.state.paketLaedt) {

                return store.state.praxis.konten.length / (store.state.paket.nutzer + store.state.paket.geraete)
            } else {
                return 0
            }
        })

        const verwaltungOeffnen = () => router.push({ name: "PraxisVerwaltungAbonnement" })

        return {
            praxisAvatar,
            praxisName,
            speicherVerbrauch,
            kontoVerbrauch,
            verwaltungOeffnen
        }
    }
}
</script>