<template>
    <div class="w-full h-screen flex flex-col justify-center items-center bg-white">
        <!-- Verschachtelte Navigation -->
        <VerschachtelteNavigation v-if="!navVerstecken" :vorherigeRoute="vorherigeRoute" :routen="routen" />

        <!-- Aktuelle (verschachtelte) Seite -->
        <div class="w-full flex-1 overflow-y-auto">
            <!-- Set a specific height for the scrollable content -->
            <div class="h-full w-full">
                <router-view />
            </div>
        </div>
    </div>
</template>
<script>
import VerschachtelteNavigation from "@/components/verschachtelte-navigation/VerschachtelteNavigation.vue"
import store from "@/store"
import { computed } from "vue"
import { useRoute } from "vue-router"
export default {
    components: {
        VerschachtelteNavigation
    },
    setup() {
        const vorherigeRoute = { name: "Organisation", pfad: "/organisation" }
        const route = useRoute()

        var routen = [
            { id: 0, name: "Logo", routenName: "PraxisVerwaltungInformationen" },
            { id: 1, name: "Berechtigungen", routenName: "PraxisVerwaltungBerechtigungen" },
            { id: 2, name: "Nutzer Hinzufügen", routenName: "PraxisVerwaltungNutzerEinladen" },
            { id: 3, name: "Zahlungen", routenName: "PraxisVerwaltungZahlungen" },
            { id: 4, name: "Abonnement", routenName: "PraxisVerwaltungAbonnement" },
            { id: 5, name: "Dokumente", routenName: "PraxisVerwaltungDokumente" },

        ]

        if (store.getters.istTesterAbo === true || !store.state.istPraxis) {
            routen = routen.filter(r => r.name != "Zahlungen" && r.name != "Abonnement").sort((a, b) => a.id - b.id)
        }

        //Navigationsleiste wird nicht auf den Unterseiten angezeigt,
        const navVerstecken = computed(() => route.name == "ZahlungsMethodeAktualisieren" || route.name == "PaketAendern" || route.name == "AbonnementKuendigen")

        return {
            vorherigeRoute,
            routen,
            navVerstecken
        }
    }
}
</script>