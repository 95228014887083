<template>
    <div :about="{ 'onboarding-komponente': !istMobile, 'onboarding-komponente-mobile': istMobile }">
        <OnboardingTitel titel="Telefonnummer Bestätigen"
            untertitel="Geben Sie zur Bestätigung Ihrer Telefonnummer den 6-stelligen Code ein, den wir Ihnen gesendet haben." />
        <div class="w-full flex my-10" :class="{ 'justify-start': !istMobile, 'justify-center': istMobile }">
            <LottieAnimation v-if="laedt" class="w-40 aspect-square" :animation-data="ladenBlau" :auto-play="true"
                :loop="true" />
            <ZiffernFeld v-else :zuruecksetzen="codeZuruecksetzen" @aktualisiert="(wert) => code = wert" />
        </div>
        <div id="re-captcha-verifier"></div>
    </div>
</template>
<script>
//Allgemeine Imports
import { auth } from '@/firebase/config';
import { computed, onMounted, ref, watch } from 'vue';
import { RecaptchaVerifier } from 'firebase/auth';
import { LottieAnimation } from 'lottie-web-vue';
import ladenBlau from "@/assets/animations/laden-blau.json"

//Komponenten
import OnboardingTitel from '@/components/OnboardingTitel.vue';
import OnboardingWeiterButton from '@/components/OnboardingWeiterButton.vue';
import ZiffernFeld from './components/ZiffernFeld.vue';
import { registrierungAbschliessen, registrierungStarten } from '@/services/mfaDienst';
import { useCloudFunction } from '@/services/cloudFunctionDienst';
import { fehlerAnzeigen } from '@/services/toastDient';
import store from '@/store';

export default {
    components: { OnboardingTitel, OnboardingWeiterButton, LottieAnimation, ZiffernFeld },
    props: ["nummer", "typ"],
    emits: ["weiter"],
    setup(props, context) {

        const istMobile = computed(() => store.state.mobile)

        /**
         * Gibt die Art des zweiten Faktors (Primär oder Sekundär) an. Standard ist immer Primär.
         */
        const faktorTyp = computed(() => {
            if (typeof props.typ == "string" && (props.typ === "primaer" || props.typ === "sekundaer")) {
                return props.typ
            } else {
                return "primaer"
            }
        })

        /** Gibt an, ob ein Ladevorgang läuft */
        const laedt = ref(false)

        /** Gibt an, ob der Nutzer die Challenge bestanden hat (automatisch oder manuell) */
        const reCaptchaVerifiziert = ref(false)

        /** Speichert den aktuell eingegebenen Code (im Ziffernfeld) */
        const code = ref("")
        const codeZuruecksetzen = ref(false)

        /** Speichert die Instanz des ReCAPTCHA Widgets, dass die Verifizierung startet sobald
         *  die Komponente gerendert ist
         */
        var reCaptcha

        const {
            pending: faktorenLaden,
            result: faktorenErgebnis,
            error: faktorenFehler,
            call: faktorenAbfragen
        } = useCloudFunction("registrierteFaktorenAbfragen")

        function vorhandendeFaktorenPruefen() {
            //TODO: Hier registrierte Faktoren prüfen, und falls gewünschter Faktor bereits vorhanden, sofort weiter gehen (mit Fehlertoast)
        }

        /** Erstellt die ReCAPTCHA Instanz, und startet damit die Verifizierung */
        function faktorenPruefenUndCaptchaInitialisiren() {
            laedt.value = true

            //Zunächst wird sichergestellt, dass der Nutzer nicht bereits einen Faktor des Typs registriert hat.
            //Wenn doch wird die Verifizierung übersprungen, und ein Fehler angezeigt (dieser Fall sollte nicht einreten!)
            faktorenAbfragen()
                .then(() => {
                    if (faktorenFehler.value === false) {
                        const gesuchterName = faktorTyp.value === "primaer" ? "a" : "b"
                        if (faktorenErgebnis.value.find(f => f.displayName == gesuchterName) == undefined) {
                            //Noch keinen Faktor der gewünschten Art, es wird fortgefahren
                            reCaptcha = new RecaptchaVerifier(
                                "re-captcha-verifier",
                                {
                                    size: "normal",
                                    "expired-callback": () => reCaptcha.verify(),
                                    callback: _ => codeSenden()
                                },
                                auth
                            )

                            //Verifizierung wird gestartet
                            reCaptcha.verify()
                        } else {
                            //Faktor bereits vorhanden
                            laedt.value = false
                            fehlerAnzeigen(`${faktorTyp.value === "primaer" ? "Primäre" : "Sekundäre"} 2-FA Nummer bereits vorhanden.`)
                        }
                    }
                })
        }

        /** Nachdem die reCAPTCHA Challenge bestanden ist (und wenn eine gültige Telefonnummer gegeben wurde)
         * ruft diese Funktion die Logik zum Anfragen eines SMS Codes an.
         * Danach wird das entsprechende Textfeld angezeigt
         */
        function codeSenden() {
            reCaptchaVerifiziert.value = true
            registrierungStarten(reCaptcha, props.nummer)
                .catch(err => console.log("Fehler beim Start der MFA Registrierung: ", err))
                .then(() => laedt.value = false)
        }

        onMounted(faktorenPruefenUndCaptchaInitialisiren)

        /** Wenn ein 6-Stelliger, gültiger Code eingebenen wurde wird die Verifizierung versucht. */
        watch(code, val => {
            if (/^\d{6}$/.test(val)) {
                registrierungAbschliessen(val, faktorTyp.value)
                    .then(() => {
                        console.log("Verifizierung erfolgreich");
                        context.emit("weiter")
                    })
                    .catch(err => {
                        console.log(err.code);
                        switch (err.code) {
                            case "auth/invalid-verification-code":
                                fehlerAnzeigen("Der angegebene Code ist ungültig.")
                                codeZuruecksetzen.value = true
                                break;
                            //Ungültiger Code, einfach erneut versuchen
                            default:
                                fehlerAnzeigen("Bei der Registrierung Ihrer 2-FA ist ein Fehler aufgetreten.")
                                //TODO: Zurücknavigieren
                                break;
                        }
                    })
            }
        })

        return {
            laedt,
            reCaptchaVerifiziert,
            ladenBlau,
            code,
            codeZuruecksetzen,
            istMobile
        }
    }
}
</script>