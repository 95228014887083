<template>
    <div class="w-52 aspect-square flex justify-center items-center">
        <LottieAnimation
            v-if="laedt"
            :animation-data="ladenBlau"
            class="w-72 aspect-square"
            :auto-play="true"
            :loop="true"
        />
        <LottieAnimation
            v-else-if="authorisiert"
            :animation-data="haken"
            class="w-40 aspect-square"
            :auto-play="true"
            :loop="false"
            @complete="abgeschlossen"
        />
        <Qrcode v-else :value="link" :size="150"/>
    </div>
</template>
<script>
import { firestore } from '@/firebase/config';
import { fehlerSenden } from '@/services/LogDienst';
import { useCloudFunction } from '@/services/cloudFunctionDienst';
import store from '@/store';
import { deleteDoc, doc, onSnapshot } from 'firebase/firestore';
import Qrcode from 'qrcode.vue';
import { computed, onMounted, onUnmounted, ref } from 'vue';
import { LottieAnimation } from 'lottie-web-vue';
import ladenBlau from "@/assets/animations/laden-blau.json"
import haken from "@/assets/animations/haken.json"
export default {
    components: {Qrcode, LottieAnimation},
    props: ["uid"],
    emits: ["authorisiert"],
    setup(props, context) {
        //TODO: Logik implementieren, die prüft ob eine korrekte UUID vorliegt und wenn ja einen Anmeldeauftrag generiert und dessen Link als QR Code anzeigt
        //TODO: Logik implementieren, die einen Listener für das Auftragsdokument einrichtet, und das "authorisiert" Event abgibt wenn das Dokument gelöscht wird
        
        /** Ob der Code aktuell geladen wird */
        const laedt = ref(false)

        /** "true", wenn durch den Listener bekannt wird dass der Auftrag authorisiert wurde. */
        const authorisiert = ref(false)

        /** Speichert den Anmeldelink, nachdem er generiert wurde */
        const link = ref(null)

        /** Wenn ein Link generiert wurde, wird hier die ID des Auftrags zurückgegen */
        const auftragsId = computed(() => {
            if(typeof link.value === "string") {
                return link.value.split("=")[1]
            }
        })

        /** Wenn ein Listener zum Auftragsdokument eingerichtet wurde, wird hier die "unsubscribe" Referenz gespeichert */
        var listenerAbmelden

        const {result, error, call} = useCloudFunction("geraetAnmeldenLinkGenerieren")

        function codeAnzeigen() {
            if(!store.state.kontenLaden && typeof props.uid === "string" && store.state.konten.find(k => k.uid == props.uid && k.typ == "geraet") != undefined) {
                laedt.value = true
                call({uid: props.uid})
                .catch(err => fehlerSenden(err, "Fehler beim Generieren eines Geräte-Anmeldelinks.", "codeAnzeigen", "GeraetAnmelden.vue"))
                .then(() => {
                    if(!error.value) {
                        link.value = result.value.link
                        authorisiertListenerEinrichten()
                    }
                })
                .finally(() => laedt.value = false)
            }
        }

        /** Richtet einen Listener ein, der im Fall dass das Dokument zum Anmeldeauftrag gelöscht wird, das "authorisiert" Event abgibt */
        function authorisiertListenerEinrichten() {
            if(auftragsId.value) {
                listenerAbmelden = onSnapshot(doc(firestore, "geraeteAuthentifizierung", auftragsId.value), sn => {
                    if(!sn.exists()) {
                        authorisiert.value = true
                        listenerAbmelden()
                    }
                })
            }
        }

        const abgeschlossen = () => context.emit("authorisiert")

        /**
         * Entfernt den Auftrag und Löscht den Auftrag, wenn dies noch nötig ist, nachdem das Widget nicht mehr sichtbar ist.
         */
         function cleanup() {
            if(listenerAbmelden) {
                listenerAbmelden()
                console.log("Listener Abgemeldet");
            }

            try {
                if(auftragsId.value) {
                    deleteDoc(doc(firestore, "geraeteAuthentifizierung", auftragsId.value))
                }
            } catch (error) {
                
            }
        }

        onMounted(codeAnzeigen)
        onUnmounted(cleanup)

        return {
            laedt,
            link,
            authorisiert,
            ladenBlau,
            haken,
            abgeschlossen
        }
    }
}
</script>