import ContextMenu from "@imengyu/vue3-context-menu";
import "@/assets/kontext-menue-styles.scss";

/**
 * Zeigt
 * @param {MouseEvent} event Klick Event
 * @param {{id: number, text: string, destruktiv: boolean, onClick: () => void}[]} items Liste von Menüpunkten, die Angezeigt werden sollen
 */
function kontextMenueAnzeigen(event, items) {
  ContextMenu.showContextMenu({
    x: event.x,
    y: event.y,
    theme: "ds",
    items: items
      .sort((a, b) => a.id - b.id)
      .map((i) => {
        return {
          label: i.text,
          onClick: i.onClick,
          customClass: i.destruktiv === true ? "destruktiv" : null,
        };
      }),
  });
}

export { kontextMenueAnzeigen };
