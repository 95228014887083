<template>
    <div class="w-full h-screen">
        <transition name="fade">
            <UploadFortschritt v-if="uploadGestartet === true" :fortschritt="uploadFortschritt"
                :komplett="uploadKomplett" />
            <div v-else class="w-full h-full flex justify-start items-center p-5 bg-white">
                <OnboardingFortschrittsAnzeige class="h-full w-1/3 mr-40" :index="index" :schritte="schrittTitel"
                    :laedt="laedt" :fehler="false" />

                <component :is="schrittKomponente" :fotos="fotos" :patient="patient" :faelle="faelle" :schritt="schritt"
                    :zaehne="zaehne" :themen="themen" :ordner="ordner" :aktivePartnerschaften="aktivePartnerschaften"
                    :gewaehltePartnerschaften="partnerschaften" @navigieren="f => navigieren(f)"
                    @fotosAktualisiert="d => fotos = d" @patientAktualisiert="p => patient = p"
                    @faelleAktualisiert="f => faelle = f" @fallAktualisiert="f => fall = f"
                    @schrittAktualisiert="s => schritt = s" @zaehneAktualisiert="z => zaehne = z"
                    @themenAktualisiert="t => themen = t" @ordnerAktualisiert="o => ordner = o"
                    @partnerschaftenAktualisiert="p => partnerschaften = p" />
            </div>
        </transition>
    </div>
</template>
<script>
import OnboardingFortschrittsAnzeige from '@/components/OnboardingFortschrittsAnzeige.vue';
import Fotos from './components/fotos/Fotos.vue';
import Patient from './components/patient/Patient.vue';
import Fall from './components/fall/Fall.vue';
import SchrittUndZaehne from './components/schritt-und-zaehne/SchrittUndZaehne.vue';
import ThemenUndOrdner from './components/themen-und-ordner/ThemenUndOrdner.vue';
import Partnerschaften from './components/partnerschaften/Partnerschaften.vue';
import UploadFortschritt from './components/upload-fortschritt/UploadFortschritt.vue';
import { ref, computed, watch } from 'vue';
import store from '@/store';
import { aufnahmeHochladen } from '@/services/helfer';
import { einmaligenStringGenerieren, patientHochladen, sessionHochladen } from '@/services/firestoreDienst';
import { nutzer } from '@/services/authentifizierungsDienst';
import { fehlerSenden } from '@/services/LogDienst';
import dayjs from 'dayjs';

export default {
    components: {
        OnboardingFortschrittsAnzeige,
        Fotos,
        Patient,
        Fall,
        SchrittUndZaehne,
        ThemenUndOrdner,
        Partnerschaften,
        UploadFortschritt
    },
    setup(_, context) {
        //Allgemeiner State
        const index = ref(0)
        const laedt = ref(false)
        const faelle = ref([])
        const aktivePartnerschaften = computed(() => {
            if (store.state.partnerschaften != null) {
                return store.state.partnerschaften.filter(p => p.aktiv === true)
            } else {
                return []
            }
        })

        //State für die "Upload Fortschritt" Seite
        const uploadGestartet = ref(false)
        const uploadTotaleSchritte = ref(-1)
        const uploadErfolgteSchritte = ref(-1)
        const uploadFortschritt = computed(() => Math.max(0, Math.min((uploadErfolgteSchritte.value / uploadTotaleSchritte.value), 1)))
        const uploadKomplett = ref(false)

        //Relevanter State für die Session
        const fotos = ref([])
        const patient = ref(null)
        const fall = ref(null)
        const schritt = ref(1)
        const zaehne = ref([])
        const themen = ref([])
        const ordner = ref([])
        const partnerschaften = ref([])

        /** Gibt an, ob alle Daten für einen Sessionupload vorhanden sind. */
        const uploadMoeglich = computed(() => {
            return fotos.value.length > 0 &&
                patient.value != null &&
                schritt.value > 0 &&
                zaehne.value.length > 0 &&
                (themen.value.length > 0 || ordner.value.length > 0)
        })

        const schrittTitel = computed(() => {
            var res = []

            for (let i = 0; i < 6; i++) {
                var titel = ""
                var untertitel = ""
                var deaktiviert = false

                switch (i) {
                    case 0:
                        titel = "Fotos"
                        untertitel = "Wählen Sie die Fotos für Ihre Session."
                        break;
                    case 1:
                        titel = "Patient"
                        untertitel = "Wählen Sie den Patienten für die Session."
                        break
                    case 2:
                        titel = "Fall"
                        untertitel = "Geben Sie an, welchem Fall die Session angehört."

                        //Kann deaktiviert sein, wenn der ausgewählte Patient keine Fälle hat.
                        deaktiviert = index.value > 1 && patient.value != null && faelle.value.length == 0

                        break;
                    case 3:
                        titel = "Informationen"
                        untertitel = "Bestimmen Sie allgemeine Infos zur Session."
                        break;
                    case 4:
                        titel = "Themen & Ordner"
                        untertitel = "Themen & Ordner, in die wir Ihre Session hinzufügen."
                        break;
                    case 5:
                        titel = "Zusammenarbeit"
                        untertitel = "Wählen Sie optional, mit wem Sie die Session teilen."

                        //Kann deaktiviert sein, wenn die Praxis noch keine Partnerschaften angelegt hat
                        deaktiviert = aktivePartnerschaften.value.length == 0

                        break;
                    default:
                        break;
                }

                res.push({
                    index: i,
                    titel: titel,
                    untertitel: untertitel,
                    deaktiviert: deaktiviert
                })
            }

            return res.sort((a, b) => a.index - b.index)
        })

        const schrittKomponente = computed(() => {
            switch (index.value) {
                case 1:
                    return Patient
                case 2:
                    return Fall
                case 3:
                    return SchrittUndZaehne
                case 4:
                    return ThemenUndOrdner
                case 5:
                    return Partnerschaften
                default:
                    return Fotos
            }
        })

        /**
         * Navigiert einen (oder mehrere je nach Situation) nach vorne, oder zurück.
         * @param {boolean} forwaerts Ob der Schritt nach vorne oder nach hinten geht
         */
        function navigieren(forwaerts) {
            if (laedt.value === true) { return }
            if (forwaerts === true) {
                if (index.value == 1 && (!faelle.value || faelle.value.length == 0)) {
                    //"Fälle" Seite überspringen
                    index.value = 3
                } else if ((index.value == 4 && aktivePartnerschaften.value.length == 0) || index.value >= 5) {
                    //Upload starten
                    hochladen()
                } else {
                    index.value++
                }
            } else {
                if (index.value == 3 && (!faelle.value || faelle.value.length == 0)) {
                    index.value = 1
                } else if (index.value > 0) {
                    index.value--
                }
            }
        }

        /** Helfer für Uploadprozess. Generiert aus den gewählten Partnerschaften eine Teilnehmerliste und gibt sie zurück. 
        */
        function sessionTeilnehmerGenerieren() {
            let eigentuemerPraxis = store.getters.praxisAbfragen;

            //Der Eiegntümer wird mit Zugriffsvektor "0" (Admin) immer Hinzugefügt
            var teilnehmer = {
                [eigentuemerPraxis.id]: {
                    id: eigentuemerPraxis.id,
                    name: eigentuemerPraxis.name,
                    avatarUrl: eigentuemerPraxis.avatarUrl,
                    zugriff: ["0"],
                }
            }

            for (let i = 0; i < partnerschaften.value.length; i++) {
                const ps = partnerschaften.value[i];

                //Die Fremde Praxis wird der Partnerschaft entnommen
                let p = Object.values(ps.teilnehmer).find(
                    (t) => t.id !== eigentuemerPraxis.id
                );

                //Für die fremde Praxis wird ein Teilnehmerobjekt erstellt
                teilnehmer[p.id] = {
                    id: p.id,
                    name: p.name,
                    avatarUrl: p.avatarUrl,

                    //Die Praxis erhält über die Partnerschaft Zugriff auf die Session
                    zugriff: [ps.id],
                };
            }

            return teilnehmer
        }

        function themenListeGenerieren() {
            var erg = []

            //Themen
            erg.push(...themen.value.map(t => t.id))

            //Ordner
            erg.push(...ordner.value.map(o => o.id))

            return erg
        }

        /** Helfer für den Uploadprozess. Lädt jedes gewählte Foto als Snap in der Session hoch, und aktualisiert dabei das übergebene Sessionobjekt. 
         * Am Ende wird die zum Upload fertige Session zurückgegeben. */
        async function snapsHochladen(session) {
            for (let i = 0; i < fotos.value.length; i++) {
                //Für das Foto (die Aufnahme) wird ein Snap generiert, und beides ins Backend hochgeladen
                let ergebnis = await aufnahmeHochladen(fotos.value[i], session.id);
                uploadErfolgteSchritte.value += 3;
                //Die ID des hochgeladenen Snaps wird in die Session eingefügt
                session.snaps.push(ergebnis.id);

                //Wenn die Aufnahme einen Entstehungszeitstempel hat, werden die Start -und Endwerte der Session falls nötig angepasst
                if (typeof ergebnis.entstehungsZeitpunkt === "number") {
                    if (!session.anfangsZeit || session.anfangsZeit > ergebnis.entstehungsZeitpunkt) {
                        session.anfangsZeit = ergebnis.entstehungsZeitpunkt;
                    }

                    if (!session.endZeit || session.endZeit < ergebnis.entstehungsZeitpunkt) {
                        session.endZeit = ergebnis.entstehungsZeitpunkt;
                    }
                }
            }

            return session
        }

        /** Nimmt die gewählte Fall-ID und setzt die  */
        function fallAktualisiert(fallId) {
            if (typeof fallId === "string") {
                const fall = faelle.value.find(f => f.id == fallId)

                if (fall != undefined) {
                    schritt.value = fall.schritt + 1
                    zaehne.value = fall.zaehne
                    themen.value = fall.themen.filter(t => t.standard === true)
                    ordner.value = fall.themen.filter(t => t.standard === false)
                }
            } else {
                schritt.value = 1
                zaehne.value = []
                themen.value = []
                ordner.value = []
            }
        }

        async function hochladen() {
            try {
                if (uploadMoeglich.value === true) {
                    //Der Ladezustand wird gesetzt.
                    uploadTotaleSchritte.value = (fotos.value.length * 3) + (patient.value.neu === true ? 2 : 1)
                    uploadErfolgteSchritte.value = 0
                    uploadGestartet.value = true
                    uploadKomplett.value = false

                    //Falls der Nutzer einen neuen Patienten erstellen möchte, wird das hier getan
                    if (patient.value.neu === true) {
                        const patientObj = {
                            id: einmaligenStringGenerieren(),
                            nummer: patient.value.nummer,
                            eigentuemer: store.getters.praxisAbfragen.id,
                        }
                        await patientHochladen(patientObj)
                        uploadErfolgteSchritte.value++;

                        //Für den Upload wird die ID des neuen Patienten auch lokal gesetzt!
                        patient.value.id = patientObj.id
                    }

                    const teilnehmer = sessionTeilnehmerGenerieren()

                    const themenListe = themenListeGenerieren()

                    //Falls der Nutzer einen neuen Fall erstellen möchte / keine aktuellen vorhanden sind, wird eine neue ID generiert
                    if (fall.value === null) {
                        fall.value = einmaligenStringGenerieren()
                    }

                    //Alle statischen Daten werden in ein Sessionobjekt geschrieben
                    var session = {
                        id: einmaligenStringGenerieren(),
                        fallId: fall.value,
                        themen: themenListe,
                        schritt: schritt.value,
                        zaehne: zaehne.value,
                        eigentuemer: store.getters.praxisAbfragen.id,
                        hochladeZeit: dayjs().unix(),
                        patient: patient.value.id,
                        behandler: nutzer.value.uid,
                        teilnehmer: teilnehmer,
                        snaps: [],
                    };

                    //Die Fotos der Session werden zuerst hochgeladen, damit das Sessionobjekt mit den finalen Snap-ID's und Zeiten aktualisiert werden kann.
                    session = await snapsHochladen(session)

                    //Die Session wird hochgeladen
                    await sessionHochladen(session);
                    uploadErfolgteSchritte.value++;

                    //Die Session wurde erfolgreich hochgeladen, die Daten werden damit aktualisiert
                    uploadKomplett.value = true
                }
            } catch (error) {
                fehlerSenden(
                    error,
                    "Fehler beim Hochladen einer Session.",
                    "hochladen",
                    "SessionHochladen");
            }
        }

        watch(fall, f => fallAktualisiert(f))

        return {
            index,
            laedt,
            schrittTitel,
            schrittKomponente,
            schritt,
            zaehne,
            fotos,
            patient,
            faelle,
            aktivePartnerschaften,
            fall,
            themen,
            ordner,
            partnerschaften,
            uploadGestartet,
            uploadFortschritt,
            uploadKomplett,
            navigieren
        }
    }
}
</script>