<template>
    <form :class="{ 'onboarding-komponente': istMobile === false, 'onboarding-komponente-mobile': istMobile === true }"
        @submit.prevent @keydown.enter.prevent="anmelden">
        <OnboardingTitel titel="Willkommen bei DentaSnap!"
            untertitel="Bitte bestätigen Sie vor dem Start noch Ihre E-Mail Adresse." :mobile="istMobile" />
        <div class="w-full h-full flex flex-col justify-between items-start" :class="{ 'space-y-10': !istMobile }">
            <input type="email"
                :class="{ 'default-textfield': istMobile === false, 'default-textfield-dynamic-length': istMobile === true }"
                placeholder="E-Mail Adresse" ref="emailRef" v-model="email">
            <OnboardingWeiterButton :deaktiviert="!emailGueltig" :laden="laedt" @click="anmelden" />
        </div>
    </form>
</template>

<script>
import OnboardingTitel from '@/components/OnboardingTitel.vue';
import OnboardingWeiterButton from '@/components/OnboardingWeiterButton.vue';
import { auth } from '@/firebase/config';
import store from '@/store';
import { signInWithEmailLink } from 'firebase/auth';
import { computed, onMounted, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
export default {
    components: {
        OnboardingTitel,
        OnboardingWeiterButton
    },
    setup(props, context) {
        const istMobile = computed(() => store.state.mobile)

        const laedt = ref(false)

        const router = useRouter()

        const emailRef = ref(null)

        const email = ref("")
        const emailGueltig = computed(() => /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(email.value))

        function anmelden() {
            if (emailGueltig.value === true) {
                laedt.value = false
                signInWithEmailLink(auth, email.value, window.location.href)
                    .catch(err => {
                        //TODO: Nutzer entsprechende Fehlermeldung und Option zum Neusenden des Links anzeigen
                        console.log("Fehler bei der Anmeldung per E-Mail Link: ", err);
                    })
                    .then(_ => {
                        laedt.value = true
                        router.push({ path: "/" })
                    })
            }
        }

        //E-Mail Feld wird nach Laden automatisch fokussiert
        onMounted(() => emailRef.value.focus())

        return {
            email,
            emailRef,
            emailGueltig,
            laedt,
            istMobile,
            anmelden
        }
    }
}
</script>