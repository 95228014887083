const vorwahlen = [
    { "name": "Afghanistan", "vorwahl": 93 },
    { "name": "Albanien", "vorwahl": 355 },
    { "name": "Algerien", "vorwahl": 213 },
    { "name": "Amerikanisch-Samoa", "vorwahl": 1 },
    { "name": "Andorra", "vorwahl": 376 },
    { "name": "Angola", "vorwahl": 244 },
    { "name": "Anguilla", "vorwahl": 1 },
    { "name": "Antarktis", "vorwahl": 672 },
    { "name": "Antigua und Barbuda", "vorwahl": 1 },
    { "name": "Argentinien", "vorwahl": 54 },
    { "name": "Armenien", "vorwahl": 374 },
    { "name": "Aruba", "vorwahl": 297 },
    { "name": "Australien", "vorwahl": 61 },
    { "name": "Österreich", "vorwahl": 43 },
    { "name": "Aserbaidschan", "vorwahl": 994 },
    { "name": "Bahamas", "vorwahl": 1 },
    { "name": "Bahrain", "vorwahl": 973 },
    { "name": "Bangladesch", "vorwahl": 880 },
    { "name": "Barbados", "vorwahl": 1 },
    { "name": "Belarus", "vorwahl": 375 },
    { "name": "Belgien", "vorwahl": 32 },
    { "name": "Belize", "vorwahl": 501 },
    { "name": "Benin", "vorwahl": 229 },
    { "name": "Bermuda", "vorwahl": 1 },
    { "name": "Bhutan", "vorwahl": 975 },
    { "name": "Bolivien", "vorwahl": 591 },
    { "name": "Bosnien und Herzegowina", "vorwahl": 387 },
    { "name": "Botswana", "vorwahl": 267 },
    { "name": "Brasilien", "vorwahl": 55 },
    { "name": "Britisches Territorium im Indischen Ozean", "vorwahl": 246 },
    { "name": "Britische Jungferninseln", "vorwahl": 1 },
    { "name": "Brunei", "vorwahl": 673 },
    { "name": "Bulgarien", "vorwahl": 359 },
    { "name": "Burkina Faso", "vorwahl": 226 },
    { "name": "Burundi", "vorwahl": 257 },
    { "name": "Kambodscha", "vorwahl": 855 },
    { "name": "Kamerun", "vorwahl": 237 },
    { "name": "Kanada", "vorwahl": 1 },
    { "name": "Kap Verde", "vorwahl": 238 },
    { "name": "Kaimaninseln", "vorwahl": 1 },
    { "name": "Zentralafrikanische Republik", "vorwahl": 236 },
    { "name": "Tschad", "vorwahl": 235 },
    { "name": "Chile", "vorwahl": 56 },
    { "name": "China", "vorwahl": 86 },
    { "name": "Weihnachtsinsel", "vorwahl": 61 },
    { "name": "Kokosinseln", "vorwahl": 61 },
    { "name": "Kolumbien", "vorwahl": 57 },
    { "name": "Komoren", "vorwahl": 269 },
    { "name": "Cookinseln", "vorwahl": 682 },
    { "name": "Costa Rica", "vorwahl": 506 },
    { "name": "Kroatien", "vorwahl": 385 },
    { "name": "Kuba", "vorwahl": 53 },
    { "name": "Curaçao", "vorwahl": 599 },
    { "name": "Zypern", "vorwahl": 357 },
    { "name": "Tschechische Republik", "vorwahl": 420 },
    { "name": "Osttimor", "vorwahl": 670 },
    { "name": "Ecuador", "vorwahl": 593 },
    { "name": "Ägypten", "vorwahl": 20 },
    { "name": "El Salvador", "vorwahl": 503 },
    { "name": "Äquatorialguinea", "vorwahl": 240 },
    { "name": "Eritrea", "vorwahl": 291 },
    { "name": "Estland", "vorwahl": 372 },
    { "name": "Äthiopien", "vorwahl": 251 },
    { "name": "Falklandinseln", "vorwahl": 500 },
    { "name": "Färöer", "vorwahl": 298 },
    { "name": "Fidschi", "vorwahl": 679 },
    { "name": "Finnland", "vorwahl": 358 },
    { "name": "Frankreich", "vorwahl": 33 },
    { "name": "Französisch-Polynesien", "vorwahl": 689 },
    { "name": "Gabun", "vorwahl": 241 },
    { "name": "Gambia", "vorwahl": 220 },
    { "name": "Georgien", "vorwahl": 995 },
    { "name": "Deutschland", "vorwahl": 49 },
    { "name": "Ghana", "vorwahl": 233 },
    { "name": "Gibraltar", "vorwahl": 350 },
    { "name": "Griechenland", "vorwahl": 30 },
    { "name": "Grönland", "vorwahl": 299 },
    { "name": "Grenada", "vorwahl": 1 },
    { "name": "Guam", "vorwahl": 1 },
    { "name": "Guatemala", "vorwahl": 502 },
    { "name": "Guernsey", "vorwahl": 44 },
    { "name": "Guinea", "vorwahl": 224 },
    { "name": "Guinea-Bissau", "vorwahl": 245 },
    { "name": "Guyana", "vorwahl": 592 },
    { "name": "Haiti", "vorwahl": 509 },
    { "name": "Honduras", "vorwahl": 504 },
    { "name": "Hongkong", "vorwahl": 852 },
    { "name": "Ungarn", "vorwahl": 36 },
    { "name": "Island", "vorwahl": 354 },
    { "name": "Indien", "vorwahl": 91 },
    { "name": "Indonesien", "vorwahl": 62 },
    { "name": "Iran", "vorwahl": 98 },
    { "name": "Irak", "vorwahl": 964 },
    { "name": "Irland", "vorwahl": 353 },
    { "name": "Isle of Man", "vorwahl": 44 },
    { "name": "Israel", "vorwahl": 972 },
    { "name": "Italien", "vorwahl": 39 },
    { "name": "Elfenbeinküste", "vorwahl": 225 },
    { "name": "Jamaika", "vorwahl": 1 },
    { "name": "Japan", "vorwahl": 81 },
    { "name": "Jersey", "vorwahl": 44 },
    { "name": "Jordanien", "vorwahl": 962 },
    { "name": "Kasachstan", "vorwahl": 7 },
    { "name": "Kenia", "vorwahl": 254 },
    { "name": "Kiribati", "vorwahl": 686 },
    { "name": "Kosovo", "vorwahl": 383 },
    { "name": "Kuwait", "vorwahl": 965 },
    { "name": "Kirgisistan", "vorwahl": 996 },
    { "name": "Laos", "vorwahl": 856 },
    { "name": "Lettland", "vorwahl": 371 },
    { "name": "Libanon", "vorwahl": 961 },
    { "name": "Lesotho", "vorwahl": 266 },
    { "name": "Liberia", "vorwahl": 231 },
    { "name": "Libyen", "vorwahl": 218 },
    { "name": "Liechtenstein", "vorwahl": 423 },
    { "name": "Litauen", "vorwahl": 370 },
    { "name": "Luxemburg", "vorwahl": 352 },
    { "name": "Macau", "vorwahl": 853 },
    { "name": "Mazedonien", "vorwahl": 389 },
    { "name": "Madagaskar", "vorwahl": 261 },
    { "name": "Malawi", "vorwahl": 265 },
    { "name": "Malaysia", "vorwahl": 60 },
    { "name": "Malediven", "vorwahl": 960 },
    { "name": "Mali", "vorwahl": 223 },
    { "name": "Malta", "vorwahl": 356 },
    { "name": "Marshallinseln", "vorwahl": 692 },
    { "name": "Mauretanien", "vorwahl": 222 },
    { "name": "Mauritius", "vorwahl": 230 },
    { "name": "Mayotte", "vorwahl": 262 },
    { "name": "Mexiko", "vorwahl": 52 },
    { "name": "Mikronesien", "vorwahl": 691 },
    { "name": "Moldau", "vorwahl": 373 },
    { "name": "Monaco", "vorwahl": 377 },
    { "name": "Mongolei", "vorwahl": 976 },
    { "name": "Montenegro", "vorwahl": 382 },
    { "name": "Montserrat", "vorwahl": 1 },
    { "name": "Marokko", "vorwahl": 212 },
    { "name": "Mosambik", "vorwahl": 258 },
    { "name": "Myanmar", "vorwahl": 95 },
    { "name": "Namibia", "vorwahl": 264 },
    { "name": "Nauru", "vorwahl": 674 },
    { "name": "Nepal", "vorwahl": 977 },
    { "name": "Niederlande", "vorwahl": 31 },
    { "name": "Niederländische Antillen", "vorwahl": 599 },
    { "name": "Neukaledonien", "vorwahl": 687 },
    { "name": "Neuseeland", "vorwahl": 64 },
    { "name": "Nicaragua", "vorwahl": 505 },
    { "name": "Niger", "vorwahl": 227 },
    { "name": "Nigeria", "vorwahl": 234 },
    { "name": "Niue", "vorwahl": 683 },
    { "name": "Nordkorea", "vorwahl": 850 },
    { "name": "Nördliche Marianen", "vorwahl": 1 },
    { "name": "Norwegen", "vorwahl": 47 },
    { "name": "Oman", "vorwahl": 968 },
    { "name": "Pakistan", "vorwahl": 92 },
    { "name": "Palau", "vorwahl": 680 },
    { "name": "Palästina", "vorwahl": 970 },
    { "name": "Panama", "vorwahl": 507 },
    { "name": "Papua-Neuguinea", "vorwahl": 675 },
    { "name": "Paraguay", "vorwahl": 595 },
    { "name": "Peru", "vorwahl": 51 },
    { "name": "Philippinen", "vorwahl": 63 },
    { "name": "Pitcairn", "vorwahl": 64 },
    { "name": "Polen", "vorwahl": 48 },
    { "name": "Portugal", "vorwahl": 351 },
    { "name": "Puerto Rico", "vorwahl": 1 },
    { "name": "Katar", "vorwahl": 974 },
    { "name": "Republik Kongo", "vorwahl": 242 },
    { "name": "Réunion", "vorwahl": 262 },
    { "name": "Rumänien", "vorwahl": 40 },
    { "name": "Russland", "vorwahl": 7 },
    { "name": "Ruanda", "vorwahl": 250 },
    { "name": "Saint-Barthélemy", "vorwahl": 590 },
    { "name": "Saint Helena", "vorwahl": 290 },
    { "name": "Saint Kitts und Nevis", "vorwahl": 1 },
    { "name": "Saint Lucia", "vorwahl": 1 },
    { "name": "Saint Martin", "vorwahl": 1 },
    { "name": "Saint-Pierre und Miquelon", "vorwahl": 508 },
    { "name": "St. Vincent und die Grenadinen", "vorwahl": 1 },
    { "name": "Samoa", "vorwahl": 685 },
    { "name": "San Marino", "vorwahl": 378 },
    { "name": "São Tomé und Principe", "vorwahl": 239 },
    { "name": "Saudi-Arabien", "vorwahl": 966 },
    { "name": "Senegal", "vorwahl": 221 },
    { "name": "Serbien", "vorwahl": 381 },
    { "name": "Seychellen", "vorwahl": 248 },
    { "name": "Sierra Leone", "vorwahl": 232 },
    { "name": "Singapur", "vorwahl": 65 },
    { "name": "Sint Maarten", "vorwahl": 1 },
    { "name": "Slowakei", "vorwahl": 421 },
    { "name": "Slowenien", "vorwahl": 386 },
    { "name": "Salomonen", "vorwahl": 677 },
    { "name": "Somalia", "vorwahl": 252 },
    { "name": "Südafrika", "vorwahl": 27 },
    { "name": "Südkorea", "vorwahl": 82 },
    { "name": "Süd-Sudan", "vorwahl": 211 },
    { "name": "Spanien", "vorwahl": 34 },
    { "name": "Sri Lanka", "vorwahl": 94 },
    { "name": "Sudan", "vorwahl": 249 },
    { "name": "Suriname", "vorwahl": 597 },
    { "name": "Svalbard und Jan Mayen", "vorwahl": 47 },
    { "name": "Swasiland", "vorwahl": 268 },
    { "name": "Schweden", "vorwahl": 46 },
    { "name": "Schweiz", "vorwahl": 41 },
    { "name": "Syrien", "vorwahl": 963 },
    { "name": "Taiwan", "vorwahl": 886 },
    { "name": "Tadschikistan", "vorwahl": 992 },
    { "name": "Tansania", "vorwahl": 255 },
    { "name": "Thailand", "vorwahl": 66 },
    { "name": "Togo", "vorwahl": 228 },
    { "name": "Tokelau", "vorwahl": 690 },
    { "name": "Tonga", "vorwahl": 676 },
    { "name": "Trinidad und Tobago", "vorwahl": 1 },
    { "name": "Tunesien", "vorwahl": 216 },
    { "name": "Türkei", "vorwahl": 90 },
    { "name": "Turkmenistan", "vorwahl": 993 },
    { "name": "Turks- und Caicosinseln", "vorwahl": 1 },
    { "name": "Tuvalu", "vorwahl": 688 },
    { "name": "Amerikanische Jungferninseln", "vorwahl": 1 },
    { "name": "Uganda", "vorwahl": 256 },
    { "name": "Ukraine", "vorwahl": 380 },
    { "name": "Vereinigte Arabische Emirate", "vorwahl": 971 },
    { "name": "Vereinigtes Königreich", "vorwahl": 44 },
    { "name": "Vereinigte Staaten", "vorwahl": 1 },
    { "name": "Uruguay", "vorwahl": 598 },
    { "name": "Usbekistan", "vorwahl": 998 },
    { "name": "Vanuatu", "vorwahl": 678 },
    { "name": "Vatikanstadt", "vorwahl": 39 },
    { "name": "Venezuela", "vorwahl": 58 },
    { "name": "Vietnam", "vorwahl": 84 },
    { "name": "Wallis und Futuna", "vorwahl": 681 },
    { "name": "Westsahara", "vorwahl": 212 },
    { "name": "Jemen", "vorwahl": 967 },
    { "name": "Sambia", "vorwahl": 260 },
    { "name": "Simbabwe", "vorwahl": 263 }
]

module.exports = { vorwahlen }