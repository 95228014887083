<template>
    <div v-if="!navVerstecken" class="w-96 h-full py-5 pl-6">
        <div class="w-full h-full bg-white rounded-2xl flex flex-col justify-between">
            <div class="w-full pt-10 flex flex-col">
                <!-- Nutzer & Praxis Info -->
                <button class="px-10 py-4 flex space-x-3 justify-start items-center" @click="zuKonto">
                    <div v-if="laedt === true || profilBild == null"
                        class="w-14 h-14 aspect-square rounded-full bg-LeichtesGrau bg-opacity-20"></div>
                    <img v-else :src="profilBild" alt="" class="w-14 h-14 aspect-square rounded-full object-cover">
                    <div class="w-full flex flex-col justify-center items-start"
                        :class="{ 'space-y-2.5': laedt === true, 'space-y-0': laedt === false }">
                        <div v-if="laedt === true" class="w-3/4 h-5 rounded-md bg-LeichtesGrau bg-opacity-20"></div>
                        <p v-else class="text-[0.9rem] font-bold">{{ nutzerName }}</p>
                        <div v-if="laedt === true" class="w-2/3 h-4 rounded-md bg-LeichtesGrau bg-opacity-20"></div>
                        <p v-else class="text-xs text-LeichtesGrau">{{ praxis.name }}</p>
                    </div>
                </button>

                <!-- Spacer -->
                <div class="w-full flex justify-center py-3">
                    <div class="w-5/6 h-[0.075rem] rounded-full bg-LeichtesGrau bg-opacity-30"></div>
                </div>

                <div class="w-full flex flex-col space-y-2 mt-4 px-5">
                    <!-- Nav-Elemente -->
                    <NavigationsElement v-for="route in routen" :key="route.index" :route="route" />
                </div>
            </div>

            <div class="w-full pb-3 flex flex-col">
                <!-- Spacer -->
                <div class="w-full flex justify-center py-3">
                    <div class="w-5/6 h-[0.075rem] rounded-full bg-LeichtesGrau bg-opacity-30"></div>
                </div>

                <button class="px-10 py-4 flex flex-col space-y-1 justify-center items-center" @click="nutzerAbmelden">
                    <img src="@/assets/logo_text.png" class="w-2/3 object-contain">
                    <p class="text-xs text-LeichtesGrau">Abmelden</p>
                </button>
            </div>
        </div>
    </div>
</template>
<script>
import { computed, ref, watch } from 'vue';
import { abmelden, nameAbfragen, nutzer, profilBildUrlAbfragen } from '@/services/authentifizierungsDienst';
import NavigationsElement from './components/NavigationsElement.vue';
import store from '@/store';
import { useRoute, useRouter } from 'vue-router';
import { routenNamen, pfadBestandteile } from '@/constants/navigationsleiste-ausnahmen';
import { patientenVerwalten, themenVerwalten, zusammenarbeitVerwalten } from '@/constants/berechtigungen';
import { eigentuemer } from '@/constants/konstanten';
import { berechtigungenPruefen } from '@/services/authentifizierungsDienst';
export default {
    components: {
        NavigationsElement
    },
    setup() {
        const route = useRoute()
        const router = useRouter()

        const navVerstecken = computed(() => {
            // Check if route.name is in routenNamen
            const isNameMatch = routenNamen.includes(route.name);

            // Check if route.path contains any substring in pfadBestandteile
            const isPathMatch = pfadBestandteile.some(substring => route.path.includes(substring));

            // Return true if either condition is met
            return isNameMatch || isPathMatch;
        })
        const laedt = computed(() => nutzer.value == null || store.state.praxisLaedt)
        const praxis = computed(() => store.state.praxis)
        const nutzerName = computed(() => {
            if (laedt.value === false) {
                return nameAbfragen()
            }
            return ""
        })

        const profilBild = ref(null)

        watch(laedt, nv => {
            if (nv === false && profilBild.value == null) {
                profilBildSetzen()
            }
        })

        const praxisRouten = computed(() => {
            var routen = [
                {
                    index: 0,
                    name: "Heute",
                    routenName: "Heute",
                },
                {
                    index: 1,
                    name: "Fälle",
                    routenName: "Gallerie",
                },
            ]

            if (berechtigungenPruefen([patientenVerwalten])) {
                routen.push({
                    index: 2,
                    name: "Patienten",
                    routenName: "Patienten",
                },)
            }

            if (berechtigungenPruefen([themenVerwalten])) {
                routen.push({
                    index: 3,
                    name: "Themen",
                    routenName: "Themen",
                    berechtigungen: [themenVerwalten]
                })
            }

            if (berechtigungenPruefen([zusammenarbeitVerwalten])) {
                routen.push({
                    index: 4,
                    name: "Partner",
                    routenName: "Partner",
                    berechtigungen: [zusammenarbeitVerwalten]
                })
            }

            if (berechtigungenPruefen([eigentuemer])) {
                routen.push({
                    index: 5,
                    name: "Organisation",
                    routenName: "Praxis",
                    berechtigungen: [eigentuemer]
                })
            }

            return routen.sort((a, b) => a.index - b.index)
        })

        const laborRouten = computed(() => {
            var routen = [
                {
                    index: 1,
                    name: "Fälle",
                    routenName: "Gallerie",
                }
            ]

            if (berechtigungenPruefen({ patientenVerwalten })) {
                routen.push({
                    index: 2,
                    name: "Patienten",
                    routenName: "Patienten",
                },)
            }

            if (berechtigungenPruefen([zusammenarbeitVerwalten])) {
                routen.push({
                    index: 0,
                    name: "Partner",
                    routenName: "Partner",
                    berechtigungen: [zusammenarbeitVerwalten]
                })
            }

            if (berechtigungenPruefen([themenVerwalten])) {
                routen.push({
                    index: 3,
                    name: "Themen",
                    routenName: "Themen",
                    berechtigungen: [themenVerwalten]
                })
            }

            if (berechtigungenPruefen([eigentuemer])) {
                routen.push({
                    index: 4,
                    name: "Organisation",
                    routenName: "Praxis",
                    berechtigungen: [eigentuemer]
                })
            }

            return routen.sort((a, b) => a.index - b.index)
        })

        const routen = computed(() => store.state.abonnementLaedt ? [] : store.state.istPraxis ? praxisRouten.value : laborRouten.value)

        function zuKonto() {
            router.push({ name: "KontoInformationen" })
        }

        function nutzerAbmelden() {
            abmelden()
            router.push({ name: "Anmeldung" })
        }

        function profilBildSetzen() {
            if (laedt.value === false) {
                profilBildUrlAbfragen()
                    .then(res => profilBild.value = res.url)
            }
        }

        return {
            navVerstecken,
            laedt,
            praxis,
            nutzerName,
            routen,
            profilBild,
            zuKonto,
            nutzerAbmelden,
            berechtigungenPruefen
        }
    }
}
</script>