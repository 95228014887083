<template>
  <img
    v-if="quelle"
    :src="quelle"
    alt="snap"
    @click="gewaehlt"
    class="h-44 aspect-square rounded-md object-cover mr-[0.175rem] mb-[0.175rem] transition duration-150 hover:scale-105 cursor-pointer"/>
    <div v-else class=" h-44 w-44 mr-1 mb-1 bg-DentaBlueLight"></div>
</template>

<script>
//Alle benötigten Frameworks und Vue-Komponenten werden importiert
import { ref } from '@vue/reactivity'
import store from '@/store'

//Alle benötigten Helferfunktionen und Konstanten werden importiert
import { snapUriAbfragen } from '@/services/helfer'

export default {
    props: ["snap"],
    emits: ["gewaehlt"],
    setup(props,context) {
        const link = store.getters.snapVorschauLinkAbfragen(props.snap.id)
        const quelle = ref(link)

        if(!quelle.value) {
            snapUriAbfragen(props.snap.id, true)
            .then(uri => {
                quelle.value = uri
                store.commit("snapVorschauLinkSetzen",{id:props.snap.id, link: uri})
            })
        }

        const gewaehlt = () => context.emit("gewaehlt", props.snap.id)

        return {
            //State für das UI
            quelle,

            //Funktionen für das UI
            gewaehlt
        }
    }
}
</script>