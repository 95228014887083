<template>
  <div v-if="thema != null && standard === true"
    class="w-56 h-44 bg-white rounded-lg p-5 flex flex-col justify-between items-start mr-3 mb-3 transition duration-150"
    :class="{
      'hover:cursor-pointer hover:shadow-md': fotoZahl > 0,
    }" @click="anzeigen">
    <img :src="iconUrl" class="w-9 aspect-square" />
    <div class="flex flex-col justify-start items-start">
      <span class="text-DentaBlue">{{ thema.name }}</span>
      <p v-if="fotoZahl > 0" class="text-Mittelgrau text-sm">
        {{ fotoZahl }} Aufnahmen
      </p>
      <p v-else class="text-Mittelgrau text-sm">Leer</p>
    </div>
  </div>
  <div v-else-if="thema != null && standard === false"
    class="w-56 h-44 bg-white rounded-lg relative mr-3 mb-3 transition duration-150" :class="{
      'hover:cursor-pointer hover:shadow-md': fotoZahl > 0,
    }" @mouseenter="hover = true" @mouseleave="hover = false" @click="anzeigen">

    <!-- Inhalt -->
    <div
      class="w-full h-full bg-white rounded-lg p-5 flex flex-col justify-between items-start transition duration-150 absolute"
      :class="{
        'hover:cursor-pointer': fotoZahl > 0,
      }">
      <img :src="iconUrl" class="w-7 aspect-square" />
      <div class="flex flex-col justify-start items-start">
        <span class="w-36 line-clamp-2 text-DunklesViolett">
          {{ thema.name }}
        </span>

        <p v-if="fotoZahl > 0" class="text-Mittelgrau text-sm">
          {{ fotoZahl }} Aufnahmen
        </p>
        <p v-else class="text-Mittelgrau text-sm">Leer</p>
      </div>
    </div>

    <!-- Buttons -->
    <transition name="fade">
      <div v-if="hover === true" class="w-full h-full absolute z-10 p-3 rounded-lg flex flex-col justify-start items-end">
        <!-- Bearbeiten -->
        <div
          class="bg-DentaBlueLight text-DentaBlue w-7 h-7 rounded-lg flex justify-center items-center mb-1.5 transition duration-150 hover:cursor-pointer hover:opacity-70"
          @mouseenter="buttonHover = true" @mouseleave="buttonHover = false" @click="bearbeiten">
          <ion-icon name="pencil-outline"></ion-icon>
        </div>

        <!-- Löschen -->
        <div v-if="fotoZahl == 0"
          class="bg-WarningHintergrund text-Warning w-7 h-7 rounded-lg flex justify-center items-center transition duration-150 hover:cursor-pointer hover:opacity-70"
          @mouseenter="buttonHover = true" @mouseleave="buttonHover = false" @click="loeschen">
          <ion-icon name="trash-outline"></ion-icon>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
//Alle benötigten Vue Komponenten und Frameworks werden importiert
import { themenVerwalten } from "@/constants/berechtigungen";
import { berechtigungenPruefen } from "@/services/authentifizierungsDienst";
import { ref } from "@vue/reactivity";
import { computed } from "@vue/runtime-core";
import store from "@/store";
import { faelleGenerieren, themenIconAbfragen } from "@/services/helfer";
import { router } from "@/router";
import { onMounted } from "vue";
const funkenIcon = require("@/assets/icons/funken.svg")
const ordnerIcon = require("@/assets/icons/ordner.svg")

export default {
  props: ["id"],
  emits: ["bearbeiten", "loeschen"],
  setup(props, context) {
    /** Gibt an, ob der Nutzer sich mit der Maus über der Karte befindet. */
    const hover = ref(false);

    /** Gibt an, ob der Nutzer mit dem Mouse-Cursor über dem "Bearbeiten" oder "Löschen" Button ist */
    const buttonHover = ref(false);

    /** Gibt das Thema zur übergebenen ID zurück */
    const thema = computed(() => store.getters.themaAbfragen(props.id));

    /** Gibt an, ob das Thema der Karte ein eigenes, oder Standardthema ist */
    const standard = computed(() => {
      if (thema.value) {
        return thema.value.standard;
      }
    });

    /** Wenn geladen wird hier der Data-URL zum Icon hinterlegt. Bei eigenen Themen ist dies immer das standard "Ordner" Icon. */
    const iconUrl = ref(standard.value === true ? funkenIcon : ordnerIcon)

    /** Gibt die Zahl der Aufnahmen mit dem Thema zurück*/
    const fotoZahl = computed(() => {
      let faelle = faelleGenerieren({ themen: [props.id] });

      var count = 0;

      if (faelle && faelle.length) {
        for (let i = 0; i < faelle.length; i++) {
          const fall = faelle[i];
          for (let y = 0; y < fall.length; y++) {
            count += fall[y].snaps.length;
          }
        }
      }

      return count;
    });

    onMounted(() => {
      if (standard.value === true) {
        iconUrl.value = themenIconAbfragen(thema.value)
      }
    })

    /**
     * Wenn mindestens eine Session mit dem Thema vorhanden ist, wird die "AufnahmenThema" Seite für das Thema geladen.
     */
    function anzeigen() {
      if (props.id && fotoZahl.value > 0 && buttonHover.value === false) {
        router.push({ name: "AufnahmenThema", params: { id: props.id } });
      }
    }

    function bearbeiten() {
      hover.value = false;
      context.emit("bearbeiten");
    }

    function loeschen() {
      hover.value = false;
      context.emit("loeschen");
    }

    let nutzerDarfThemenVerwalten = computed(() =>
      berechtigungenPruefen([themenVerwalten])
    );

    return {
      //State für das UI
      hover,
      buttonHover,
      thema,
      standard,
      fotoZahl,
      nutzerDarfThemenVerwalten,
      iconUrl,

      //Funktionen für das UI
      bearbeiten,
      loeschen,
      anzeigen,
    };
  },
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>