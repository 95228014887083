<template>
    <div class="onboarding-komponente">
        <OnboardingTitel titel="Neue Zahlungsmethode" untertitel="Wählen Sie Ihre neue Zahlungsmethode." />

        <InfoKasten titel="Wechselgebühr"
            body='Aus technischen Gründen fällt für die Zahlungsmethode "GiroPay" eine Wechselgebühr von 1,00€ (inklusive MwST) an.' />

        <ZahlungsMethodenWahl class="w-96" @aktualisiert="methodeAktualisieren" />

        <OnboardingWeiterButton :deaktiviert="zahlungsMethode == null" :laden="laedt" :text="buttonText" @click="aendern" />
    </div>
</template>
<script>
import OnboardingTitel from '@/components/OnboardingTitel.vue';
import OnboardingWeiterButton from '@/components/OnboardingWeiterButton.vue';
import ZahlungsMethodenWahl from '@/components/zahlungs-methoden-wahl/ZahlungsMethodenWahl.vue';
import InfoKasten from '@/components/text-kaesten/InfoKasten.vue';
import { zahlungsMethoden } from '@/constants/zahlungsMethoden';
import { computed } from 'vue';
export default {
    components: {
        OnboardingTitel,
        OnboardingWeiterButton,
        ZahlungsMethodenWahl,
        InfoKasten
    },
    props: ["zahlungsMethode", "laedt"],
    emits: ["zahlungsMethodeAktualisiert", "aendern"],
    setup(props, context) {

        const buttonText = computed(() => props.zahlungsMethode === "giropay" ? "Änderung Kostenpflichtig Durchführen" : "Änderung Durchführen")

        const methodeAktualisieren = (methode) => context.emit("zahlungsMethodeAktualisiert", methode)
        const aendern = () => context.emit("aendern")

        return { zahlungsMethoden, buttonText, methodeAktualisieren, aendern }
    }
}
</script>