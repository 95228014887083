<template>
    <router-link 
        :to="{name: route.routenName}" 
        :class="{
            'bg-DentaBlueUltraLight bg-opacity-70 text-DentaBlue font-bold': aktiveRoute === true,
            'bg-transparent text-Mittelgrau': aktiveRoute === false
        }"
        class="w-full h-13 py-3 flex justify-center items-center space-x-5 rounded-xl text-lg font-dm-sans">
            <div class="w-5 h-full flex justify-end items-center">
                <img :src="icon" alt="icon" class="w-5 h-5 aspect-square object-contain">
            </div>
            <div class="w-1/4 h-full flex justify-start items-center">
                {{ route.name }}
            </div>
    </router-link>
</template>
<script>
import { computed } from 'vue';
import { useRoute } from 'vue-router';

export default {
    props: ["route", "collapsed"],
    setup(props) {
        const route = useRoute()

        const aktiveRoute = computed(() => route.name == props.route.routenName)

        const icon = computed(() => {
            if(aktiveRoute.value === true) {
                return require(`@/assets/icons/navigation/blau/${props.route.routenName}.svg`)
            } else {
                return require(`@/assets/icons/navigation/grau/${props.route.routenName}.svg`)
            }
        })

        return {
            icon,
            aktiveRoute
        }
    }
}
</script>