<template>
    <div class="flex flex-col space-y-10 font-dm-sans"
        :class="{ 'w-full items-center': istMobile, 'w-85': !istMobile }">
        <OnboardingTitel titel="Nachricht Versendet"
            untertitel="Wir haben Ihnen eine E-Mail mit allen Informationen gesendet." />
        <LottieAnimation class="w-32 h-32" :animationData="mail" :loop="true" :autoPlay="true" />
    </div>
</template>
<script>
import OnboardingTitel from '@/components/OnboardingTitel.vue';
import { LottieAnimation } from 'lottie-web-vue';
import mail from "@/assets/animations/mail.json"
import { computed } from 'vue';
import store from '@/store';
export default {
    components: { OnboardingTitel },
    setup() {
        const istMobile = computed(() => store.state.mobile)
        return { istMobile, mail }
    }
}
</script>