<template>
    <div class="flex flex-col justify-center items-center space-y-5 font-dm-sans">
        <!-- Karte -->
        <div class="bg-TextField w-80 aspect-paketkarte rounded-3xl px-8 py-10 flex flex-col justify-between space-y-10">
            <!-- Header -->
            <div class="flex flex-col justify-start items-start space-y-2">
                <p class=" text-3xl font-bold">{{ paket.name }}</p>
                <div class="w-1/6 h-0.5 rounded-full" :class="[akzent]"></div>
                <p class="text-sm font-light">{{ paket.beschreibung }}</p>
            </div>

            <!-- Informationen -->
            <div class="w-full flex flex-col">
                <!-- Speicher -->
                <div class="w-full flex justify-center items-center text-Mittelgrau">
                    <div class="w-1/4 flex justify-end mr-3" :class="akzentText">{{ speicher }}</div>
                    <div class="w-3/4 flex justify-start font-light">GB Cloud Speicher</div>
                </div>

                <!-- Nutzer -->
                <div class="w-full flex justify-center items-center text-Mittelgrau">
                    <div class="w-1/4 flex justify-end mr-3" :class="akzentText">{{ paket.nutzer }}</div>
                    <div class="w-3/4 flex justify-start font-light">Individuelle Nutzer</div>
                </div>

                <!-- iOS App -->
                <div class="w-full flex justify-center items-center text-Mittelgrau">
                    <div class="w-1/4 flex justify-end mr-3"><img :src="haken" class="w-3 aspect-square"></div>
                    <div class="w-3/4 flex justify-start font-light">iOS App</div>
                </div>

                <!-- Web App -->
                <div class="w-full flex justify-center items-center text-Mittelgrau">
                    <div class="w-1/4 flex justify-end mr-3"><img :src="haken" class="w-3 aspect-square"></div>
                    <div class="w-3/4 flex justify-start font-light">Web App</div>
                </div>

                <!-- DSGVO Vorlagen -->
                <div class="w-full flex justify-center items-center text-Mittelgrau">
                    <div class="w-1/4 flex justify-end mr-3"><img :src="haken" class="w-3 aspect-square"></div>
                    <div class="w-3/4 flex justify-start font-light">DSGVO Vorlagen</div>
                </div>
            </div>

            <!-- Preis -->
            <div class="w-full flex justify-center items-end space-x-1.5 tracking-wide">
                <p class="text-lg">{{ preis }}</p>
            </div>
        </div>

        <!-- Wechselbutton -->
        <div v-if="wechselButton == null || wechselButton === true" class="w-full flex justify-center items-center">
            <div v-if="paket.id === aktuellesPaket.id || paket.rang <= aktuellesPaket.rang || abonnement.upgradeDurchgefuehrt"
                class="w-2/5 py-2 bg-TextField text-LeichtesGrau flex justify-center items-center rounded-full pointer-events-none">
                <p>Wechseln</p>
            </div>
            <div v-else
                class="w-2/5 py-2 flex justify-center items-center rounded-full transition duration-100 hover:cursor-pointer hover:bg-opacity-70"
                :class="[akzentHintergrund, akzentText]" @click="paketAendern">
                <p>Wechseln</p>
            </div>
        </div>
    </div>
</template>
<script>
import { computed } from 'vue';
import store from '@/store';
import { router } from '@/router';
import { preisStringGenerieren } from "@/services/helfer"
import { mehrwertSteuerSatz } from "@/constants/konstanten"
export default {
    props: ["paket", "wechselButton"],
    setup(props) {

        /** Daten des aktuellen Pakets der Praxis */
        const abonnement = computed(() => store.state.abonnement)
        const aktuellesPaket = computed(() => abonnement.value.paket)

        /* Klasse wird für das einfärben eines Element-Hintergrunds mit der regulären Akzentfarbe verwendet */
        const akzent = computed(() => {
            if (props.paket) {
                switch (props.paket.rang) {
                    case 0: return "bg-BehandlerGruen"
                    case 1: return "bg-DentaBlue"
                    default: return "bg-GeteiltViolett"
                }
            }
        })

        /* Klasse wird für das einfärben eines Element-Hintergrunds mit der Hintergrundversion der Akzentfarbe verwendet */
        const akzentHintergrund = computed(() => {
            if (props.paket) {
                switch (props.paket.rang) {
                    case 0: return "bg-BehandlerGruenHintergrund"
                    case 1: return "bg-DentaBlueUltraLight"
                    default: return "bg-GeteiltViolettHintergrund"
                }
            }
        })

        /* Klasse wird für das einfärben eines Element-Textes mit der regulären Akzentfarbe verwendet */
        const akzentText = computed(() => {
            if (props.paket) {
                switch (props.paket.rang) {
                    case 0: return "text-BehandlerGruen"
                    case 1: return "text-DentaBlue"
                    default: return "text-GeteiltViolett"
                }
            }
        })

        const speicher = computed(() => {
            if (props.paket) {
                return props.paket.kapazitaet
            }
        })

        const haken = computed(() => {
            if (props.paket) {
                switch (props.paket.rang) {
                    case 0: return require("@/assets/icons/haken-akzent-gruen.png")
                    case 1: return require("@/assets/icons/haken-akzent-blau.png")
                    default: return require("@/assets/icons/haken-akzent-lila.png")
                }
            }
        })

        const preis = computed(() => {
            if (props.paket) {
                const netto = abonnement.value.jaehrlich ? props.paket.jahresNettoPreis : props.paket.monatsNettoPreis
                return `${preisStringGenerieren(netto * mehrwertSteuerSatz)}€ / ${abonnement.value.jaehrlich ? 'Jahr' : 'Monat'}`
            }
        })

        function paketAendern() {
            if (props.paket && abonnement.value.upgradeDurchgefuehrt === false) {
                router.push({ name: "PaketAendern", query: { p: props.paket.id } })
            }
        }

        return { akzent, akzentHintergrund, abonnement, akzentText, speicher, aktuellesPaket, haken, preis, paketAendern }
    }
}
</script>