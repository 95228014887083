<template>
    <div class="onboarding-komponente">
        <OnboardingTitel titel="Joint Controll Vertrag"
            :untertitel="`Öffnen, lesen und unterschreiben Sie den Joint Controll Vertrag nach Art. 28 DSGVO, den Sie mit ${partnerName} abschließen.`" />
        <DokumentZurUnterschrift :laedt="dokumentLaedt" :laedtText="dokumentLaedtText" :name="dokumentName"
            :signingUrl="signingUrl" @unterzeichnet="anfrageSenden" />
    </div>
</template>
<script>
import OnboardingTitel from "@/components/OnboardingTitel.vue"
import DokumentZurUnterschrift from "@/components/DokumentZurUnterschrift.vue";
import { onMounted, ref } from "vue";
import { useCloudFunction } from "@/services/cloudFunctionDienst";
import { router } from "@/router";
import { erfolgAnzeigen } from "@/services/toastDient";
export default {
    components: {
        OnboardingTitel,
        DokumentZurUnterschrift
    },
    props: ["partnerName", "partnerCode"],
    setup(props, context) {
        const dokumentLaedt = ref(false)
        const dokumentLaedtText = ref(null)

        const dokumentName = ref(null)
        const signingUrl = ref(null)
        const signatureRequestId = ref(null)

        const { error: vorbereitenFehler, result: vorbereitenResult, call: vorbereiten } = useCloudFunction("jcvVorbereiten")
        const { error: anfrageFehler, result: anfrageErgebnis, call: anfragen } = useCloudFunction("zuPartnerschaftEinladen")

        function jcvVorbereiten() {
            if (typeof props.partnerCode === "string") {
                dokumentLaedt.value = true
                dokumentLaedtText.value = "jcv wird generiert..."
                vorbereiten({ code: props.partnerCode })
                    .then(() => {
                        if (vorbereitenFehler.value === false) {
                            dokumentName.value = vorbereitenResult.value.name
                            signingUrl.value = vorbereitenResult.value.signingUrl
                            signatureRequestId.value = vorbereitenResult.value.signingRequestId
                            console.log(dokumentName.value, signingUrl.value);
                        }
                    })
                    .finally(() => {
                        dokumentLaedt.value = false
                        dokumentLaedtText.value = null
                    })
            }
        }

        function anfrageSenden() {
            if (typeof props.partnerCode === "string" || typeof signatureRequestId.value === "string") {
                anfragen({ code: props.partnerCode, signatureRequestId: signatureRequestId.value })
                    .then(() => {
                        if (anfrageFehler.value === false) {
                            erfolgAnzeigen("Anfrage Versendet.")
                            router.push({ name: "Partner" })
                        }
                    })
            }
        }

        onMounted(jcvVorbereiten)

        return {
            dokumentLaedt,
            dokumentLaedtText,
            dokumentName,
            signingUrl,
            jcvVorbereiten,
            anfrageSenden
        }
    }
}
</script>