<template>
    <div class="w-full h-screen flex flex-col space-y-12 justify-center items-center font-dm-sans">
        <LottieAnimation class="w-44 aspect-square" :animation-data="verboten" :auto-play="true" :loop="true" />
        <p class="text-xl font-bold text-Warning">Kein Zugriff.</p>
    </div>
</template>
<script>
import verboten from "@/assets/animations/verboten.json"
import { LottieAnimation } from "lottie-web-vue";
export default {
    components: {
        LottieAnimation
    },
    setup() {
        return { verboten }
    }
}
</script>