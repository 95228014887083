<template>
  <ThemaErstellenDialog v-if="istPraxis === true" :state="themaErstellenState"
    @schliessen="themaErstellenState = false" />

  <ThemaBearbeitenDialog v-if="istPraxis === true" :state="bearbeitenState" :thema="bearbeitenZiel"
    @schliessen="themaBearbeitenSchliessen" />

  <ThemaLoeschenDialog v-if="istPraxis === true" :state="loeschenState" :thema="loeschenZiel"
    @schliessen="themaLoeschenSchliessen" />

  <LadenInline v-if="themenLaden" />

  <div v-else class="standard-seite">
    <div class="w-full flex justify-between items-center">
      <PageTitle icon="pricetag" title="Themen" />
      <div class="w-full flex justify-end items-center">
        <AktionsButtonPrimaer v-if="nutzerDarfThemenVerwalten === true && istPraxis === true" iconName="add-outline"
          @click="themaErstellenState = true" />
      </div>
    </div>

    <div class="w-full flex flex-wrap justify-start items-start">
      <Thema v-for="thema in standardThemen" :key="thema.id" :id="thema.id" />
    </div>
    <p v-if="eigeneThemen && eigeneThemen.length > 0" class="text-lg my-5">
      Ordner
    </p>
    <div class="w-full flex flex-wrap justify-start items-start">
      <Thema v-for="thema in eigeneThemen" :key="thema.id" :id="thema.id" @bearbeiten="themaBearbeitenAnzeigen(thema)"
        @loeschen="themaLoeschenAnzeigen(thema)" />
    </div>
  </div>
</template>

<script>
//Alle benötigten Frameworks und Vue-Komponenten werden importiert
import store from "@/store";
import { ref } from "@vue/reactivity";
import { computed } from "@vue/runtime-core";

//Alle benötigten eigenen Komponenten werden importiert
import PageTitle from "@/components/PageTitle.vue";
import AktionsButtonPrimaer from "@/components/AktionsButtonPrimaer.vue";
import AktionsButtonSekundaer from "@/components/AktionsButtonSekundaer.vue";
import Thema from "./components/Thema.vue";
import ThemaErstellenDialog from "./components/ThemaErstellenDialog.vue";
import ThemaBearbeitenDialog from "./components/ThemaBearbeitenDialog.vue";
import ThemaLoeschenDialog from "./components/ThemaLoeschenDialog.vue";
import LadenInline from "@/components/LadenInline.vue";

//Alle benötigten Konstanten und Helferfunktionen werden importiert
import { berechtigungenPruefen } from "@/services/authentifizierungsDienst";
import { themenVerwalten } from "@/constants/berechtigungen";

export default {
  components: {
    PageTitle,
    AktionsButtonPrimaer,
    AktionsButtonSekundaer,
    Thema,
    ThemaErstellenDialog,
    ThemaBearbeitenDialog,
    ThemaLoeschenDialog,
    LadenInline,
  },

  setup() {
    /** Ob die Themen und benötigten Daten zum Anzeigen der verbundenen Sessions aktuell (noch) laden. */
    let themenLaden = computed(() => { return store.state.themenLaden || store.state.snapsLaden })

    let istPraxis = computed(() => store.state.istPraxis)

    let nutzerDarfThemenVerwalten = computed(() =>
      berechtigungenPruefen([themenVerwalten])
    );

    /** Speichert "Thema Mockup" Instanzen für alle aktuellen Themen */
    const standardThemen = computed(() => {
      if (!store.state.themen || !store.state.themen.length) return null;
      return store.state.themen
        .filter((t) => t.standard == true)
        .sort((a, b) => a.name.localeCompare(b.name));
    });

    const eigeneThemen = computed(() => {
      if (!store.state.themen || !store.state.themen.length) return null;
      return store.state.themen
        .filter((t) => t.standard == false)
        .sort((a, b) => a.name.localeCompare(b.name));
    });

    /** Gibt an, ob der "Thema Erstellen" Dialog geöffnet oder geschlossen ist. */
    const themaErstellenState = ref(false);


    //State und Zielobjekt für den "Bearbeiten" Dialog bei Ordnern
    const bearbeitenState = ref(false);
    const bearbeitenZiel = ref(null);

    //State und Zielobjekt für den "Löschen" Dialog bei Ordnern
    const loeschenState = ref(false);
    const loeschenZiel = ref(null);

    /**
     * Setzt die übergebene "Thema Mockup" Instanz als Ziel der Bearbeitung, und zeigt dann den entsprechenden Dialog an.
     * @param {*} thema Das Thema, das bearbeitet werden soll.
     */
    function themaBearbeitenAnzeigen(thema) {
      bearbeitenZiel.value = thema;
      bearbeitenState.value = true;
      console.log("Bearbeiten Dialog wird angezeigt.");
    }

    function themaBearbeitenSchliessen() {
      bearbeitenState.value = false;
      bearbeitenState.value = null;
    }

    /**
     * Setzt die übergebene "Thema Mockup" Instanz als Ziel der Löschung, und zeigt dann den entsprechenden Dialog an.
     * @param {{}} thema Die "Thema Mockup" Instanz, dessen zugrundeliegendes Thema gelöscht werden soll.
     */
    function themaLoeschenAnzeigen(thema) {
      loeschenZiel.value = thema;
      loeschenState.value = true;
    }

    function themaLoeschenSchliessen() {
      loeschenState.value = false;
      loeschenZiel.value = null;
    }

    return {
      //State für das UI
      nutzerDarfThemenVerwalten,
      standardThemen,
      eigeneThemen,
      themenLaden,
      themaErstellenState,
      bearbeitenState,
      bearbeitenZiel,
      loeschenState,
      loeschenZiel,
      istPraxis,

      //Funktionen für das UI
      themaBearbeitenAnzeigen,
      themaLoeschenAnzeigen,
      themaBearbeitenSchliessen,
      themaLoeschenSchliessen,
    };
  },
};
</script>