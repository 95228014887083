<template>
    <div class="flex flex-col bg-TextField justify-start items-center rounded-md py-8 px-5 text-center space-y-3">
    
        <div class="flex flex-col justify-start items-center mb-8">
            <p class="text-3xl text-black font-bold">Scan & Snap</p>
            <p class="text-sm text-Mittelgrau">Um ein Gerät zu registrieren, Scannen Sie diesen Code in der DentaSnap App.</p>
        </div>

        <div class="px-8 py-5 bg-white rounded-md flex justify-center items-center">

            <!-- Ladeanimation -->
            <LottieAnimation
                v-if="laedt"
                class="w-16 h-16"
                ref="anim"
                :animationData="laedBlau"
                :loop="true"
                :autoPlay="true"
                :speed="1"
            />

            <!-- Fehlercontainer -->
            <p 
                v-else-if="!laedt && fehler != null"
                class="text-Warning text-lg">
                {{ fehler }}
            </p>

            <!-- QR Code -->
            <Qrcode
                v-else
                :value="link" 
                :size="150" 
            />
        </div>
    </div>
</template>
<script>
import { useCloudFunction } from '@/services/cloudFunctionDienst';
import { doc, onSnapshot, deleteDoc } from "firebase/firestore";
import Qrcode from 'qrcode.vue';
import { LottieAnimation } from 'lottie-web-vue';
const laedBlau = require("@/assets/laed_blau.json");
import { computed, onMounted, onUnmounted, ref } from 'vue';
import { firestore } from '@/firebase/config';
export default {
    components: {
        LottieAnimation,
        Qrcode
    },
    setup() {
        /** Wenn "true" wird die Ladeanimation angezeigt, statt eines codes */
        const laedt = ref(true)

        /** Wenn ein Link generiert wurde, wird er hier gespeichert. Auf Basis dieses Werts wird der QR Code generiert */
        const link = ref(null)

        /** Sollte beim Generieren des Auftragslink ein Fehler auftreten, wird hier eine Nutzerfreundliche Fehlermeldung gesetzt */
        const fehler = ref(null)

        /** Wenn ein Registrierungslink vorliegt, wird hier die Auftrags-ID aus dem Link entnommen und einzeln zurückgegeben */
        const auftragsId = computed(() => {
            if(typeof link.value === "string") {
                return link.value.split("=")[1]
            }
        })

        /** Wenn ein Listener für das Dokument hinter dem generierten Auftrag eingerichtet wurde, wird hier eine Referenz zum "unsubscribe" Endpunkt gespeichert */
        var auftragsListenerAbmelden

        const {error: linkGenerierenFehler, result: generierterLink, call: linkGenerieren} = useCloudFunction("geraetRegistrierenLinkGenerieren")

        function auftragGenerieren() {
            laedt.value = true
            fehler.value = null
            linkGenerieren()
            .then(() => {
                if(linkGenerierenFehler.value == true) {
                    fehlerNachrichtGenerieren()
                } else {
                    //Link setzten
                    link.value = generierterLink.value.link
                    
                    //Listener für das zugehörige Dokument wird registriert
                    auftragsListenerAbmelden = onSnapshot(doc(firestore, "geraeteAuthentifizierung", auftragsId.value), sn => {
                        if(!sn.exists()) {
                            //Wenn das Dokument gelöscht wurde, wurde der Code verwendet. In dem Fall wird der alte Listener abgemeldet, und
                            //der Generierungsprozess neugestartet
                            auftragsListenerAbmelden()
                            auftragGenerieren()
                        }
                    })
                }
                laedt.value = false
            })
        }

        function auftragLoeschen() {
            if(typeof auftragsId.value === "string") {
                if(auftragsListenerAbmelden != null) {
                    auftragsListenerAbmelden()
                }

                try {
                    deleteDoc(doc(firestore, "geraeteAuthentifizierung", auftragsId.value))
                } catch (error) {
                    console.log("Fehler beim entfernen eines ungenutzten Registrierungsauftrags");
                }
            }
        }

        //TODO: Nachdem Cloud Funktionen ihre Fehlercodes zurückgeben, hier basierend auf dem Fehlercode eine passende Fehlernachricht generieren
        function fehlerNachrichtGenerieren() {
            fehler.value = "Beim Generieren Ihres Registrierungscodes ist ein Fehler aufgetreten."              
        }

        onMounted(auftragGenerieren)
        onUnmounted(auftragLoeschen)

        return {
            laedt,
            link,
            fehler,
            laedBlau
        }
    }
}
</script>