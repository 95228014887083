<template>
    <div class="w-full h-full flex justify-center items-start">

        <BestaetigungsDialog 
            :state="loeschenState" 
            titel="Profilfoto Löschen?" 
            body="Diese Aktion kann nicht rückgängig gemacht werden."
            bestaetigungsText="Löschen"
            :laedt="laedt"
            :destruktiv="true"
            @abgebrochen="loeschenState = false"
            @bestaetigt="fotoLoeschen"/>

        <GDialog 
            v-model="zuschneideState" 
            max-width="800"
            :persistent="true">
            <ZuschneidenDialog
                :url="gewaehlteDateiUrl"
                :istAvatar="true"
                @schliessen="zuschneideState = false"
                @gespeichert="dateiZugeschnitten"
            />
        </GDialog>

        <div class="w-[50rem] flex flex-col rounded-lg shadow-md">
            <!-- Header -->
            <img src="@/assets/images/wellen-blau.jpg" alt="header" class="w-full h-36 object-cover rounded-t-lg">
        
            <!-- Body -->
            <form class="w-full flex flex-col px-12 py-10 space-y-10" @submit.prevent>
                <!-- Profilbild Box -->
                <div class="flex justify-start items-center space-x-12 px-8 py-6 bg-[#E1F1FF] bg-opacity-50">
                    <div 
                        v-if="!avatar" 
                        class="w-24 h-24 aspect-square rounded-full bg-DentaBlueLight"></div>
                    <img 
                        v-else-if="gewaehlteDateiUrl != null && zuschneideState === false" 
                        :src="gewaehlteDateiUrl" 
                        alt="avatar" 
                        class="w-24 h-24 aspect-square rounded-full">
                    <img 
                        v-else 
                        :src="avatar.url" 
                        alt="avatar" 
                        class="w-24 h-24 aspect-square rounded-full">
                    <div class="w-full flex flex-col space-y-2 justify-start items-start">
                        <span class="text-2xl">Profilfoto</span>
                        <span class="text-xs">Mit dem passenden Profilfoto erkennt Ihr Team Sie leichter<br/>Nur Mitglieder Ihrer Praxis können es sehen.</span>
                        <div class="flex space-x-6 pt-2">
                            <!-- Upload Button für eigene Profilbilder -->
                            <label
                                for="file"
                                :disabled="laedt" 
                                class="py-2 px-6 rounded-full bg-DentaBlueUltraLight text-DentaBlue"
                                :class="{'opacity-40': laedt === true}">
                                Foto Hochladen
                            </label>
                            <button
                                :disabled="laedt || (avatar != null && avatar.standard === true)" 
                                class="py-2 px-6 rounded-full bg-DentaBlueUltraLight text-Warning"
                                :class="{'opacity-40': laedt || (avatar != null && avatar.standard === true)}"
                                @click="loeschenState = true">
                                Foto Löschen
                            </button>
                            <input
                                type="file"
                                name="file"
                                id="file"
                                class="inputfile"
                                accept="image/png, image/jpg, image/jpeg"
                                @change="dateiGewaehlt"
                            />
                        </div>
                    </div>
                </div>

                <!-- Name und Ansprachebox -->
                <div class="flex flex-col space-y-3">
                    <div class="w-full flex space-x-3">
                        <div class="w-52 relative">
                            <div class="absolute w-full h-full bg-TextField rounded-md flex justify-center items-center">
                                <p>{{ anrede }}</p>
                            </div>
                            <select 
                                v-model="anrede" 
                                name="anrede" 
                                id="anrede" 
                                class="absolute w-full h-full rounded-md flex justify-center items-start opacity-0 hover:cursor-pointer"
                                @change="nummerFokussieren">
                                <option 
                                    v-for="a in anreden"
                                    :key="a"
                                    :value="a"
                                    >{{ a }}
                                </option>
                            </select>
                        </div>
                        <div class="w-full">
                            <input 
                                type="text" 
                                placeholder="Vorname" 
                                v-model="vorname" 
                                class="default-textfield-dynamic-length"
                                oninput="this.value = this.value.replace(/[^a-zäöüß ]/gi, '')">
                        </div>
                        <div class="w-full">
                            <input 
                                type="text" 
                                placeholder="Nachname" 
                                v-model="nachname" 
                                class="default-textfield-dynamic-length"
                                oninput="this.value = this.value.replace(/[^a-zäöüß ]/gi, '')">
                        </div>
                    </div>

                    <!-- E-Mail Box -->
                    <div class="w-full rounded  -md bg-TextField text-LeichtesGrau px-5 py-3 flex justify-center items-center text-start">
                        <p>{{email}}</p>
                    </div>

                    <!-- E-Mail Infobox -->
                    <div class="flex space-x-2 px-5 pt-3 justify-start items-center text-sm text-DunklesGrau">
                        <img class="w-5 aspect-square object-contain" src="@/assets/icons/warnung-kreis-dunkelgrau.svg" alt="i">
                        <span>Wenn Sie Ihre E-Mail Adresse ändern möchten, kontaktieren Sie bitte <a class="text-DentaBlue" href="mailto:support@dentasnap.de">support@dentasnap.de</a></span>
                    </div>
                </div>

                <div class="w-full flex justify-center">
                    <button 
                        :disabled="!aenderungVorhanden" 
                        class="w-64 h-12 rounded-full flex justify-center items-center text-lg bg-DentaBlueUltraLight text-DentaBlue"
                        :class="{
                            'opacity-30': aenderungVorhanden === false || laedt === true
                        }"
                        @click="displayNameAktualisieren">
                            <LottieAnimation
                                v-if="laedt === true"
                                class="h-16 aspect-square"
                                :animation-data="ladenBlau"
                                :loop="true"
                            />
                            <p v-else>Änderungen Speichern</p>
                    </button>
                </div>
            </form>
        </div>
    </div>
</template>
<script>
import { computed, onMounted, ref, watch } from "vue";
import { anreden } from "@/constants/konstanten";
import { useCloudFunction } from "@/services/cloudFunctionDienst";
import {nutzerProfilbildLoeschen, nutzerAvatarHochladen} from "@/services/cloudStorageDienst"
import { LottieAnimation } from "lottie-web-vue";
import ladenBlau from "@/assets/animations/laden-blau.json"
import { erfolgAnzeigen, fehlerAnzeigen } from "@/services/toastDient";
const {profilBildUrlAbfragen, nutzer, nameAbfragen, nutzerAktualisieren} = require("@/services/authentifizierungsDienst")
import ZuschneidenDialog from "@/components/ZuschneidenDialog.vue";
import BestaetigungsDialog from "@/components/dialoge/BestaetigungsDialog.vue";
import { GDialog } from "gitart-vue-dialog";
import {fileToDataUri} from "@/services/helfer"
export default {
    components: {LottieAnimation, BestaetigungsDialog, ZuschneidenDialog, GDialog}, 
    setup() {
        const {error: datenAktualisierungFehler, call: datenAktualisierungAufrufen} = useCloudFunction("kontoAktualisieren")

        const laedt = ref(false)

        const avatar = ref(null)

        const anrede = ref("")
        const vorname = ref("")
        const nachname = ref("")

        //State fürs Löschen des aktuellen Bildes
        const loeschenState = ref(false)

        //State für Auswahl eines neuen Bildes
        const zuschneideState = ref(false)
        const gewaehlteDatei = ref(null)
        const gewaehlteDateiUrl = ref(null)

        const displayName = computed(() => {
            if(anrede.value != "" && vorname.value != "" && nachname.value != "") {
                return `${anrede.value}-${vorname.value}-${nachname.value}`
            }

            return ""
        })

        const formattierterName = computed(() => {
            if(displayName.value != "") {
                return nameAbfragen(displayName.value)
            }

            return ""
        })

        const aenderungVorhanden = computed(() => {
            if(nutzer.value != null) {
                return (displayName.value != "" && nutzer.value.displayName !== displayName.value) || gewaehlteDatei.value != null
            }

            return false
        })

        const email = computed(() => {
            if(nutzer.value != null) {
                return nutzer.value.email
            } else {
                return ""
            }
        })

        function datenInitialisieren() {
            if(nutzer.value != null) {
                const ns = nutzer.value.displayName.split("-")
                
                anrede.value = anreden.find(a => a == ns[0])
                vorname.value = ns[1].charAt(0).toUpperCase() + ns[1].slice(1)
                nachname.value = ns[2].charAt(0).toUpperCase() + ns[2].slice(1)
            }
        }

        //Wird in "onMounted" und nach Aktualisierung von Daten aufgerufen
        async function nutzerDatenSetzen() {
            const url = await profilBildUrlAbfragen()
            avatar.value = url

            console.log(avatar.value);
        }

        onMounted(() => {
            datenInitialisieren()
            nutzerDatenSetzen()
        })

        function fotoLoeschen() {
            if(nutzer.value != null) {
                laedt.value = true
                nutzerProfilbildLoeschen()
                .then(erf => {
                    if(erf === true) {
                        nutzerDatenSetzen()
                        .then(() => erfolgAnzeigen("Profilfoto gelöscht."))
                    } else {
                        fehlerAnzeigen("Fehler beim Löschen des Profilfotos.")
                    }
                })
                .finally(() => {
                    laedt.value = false
                    loeschenState.value = false
                })
            }
        }

        function dateiGewaehlt(event) {
            if(event.target.files != null && event.target.files.length != null) {
                laedt.value = true
                gewaehlteDatei.value = event.target.files[0]
                fileToDataUri(gewaehlteDatei.value)
                .then(url => {
                    gewaehlteDateiUrl.value = url
                    zuschneideState.value = true
                })
                .finally(() => laedt.value = false)
            } else {
                gewaehlteDatei.value = null
                gewaehlteDateiUrl.value = null
                zuschneideState.value = false
            }
        }

        function dateiZugeschnitten(datei) {
            laedt.value = true
            fileToDataUri(datei)
            .then(url => gewaehlteDateiUrl.value = url)
            .finally(() => {
                laedt.value = false
                gewaehlteDatei.value = datei
                zuschneideState.value = false
            })
        }

        async function aenderungenSpeichern() {
            if(aenderungVorhanden.value === true && laedt.value === false) {
                laedt.value = true
                
                if(nutzer.value.displayName !== displayName.value) {
                    await datenAktualisierungAufrufen({displayName: displayName.value})
                }

                if(gewaehlteDatei.value != null) {
                    await nutzerAvatarHochladen(gewaehlteDatei.value)
                }

                if(datenAktualisierungFehler.value === false) {
                    await nutzerAktualisieren(true)
                    await nutzerDatenSetzen()

                    erfolgAnzeigen("Änderungen gespeichert.")
                    laedt.value = false
                } else {
                    fehlerAnzeigen("Fehler beim Speichern der Änderungen.")
                    laedt.value = false
                }
            }
        }

        return { laedt, avatar, email, anreden, anrede, vorname, nachname, aenderungVorhanden, formattierterName, ladenBlau,loeschenState,gewaehlteDateiUrl,zuschneideState, displayNameAktualisieren: aenderungenSpeichern, fotoLoeschen, dateiGewaehlt, dateiZugeschnitten }
    }
}
</script>