<template>
    <div class="onboarding-komponente">
        <!-- Zuschneidedialog -->
        <GDialog v-model="zuschneideDialogState" max-width="800" :persistent="true">
            <ZuschneidenDialog :url="dateiUrl" :istAvatar="true" @gespeichert="dateiBeschnitten"
                @schliessen="zuschneideDialogState = false" />
        </GDialog>

        <OnboardingTitel titel="Profilbild"
            untertitel="Optionaler Schritt. Nutzer aus Ihrer Organisation, sowie aus Organisationen mit denen eine Partnerschaft besteht, können Ihr Profilbild sehen." />

        <!-- Praxisavatar -->
        <input type="file" name="file" id="file" class="inputfile" accept="image/png, image/jpg, image/jpeg, image/gif"
            @change="dateiGewaehlt" />

        <div class="w-min flex justify-start items-center space-x-6">
            <label for="file">
                <div class="w-28 aspect-square relative transition duration-100 hover:cursor-pointer hover:opacity-80">
                    <div v-if="!gewaehlteDatei" class="w-full h-full bg-TextField rounded-full absolute"></div>
                    <img v-else class="w-full h-full rounded-full absolute opacity-30" :src="dateiUrl" />

                    <div
                        class="w-full h-full flex flex-col justify-center items-center absolute z-10 transition duration-100 hover:scale-105">
                        <img src="@/assets/icons/hinzufuegen-kreis-blau.svg" class="w-8 aspect-square object-cover">
                        <p class="text-dm-sans text-sm text-DentaBlue">Profilbild</p>
                    </div>
                </div>
            </label>

            <!-- Button zum Löschen eines gewählten Bildes -->
            <button v-if="gewaehlteDatei"
                class="w-6 h-6 rounded-full bg-WarningHintergrund bg-opacity-40 flex justify-center items-center"
                @click="dateiZuruecksetzen">
                <img src="@/assets/icons/schliessen-rot.svg" class="w-2.5 aspect-square object-cover">
            </button>
        </div>

        <OnboardingWeiterButton :deaktiviert="false" :laden="laedt" @click="speichern" />
    </div>
</template>
<script>
import OnboardingTitel from '@/components/OnboardingTitel.vue';
import OnboardingWeiterButton from '@/components/OnboardingWeiterButton.vue';
import ZuschneidenDialog from '@/components/ZuschneidenDialog.vue';
import { fehlerSenden } from '@/services/LogDienst';
import { profilBildZuschneiden } from '@/services/bildBearbeitungsDienst';
import { nutzerAvatarHochladen } from '@/services/cloudStorageDienst';
import { fileToDataUri } from '@/services/helfer';
import { fehlerAnzeigen } from '@/services/toastDient';
import { GDialog } from 'gitart-vue-dialog';
import { ref } from 'vue';
export default {
    components: {
        OnboardingTitel,
        OnboardingWeiterButton,
        ZuschneidenDialog,
        GDialog
    },
    emits: ["weiter"],
    setup(_, context) {

        /** "true" wärend die Änderungen gespeichert werden. */
        const laedt = ref(false)

        /** Ob der Dialog zum Zuschneiden aktuell geöffnet ist, oder nicht */
        const zuschneideDialogState = ref(false)

        /** Wenn vorhanden wird hier der "Data URI" für die ausgewählte Datei gespeichert.*/
        const dateiUrl = ref(null)

        /** Nachdem eine Datei zugeschnitten wurde, wird sie fertig für den Upload hier gespeichert */
        const gewaehlteDatei = ref(null)

        function dateiGewaehlt(event) {
            if (event.target.files && event.target.files.length) {
                //Die Datei wird in einen Daten-URI umgewandelt
                fileToDataUri(event.target.files[0])
                    .catch(_ => {
                        fehlerAnzeigen("Die Datei konnte nicht ausgewählt werden.")
                        dateiZuruecksetzen()
                    })
                    .then(uri => {
                        dateiUrl.value = uri
                        zuschneideDialogState.value = true
                    })
            }
        }

        function dateiZuruecksetzen() {
            gewaehlteDatei.value = null
            dateiUrl.value = null
        }

        function dateiBeschnitten(blob) {
            gewaehlteDatei.value = blob
        }

        /** Wenn ein gültiger Name gegeben wurde, wird zunächst (wenn nötig) der Praxisavatar in den Cloud-Storage hochgeladen.
         * Dann wird mit dem neuen Namen die "praxisAktualisieren" Cloudfunktion aufgerufen
         */
        async function speichern() {
            try {
                if (gewaehlteDatei.value) {
                    laedt.value = true

                    //Das gewählte Foto wird beschnitten und ins ".jpg" Format konvertiert
                    const dateiFinal = await profilBildZuschneiden(gewaehlteDatei.value)

                    //Das gewählte Foto wird in den Cloud Storage hochgeladen
                    await nutzerAvatarHochladen(dateiFinal)
                }

                //In jedem Fall geht der Nutzer weiter
                context.emit("weiter")
            } catch (error) {
                fehlerSenden(
                    error,
                    "Fehler beim Beschneiden und Hochladen eines Nutzer Profilbilds.",
                    "speichern",
                    "ProfilBild.vue"
                )
                fehlerAnzeigen("Fehler bei der Profilbildwahl.")
                dateiZuruecksetzen()
            }

            laedt.value = false
        }



        return {
            laedt,
            zuschneideDialogState,
            dateiUrl,
            gewaehlteDatei,
            dateiGewaehlt,
            dateiZuruecksetzen,
            dateiBeschnitten,
            speichern
        }
    }
}
</script>