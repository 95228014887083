<template>
  <LadenInline v-if="partnerschaftenLaden" />
  <div v-else class="standard-seite">
    <!-- Austreten -->
    <BestaetigungsDialog :state="austretenState" titel="Partnerschaft Beenden?"
      :body="`Sie verlieren Zugriff auf alle Sessions, die '${austretenZielName}' mit Ihnen geteilt hat.`"
      :destruktiv="true" bestaetigungsText="Beenden" :laedt="austretenLaedt" @abgebrochen="() => {
        austretenState = false
        austretenZiel = null
      }" @bestaetigt="ausPartnerschaftAustreten" />

    <div class="w-full flex justify-between items-center">
      <PageTitle icon="people-circle" title="Partner" />
      <!-- Partnerschaften sind temporär deaktiviert, bis der JCV finalisiert ist. -->
      <AktionsButtonPrimaer iconName="add" @gedrueckt="zuAnfragenSenden" />
    </div>
    <LeereSeiteVorlage v-if="platzhalterAnzeigen" :animationsDaten="partnerschaftenLeer" titel="Partnerschaften"
      body="Sie haben noch keine Partnerschaften mit anderen DentaSnap Organisationen erstellt." />
    <div v-else class="w-full h-full flex flex-col justify-start items-start">
      <div class="w-full flex flex-wrap justify-start items-start">
        <PartnerschaftAusstehend v-for="wert in ausstehend" :key="wert.id" :id="wert.id" />
      </div>
      <div class="w-full flex flex-wrap justify-start items-start">
        <Partnerschaft v-for="wert in aktiv" :key="wert.id" :id="wert.id" @austreten="() => {
          austretenZiel = wert
          austretenState = true
        }" />
      </div>
    </div>
  </div>
</template>

<script>
import { GDialog } from "gitart-vue-dialog";
import PageTitle from "@/components/PageTitle.vue";
import AktionsButtonPrimaer from "@/components/AktionsButtonPrimaer.vue";
import AktionsButtonSekundaer from "@/components/AktionsButtonSekundaer.vue";
import Partnerschaft from "./components/Partnerschaft.vue";
import PartnerschaftAusstehend from "./components/PartnerschaftAusstehend.vue";
import store from "@/store";
import { useRouter } from "vue-router";
import { computed, ref } from "vue";
import LadenInline from "@/components/LadenInline.vue";
import LeereSeiteVorlage from "@/components/LeereSeiteVorlage.vue";
import partnerschaftenLeer from "@/assets/animations/partnerschaften-leer.json"
import BestaetigungsDialog from "@/components/dialoge/BestaetigungsDialog.vue";
import { useCloudFunction } from "@/services/cloudFunctionDienst";
import { erfolgAnzeigen, fehlerAnzeigen } from "@/services/toastDient";
export default {
  components: {
    GDialog,
    PageTitle,
    AktionsButtonPrimaer,
    AktionsButtonSekundaer,
    Partnerschaft,
    PartnerschaftAusstehend,
    LadenInline,
    LeereSeiteVorlage,
    BestaetigungsDialog
  },
  setup() {
    const router = useRouter()

    /** Gibt an, ob der "Partnerschaft Austreten" Dialog geöffnet ist */
    const austretenState = ref(false);

    /** Speichert eine Referenz zur Partnerschaft, aus der ausgetreten wird wenn der Dialog geöffnet ist */
    const austretenZiel = ref(null);

    const austretenZielName = computed(() => {
      if (austretenZiel.value != null) {
        const partner = Object.values(austretenZiel.value.teilnehmer).find(t => t.id !== store.getters.praxisAbfragen.id)
        if (partner != undefined) {
          return partner.name
        }
      }
      return ""
    })

    const partnerschaftenLaden = computed(() => store.state.partnerschaftenLaden)

    const platzhalterAnzeigen = computed(() => !store.state.partnerschaftenLaden && (!store.state.partnerschaften || !store.state.partnerschaften.length))

    /** Alle Partnerschaften, die die Eigene Praxis noch nicht angenommen hat. */
    const ausstehend = computed(() => {
      if (store.state.partnerschaften && store.state.partnerschaften.length) {
        return store.state.partnerschaften.filter((p) => p.aktiv === false);
      }
    });

    /** Alle Partnerschaften, die aktiv und von beiden Mitgliedern angenommen wurden. */
    const aktiv = computed(() => {
      if (store.state.partnerschaften && store.state.partnerschaften.length) {
        return store.state.partnerschaften.filter((p) => p.aktiv === true);
      }
    });

    const {
      pending: austretenLaedt,
      error: austretenFehler,
      call: austreten,
    } = useCloudFunction("partnerschaftLoeschen");

    function ausPartnerschaftAustreten() {
      if (austretenZiel.value != null) {
        austreten({ id: austretenZiel.value.id }).
          then(() => {
            if (austretenFehler.value === false) {
              erfolgAnzeigen("Aus Partnerschaft ausgetreten.")
            } else {
              fehlerAnzeigen("Fehler beim Austreten.")
            }
          })
          .finally(() => austretenState.value = false)
      }
    }

    function zuAnfragenSenden() {
      router.push({ name: "AnfrageSenden" })
    }

    return {
      partnerschaftenLeer,
      partnerschaftenLaden,
      platzhalterAnzeigen,
      ausstehend,
      aktiv,
      austretenState,
      austretenZiel,
      austretenLaedt,
      austretenZielName,
      zuAnfragenSenden,
      ausPartnerschaftAustreten
    };
  },
};
</script>