<template>
    <form @submit.prevent class="h-full flex flex-col space-y-10 font-dm-sans" :class="{
        'w-85': istMobile === false,
        'w-full h-full justify-between': istMobile === true
    }">
        <div class="w-full space-y-10">
            <div class="w-full flex flex-col">
                <div class="flex space-x-2 text-sm text-DentaBlue transition duration-100 hover:cursor-pointer hover:opacity-70"
                    @click="zurueck">
                    <img src="@/assets/icons/pfeil-links-blau.svg" alt="back-arrow" class="w-2 aspect-square">
                    <p>Zurück</p>
                </div>
                <OnboardingTitel titel="Passwort Zurücksetzen"
                    untertitel="Geben Sie Ihre E-Mail Adresse an. Wir senden Ihnen einen Link zum Passwort zurücksetzen." />
            </div>
            <input type="email" v-model="emailLocal"
                :class="{ 'default-textfield': istMobile === false, 'default-textfield-dynamic-length': istMobile === true }"
                placeholder="E-Mail Adresse" ref="emailRef">
        </div>
        <OnboardingWeiterButton :deaktiviert="!emailGültig" :laden="linkAnfrageLädt"
            @click="zurücksetzenLinkAnfragen" />
    </form>
</template>
<script>
import OnboardingTitel from '@/components/OnboardingTitel.vue';
import OnboardingWeiterButton from '@/components/OnboardingWeiterButton.vue';
import { router } from '@/router';
import { useCloudFunction } from '@/services/cloudFunctionDienst';
import { fehlerAnzeigen } from '@/services/toastDient';
import store from '@/store';
import { computed, onMounted, ref } from 'vue';
export default {
    components: { OnboardingTitel, OnboardingWeiterButton },
    props: ["email"],
    emits: ["linkAngefragt", "zurueck"],
    setup(props, context) {
        const istMobile = computed(() => store.state.mobile)

        const emailLocal = ref(props.email)
        const emailRef = ref(null)

        const emailGültig = computed(() => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(emailLocal.value))

        const linkAnfrageLädt = ref(false)
        const linkAnfrageFehler = ref(null)

        const { error: anfrageFehler, call: anfragen } = useCloudFunction("passwortZuruecksetzenLinkSenden")

        function zurueck() {
            context.emit("zurueck")
        }

        function zurücksetzenLinkAnfragen() {
            if (emailGültig.value && !linkAnfrageLädt.value) {
                linkAnfrageLädt.value = false
                linkAnfrageFehler.value = null

                anfragen({ email: emailLocal.value })
                    .then(() => {
                        if (anfrageFehler.value === false) {
                            context.emit("linkAngefragt")
                        } else {
                            fehlerAnzeigen("Fehler beim Versenden des Passwort-Zurücksetzen Links.")
                            router.push({ name: "Anmeldung" })
                        }

                        linkAnfrageLädt.value = false
                    })
            }
        }

        onMounted(() => {
            if (!emailLocal.value) {
                emailRef.value.focus()
            }
        })

        return {
            emailGültig,
            emailRef,
            emailLocal,
            linkAnfrageLädt,
            linkAnfrageFehler,
            istMobile,
            zurücksetzenLinkAnfragen,
            zurueck
        }
    }
}
</script>