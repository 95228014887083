<template>
    <div class="w-full p-5 flex flex-col justify-start items-start rounded-xl bg-DentaBlueUltraLight text-lg">
        <!-- Header -->
        <div class="flex space-x-4">
            <img src="@/assets/icons/information-blau.svg" alt="!" class="w-5 aspect-square object-contain">
            <p class="text-DentaBlue">{{ titel }}</p>
        </div>
        <span v-html="body" class=" text-sm w-full px-2 py-3"></span>
    </div>
</template>
<script>
export default {
    props: ["titel", "body"]
}
</script>