<template>
    <div v-if="!istMobile" class="flex justify-center items-center space-x-32">
        <form @submit.prevent @keydown.enter.prevent class="space-y-12">
            <OnboardingTitel titel="Ihre Mitarbeiter"
                untertitel="Gewähren Sie ihrem Team Zugriff auf Ihre Organisation" />
            <div class="flex flex-col space-y-4">
                <input type="text" ref="emailRef" class="default-textfield" placeholder="E-Mail Adresse(n)"
                    v-model="text" @keydown.enter="enterGedrueckt">

                <div class="w-96 h-36 flex flex-col justify-between items-start">
                    <div class="w-full h-full flex flex-wrap justify-start items-start overflow-y-auto">
                        <EmailContainer v-for="adresse in emailAdressen" :key="adresse" :email="adresse"
                            @entfernen="andresseEntfernen(adresse)" />

                        <div v-if="!emailAdressen.length"
                            class="w-full h-full text-DentaBlue bg-DentaBlueUltraLight border-DentaBlueLight border rounded-lg text-sm flex justify-center items-center space-x-2">
                            <p>E-Mail eingeben und </p>
                            <img src="@/assets/icons/enter-taste-blau.png" class="w-4 aspect-square object-cover">
                            <p>drücken!</p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="w-96 flex flex-col space-y-1 text-xs text-Mittelgrau">
                <span>Die E-Mail Adressen erhalten einen Einladelink für Ihre Organisation. Ihre Mitarbeiter erhalten
                    Zugriff
                    auf
                    Fälle in der Webapp{{ istPraxis ? ' und vollen Zugriff auf die iOS App' : '' }}. Sie können die
                    Berechtigungen aller Nutzer
                    in
                    den Einstellungen anpassen.</span>
            </div>

            <OnboardingWeiterButton :deaktiviert="weiterDeaktiviert" :laden="laedt" @click="speichern" />
        </form>

        <GeraeteRegistrierungsKarte v-if="istPraxis === true" class="w-72 h-min" />
    </div>
    <form @submit.prevent @keydown.enter.prevent v-else class="onboarding-komponente-mobile">

        <OnboardingTitel titel="Ihre Mitarbeiter" untertitel="Gewähren Sie ihrem Team Zugriff auf Ihre Organisation." />
        <div class="w-full flex flex-col space-y-4">
            <input type="text" ref="emailRef" class="default-textfield-dynamic-length" placeholder="E-Mail Adresse(n)"
                v-model="text" @keydown.enter="enterGedrueckt">

            <div class="w-full h-36 flex flex-col justify-between items-start">
                <div class="w-full h-full flex flex-wrap justify-start items-start overflow-y-auto">
                    <EmailContainer v-for="adresse in emailAdressen" :key="adresse" :email="adresse"
                        @entfernen="andresseEntfernen(adresse)" />

                    <div v-if="!emailAdressen.length"
                        class="w-full h-full text-DentaBlue bg-DentaBlueUltraLight border-DentaBlueLight border rounded-lg text-sm flex justify-center items-center space-x-2">
                        <p>E-Mail eingeben und </p>
                        <img src="@/assets/icons/enter-taste-blau.png" class="w-4 aspect-square object-cover">
                        <p>drücken!</p>
                    </div>
                </div>
            </div>
        </div>

        <div class="w-full flex flex-col space-y-1 text-xs text-Mittelgrau">
            <span>Die E-Mail Adressen erhalten einen Einladelink zu Ihrer Organisation. Ihre Mitarbeiter erhalten
                Zugriff
                auf
                Fälle in der Webapp und vollen Zugriff auf die iOS App. Sie können die Berechtigungen aller Nutzer
                in
                den Einstellungen anpassen.</span>
        </div>

        <GeraeteRegistrierungsKarte class="w-full h-min" />

        <div class="w-full h-24 flex flex-col justify-start pb-10">
            <OnboardingWeiterButton :deaktiviert="weiterDeaktiviert" :laden="laedt" @click="speichern" />
        </div>
    </form>
</template>
<script>
import OnboardingTitel from '@/components/OnboardingTitel.vue';
import EmailContainer from './components/EmailContainer.vue';
import OnboardingWeiterButton from '@/components/OnboardingWeiterButton.vue';
import GeraeteRegistrierungsKarte from '@/components/geraete-registrierungs-karte/GeraeteRegistrierungsKarte.vue';
import { computed, onMounted, ref, watch } from 'vue';
import { useCloudFunction } from '@/services/cloudFunctionDienst';
import store from '@/store';
export default {
    components: {
        OnboardingTitel,
        EmailContainer,
        OnboardingWeiterButton,
        GeraeteRegistrierungsKarte,
        OnboardingWeiterButton
    },
    props: ["onboarding"],
    emits: ["weiter"],
    setup(props, context) {
        const istMobile = computed(() => store.state.mobile)

        /** Ob aktuell ein Ladeprozess läuft. */
        const laedt = ref(false)

        /** Ob die einzurichtende Organisation eine Praxis oder ein Labor ist */
        const istPraxis = computed(() => store.state.istPraxis)

        /** Speichert den aktuellen Text, der im Eingabefeld vorhanden ist */
        const text = ref("")

        /** Speichert die aktuelle Liste von E-Mail Adressen */
        const emailAdressen = ref([])

        /** Referenz zum Textfeld, verwendet zum Setzen von Fokus */
        const emailRef = ref(null)

        /** Wenn das Formular nicht wärend des Onboarding-Flows verwendet wird, und leer ist, kann "weiter" nicht gedrückt werden. */
        const weiterDeaktiviert = computed(() => (props.onboarding != null && props.onboarding === false) && emailAdressen.value.length == 0)

        /** Wenn der Nutzer "enter" drückt, wird der aktuelle "text" Wert mit der E-Mail Regex abgeglichen.
         * Wenn eine gültige E-Mail vorhanden ist, wird der String als E-Mail Adresse gespeichert 
         */
        function enterGedrueckt() {
            const neu = !emailAdressen.value.find(a => a === text.value)
            const gueltig = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(text.value)
            if (neu && gueltig) {
                emailAdressen.value.push(text.value)
                text.value = ""
                emailRef.value.focus()
            }
        }

        function andresseEntfernen(adresse) {
            if (typeof adresse === "string" && emailAdressen.value.find(a => a === adresse)) {
                emailAdressen.value = emailAdressen.value.filter(a => a !== adresse)
            }
        }

        onMounted(() => emailRef.value.focus())

        const {
            error: einladeFehler,
            call: einladen
        } = useCloudFunction("benutzerEinladen")

        /** Wenn keine E-Mail angegeben ist, wird einfach "weiter" zurückgegeben. 
         * Ansonsten wird mit allen gegebenen Adressen die "benutzerEinladen" Cloud Funktion aufgerufen, und dann weiter gegangen. */
        async function speichern() {
            //Keine E-Mail Adresse, nutzer Überspringt
            if (emailAdressen.value.length == 0) {
                context.emit("weiter")
            } else {
                laedt.value = true
                await einladen({ emails: emailAdressen.value })

                if (!einladeFehler.value) {
                    emailAdressen.value = []
                    context.emit("weiter")
                } else {
                    //Nutzer über Fehler informieren, Fehler loggen
                }
                laedt.value = false
            }
        }

        return {
            text,
            emailAdressen,
            emailRef,
            laedt,
            weiterDeaktiviert,
            istPraxis,
            enterGedrueckt,
            istMobile,
            andresseEntfernen,
            speichern
        }
    }
}
</script>