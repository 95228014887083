<template>
  <div class="standard-seite">
    <!-- Titel -->
    <div class="flex flex-col items-start justify-start mt-6 text-black">
      <p class="text-3xl font-bold">{{ titel }}</p>
      <p class="text-Mittelgrau">{{ datum }}</p>
    </div>

    <!-- Inhalt -->
    <LeereSeiteVorlage 
      v-if="leereSeiteAnzeigen" 
      :animationsDaten="dashboardLeer" 
      titel="Dashboard" 
      body="Lade die ersten Fotos mit der iOS App hoch, um dein Dashboard zu füllen."
      :appStoreQrAnzeigen="true"
    />
    <div v-else class="w-full h-full flex flex-col justify-start items-start mt-16 mb-8">
      <!-- Statistik -->
      <div class="w-full h-[55%] mb-8 flex space-x-8">
        <Statistik class="w-8/12" />
        <Aktivitaeten class="w-4/12" />
      </div>

      <div class="w-full h-[45%] flex space-x-8">
        <Videos class="w-8/12" />
        <Updates class="w-4/12" />
      </div>
    </div>
  </div>
</template>

<script>
import { computed, ref } from "vue";
import { nutzer } from "@/services/authentifizierungsDienst";
import Statistik from "./components/Statistik.vue";
import Aktivitaeten from "./components/Aktivitaeten.vue";
import Videos from "./components/Videos.vue";
import Updates from "./components/Updates.vue";
import LeereSeiteVorlage from "@/components/LeereSeiteVorlage.vue";
import dayjs from "dayjs";
import store from "@/store";
import dashboardLeer from "@/assets/animations/dashboard-leer.json"
export default {
  components: {
    Statistik,
    Aktivitaeten,
    Videos,
    Updates,
    LeereSeiteVorlage,
  },
  setup() {
    /** Wenn "true" wird der Platzhalter für neue / leere Praxen angezeigt */
    const leereSeiteAnzeigen = computed(() => (!store.state.sessions || !store.state.sessions.length) && !store.state.sessionsLaden)

    const titel = computed(() =>
      nutzer.value
        ? `Guten Morgen, ${nutzer.value.displayName.split("-")[1]}`
        : ""
    );

    const datum = computed(() => {
      const format = "dddd, DD. MMMM"
      return dayjs().format(format)
    });

    return {
      titel,
      datum,
      leereSeiteAnzeigen,
      dashboardLeer
    };
  },
};
</script>