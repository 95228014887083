import { storage } from "@/firebase/config";
import {
  ref,
  uploadBytes,
  getMetadata,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";
import { nutzer } from "./authentifizierungsDienst";
import { rueckgabeErstellen } from "./helfer";
import { fehlerSenden } from "./LogDienst";

//Läd den gegebenen Bildblob unter einem durch ID's und thumbnail boolean erstelltem Pfad
//in die Cloud Storage instanz hoch.
const bildHochladen = async (blob, istThumbnail, snapId) => {
  try {
    //Der Pfad für die Bilddatei im Cloudspeicher wird erstellt
    const pfad = `snaps/${snapId}_${istThumbnail ? "thum" : "full"}.jpg`;

    //Anhand des Pfades wird eine Speicherreferenz erstellt
    const referenz = ref(storage, pfad);

    //Um das richtige Dateiformat sicherzustellen, werden eigene Metadaten für die Datei erstellt
    const metadaten = { contentType: "image/jpg" };

    await uploadBytes(referenz, blob, metadaten);

    return rueckgabeErstellen(0);
  } catch (error) {
    fehlerSenden(
      error,
      "Fehler beim Hochladen einer Snap-Bilddatei in den Firebase Cloudstorage.",
      "bildHochladen",
      "cloudStorageDienst"
    );
    return rueckgabeErstellen(11);
  }
};

const bildLoeschen = async (snapId, istThumnail) => {
  try {
    //Der Pfad zum Snap wird erstellt
    const pfad = `snaps/${snapId}_${istThumnail ? "thum" : "full"}.jpg`;

    //Anhand des Pfades wird eine Speicherreferenz erstellt
    const referenz = ref(storage, pfad);

    //Das Objekt auf das die Referenz zeigt wird gelöscht
    await deleteObject(referenz);

    return rueckgabeErstellen(0);
  } catch (error) {
    fehlerSenden(
      error,
      "Fehler beim Löschen einer Snap-Bilddatei aus dem Firebase Cloudstorage.",
      "bildLoeschen",
      "cloudStorageDienst"
    );
    return rueckgabeErstellen(11);
  }
};

/**
 * Lädt die Datei als Avatar für den aktuell angemeldeten Nutzer hoch (wenn möglich)
 * @param {Blob} blob (Fertig verarbeitete) Fotodatei
 * @returns {Boolean} Ob das Hochladen erfolgreich war
 */
async function nutzerAvatarHochladen(blob) {
  try {
    if (
      nutzer.value != null &&
      nutzer.value.uid != null &&
      typeof nutzer.value.uid == "string" &&
      blob != null &&
      typeof blob == "object"
    ) {
      let pfad = `nutzer-avatare/${nutzer.value.uid}.jpg`;
      let dateiRef = ref(storage, pfad);
      let meta = { contentType: "image/jpg" };
      await uploadBytes(dateiRef, blob, meta);
      return true;
    } else {
      return false;
    }
  } catch (error) {
    fehlerSenden(
      error,
      "Fehler beim Hochladen eines Nutzeravatars in den Firebase Cloudstorage",
      "nutzerAvatarHochladen",
      "cloudStorageDienst"
    );
    return false;
  }
}

//Anhand des Namens und der Farbe wird ein Pfad und eine Referenz zu einem Standard Avatar erstellt.
//Für die Referenz wird ein langlebiger download URL abgefragt und zurückgegeben.
const urlZuStandardAvatarAbfragen = async (name, farbe) => {
  try {
    //Der Pfad zum gewünschten Avatar wird erstellt
    const pfad = `standard-nutzer-avatare/${name}/${farbe}.png`;

    //Anhand des Pfades wird eine Referenz zum Cloud Speicher erstellt
    const referenz = ref(storage, pfad);

    //Der langlebige download URL für das Bild wird abgefragt und zurückgegeben
    return await getDownloadURL(referenz);
  } catch (error) {
    fehlerSenden(
      error,
      "Fehler beim Abfragen eines Standard Avatar-URLs.",
      "urlZuStandardAvatarAbfragen",
      "cloudStorageDienst"
    );
  }
};

//Läd die ausgesuchte Datei als Avatar für die gegebene Praxis-ID hoch.
async function praxisAvatarHochladen(datei, praxisId) {
  try {
    const dateiExtension = datei.type.split("/")[1];
    const referenz = ref(
      storage,
      `praxisAvatare/${praxisId}.${dateiExtension}`
    );

    await uploadBytes(referenz, datei);

    const url = await getDownloadURL(referenz);

    return url;
  } catch (error) {
    fehlerSenden(
      error,
      "Fehler beim Hochladen der Bilddatei zu einem Praxisavatar.",
      "praxisAvatarHochladen",
      "cloudStorageDienst"
    );
  }
}

/**
 * Fragt den Download-URL des Avatar der Praxis ab, dessen ID gegeben wird.
 * @param {string} id ID der Praxis
 * @returns {string} Avatar URL der Praxis, wenn vorhanden.
 */
async function praxisAvatarUrlAbfragen(id) {
  try {
    //Der Pfad zum gewünschten Avatar wird erstellt
    const pfad = `praxisAvatare/${id}.png`;

    //Anhand des Pfades wird eine Referenz zum Cloud Speicher erstellt
    const referenz = ref(storage, pfad);

    //Der langlebige download URL für das Bild wird abgefragt und zurückgegeben
    return await getDownloadURL(referenz);
  } catch (error) {
    fehlerSenden(
      error,
      "Fehler beim Abfragen eines Praxis Avatar-URLs.",
      "praxisAvatarUrlAbfragen",
      "cloudStorageDienst"
    );
  }
}

/**
 * Falls der angemeldete Nutzer ein Profilbild hat, wird es aus dem Cloud Storage gelöscht
 */
async function nutzerProfilbildLoeschen() {
  try {
    if (nutzer.value != null) {
      const uid = nutzer.value.uid;
      const file = ref(storage, `nutzer-avatare/${uid}.jpg`);

      //Fehler, dass die Datei nicht gelöscht werden kann weil sie nicht existiert wird erwartet
      //und nicht als Fehler behandelt
      try {
        await deleteObject(file);
      } catch (_) {}

      return true;
    }
  } catch (error) {
    fehlerSenden(
      error,
      "Fehler beim Löschen eines Nutzer Profilbilds.",
      "nutzerProfilbildLoeschen",
      "cloudStorageDienst"
    );
  }
  return false;
}

export {
  bildHochladen,
  bildLoeschen,
  urlZuStandardAvatarAbfragen,
  praxisAvatarHochladen,
  praxisAvatarUrlAbfragen,
  nutzerAvatarHochladen,
  nutzerProfilbildLoeschen,
};
