<template>
    <div class="w-full h-6 flex justify-between items-center px-3">
        <div class="flex space-x-5 justify-center items-center">
            <img class="w-5 aspect-square object-contain" src="@/assets/icons/dokument-schwarz.svg" alt="doc">
            <p class="text-base">{{ dokument.name }}</p>
        </div>

        <button :disabled="downloadLaedt" class="transition duration-100 hover:opacity-70" @click="download"
            :class="{ 'opacity-70': downloadLaedt }">
            <img class="w-4 aspect-auto object-contain" src="@/assets/icons/download-blau.svg" alt="download">
        </button>
    </div>
</template>
<script>
import { ref } from 'vue';

export default {
    props: ["dokument"],
    setup(props) {

        const downloadLaedt = ref(false)

        function download() {
            if (props.dokument != null) {
                window.open(props.dokument.downloadUrl)
            }
        }

        return {
            downloadLaedt,
            download
        }
    }
}
</script>