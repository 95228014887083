<template>
    <EingabeDialog :state="state" titel="Neuen Patient Erstellen" bestaetigungsText="Erstellen" :bestaetigungAktiv="gueltig"
        :laedt="laedt" @abgebrochen="schliessen" @bestaetigt="erstellen">
        <form class="w-full flex flex-col space-y-2" @submit="erstellen">
            <input ref="nameRef" class="default-textfield-dynamic-length" type="text" v-model="name"
                placeholder="Vor -und Nachname">
            <div class="w-full flex space-x-2">
                <!-- Geburtsdatum -->
                <div class="w-64">
                    <VueDatePicker text-input format="dd.MM.yyyy" :placeholder="placeHolder" v-model="gb" ref="gbRef"
                        :hide-input-icon="true" :auto-apply="true" @focus="gbFokussiert = true" @open="closeMenu">
                    </VueDatePicker>
                </div>

                <!-- Patientennummer -->
                <input class="default-textfield-dynamic-length w-full" type="text" v-model="nummer"
                    placeholder="Patientennummer">
            </div>
        </form>
    </EingabeDialog>
</template>
<script>
import EingabeDialog from '@/components/dialoge/EingabeDialog.vue';
import { nummerPruefRegex } from '@/constants/konstanten';
import { firestore } from '@/firebase/config';
import { useCloudFunction } from '@/services/cloudFunctionDienst';
import { erfolgAnzeigen, fehlerAnzeigen } from '@/services/toastDient';
import dayjs from 'dayjs';
import { doc, setDoc } from 'firebase/firestore';
import { computed, nextTick, onMounted, ref, watch } from 'vue';
export default {
    components: { EingabeDialog },
    props: ["state", "nummer"],
    emits: ["schliessen", "erstellt"],
    setup(props, context) {
        const { pending: laedt, error: fehler, result: erg, call: aufrufen } = useCloudFunction("patientenErstellen")

        const name = ref("")
        const nameRef = ref(null)

        const nummer = ref("")

        const gb = ref(null)
        const gbRef = ref(null)
        const gbText = ref("")
        const gbFokussiert = ref(false)

        const placeHolder = computed(() => gbFokussiert.value ? "tt.mm.jjjj" : "Geb. Datum")

        const closeMenu = () => {
            if (gbRef.value) {
                gbRef.value.closeMenu()
            }
        }

        const geburtsDatum = computed(() => {
            if (gb.value != null) {
                const date = dayjs(gb.value)

                if (date.isValid()) {
                    return date.format("DD.MM.YYYY")
                }
            }
        })

        const gueltig = computed(() => {
            return typeof name.value === "string" &&
                name.value != "" &&
                typeof nummer.value === "string" &&
                nummerPruefRegex.test(nummer.value) &&
                typeof geburtsDatum.value === "string" &&
                dayjs(gb.value).isValid()
        })

        function erstellen() {
            if (gueltig.value) {
                aufrufen({
                    nummer: nummer.value,
                    name: name.value,
                    geburtsDatum: geburtsDatum.value
                }).then(() => {
                    if (fehler.value === false) {
                        erfolgAnzeigen("Patient Erstellt.")
                        context.emit("erstellt", erg.value.patient)
                    } else {
                        fehlerAnzeigen("Bei der Erstellung ist ein Fehler aufgetreten.")
                    }
                })
                    .finally(() => context.emit("schliessen"))
            }
        }

        const schliessen = () => context.emit("schliessen")

        watch(() => props.state, val => {
            if (val === true) {
                name.value = ""
                nummer.value = props.nummer ?? ""
                gb.value = null
                gbFokussiert.value = false
            }
        })

        onMounted(() => {
            nextTick(() => {
                if (nameRef.value) {
                    nameRef.value.focus()
                }
            })
        })

        return { name, nummer, gb, gbRef, gbText, gueltig, gbFokussiert, placeHolder, laedt, closeMenu, erstellen, schliessen }
    }
}
</script>