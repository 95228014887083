<template>
    <div class="onboarding-komponente">
        <OnboardingTitel titel="Organisation Gesperrt"
            :untertitel="istEigentuemer === true ? 'Ihre Organisation wurde temporär gesperrt.' : 'Organisation wurde temporär gesperrt'" />
        <div class="w-72 pt-3 pb-6 flex flex-col space-y-8 justify-center items-center">
            <LottieAnimation :animation-data="gesperrt" class="w-44" />
            <span v-if="istEigentuemer" class="text-center" v-html="infoHtml"></span>
            <span v-else class="text-sm text-center font-bold">
                Kontaktieren Sie den Eigentümer für mehr Informationen.
            </span>
        </div>

        <OnboardingWeiterButton v-if="istEigentuemer" :deaktiviert="false" :laedt="false" text="Abonnement Reaktivieren"
            @click="weiter" />
        <OnboardingWeiterButton v-else :deaktiviert="false" :laedt="false" text="Abmelden" @click="abmelden" />
    </div>
</template>
<script>
import OnboardingTitel from '@/components/OnboardingTitel.vue';
import OnboardingWeiterButton from '@/components/OnboardingWeiterButton.vue';
import { LottieAnimation } from 'lottie-web-vue';
import { eigentuemer } from '@/constants/berechtigungen';
import { berechtigungenPruefen, abmelden } from '@/services/authentifizierungsDienst';
import { computed } from 'vue';
import { ausstehend, beendet, zahlungFehlgeschlagen } from '@/constants/abonnement-status';
import gesperrt from "@/assets/animations/gesperrt.json"
import store from '@/store';
import dayjs from 'dayjs';
export default {
    components: {
        OnboardingTitel,
        OnboardingWeiterButton,
        LottieAnimation
    },
    emits: ["weiter"],
    setup(props, context) {
        const istEigentuemer = computed(() => berechtigungenPruefen([eigentuemer]))

        const zeitpunkt = computed(() => dayjs.unix(store.state.abonnement.status.zeitpunkt).format("DD.MM.YYYY"))

        const infoHtml = computed(() => {
            console.log(store.state.abonnement.status.status);
            switch (store.state.abonnement.status.status) {
                case beendet:
                    return `Ihr Abonnement wurde am <b>${zeitpunkt.value}</b> beendet.<br/><br/>Erneuern Sie es jetzt, um den vollen Zugang zu DentaSnap wiederherzustellen.`
                case zahlungFehlgeschlagen:
                    return `Ihre Zahlung vom <b>${zeitpunkt.value}</b> konnte leider nicht verarbeitet werden oder wurde abgebrochen. Dadurch ist Ihr Abonnement vorübergehend ausgesetzt.<br/><br/>Um den Zugang zu Ihrer Praxis wiederherzustellen, können Sie Ihr Abonnement hier reaktivieren. Wir freuen uns darauf, Sie weiterhin bei uns zu haben.`
                case ausstehend:
                    return "Ihre Praxis ist aktuell in der Registrierung und wird in Kürze für Sie zugänglich sein."
                default:
                    return "Praxis in Testphase, das supporten wir noch net digga"
            }
        })

        const weiter = () => context.emit("weiter")

        return {
            gesperrt,
            istEigentuemer,
            zeitpunkt,
            infoHtml,
            abmelden,
            weiter
        }
    }
}
</script>