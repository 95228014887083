<template>
    <div class="onboarding-komponente h-screen justify-center">
        <OnboardingTitel titel="Zusammenfassung" untertitel="Zu diesen Konditionen führen Sie Ihr Abonnement weiter." />
        <div class="w-full max-h-[70%] flex flex-col overflow-y-auto space-y-6 pr-6">
            <BestellungKosten :zusammenstellung="positionen" :jaehrlich="abonnement.jaehrlich" />
            <BestellungZahlungsMethode :zahlungsmethode="zahlungsMethode" :jaehrlich="abonnement.jaehrlich" />
            <BestellungRechnungsAdresse :adresse="abonnement.adresse" />
        </div>
        <OnboardingWeiterButton :deaktiviert="positionen == null" :laden="zahlungLaedt"
            text="Abonnement kostenpflichtig fortsetzen" @click="zahlen" />
    </div>
</template>
<script>
import OnboardingTitel from '@/components/OnboardingTitel.vue';
import OnboardingWeiterButton from '@/components/OnboardingWeiterButton.vue';
import BestellungKosten from '@/components/bestellung-kosten/BestellungKosten.vue';
import BestellungZahlungsMethode from '@/components/BestellungZahlungsMethode.vue';
import BestellungRechnungsAdresse from '@/components/BestellungRechnungsAdresse.vue';
import { computed, onMounted, ref } from 'vue';
import store from '@/store';
import { useCloudFunction } from '@/services/cloudFunctionDienst';
import { fehlerAnzeigen } from '@/services/toastDient';
export default {
    components: {
        OnboardingTitel,
        OnboardingWeiterButton,
        BestellungKosten,
        BestellungRechnungsAdresse,
        BestellungZahlungsMethode,
    },
    props: ["zahlungsMethode"],
    setup(props, context) {

        const zahlungLaedt = ref(false)

        const abonnement = computed(() => store.state.abonnement)

        const positionen = ref(null)

        const { pending: positionenLaden, error: positionenFehler, result: positionenErgebnis, call: abfragen } = useCloudFunction("bestehendenAbonnementPreisAbfragen")
        const { error: zahlungFehler, result: zahlungErgebnis, call: zahlungAbfragen } = useCloudFunction("abonnementWeiterfuehrenStarten")

        function positionenAbfragen() {
            if (positionen.value == null && positionenLaden.value === false) {
                abfragen()
                    .then(() => {
                        if (positionenFehler.value === false) {
                            positionen.value = positionenErgebnis.value
                        } else {
                            fehlerAnzeigen("Fehler beim Anzeigen der Zusammenfassung.")
                        }
                    })
            }
        }

        /** Beginnt die fortführung des Abonnements */
        function zahlen() {
            if (zahlungLaedt.value === false) {
                zahlungLaedt.value = true
                zahlungAbfragen({ methode: props.zahlungsMethode })
                    .then(() => {
                        if (zahlungFehler.value === false) {
                            window.location.href = zahlungErgebnis.value.weiterleitung
                        } else {
                            fehlerAnzeigen("Fehler beim Starten des Zahlungsvorgangs. Es wurde nichts berechnet.")
                            zahlungLaedt.value = false
                        }
                    })
            }
        }

        onMounted(positionenAbfragen)

        return {
            abonnement,
            positionen,
            zahlungLaedt,
            zahlen
        }
    }
}
</script>