<template>
  <div class="w-full h-full flex flex-col justify-start items-center px-12 pb-12">
    <!-- Titel -->
    <TitelZeile />

    <!-- Berechtigungszeile -->
    <div v-if="konten && konten.length" class="w-full h-full">
      <KontoBerechtigungenZeile v-for="konto in konten" :key="konto.uid" :konto="konto"
        @aenderung="(liste) => aenderung(konto.uid, liste)" />
    </div>

    <!-- "Speichern" Button -->
    <button class="w-40 h-14 rounded-lg flex justify-center items-center" :class="{
      'bg-DentaBlueUltraLight text-DentaBlue transition duration-100 hover:bg-opacity-70': aenderungVorhanden === true && aktualisierungLaed === false,
      ' bg-TextField text-LeichtesGrau': aenderungVorhanden === false,
    }" :deaktiviert="aenderungVorhanden === false || aktualisierungLaed === true" @click="aenderungenSpeichern">
      <p v-if="aktualisierungLaed === false">Speichern</p>
      <LottieAnimation v-else :animation-data="ladenBlau" :loop="true" class="h-14 aspect-square" />
    </button>
  </div>
</template>
  
<script>
//Alle benötigten Vue Komponenten und Frameworks werden importiert
import { computed, ref } from "@vue/runtime-core";
import store from "@/store";

//Import aller benötigten eigenen Komponenten
import PageTitle from "@/components/PageTitle.vue";
import TitelZeile from "./components/TitelZeile.vue";
import KontoBerechtigungenZeile from "./components/KontoBerechtigungenZeile.vue";
import { LottieAnimation } from "lottie-web-vue";

//Import aller benötigten Konstanten und Helferfunktionen
import { useCloudFunction } from "@/services/cloudFunctionDienst";
import ladenBlau from "@/assets/animations/laden-blau.json"
import { erfolgAnzeigen } from "@/services/toastDient";

export default {
  components: {
    PageTitle,
    TitelZeile,
    KontoBerechtigungenZeile,
    LottieAnimation,
  },
  setup(_, __) {
    //Gibt die aktuell gespeicherte Liste von Konten zurück
    const konten = computed(() => store.state.konten.filter(k => k.typ === "nutzer"));

    //Speichert alle akuellen Berechtigungslisten
    const neueBerechtigungen = ref({});

    //Gibt an, ob zwischen den aktuellen und ausgewählten Berechtigungen eine Änderung vorliegt.
    //Nur dann können die Änderungen gespeichert werden.
    const aenderungVorhanden = computed(() => {
      for (let kontoIndex = 0; kontoIndex < konten.value.length; kontoIndex++) {
        const konto = konten.value[kontoIndex];

        if (
          neueBerechtigungen.value[konto.uid] &&
          listenIdentisch(
            konto.berechtigungen,
            neueBerechtigungen.value[konto.uid]
          ) == false
        ) {
          return true;
        }
      }

      return false;
    });

    //Aufrufbare Instanz der "berechtigungenAktualisieren" Cloud Funktion
    const {
      pending: aktualisierungLaed,
      error: aktualisierungFehler,
      call: berechtigungenAktualisieren,
    } = useCloudFunction("berechtigungenAktualisieren");

    //Aufrufbare Instanz der "kontenAbfragen" Cloud Funktion
    const {
      pending: kontenLaden,
      error: kontenFehler,
      result: kontenErgebnis,
      call: kontenAbfragen,
    } = useCloudFunction("kontenAbfragen");

    //Wird aufgerufen wenn eine Änderung in einer Berechtigungsliste vorliegt
    const aenderung = (uid, liste) => {
      if (
        listenIdentisch(
          liste,
          konten.value.find((k) => k.uid == uid).berechtigungen
        ) == false
      ) {
        neueBerechtigungen.value[uid] = liste;
      } else {
        neueBerechtigungen.value[uid] = undefined;
      }
    };

    const aenderungenSpeichern = async () => {
      if (aenderungVorhanden.value == true) {
        //TODO: Erfolgreiche Aktualisierung abwarten
        await berechtigungenAktualisieren(neueBerechtigungen.value);
        if (aktualisierungFehler.value == false) {
          kontenAbfragen().then(() => {
            if (kontenFehler.value == false) {
              store.commit("kontenSetzen", kontenErgebnis.value);
              erfolgAnzeigen("Berechtigungen Gespeichert.")
            }
          });
        }
      }
    };

    //Helfer der prüft, ob alle Einträge in Liste A auch in B vorhanden sind, und andersherum.
    const listenIdentisch = (array1, array2) => {
      if (array1.length === array2.length) {
        return array1.every((element, index) => {
          if (element === array2[index]) {
            return true;
          }

          return false;
        });
      }

      return false;
    };

    return {
      //State für das UI
      konten,
      aenderungVorhanden,
      aktualisierungLaed,
      kontenLaden,
      ladenBlau,

      //Funktionen für das UI
      aenderung,
      aenderungenSpeichern,
    };
  },
};
</script>